import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { FaLock } from "react-icons/fa";
import MyInputPassword from "../../utils/Form/MyInputPassword";
import ErrorContainer from "../../layout/ErrorContainer";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

interface Props {
	token?: string;
	title: string;
}

interface FormValues {
	password: string;
	confirmPassword: string;
}

const NewPassword = ({ token, title }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const methods = useForm<FormValues>();
	const { handleSubmit } = methods;
	const [httpResponse, setHttpResponse] = useState<{ error: boolean; msg: string | null } | null>(null);
	const [passwordsAreEqual, setPasswordsAreEqual] = useState(true);

	const onSubmit = (values: FormValues): void => {
		setPasswordsAreEqual(true);
		if (values.password === values.confirmPassword) {
			const data = {
				token: token,
				password: values.password,
				confirm_password: values.confirmPassword
			};
			http
				.post(`${url.BASEURL}/lost_password/recovery`, data)
				.then(() => setHttpResponse({ error: false, msg: null }))
				.catch(() => setHttpResponse({ error: true, msg: null }));
		} else {
			setPasswordsAreEqual(false);
		}
	};

	return (
		<FormProvider {...methods}>
			<h2 className="center mt-4">{title}</h2>
			<ErrorContainer
				alert={<Link to="/">{t("users.__newPasswordError__")}</Link>}
				success={t("users.__newPasswordSaved__")}
				httpResponse={httpResponse}
				setHttpResponse={setHttpResponse}
			/>

			{httpResponse ? (
				!httpResponse.error && (
					<div className="center mt-5">
						<Link to="/">
							<button type="button" className="btn btn-primary">
								{t("users.__loginBtn__")}
							</button>
						</Link>
					</div>
				)
			) : (
				<>
					<div className="alert alert-warning mb-4">
						<FaLock /> <b>{t("users.__securityPasswordWarning__")}</b>
						<br />
						<p className="mt-2">{t("users.__passwordWarning__")}</p>
					</div>
					<form onSubmit={handleSubmit(onSubmit)}>
						<MyInputPassword
							label={t("common.__password__")}
							name="password"
							behavior={{
								required: true,
								pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[²€£µ<>^!@=#$%^&*()`§_+,.\\/;':"-]).{8,}$/
							}}
						/>
						<br />
						<MyInputPassword label={t("common.__confirmPassword__")} name="confirmPassword" behavior={{ required: true }} />
						{!passwordsAreEqual ? <p className="error-msg mt-4">{t("login.__passwordVerification__")}</p> : null}
						<br />
						<button type="submit" className="btn btn-primary mt-2 center">
							{t("common.__validate__")}
						</button>
					</form>
				</>
			)}
		</FormProvider>
	);
};

export default NewPassword;
