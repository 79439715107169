import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

// internationalisation
import { I18nextProvider } from "react-i18next";
import i18next from "./i18n/i18next";

// CSS
import "./index.css";
import "./custom.scss";
import "./App/App.scss";
import "./fonts/NotoSans/NotoSans-Bold.ttf";
import "./fonts/NotoSans/NotoSans-Regular.ttf";
import "./fonts/NotoSans/NotoSans-Medium.ttf";

// APP COMPONENT
import App from "./App/App";

ReactDOM.render(
	<React.StrictMode>
		<I18nextProvider i18next={i18next}>
			<App />
		</I18nextProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

serviceWorker.unregister();
