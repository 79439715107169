import React from "react";
import { useTranslation } from "react-i18next";
import { FaRegSun } from "react-icons/fa";
import { Link } from "react-router-dom";
import { HealthWorkerInterface } from "../../interfaces/UserInterface";
import { Tooltip } from "./Tooltip";

interface Props {
	canManagePermissions: boolean;
	user: HealthWorkerInterface;
}

const GoToPermissionIcon = ({ canManagePermissions, user }: Props): JSX.Element | null => {
	const { t } = useTranslation("common");

	return canManagePermissions ? (
		user.permissions.includes(-1) || user.permissions.includes(-10) ? null : (
			<Tooltip text={t("permissions.__managePermissions__")}>
				<Link to={{ pathname: `/user/hwpermissions/${user.id}`, state: { user: user } }}>
					<FaRegSun className="table-icon" role="button" aria-label="go-to-permissions-page" />
				</Link>
			</Tooltip>
		)
	) : null;
};
export default GoToPermissionIcon;
