import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaAngleDoubleRight, FaTimes } from "react-icons/fa";
import { PhotoFramePreferenceInterface } from "./PreferencesReducer";
import { SeniorInterface } from "../../../interfaces/UserInterface";
import OnlyTimePicker from "../../../utils/DatePicker/OnlyTimePicker";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";

interface Props {
	setAddPreference: (args: { show: boolean; info: null; title: string }) => void;
	preferences: PhotoFramePreferenceInterface;
	dispatch: React.Dispatch<any>;
	homeId: number;
	senior: SeniorInterface | null;
	userLocale: string;
}

const AddPhotoFramePreferences = ({
	setAddPreference,
	preferences,
	dispatch,
	homeId,
	senior,
	userLocale
}: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const [error, setError] = useState(false);
	const [startTime, setStartTime] = useState(new Date());
	const [endTime, setEndTime] = useState(new Date());

	const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		setError(false);
		const data = senior
			? {
					config: {
						PHOTO_FRAME_ACTIVATION_RANGES: [
							...preferences.seniorSelected.photoframe,
							{
								end: [endTime.getHours(), endTime.getMinutes()],
								start: [startTime.getHours(), startTime.getMinutes()]
							}
						]
					}
			  }
			: {
					config: {
						PHOTO_FRAME_ACTIVATION_RANGES: [
							...preferences.home.photoframe,
							{
								end: [endTime.getHours(), endTime.getMinutes()],
								start: [startTime.getHours(), startTime.getMinutes()]
							}
						]
					}
			  };
		const addUrl = senior ? `${url.SENIORSURL}/${senior.id}/device_config` : `${url.HOMESURL}/${homeId}/device_config`;
		http
			.put(addUrl, data)
			.then(res => {
				if (senior) {
					dispatch({
						type: "ADD_SENIOR_PHOTOFRAME_PREFERENCE",
						payload: res.data.PHOTO_FRAME_ACTIVATION_RANGES
					});
				} else {
					dispatch({
						type: "ADD_HOME_PHOTOFRAME_PREFERENCE",
						payload: res.data.PHOTO_FRAME_ACTIVATION_RANGES
					});
				}
				setAddPreference({ show: false, info: null, title: t("device.__addPhotoframeTiming__") });
			})
			.catch(() => {
				setError(true);
			});
	};

	return (
		<>
			{senior ? (
				<p className="mt-4 mb-3 blue">
					<FaAngleDoubleRight className="mr-2" />
					<b>{senior.name}</b>
				</p>
			) : null}

			<form onSubmit={onSubmit}>
				{error ? (
					<p className="error-msg mt-2">
						<span className="right" role="button" onClick={(): void => setError(false)}>
							<FaTimes />
						</span>
						<b>{t("common.__errorOccured__")}</b>
						<br />
						{t("device.__photoFrameOverlap__")}
					</p>
				) : null}
				<div className="row mt-4">
					<div className="col-6">
						<label>{t("common.__begining__")} : </label>
						<OnlyTimePicker startTime={startTime} setStartTime={setStartTime} userLocale={userLocale} />
					</div>
					<div className="col-6">
						<label>{t("common.__to__")} : </label>
						<OnlyTimePicker startTime={endTime} setStartTime={setEndTime} userLocale={userLocale} />
					</div>
				</div>
				<div className="mt-4 mb-5">
					<button type="submit" className="btn btn-sm btn-primary">
						{t("common.__save__")}
					</button>
				</div>
			</form>
		</>
	);
};
export default AddPhotoFramePreferences;
