import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table, SelectColumnFilter } from "../../../utils/Table/TableWithFilter";
import { FaReply, FaAngleRight, FaCheck } from "react-icons/fa";
import AnswerMessage from "./AnswerMessage";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import * as p from "../../../constants/Permissions";
import * as types from "../../../constants/TypesConstants";
import { handleDate } from "@e4ia/e4link_modules.datetime";

export default function HandleMessages({ data, userState, homeId }) {
	const { t } = useTranslation("common");
	const [handleResponse, setHandleResponse] = useState(false);
	const [msgData, setMsgData] = useState(null);
	const canManageMessages = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.ALL_FAMILY_USER_MESSAGES]);

	const messages = data.map(function (msg) {
		return {
			...msg,
			family: `${msg.family_user.first_name} ${msg.family_user.last_name}`,
			messageSearch: msg.message + " " + msg.subject + " " + msg.user.first_name + " " + msg.user.last_name,
			seniorName: msg.senior ? `${msg.senior.last_name.toUpperCase()} ${msg.senior.first_name}` : null
		};
	});

	const answerMessage = (id, family, senior) => {
		if (canManageMessages) {
			setMsgData({ id, family, senior });
			setHandleResponse(true);
		}
	};

	const checkMessage = id => {
		if (id) {
			http.get(`${url.HOMESURL}/controlpanel/messages/${id}/check`);
		}
	};

	const columns = useMemo(
		() => [
			{
				Header: t("mailbox.__from__"),
				accessor: "family",
				Filter: SelectColumnFilter,
				width: 75,
				Cell: row => (
					<>
						<p>
							<b>{row.row.values.family}</b>
						</p>
						<span className="meta">
							{t("common.__senior__")} :
							<br />
							{row.row.original.seniorName}
						</span>
					</>
				)
			},
			{
				Header: t("common.__messages__"),
				accessor: "messageSearch",
				Cell: row => {
					return (
						<div className="mb-3">
							<p className="meta">
								{handleDate(row.row.original.updated_at, "Pp", userState.userConnected.locale)}
								<span className="mr-2 ml-2">-</span>
								{t("common.__begining__")} : {row.row.original.user.first_name} {row.row.original.user.last_name}
							</p>
							<div className="mailbox mt-2">
								{types.handleContent(
									row.row.original.type,
									row.row.original.subject,
									row.row.original.message,
									row.row.original.visio_datetime,
									row.row.original.id,
									row.row.original.unread,
									userState,
									true
								)}
							</div>
						</div>
					);
				}
			},
			{
				Header: "",
				accessor: "id",
				disableFilters: true,
				disableSortBy: true,
				width: 75,
				Cell: row => {
					return canManageMessages ? (
						<div className="right mr-2">
							<span
								role="button"
								className="category-badge blue"
								onClick={() => answerMessage(row.row.values.id, row.row.original.family, row.row.original.seniorName)}
							>
								<FaReply className="mr-2" />
								{t("mailbox.__answer__")}
							</span>
							<span role="button" className="category-outline-badge blue" onClick={() => checkMessage(row.row.values.id)}>
								<FaCheck className="mr-2" />
								{t("rules.__check__")}
							</span>
						</div>
					) : null;
				}
			}
		],
		[]
	);

	return (
		<div className="big-card">
			<div className="row">
				<div className="col-6">
					<h2>{t("common.__messages__")}</h2>
				</div>
				<div className="col-6">
					{handleResponse ? (
						<button type="button" className="btn btn-sm btn-outline-primary right" onClick={() => setHandleResponse(false)}>
							{t("common.__back__")}
						</button>
					) : null}
				</div>
				{canManageMessages ? null : (
					<div className="col-12">
						<div className="error-msg">
							<b>{t("common.__permissionError__")}</b>
						</div>
						<div className="space"></div>
					</div>
				)}
			</div>
			{handleResponse ? (
				<AnswerMessage msgData={msgData} homeId={homeId} userState={userState} setHandleResponse={setHandleResponse} />
			) : data.length === 0 ? (
				<h5>
					<FaAngleRight className="mr-2" />
					{t("mailbox.__noMsg__")}
				</h5>
			) : (
				<Table columns={columns} data={messages} />
			)}
		</div>
	);
}
