import React from "react";
import { useTranslation } from "react-i18next";
import { FaRegFilePdf, FaTimes } from "react-icons/fa";
import { fileSize } from "../../../utils/Blogging";

export default function PdfsManager({ setUploadSize, medias, setMedias, initialMedias, setInitialMedias }) {
	const { t } = useTranslation("common");
	const pdfFiles = medias.filter(media => media.type.split("/")[0] === "application");
	const existingPdfFiles =
		initialMedias.length > 0 ? initialMedias.filter(media => media.content_type.split("/")[0] === "application") : [];

	const removePdfFile = (name, size) => {
		const newFileList = medias.filter(f => f.name !== name);
		setMedias(newFileList);
		setUploadSize(uploadSize => uploadSize - size);
	};

	const removeInitialPdfFiles = (path, size) => {
		const newInitialMediasList = initialMedias.filter(f => f.path !== path);
		setInitialMedias(newInitialMediasList);
		setUploadSize(uploadSize => uploadSize - size);
	};

	return (
		<>
			{existingPdfFiles && existingPdfFiles.length > 0
				? existingPdfFiles.map(pdf => (
						<div key={pdf.path} className="header-details">
							<div className="d-flex justify-content-between">
								<a href={`/storages/blog_media${pdf.path}`} download={`${pdf.filename}`}>
									<FaRegFilePdf className="mr-3 ml-2 red" style={{ width: "21px", height: "auto" }} />
									<b>
										{t("common.__pdfFile__")}
										{pdf.filename ? `: ${pdf.filename}` : null}
									</b>
								</a>
								<button
									type="button"
									className="post-suppr-btn"
									onClick={() => removeInitialPdfFiles(pdf.path, pdf.content_length)}
								>
									<FaTimes style={{ marginLeft: "-3px", marginTop: "-3px" }} />
								</button>
							</div>
						</div>
				  ))
				: null}
			{pdfFiles.length > 0 &&
				pdfFiles.map((pdf, index) => (
					<div key={index} className="header-details">
						<button type="button" className="post-suppr-btn right" onClick={() => removePdfFile(pdf.name, pdf.size)}>
							<FaTimes style={{ marginLeft: "-3px", marginTop: "-3px" }} />
						</button>
						<FaRegFilePdf className="mr-3 ml-2 red" style={{ width: "21px", height: "auto" }} />
						<b>{pdf.name}</b> - {fileSize(pdf.size)}
					</div>
				))}
		</>
	);
}
