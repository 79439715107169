const DisplayName = (id: number, array: any[]): string | null => {
	const data = array.find(data => data.id === id);
	if (data) {
		return data.name;
	} else {
		return null;
	}
};

export default DisplayName;
