import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaBell, FaRegEnvelope, FaVideo } from "react-icons/fa";
import "./ControlPanel.scss";

export default function ControlPanelCounters({
	messages,
	visioRequests,
	isDiaperManager,
	diapersAlertCounter,
	isMessageManager
}) {
	const { t } = useTranslation("common");

	return (
		<div className="d-flex justify-content-between">
			{isMessageManager ? (
				<>
					<Link to="/controlpanel/newmsg" className="flex-fill">
						<div className={`panel-gradient-card ${messages.length > 0 ? "blue" : "grey"} mt-2`}>
							<FaRegEnvelope className="panel-icon mr-3" />
							{messages.length > 0 ? (
								<>
									<span className="panel-number mr-2">{messages.length}</span>
									<span className="panel-meta">
										{messages.length === 1 ? <>{t("mailbox.__newMessage__")}</> : <>{t("mailbox.__newMessages__")}</>}
									</span>
								</>
							) : (
								<span className="meta" style={{ lineHeight: "2.25rem" }}>
									<>{t("mailbox.__noNewMessage__")}</>
								</span>
							)}
						</div>
					</Link>
					<Link to="/controlpanel/visiorequest" className="flex-fill ml-2">
						<div className={`panel-gradient-card ${visioRequests.length > 0 ? "blue" : "grey"} mt-2`}>
							<FaVideo className="panel-icon mr-3" />
							{visioRequests.length > 0 ? (
								<>
									<span className="panel-number mr-2">{visioRequests.length}</span>
									<span className="panel-meta">
										{visioRequests.length === 1 ? t("mailbox.__visioRequest__") : t("mailbox.__visioRequests__")}
									</span>
								</>
							) : (
								<span className="meta" style={{ lineHeight: "2.25rem" }}>
									<>{t("mailbox.__noNewVisioRequest__")}</>
								</span>
							)}
						</div>
					</Link>
				</>
			) : null}
			{!isMessageManager && isDiaperManager ? null : (
				<Link to="/controlpanel/diaperalerts" className="flex-fill ml-2">
					<div className={`panel-gradient-card ${diapersAlertCounter > 0 ? "red" : "grey"} mt-2`}>
						<FaBell className="panel-icon mr-3" />
						<span className="panel-number mr-2">{diapersAlertCounter}</span>
						<span className="panel-meta">{t("diaper.__saturationAlert__")}</span>
					</div>
				</Link>
			)}
		</div>
	);
}
