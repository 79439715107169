import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DiaperContext } from "../context/DiaperContext";
import { UserInterface } from "../interfaces/UserInterface";
import useDateBeforeOrAfter from "../hooks/useDateBeforeOrAfter";
import useStoreData from "../hooks/useStoreData";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import AccessControl from "../layout/AccessControl";
import AdminSecondaryNav from "../layout/AdminSecondaryNav";
import SelectHomeInput from "../layout/SelectHomeInput";
import SaturationDebug from "../components/Admin/Diapers/SaturationDebug";
import ChangeDebug from "../components/Admin/Diapers/ChangeDebug";
import TabDiapers from "../components/Admin/Diapers/TabDiapers";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import * as p from "../constants/Permissions";

interface Props {
	userState: UserInterface;
	homeId: number | null;
}

const AdminDebugDiapers = ({ userState, homeId }: Props): JSX.Element => {
	const { component } = useParams<{ component: string }>();
	const { store } = useStoreData({ userState, homeId });
	const { diaperStore, diaperDispatch } = useContext(DiaperContext);
	const saturationMode = component === "saturation" ? true : false;
	const seniorsFromHome = store.seniors.seniorsFromHome;
	const today = new Date();
	const yesterday = useDateBeforeOrAfter({ isBefore: true, numberOfDays: 1 });
	const [startDate, setStartDate] = useState(yesterday);
	const [endDate, setEndDate] = useState(today);

	useEffect(() => {
		if (homeId) {
			http.get(`${url.HOMESURL}/${homeId}/rht/diaper_sensors`).then(res => {
				diaperDispatch({
					type: "GET_SENSORS",
					payload: res.data.diaper_sensors
				});
			});
		}
		// eslint-disable-next-line
	}, [homeId]);

	useEffect(() => {
		if (diaperStore.diapers.length === 0) {
			http.get(`${url.BASEURL}/rht/diapers`).then(res => {
				diaperDispatch({
					type: "GET_DIAPERS",
					payload: res.data.diapers
				});
			});
		}
		// eslint-disable-next-line
	}, [diaperStore.diapers.length]);

	return (
		<AccessControl userPermissions={userState.userConnected.permissions} permissionNeeded={[p.ADMIN, p.SUPER_ADMIN]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<AdminSecondaryNav userState={userState} />}
				component={
					!homeId ? (
						<SelectHomeInput />
					) : (
						<TabDiapers
							saturationMode={saturationMode}
							userState={userState}
							startDate={startDate}
							setStartDate={setStartDate}
							endDate={endDate}
							setEndDate={setEndDate}
							component={
								saturationMode ? (
									<SaturationDebug
										homeId={homeId}
										seniorsFromHome={seniorsFromHome}
										diapers={diaperStore.diapers}
										sensors={diaperStore.sensors}
										startDate={startDate}
										endDate={endDate}
									/>
								) : (
									<ChangeDebug
										homeId={homeId}
										seniorsFromHome={seniorsFromHome}
										diapers={diaperStore.diapers}
										startDate={startDate}
										endDate={endDate}
										userState={userState}
									/>
								)
							}
						/>
					)
				}
			/>
		</AccessControl>
	);
};
export default AdminDebugDiapers;
