import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { handleIllustration, handleModelIcon } from "../Illustrations";
import HandleError from "../../../layout/HandleError";

export default function HomeDiapersList({ error, setError, homeDiapersList, homeDiapersToDisplay }) {
	const { t } = useTranslation("common");

	return (
		<div className="big-card">
			<div className="row">
				<div className="col-6">
					<h2>{t("diaper.__referencedDiapers__")}</h2>
				</div>
				<div className="col-6">
					{homeDiapersList.length > 0 ? (
						<div className="right mt-1">
							<Link to="/inco/diaper/updatehomediapers">
								<button type="button" className="btn btn-sm btn-outline-primary">
									{t("common.__update__")}
								</button>
							</Link>
						</div>
					) : null}
				</div>
			</div>
			<div className="header-details">
				<p>{t("diaper.__homeDiapersList__")}</p>
			</div>
			<HandleError error={error} setError={setError} />

			{homeDiapersToDisplay.length > 0 ? (
				homeDiapersToDisplay.map(diaper => (
					<div key={diaper.id} className="diapers-list">
						<div className="d-flex justify-content-between">
							<div className="mt-1">
								<p>
									<b>{diaper.name}</b>
									<br />
									{handleModelIcon(diaper)}
								</p>
							</div>
							<div>{handleIllustration(diaper.brand, diaper.line, diaper.ref, true)}</div>
						</div>
					</div>
				))
			) : (
				<Link to="/inco/diaper/updatehomediapers">
					<button type="button" className="btn btn-sm btn-primary">
						{t("diaper.__addReferencedDiapers__")}
					</button>
				</Link>
			)}
		</div>
	);
}
