import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import { useTranslation } from "react-i18next";
import { FaRegEnvelope, FaTimes } from "react-icons/fa";
import HandleRecipientsContainer from "./HandleRecipientsContainer";

const HandleMsgRecipients = ({ homeId, users, type }) => {
	const { t } = useTranslation("common");
	const activeUsers = users.filter(user => user.active);
	const [error, setError] = useState(false);
	const history = useHistory();
	const [msgRecipients, setMsgRecipients] = useState([]);
	const recipientsIds = msgRecipients.map(user => user.id);
	const recipientsAvailable = activeUsers.filter(user => !recipientsIds.includes(user.id));

	const usersToSelectForMsg = recipientsAvailable.map(user => {
		return {
			...user,
			label: user.name,
			value: user.id
		};
	});

	useEffect(() => {
		if (homeId) {
			http
				.get(`${url.HOMESURL}/${homeId}/message/recipients`)
				.then(res => setMsgRecipients(res.data.user_recipients))
				.catch(() => setMsgRecipients([]));
		}
	}, [homeId]);

	return (
		<div className="big-card">
			<div className="row">
				<div className="col-md-6">
					<h2>{t("common.__recipients__")}</h2>
				</div>
				<div className="col-md-6">
					<button className="btn btn-sm btn-outline-secondary right" type="button" onClick={() => history.goBack()}>
						{t("common.__back__")}
					</button>
				</div>
			</div>
			{error ? (
				<p className="error-msg mt-2">
					<span className="right" role="button" tabIndex="0" onClick={() => setError(false)}>
						<FaTimes />
					</span>
					<b>{t("common.__errorOccured__")}</b>
				</p>
			) : (
				<br />
			)}

			<h3>
				<FaRegEnvelope className="mr-2" />
				{t("common.__messageRecipients__")} :
			</h3>
			<HandleRecipientsContainer
				actualRecipients={msgRecipients}
				recipientsToSelect={usersToSelectForMsg}
				homeId={homeId}
				setError={setError}
				type={type}
			/>
		</div>
	);
};

export default HandleMsgRecipients;
