import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaAngleDoubleRight } from "react-icons/fa";
import Select from "react-select";
import OnlyTimePicker from "../../../utils/DatePicker/OnlyTimePicker";
import OnlyDatePicker from "../../../utils/DatePicker/OnlyDatePicker";
import ChangeStatisticsBarChart from "./ChangeStatisticsBarChart";
import ChangeStatisticsMultiBarChart from "./ChangeStatisticsMultiBarChart";
import DirtyBedStatistics from "./DirtyBedStatistics";
import ChangeCounters from "./ChangeCounters";
import DisplayPieChart from "./DisplayPieChart";
import Loader from "../../../layout/Loader";

export default function ChangeStatistics({
	userState,
	changeStatisticStore,
	changeStatisticDispatch,
	seniorsFromHome,
	homeId
}) {
	const { t } = useTranslation("common");
	const userLocale = userState.userConnected.locale;
	const [barChartStats, setBarChartStats] = useState([]);
	const [multiBarChartStats, setMultiBarChartStats] = useState([]);
	const [dirtyBed, setDirtyBed] = useState({ display: true, data: [] });
	const [diaperSaturated, setDiaperSaturated] = useState({ display: true, data: [] });
	const [presenceOfRedness, setPresenceOfRedness] = useState({ display: false, data: [] });
	const [presenceOfStools, setPresenceOfStools] = useState({ display: false, data: [] });
	const [drawSheetChanged, setDrawSheetChanged] = useState({ display: false, data: [] });
	const [tornOff, setTornOff] = useState({ display: false, data: [] });
	const [bringToWc, setBringToWc] = useState({ display: false, data: [] });

	useEffect(() => {
		if (changeStatisticStore.data && "dirty_bed" in changeStatisticStore.data) {
			const data = [
				{
					name: t("diaper.__dirtyBed__"),
					half: 0,
					yes: changeStatisticStore.data.dirty_bed.percentages.yes,
					no: changeStatisticStore.data.dirty_bed.percentages.no
					// noAnswer: statistics.dirty_bed.percentages.undefined
				}
			];
			if (diaperSaturated.display) {
				const saturationData = {
					name: t("diaper.__diaperSaturated__"),
					half: changeStatisticStore.data.diaper_saturated.percentages.half,
					yes: changeStatisticStore.data.diaper_saturated.percentages.yes,
					no: changeStatisticStore.data.diaper_saturated.percentages.no
					// noAnswer: statistics.diaper_saturated.percentages.undefined
				};
				data.push(saturationData);
			}
			if (tornOff.display) {
				const tornOffData = {
					name: t("diaper.__diaperTornOff__"),
					half: 0,
					yes: changeStatisticStore.data.torn_off.percentages.yes,
					no: changeStatisticStore.data.torn_off.percentages.no
					// noAnswer: statistics.torn_off.percentages.undefined
				};
				data.push(tornOffData);
			}
			if (presenceOfRedness.display) {
				const rednessData = {
					name: t("diaper.__presenceOfRedness__"),
					half: 0,
					yes: changeStatisticStore.data.presence_of_redness.percentages.yes,
					no: changeStatisticStore.data.presence_of_redness.percentages.no
					// noAnswer: statistics.presence_of_redness.percentages.undefined
				};
				data.push(rednessData);
			}
			if (presenceOfStools.display) {
				const stoolsData = {
					name: t("diaper.__presenceOfStools__"),
					half: 0,
					yes: changeStatisticStore.data.presence_of_stools.percentages.yes,
					no: changeStatisticStore.data.presence_of_stools.percentages.no
					// noAnswer: statistics.presence_of_stools.percentages.undefined
				};
				data.push(stoolsData);
			}
			if (drawSheetChanged.display) {
				const drawSheetData = {
					name: t("diaper.__drawSheetChanged__"),
					half: 0,
					yes: changeStatisticStore.data.draw_sheet_changed.percentages.yes,
					no: changeStatisticStore.data.draw_sheet_changed.percentages.no
					// noAnswer: statistics.draw_sheet_changed.percentages.undefined
				};
				data.push(drawSheetData);
			}
			if (bringToWc.display) {
				const bringToWc = {
					name: t("diaper.__bringToWc__"),
					half: 0,
					yes: changeStatisticStore.data.bring_to_wc.percentages.yes,
					no: changeStatisticStore.data.bring_to_wc.percentages.no
				};
				data.push(bringToWc);
			}
			setBarChartStats(data);
		}
		// eslint-disable-next-line
	}, [
		changeStatisticStore.data,
		dirtyBed.display,
		tornOff.display,
		presenceOfRedness.display,
		presenceOfStools.display,
		drawSheetChanged.display,
		bringToWc.display
	]);

	useEffect(() => {
		if (changeStatisticStore.data && "dirty_bed" in changeStatisticStore.data) {
			const data = [
				{
					name: t("common.__yes__"),
					dirtyBed: changeStatisticStore.data.dirty_bed.percentages.yes,
					diaperSaturated: changeStatisticStore.data.diaper_saturated.percentages.yes,
					presenceOfRedness: changeStatisticStore.data.presence_of_redness.percentages.yes,
					presenceOfStools: changeStatisticStore.data.presence_of_stools.percentages.yes,
					tornOff: changeStatisticStore.data.torn_off.percentages.yes,
					drawSheetChanged: changeStatisticStore.data.draw_sheet_changed.percentages.yes,
					bringToWc: changeStatisticStore.data.bring_to_wc.percentages.yes
				},
				{
					name: t("common.__no__"),
					dirtyBed: changeStatisticStore.data.dirty_bed.percentages.no,
					diaperSaturated: changeStatisticStore.data.diaper_saturated.percentages.no,
					presenceOfRedness: changeStatisticStore.data.presence_of_redness.percentages.no,
					presenceOfStools: changeStatisticStore.data.presence_of_stools.percentages.no,
					tornOff: changeStatisticStore.data.torn_off.percentages.no,
					drawSheetChanged: changeStatisticStore.data.draw_sheet_changed.percentages.no,
					bringToWc: changeStatisticStore.data.bring_to_wc.percentages.no
				},
				{
					name: t("diaper.__half__"),
					dirtyBed: changeStatisticStore.data.dirty_bed.percentages.half,
					diaperSaturated: changeStatisticStore.data.diaper_saturated.percentages.half,
					presenceOfRedness: changeStatisticStore.data.presence_of_redness.percentages.half,
					presenceOfStools: changeStatisticStore.data.presence_of_stools.percentages.half,
					tornOff: changeStatisticStore.data.torn_off.percentages.half,
					drawSheetChanged: changeStatisticStore.data.draw_sheet_changed.percentages.half,
					bringToWc: changeStatisticStore.data.bring_to_wc.percentages.half
				}
			];
			setMultiBarChartStats(data);
		}
		// eslint-disable-next-line
	}, [changeStatisticStore.data]);

	useEffect(() => {
		if (changeStatisticStore.data && "dirty_bed" in changeStatisticStore.data) {
			const data = [
				{
					name: t("common.__yes__"),
					value: changeStatisticStore.data.dirty_bed.percentages.yes
				},
				{
					name: t("common.__no__"),
					value: changeStatisticStore.data.dirty_bed.percentages.no
				}
			];
			setDirtyBed({ ...dirtyBed, data: data });
		}
		// eslint-disable-next-line
	}, [changeStatisticStore.data]);

	useEffect(() => {
		if (changeStatisticStore.data && "draw_sheet_changed" in changeStatisticStore.data) {
			const data = [
				{
					name: t("common.__yes__"),
					value: changeStatisticStore.data.draw_sheet_changed.percentages.yes
				},
				{
					name: t("common.__no__"),
					value: changeStatisticStore.data.draw_sheet_changed.percentages.no
				}
			];
			setDrawSheetChanged({ ...drawSheetChanged, data: data });
		}
		// eslint-disable-next-line
	}, [changeStatisticStore.data]);

	useEffect(() => {
		if (changeStatisticStore.data && "torn_off" in changeStatisticStore.data) {
			const data = [
				{
					name: t("common.__yes__"),
					value: changeStatisticStore.data.draw_sheet_changed.percentages.yes
				},
				{
					name: t("common.__no__"),
					value: changeStatisticStore.data.draw_sheet_changed.percentages.no
				}
			];
			setTornOff({ ...tornOff, data: data });
		}
		// eslint-disable-next-line
	}, [changeStatisticStore.data]);

	useEffect(() => {
		if (changeStatisticStore.data && "presence_of_redness" in changeStatisticStore.data) {
			const data = [
				{
					name: t("common.__yes__"),
					value: changeStatisticStore.data.presence_of_redness.percentages.yes
				},
				{
					name: t("common.__no__"),
					value: changeStatisticStore.data.presence_of_redness.percentages.no
				}
			];
			setPresenceOfRedness({ ...presenceOfRedness, data: data });
		}
		// eslint-disable-next-line
	}, [changeStatisticStore.data]);

	useEffect(() => {
		if (changeStatisticStore.data && "presence_of_stools" in changeStatisticStore.data) {
			const data = [
				{
					name: t("common.__yes__"),
					value: changeStatisticStore.data.presence_of_stools.percentages.yes
				},
				{
					name: t("common.__no__"),
					value: changeStatisticStore.data.presence_of_stools.percentages.no
				}
			];
			setPresenceOfStools({ ...presenceOfStools, data: data });
		}
		// eslint-disable-next-line
	}, [changeStatisticStore.data]);

	useEffect(() => {
		if (changeStatisticStore.data && "diaper_saturated" in changeStatisticStore.data && diaperSaturated) {
			const data = [
				{
					name: t("common.__yes__"),
					value: changeStatisticStore.data.diaper_saturated.percentages.yes
				},
				{
					name: t("common.__no__"),
					value: changeStatisticStore.data.diaper_saturated.percentages.no
				},
				{
					name: t("diaper.__half__"),
					value: changeStatisticStore.data.diaper_saturated.percentages.half
				}
			];
			setDiaperSaturated({ ...diaperSaturated, data: data });
		}
		// eslint-disable-next-line
	}, [changeStatisticStore.data]);

	useEffect(() => {
		if (changeStatisticStore.data && "bring_to_wc" in changeStatisticStore.data) {
			const data = [
				{
					name: t("common.__yes__"),
					value: changeStatisticStore.data.bring_to_wc.percentages.yes
				},
				{
					name: t("common.__no__"),
					value: changeStatisticStore.data.bring_to_wc.percentages.no
				}
			];
			setBringToWc({ ...bringToWc, data: data });
		}
		// eslint-disable-next-line
	}, [changeStatisticStore.data]);

	return (
		<>
			<div className="big-card dont-print-this mb-2">
				<h2>{t("diaper.__changeMonitoring__")}</h2>

				<div className="row mt-4">
					<div className="col-sm-2">
						<p>
							<b>{t("common.__dates__")} : </b>
						</p>
					</div>
					<div className="col-sm-5">
						<OnlyDatePicker
							startDate={changeStatisticStore.startDate}
							dispatch={changeStatisticDispatch}
							type="START"
							userLocale={userLocale}
						/>
					</div>
					<div className="col-sm-5">
						<OnlyTimePicker
							startTime={changeStatisticStore.startTime}
							dispatch={changeStatisticDispatch}
							type="START"
							userLocale={userLocale}
						/>
					</div>
					<div className="col-sm-2">
						<p>{t("common.__toward__")}</p>
					</div>
					<div className="col-sm-5">
						<OnlyDatePicker
							startDate={changeStatisticStore.endDate}
							dispatch={changeStatisticDispatch}
							type="END"
							userLocale={userLocale}
						/>
					</div>
					<div className="col-sm-5">
						<OnlyTimePicker
							startTime={changeStatisticStore.endTime}
							dispatch={changeStatisticDispatch}
							type="END"
							userLocale={userLocale}
						/>
					</div>
					<div className="col-12 mt-2">
						<p className="meta">{t("common.__selectInsight__")} :</p>
					</div>

					<div className="col-sm-4">
						<input
							type="checkbox"
							checked={diaperSaturated.display}
							onChange={() =>
								diaperSaturated.display
									? setDiaperSaturated({ ...diaperSaturated, display: false })
									: setDiaperSaturated({ ...diaperSaturated, display: true })
							}
						/>
						<span className="ml-2">
							<b>{t("diaper.__diaperSaturated__")}</b>
						</span>
					</div>
					<div className="col-sm-4">
						<input
							type="checkbox"
							checked={dirtyBed.display}
							onChange={() =>
								dirtyBed.display ? setDirtyBed({ ...dirtyBed, display: false }) : setDirtyBed({ ...dirtyBed, display: true })
							}
						/>
						<span className="ml-2">
							<b>{t("diaper.__dirtyBed__")}</b>
						</span>
					</div>
					<div className="col-sm-4">
						<input
							type="checkbox"
							checked={tornOff.display}
							onChange={() =>
								tornOff.display ? setTornOff({ ...tornOff, display: false }) : setTornOff({ ...tornOff, display: true })
							}
						/>
						<span className="ml-2">
							<b>{t("diaper.__diaperTornOff__")}</b>
						</span>
					</div>
					<div className="col-sm-4">
						<input
							type="checkbox"
							checked={drawSheetChanged.display}
							onChange={() =>
								drawSheetChanged.display
									? setDrawSheetChanged({ ...drawSheetChanged, display: false })
									: setDrawSheetChanged({ ...drawSheetChanged, display: true })
							}
						/>
						<span className="ml-2">
							<b>{t("diaper.__drawSheetChanged__")}</b>
						</span>
					</div>
					<div className="col-sm-4">
						<input
							type="checkbox"
							checked={presenceOfRedness.display}
							onChange={() =>
								presenceOfRedness.display
									? setPresenceOfRedness({ ...presenceOfRedness, display: false })
									: setPresenceOfRedness({ ...presenceOfRedness, display: true })
							}
						/>
						<span className="ml-2">
							<b>{t("diaper.__presenceOfRedness__")}</b>
						</span>
					</div>

					<div className="col-sm-4">
						<input
							type="checkbox"
							checked={presenceOfStools.display}
							onChange={() =>
								presenceOfStools.display
									? setPresenceOfStools({ ...presenceOfStools, display: false })
									: setPresenceOfStools({ ...presenceOfStools, display: true })
							}
						/>
						<span className="ml-2">
							<b>{t("diaper.__presenceOfStools__")}</b>
						</span>
					</div>
					<div className="col-sm-6">
						<input
							type="checkbox"
							checked={bringToWc.display}
							onChange={() =>
								bringToWc.display
									? setBringToWc({ ...bringToWc, display: false })
									: setBringToWc({ ...bringToWc, display: true })
							}
						/>
						<span className="ml-2">
							<b>{t("diaper.__bringToWc__")}</b>
						</span>
					</div>
					<div className="col-sm-6"></div>
					<div className="col-12 mt-4">
						<p className="meta">{t("common.__filterBySenior__")} :</p>
						<Select
							placeholder={t("senior.__selectSenior__")}
							name="seniors"
							options={seniorsFromHome}
							className="basic-multi-select mb-2 mt-3"
							classNamePrefix="select"
							onChange={e => changeStatisticDispatch({ type: "SET_SENIOR", payload: e })}
							isClearable
							value={changeStatisticStore.selectedSenior ? changeStatisticStore.selectedSenior : 0}
						/>
					</div>
				</div>
			</div>

			{changeStatisticStore.loading ? (
				<Loader />
			) : (
				<>
					{changeStatisticStore.data ? (
						"dirty_bed" in changeStatisticStore.data ? (
							<>
								<ChangeCounters statistics={changeStatisticStore.data} />
								<div className="big-card mb-3">
									<div className="row">
										<DisplayPieChart
											display={diaperSaturated.display}
											data={diaperSaturated.data}
											title={t("diaper.__diaperSaturated__")}
										/>
										<DisplayPieChart display={dirtyBed.display} data={dirtyBed.data} title={t("diaper.__dirtyBed__")} />
										<DisplayPieChart
											display={presenceOfRedness.display}
											data={presenceOfRedness.data}
											title={t("diaper.__presenceOfRedness__")}
										/>
										<DisplayPieChart
											display={presenceOfStools.display}
											data={presenceOfStools.data}
											title={t("diaper.__presenceOfStools__")}
										/>
										<DisplayPieChart
											display={drawSheetChanged.display}
											data={drawSheetChanged.data}
											title={t("diaper.__drawSheetChanged__")}
										/>
										<DisplayPieChart display={tornOff.display} data={tornOff.data} title={t("diaper.__diaperTornOff__")} />
										<DisplayPieChart display={bringToWc.display} data={bringToWc.data} title={t("diaper.__bringToWc__")} />
									</div>
								</div>
								<div className="big-card mt-3">
									<h5>{t("diaper.__postChangeStats__")}</h5>
									<ChangeStatisticsBarChart barChartStats={barChartStats} />
									<ChangeStatisticsMultiBarChart
										barChartStats={multiBarChartStats}
										presenceOfRedness={presenceOfRedness.display}
										presenceOfStools={presenceOfStools.display}
										tornOff={tornOff.display}
										drawSheetChanged={drawSheetChanged.display}
										diaperSaturated={diaperSaturated.display}
										bringToWc={bringToWc.display}
									/>
								</div>
								<DirtyBedStatistics
									homeId={homeId}
									userState={userState}
									selectedSenior={changeStatisticStore.selectedSenior}
									startDate={changeStatisticStore.startDate}
									endDate={changeStatisticStore.endDate}
								/>
							</>
						) : null
					) : (
						<div className="mt-3">
							<div className="big-card">
								<p className="meta">{t("common.__noElement__")}</p>
								<p className="red">
									<b>
										<FaAngleDoubleRight /> {t("common.__selectNewDate__")}
									</b>
								</p>
							</div>
						</div>
					)}
				</>
			)}
		</>
	);
}
