import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";

export default function AmdSeniorSyncInput({ show, setShow }) {
	const { t } = useTranslation("common");
	const seniorId = show.info.id;
	const partnerId = show.info.partnerId;
	const [seniorPartnerId, setSeniorPartnerId] = useState(partnerId);
	const [error, setError] = useState(false);

	const onSubmit = e => {
		e.preventDefault();
		setError(false);
		if (seniorPartnerId && seniorId) {
			const data = {
				senior_id: seniorId,
				senior_partner_id: seniorPartnerId
			};
			http
				.post(`${url.SENIORSURL}/amd_profile/partner_id`, data)
				.then(() => setShow({ show: false, info: { id: seniorId, partnerId: seniorPartnerId }, title: "", success: true }))
				.catch(() => setError(true));
		}
	};

	return (
		<form onSubmit={onSubmit}>
			{error ? (
				<p className="error-msg">
					<span className="right ml-2" role="button" tabIndex="0" onClick={() => setError(false)}>
						<FaTimes />
					</span>
					{t("common.__errorOccured__")}
				</p>
			) : null}
			<div className="mt-3">
				<div className="form-inline">
					<label className="meta">AMD ID Résident : </label>
					<input
						type="text"
						onChange={e => setSeniorPartnerId(e.target.value)}
						className="form-control form-control-sm mr-2 ml-2"
						defaultValue={seniorPartnerId}
					/>
					<button type="submit" className="btn btn-sm btn-primary">
						{t("common.__save__")}
					</button>
				</div>
			</div>
		</form>
	);
}
