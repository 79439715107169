import React from "react";
import FamilyUserDetails from "../components/Seniors/Family/FamilyUserDetails";
import { useParams } from "react-router-dom";
import { SeniorTabs } from "../utils/Tabs/TabsToDisplay";
import Tabs from "../utils/Tabs/Tabs";

const Seniors_Family = (): JSX.Element => {
	const { id } = useParams<{ id: string }>();
	return (
		<>
			<Tabs tabsToDisplay={SeniorTabs} currentTab={"FAMILIES"} />
			<FamilyUserDetails familyId={id} />
		</>
	);
};
export default Seniors_Family;
