import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaRegCalendarCheck, FaToggleOff, FaToggleOn } from "react-icons/fa";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import Select from "react-select";
import DateTimePicker from "../../utils/DatePicker/DateTimePicker";
import DisplayName from "../../utils/DisplayName";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import HandleError from "../../layout/HandleError";

export default function NewLiveMeeting({ seniorsFromHome, families, userState }) {
	const { t } = useTranslation("common");
	const [seniors, setSeniors] = useState([]);
	const [seniorId, setSeniorId] = useState(null);
	const [familyId, setFamilyId] = useState(null);
	const [error, setError] = useState(false);
	const today = new Date();
	const [startDate, setStartDate] = useState(today);
	const userLocale = userState.userConnected.locale;
	const [appointmentIsCreated, setAppointmentIsCreated] = useState(false);
	const [appointmentIsConfirmed, setAppointmentIsConfirmed] = useState(false);

	const onSubmit = e => {
		e.preventDefault();

		if (seniorId && familyId) {
			setError(false);
			const visioDateTime = handleDate(startDate, "yyyy-MM-dd'T'HH:mm:ss");
			const data = {
				family_user_id: familyId,
				senior_id: seniorId,
				visio_datetime: visioDateTime,
				confirmed: appointmentIsConfirmed
			};
			http
				.post(`${url.HOMESURL}/visio/new`, data)
				.then(() => setAppointmentIsCreated(true))
				.catch(() => {
					setAppointmentIsCreated(false);
					setError(true);
				});
		} else {
			setAppointmentIsCreated(false);
			setError(true);
		}
	};

	useEffect(() => {
		const data = seniorsFromHome.map(senior => {
			return {
				value: senior.id,
				label: senior.name
			};
		});
		setSeniors(data);
	}, [seniorsFromHome]);

	return (
		<div className="mb-4 mt-2">
			{appointmentIsCreated ? (
				<>
					<div className="d-flex justify-content-between">
						<p className="mt-1">
							<FaRegCalendarCheck className="social-icon mr-2" />
							<b>{t("mailbox.__visioSuccess__")}</b>
						</p>
						<button type="button" className="btn btn-sm btn-primary right" onClick={() => setAppointmentIsCreated(false)}>
							{t("mailbox.__createVisio__")}
						</button>
					</div>
					<div className="form-card mt-4 mb-4">
						<div className="row">
							<div className="col-md-3 mt-2">{t("common.__senior__")}:</div>
							<div className="col-md-9 mt-2">{DisplayName(seniorId, seniorsFromHome)}</div>
							<div className="col-md-3 mt-2">{t("mailbox.__from__")} :</div>
							<div className="col-md-9 mt-2">{DisplayName(familyId, families)}</div>
							<div className="col-md-3 mt-2">{t("common.__date__")} :</div>
							<div className="col-md-9 mt-2">{handleDate(startDate, "PPPPp", userLocale)}</div>
							<div className="col-12 mt-4">
								{appointmentIsConfirmed ? (
									<>
										<FaToggleOn className="blue" /> <span className="meta">{t("mailbox.__appointmentIsConfirmed__")}</span>
									</>
								) : (
									<>
										<FaToggleOff className="blue" /> <span className="meta">{t("mailbox.__appointmentMustBeConfirmed__")}</span>
									</>
								)}
							</div>
						</div>
					</div>
				</>
			) : (
				<>
					<h5>{t("mailbox.__createVisio__")}</h5>
					<HandleError error={error} setError={setError} message={t("common.__cautionFormIncomplete__")} />

					<form onSubmit={onSubmit}>
						<label>{t("common.__senior__")} :</label>
						<Select
							placeholder={<b>{t("senior.__selectSenior__")}</b>}
							name="senior"
							options={seniors}
							className="basic-multi-select mb-3"
							classNamePrefix="select"
							onChange={e => setSeniorId(e.value)}
						/>
						<label>{t("common.__family__")} :</label>
						<Select
							placeholder={<b>{t("family.__selectFamilyUser__")}</b>}
							name="family"
							options={seniorId ? families.filter(family => family.senior_id === seniorId) : families}
							className="basic-multi-select mb-3"
							classNamePrefix="select"
							onChange={e => setFamilyId(e.value)}
							noOptionsMessage={() => <span className="red">{t("senior.__noFamilyUser__")}</span>}
						/>
						<div className="mt-4">
							<p>{t("calendar.__eventDates__")} </p>
							<DateTimePicker userLocale={userLocale} startDate={startDate} setStartDate={setStartDate} />
						</div>
						<div className="mt-4">
							<div
								className={appointmentIsConfirmed ? "toggle-button-checked mr-3" : "toggle-button mr-3"}
								onClick={() => setAppointmentIsConfirmed(prevState => !prevState)}
							/>
							{appointmentIsConfirmed ? t("mailbox.__appointmentIsConfirmed__") : t("mailbox.__appointmentMustBeConfirmed__")}
						</div>
						<button type="submit" className="btn btn-sm btn-primary mt-4">
							{t("common.__send__")}
						</button>
					</form>
				</>
			)}
		</div>
	);
}
