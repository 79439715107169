import React, { useEffect } from "react";
import { FaAngleRight, FaRegClock, FaTimes } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { PhotoFrameInterface } from "./PreferencesReducer";
import HandleError from "../../../layout/HandleError";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";

interface Props {
	preferences: PhotoFrameInterface;
	dispatch: React.Dispatch<any>;
	homeId?: number;
	seniorId?: number;
}

const PhotoFramePreferences = ({ preferences, dispatch, homeId, seniorId }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const photoframePrefs = preferences.photoframe ? preferences.photoframe : [];

	const handleMinutes = (minutes: number): number | string => {
		if (minutes.toString().length === 1) {
			return `0${minutes}`;
		} else {
			return minutes;
		}
	};

	const removePreference = (index: number): void => {
		const newPreferences = preferences.photoframe.filter((elt, id) => id !== index);
		const data = {
			config: {
				PHOTO_FRAME_ACTIVATION_RANGES: newPreferences
			}
		};
		const removeUrl = seniorId
			? `${url.SENIORSURL}/${seniorId}/device_config`
			: `${url.HOMESURL}/${homeId}/device_config`;
		http
			.put(removeUrl, data)
			.then(res => {
				if (seniorId) {
					dispatch({
						type: "REMOVE_SENIOR_PHOTOFRAME_PREFERENCE",
						payload: res.data.PHOTO_FRAME_ACTIVATION_RANGES
					});
				} else {
					dispatch({
						type: "REMOVE_HOME_PHOTOFRAME_PREFERENCE",
						payload: res.data.PHOTO_FRAME_ACTIVATION_RANGES
					});
				}
			})
			.catch(() => {
				dispatch({
					type: "DISPLAY_ERROR"
				});
			});
	};

	useEffect(() => {
		return function cleanup() {
			dispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<HandleError error={preferences.error} dispatch={dispatch} noSpace message={t("common.__delError__")} />
			<p className="mt-2 mb-3">
				<FaAngleRight className="mr-2" />
				{t("device.__photoFrameWarning__")} :
			</p>
			{photoframePrefs.length > 0 ? (
				photoframePrefs.map((preference, index) => (
					<div key={index} className="recipient-badge">
						<div className="d-flex justify-content-between ">
							<FaRegClock className="regular-icon" />
							<div>
								<b className="mr-2">{t("common.__begining__")} </b> {preference.start[0]} : {handleMinutes(preference.start[1])}
							</div>
							<div>
								<b className="mr-2">{t("common.__to__")} </b> {preference.end[0]} : {handleMinutes(preference.end[1])}
							</div>
							<FaTimes role="button" className="table-icon" onClick={(): void => removePreference(index)} />
						</div>
					</div>
				))
			) : seniorId ? null : (
				<p className="meta">{t("common.__noElement__")}</p>
			)}
		</>
	);
};
export default PhotoFramePreferences;
