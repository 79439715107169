import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaMapMarkerAlt, FaUserMd, FaUserCog } from "react-icons/fa";
import http from "../../services/Interceptor";
import * as p from "../../constants/Permissions";
import * as url from "../../constants/Url";

export default function HomeCounters({ homeId, groupId, tenantId, userState, isSuperAdmin }) {
	const { t } = useTranslation("common");
	const [homeCounters, setHomeCounters] = useState({});
	const canManageUserAndBadge = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_USER_AND_BADGE]);

	useEffect(() => {
		if (homeId) {
			http
				.get(`${url.HOMESURL}/${homeId}/counters`)
				.then(res => setHomeCounters(res.data.counters))
				.catch(() => setHomeCounters({}));
		} else {
			if (groupId) {
				http
					.get(`${url.BASEURL}/groups/${groupId}/counters `)
					.then(res => setHomeCounters(res.data.counters))
					.catch(() => setHomeCounters({}));
			} else if (tenantId && isSuperAdmin) {
				http
					.get(`${url.BASEURL}/tenants/${tenantId}/counters  `)
					.then(res => setHomeCounters(res.data.counters))
					.catch(() => setHomeCounters({}));
			} else {
				http
					.get(`${url.BASEURL}/counters`)
					.then(res => setHomeCounters(res.data.counters))
					.catch(() => setHomeCounters({}));
			}
		}
	}, [homeId, groupId, tenantId, isSuperAdmin]);

	return (
		<div className="row mt-3 mb-3">
			<div className="col-4">
				<div className="gradient-card blue">
					<h5 className="gradient-title ml-2">{t("common.__home__")}</h5>
					<FaMapMarkerAlt className="gradient-card-icon" />
					<Link to="/manage/room/rooms">
						<span className="number ml-2">{homeCounters.room}</span>
						<span className="meta-counter ml-2 mr-2">{t("homeBoard.__rooms__")}</span>
					</Link>
					<br />
					<span className="number ml-2">{homeCounters.device}</span>
					<span className="meta-counter ml-2">{t("homeBoard.__devices__")}</span>
				</div>
			</div>
			<div className="col-4">
				<div className="gradient-card blue">
					<h5 className="gradient-title ml-2">{t("homeBoard.__seniors__")}</h5>
					<FaUserCog className="gradient-card-icon" />
					<Link to="/senior/seniors">
						<span className="number ml-2">{homeCounters.senior}</span>
						<span className="meta-counter ml-2 mr-2">{t("homeBoard.__seniors__")}</span>
					</Link>
					<br />
					<Link to="/senior/family">
						<span className="number ml-2">{homeCounters.family_user}</span>
						<span className="meta-counter ml-2 mr-2">{t("homeBoard.__family__")}</span>
					</Link>
				</div>
			</div>
			<div className="col-4">
				<div className="gradient-card blue">
					<h5 className="gradient-title ml-2">{t("homeBoard.__team__")}</h5>
					<FaUserMd className="gradient-card-icon" />
					{canManageUserAndBadge ? (
						<>
							<Link to="/user/hw/healthworkers">
								<span className="number ml-2">{homeCounters.user}</span>
								<span className="meta-counter ml-2 mr-2">{t("healthworker.__teamMembers__")}</span>
							</Link>
							<br />
							<Link to="/user/healthworkerbadges">
								<span className="number ml-2">{homeCounters.user_badge}</span>
								<span className="meta-counter ml-2 mr-2">{t("homeBoard.__badges__")}</span>
							</Link>
						</>
					) : (
						<>
							<span className="number ml-2">{homeCounters.user}</span>
							<span className="meta-counter ml-2 mr-2">{t("healthworker.__teamMembers__")}</span>
							<br />
							<span className="number ml-2">{homeCounters.user_badge}</span>
							<span className="meta-counter ml-2 mr-2">{t("homeBoard.__badges__")}</span>
						</>
					)}
				</div>
			</div>
		</div>
	);
}
