import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import LanguageSwitcher from "../../i18n/LanguageSwitcher";

const UpdateLanguage = ({ userState, userDispatch }) => {
	const { t } = useTranslation("common");
	const [userLocale, setUserLocale] = useState(userState.userConnected.locale);

	const languages = [
		{
			id: 1,
			name: t("users.__french__"),
			locale: "fr"
		},
		{
			id: 2,
			name: t("users.__english__"),
			locale: "en"
		},
		{
			id: 3,
			name: t("users.__dutch__"),
			locale: "nl"
		}
	];

	useEffect(() => {
		const userData = {
			user: {
				locale: userLocale
			}
		};
		http.put(`${url.BASEURL}/me`, userData).then(res => {
			userDispatch({
				type: "UPDATE_MY_ACCOUNT",
				payload: res.data.user
			});
			localStorage.setItem("locale", JSON.stringify(res.data.user.locale));
			if (res.data.user.locale === "fr") {
				localStorage.setItem("i18next", "fr-FR");
			} else if (res.data.user.locale === "nl") {
				localStorage.setItem("i18next", "nl-BE");
			} else {
				localStorage.setItem("i18next", "en-EN");
			}
		});
	}, [userLocale]);

	return <LanguageSwitcher userLocale={userLocale} setUserLocale={setUserLocale} languages={languages} />;
};

export default UpdateLanguage;
