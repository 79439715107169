import React, { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FaCheck, FaExclamationTriangle, FaIdCardAlt } from "react-icons/fa";
import { badgeValidityType } from "../../constants/BadgeValidityType";
import { handleValidityDetails } from "../../utils/BadgeRestrictions";
import { BadgeInterface, StoreInterface, UserFormInterface } from "../../interfaces/UserInterface";
import ModalContainer from "../../utils/ModalContainer";
import CreateHealthWorkerBadge from "./Badges/CreateHealthWorkerBadge";
import * as p from "../../constants/Permissions";
import * as API from "../../services/API";

interface Props {
	hwFormStore: UserFormInterface;
	hwFormDispatch: React.Dispatch<any>;
	userLocale: string;
	store: StoreInterface;
	dispatch: React.Dispatch<any>;
}

const initialState = {
	badges: []
};

const BadgesAvailableReducer = (state: any, action: { type: string; payload: any }): any => {
	switch (action.type) {
		case "GET_BADGES_AVAILABLE":
			return {
				...state,
				badges: action.payload
			};
		case "NEW_BADGE":
			return {
				...state,
				badges: [action.payload, ...state.badges]
			};
		default:
			return state;
	}
};

const AddHealthWorkerStepThree = ({ hwFormStore, hwFormDispatch, userLocale, store, dispatch }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const history = useHistory();
	const [show, setShow] = useState({ show: false, info: null });
	const [badgesAvailableStore, badgesAvailableDispatch] = useReducer(BadgesAvailableReducer, initialState);

	useEffect(() => {
		if (hwFormStore.role.home_id) {
			API.getBadgesAvailable(hwFormStore.role.home_id, (res: any) => {
				badgesAvailableDispatch({ type: "GET_BADGES_AVAILABLE", payload: res });
			});
		}
	}, [hwFormStore.role.home_id]);

	const handleBadge = (badge: BadgeInterface): void => {
		if (hwFormStore.badge && hwFormStore.badge.id === badge.id) {
			hwFormDispatch({
				type: "SAVE_BADGE",
				payload: null
			});
		} else {
			hwFormDispatch({
				type: "SAVE_BADGE",
				payload: badge
			});
		}
	};

	return (
		<>
			{hwFormStore.role.home_id ? (
				hwFormStore.role.permissions.includes(p.CONTROL_PANEL) ? (
					<p className="form-card pb-3">
						<FaExclamationTriangle className="mr-2" />
						{t("badge.__noBadgeForThisTypeOfAccount__")}
					</p>
				) : badgesAvailableStore.badges.length > 0 ? (
					<>
						<h5 className="mt-2 mb-3">{t("badge.__badgeSelection__")}</h5>
						{badgesAvailableStore.badges.map((badge: BadgeInterface): any => (
							<div key={badge.id} className="input-badge">
								<input
									type="radio"
									id={badge.value}
									value={badge.id}
									checked={hwFormStore.badge && hwFormStore.badge.id === badge.id ? true : false}
									onClick={(): void => handleBadge(badge)}
								/>
								<label htmlFor={badge.value}>
									<div className="d-flex justify-content-between">
										<div className="d-flex">
											<FaIdCardAlt className="mr-4" />
											<div>
												{badge.type_id === 1 && (
													<p>
														<b>{t("badge.__pinCode__")} :</b> {badge.value}
													</p>
												)}
												{badge.type_id === 2 && (
													<p>
														<b>{t("badge.__qrCode__")} :</b> {badge.value}
													</p>
												)}
												{badgeValidityType(badge.validity.type)}
												{handleValidityDetails(badge.validity.type, badge.validity.values, userLocale)}
											</div>
										</div>
										{hwFormStore.badge && hwFormStore.badge.id === badge.id ? <FaCheck /> : null}
									</div>
								</label>
							</div>
						))}
					</>
				) : (
					<>
						<h5>{t("badge.__badges__")} :</h5>
						<p className="red">{t("badge.__noBadgeAvailable__")}</p>
						<button
							type="button"
							className="btn btn-outline-primary btn-sm mt-2"
							onClick={(): void => setShow({ show: true, info: null })}>
							{t("badge.__addBadge__")}
						</button>
					</>
				)
			) : (
				<p className="form-card pb-3">
					<FaExclamationTriangle className="mr-2" />
					{t("badge.__noBadgeForGroupAccess__")}
				</p>
			)}
			<div className="d-flex justify-content-end mb-3">
				<button type="submit" className="btn btn-primary btn-sm" onClick={(): void => history.push("/user/adduser/resume")}>
					{t("common.__nextStep__")}
				</button>
			</div>
			<ModalContainer show={show} setShow={setShow}>
				<CreateHealthWorkerBadge
					homeId={hwFormStore.role.home_id}
					userLocale={userLocale}
					store={store}
					dispatch={dispatch}
					badgesAvailableDispatch={badgesAvailableDispatch}
					setShow={setShow}
					isModal
				/>
			</ModalContainer>
		</>
	);
};
export default AddHealthWorkerStepThree;
