import React from "react";
import { useParams } from "react-router-dom";
import {
	HealthWorkerInterface,
	HomeUnitInterface,
	SupervisorInterface,
	UserInterface
} from "../interfaces/UserInterface";
import useStoreData from "../hooks/useStoreData";
import AccessControl from "../layout/AccessControl";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import HomesSecondaryNav from "../layout/HomesSecondaryNav";
import EditHomeUnit from "../components/Home/HomeUnit/EditHomeUnit";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const MyHome_EditHomeUnit = ({ homeId, userState }: Props): JSX.Element => {
	const { id } = useParams<{ id: string }>();
	const { store, dispatch } = useStoreData({ userState, homeId });

	const homeUnitDetails = store.home.homeUnits.find((homeUnit: HomeUnitInterface) => homeUnit.id === parseInt(id));
	const homeUnitSupervisors: SupervisorInterface[] = homeUnitDetails.supervisors;
	const ids = homeUnitSupervisors.map(user => user.id);

	const healthWorkersFromThisHome = userState.userConnected.home_id
		? store.users.healthWorkers
		: store.users.healthWorkers.filter((hw: HealthWorkerInterface) => hw.home_id === homeId);
	const activeHealthWorkers = healthWorkersFromThisHome.filter((user: HealthWorkerInterface) => user.active);
	const activeHealthWorkersAvailable = activeHealthWorkers.filter(
		(user: HealthWorkerInterface) => !ids.includes(user.id)
	);
	const activeUsersToSelect = activeHealthWorkersAvailable.map((user: HealthWorkerInterface) => {
		return {
			...user,
			value: user.id,
			label: user.name
		};
	});

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_HOME_UNIT]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<HomesSecondaryNav userState={userState} />}
				component={
					<EditHomeUnit
						userState={userState}
						store={store}
						dispatch={dispatch}
						activeUsersToSelect={activeUsersToSelect}
						homeUnitSupervisors={homeUnitSupervisors}
						homeUnitDetails={homeUnitDetails}
					/>
				}
			/>
		</AccessControl>
	);
};
export default MyHome_EditHomeUnit;
