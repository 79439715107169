import React, { useContext, useEffect } from "react";
import { HomeContext } from "../context/HomeContext";
import { UserInterface } from "../interfaces/UserInterface";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import HomesSecondaryNav from "../layout/HomesSecondaryNav";
import Document from "../components/HandleDocuments/Document";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const MyHome_Documents = ({ homeId, userState }: Props): JSX.Element => {
	const { esState, esDispatch } = useContext(HomeContext);

	useEffect(() => {
		return function cleanup(): void {
			esDispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	return (
		<PageWithSecondaryNavLayout
			secondaryNav={<HomesSecondaryNav userState={userState} />}
			component={<Document esState={esState} esDispatch={esDispatch} homeId={homeId} userState={userState} />}
		/>
	);
};
export default MyHome_Documents;
