import React, { useEffect } from "react";
import { UserInterface } from "../interfaces/UserInterface";
import useStoreData from "../hooks/useStoreData";
import AccessControl from "../layout/AccessControl";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import AdminSecondaryNav from "../layout/AdminSecondaryNav";
import HealthWorkersTypes from "../components/HealthWorkers/Types/HealthWorkersTypes";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const HealthWorker_Types = ({ userState, homeId }: Props): JSX.Element => {
	const { store, dispatch } = useStoreData({ userState, homeId });

	useEffect(() => {
		return function cleanup(): void {
			dispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	return (
		<AccessControl userPermissions={userState.userConnected.permissions} permissionNeeded={[p.SUPER_ADMIN]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<AdminSecondaryNav userState={userState} />}
				component={<HealthWorkersTypes store={store} dispatch={dispatch} />}
			/>
		</AccessControl>
	);
};
export default HealthWorker_Types;
