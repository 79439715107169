import React from "react";
import { FaRegTired, FaRegFrown, FaRegMeh, FaRegSmile, FaRegGrin } from "react-icons/fa";

// Smiley | Mood
export const SMILEY_VERY_SAD = 1;
export const SMILEY_SAD = 2;
export const SMILEY_RAS = 3;
export const SMILEY_HAPPY = 4;
export const SMILEY_VERY_HAPPY = 5;

export const getActivityMood = (o: number): JSX.Element | null => {
	switch (o) {
		case SMILEY_VERY_SAD:
			return <FaRegTired className="mood-emoticon" />;
		case SMILEY_SAD:
			return <FaRegFrown className="mood-emoticon" />;
		case SMILEY_RAS:
			return <FaRegMeh className="mood-emoticon" />;
		case SMILEY_HAPPY:
			return <FaRegSmile className="mood-emoticon" />;
		case SMILEY_VERY_HAPPY:
			return <FaRegGrin className="mood-emoticon" />;
		default:
			return null;
	}
};
