import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { eventTypes } from "../../constants/EventType";
import HandleRecurringEvents from "./HandleRecurringEvents";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

export default function ModalEvent({ show, userState }) {
	const { t } = useTranslation("common");
	const eventDetails = show.info;
	const [recurringData, setRecurringData] = useState({});

	useEffect(() => {
		if (eventDetails.id && eventDetails.recurring_entry_id) {
			http
				.get(`${url.BASEURL}/home_calendar_entries/${eventDetails.recurring_entry_id}`)
				.then(res => setRecurringData(res.data.calendar_entry.recurring_data))
				.catch(() => setRecurringData({}));
		}
	}, [eventDetails]);

	return (
		<>
			<div className="row mt-4">
				<div className="col-md-4">
					<p className="blue">
						<strong>{eventTypes(eventDetails.entry_type_id)}</strong>
					</p>
				</div>
				<div className="col-md-8">
					<p>
						<b>{eventDetails.info}</b>
					</p>
					{eventDetails.detail ? <p>{eventDetails.detail}</p> : null}
				</div>
				<div className="col-md-4 mb-2">{t("calendar.__eventStart__")}</div>
				<div className="col-md-8 mb-2">
					{handleDate(eventDetails.begin_datetime, "PPPPp", userState.userConnected.locale)}
				</div>
				{eventDetails.end_datetime ? (
					<>
						<div className="col-md-4 mb-4">{t("calendar.__eventEnd__")}</div>
						<div className="col-md-8 mb-4">
							{handleDate(eventDetails.end_datetime, "PPPPp", userState.userConnected.locale)}
						</div>
					</>
				) : null}
				{eventDetails.recurring_entry_id && (
					<HandleRecurringEvents recurringData={recurringData} userLocale={userState.userConnected.locale} />
				)}
			</div>
			<Link to={`/planner/editEvent/${eventDetails.id}`}>
				<button type="button" className="btn btn-sm btn-outline-secondary mt-3 mr-2">
					{t("common.__update__")}
				</button>
			</Link>
		</>
	);
}
