import React from "react";
import { useTranslation } from "react-i18next";
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart, Bar, ResponsiveContainer, Area } from "recharts";
import { BsDropletFill } from "react-icons/bs";

const SeniorWithDiaperStatisticsContainer = ({ values }) => {
	const { t } = useTranslation("common");

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			return (
				<div style={{ backgroundColor: "white", border: "solid 1px Gainsboro", padding: "0.5rem", borderRadius: "7px" }}>
					<p>
						<b>{label}</b>
					</p>
					<div className="social-separator mt-2 mb-2"></div>
					<p>
						<BsDropletFill style={{ marginRight: "10px", height: "1rem", width: "auto" }} /> {payload[0].value}
					</p>
					{payload[0].payload.wc === 1 ? (
						<p>
							<img src="/img/diaper/wc.png" alt="wc" style={{ marginRight: "10px", height: "1.1rem", width: "auto" }} />
							{t("diaper.__bringToWc__")}
						</p>
					) : null}
				</div>
			);
		}
		return null;
	};
	return (
		<div style={{ width: "100%", height: 420 }}>
			<ResponsiveContainer>
				<ComposedChart
					data={values}
					margin={{
						top: 20,
						right: 0,
						bottom: 20,
						left: 0
					}}>
					<CartesianGrid stroke="#f5f5f5" />
					<XAxis dataKey="d" />
					<YAxis />
					<Tooltip content={<CustomTooltip />} />
					<Legend verticalAlign="top" height={20} iconSize={16} />
					<Area type="monotone" dataKey="h" fill="#f6f9fd" stroke="#00a0e3" activeDot={{ r: 8 }} name="Humidité" />
					<Bar dataKey="c" barSize={2} fill="#50c878" name={t("diaper.__change__")} />
					<Bar dataKey="s" barSize={2} fill="#fa5456" name={t("diaper.__saturation__")} />
					<Bar dataKey="t" barSize={2} fill="#ffae42" name={t("diaper.__control__")} />
				</ComposedChart>
			</ResponsiveContainer>
		</div>
	);
};

export default SeniorWithDiaperStatisticsContainer;
