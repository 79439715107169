import React from "react";
import { useTranslation } from "react-i18next";
import { FaUserPlus } from "react-icons/fa";
import { RoomInterface } from "../../interfaces/HomeInterface";
import { Tooltip } from "./Tooltip";

interface Props {
	setShowAddSeniorModal: (data: {
		show: boolean;
		info: { room: RoomInterface | null; homeId: number };
		title: string;
		success: boolean;
	}) => void;
	room: RoomInterface;
	homeId: number;
}

const SeniorAssignToRoomIcon = ({ setShowAddSeniorModal, room, homeId }: Props): JSX.Element => {
	const { t } = useTranslation("common");

	return (
		<Tooltip text={t("senior.__assignSenior__")}>
			<FaUserPlus
				className="board-card-icon"
				onClick={(): void =>
					setShowAddSeniorModal({
						show: true,
						info: { room: room, homeId: homeId },
						title: `${t("common.__room__")} ${room.name}`,
						success: false
					})
				}
			/>
		</Tooltip>
	);
};
export default SeniorAssignToRoomIcon;
