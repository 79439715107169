import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { fromToUrl, handleDate } from "@e4ia/e4link_modules.datetime";
import { sub } from "date-fns";
import { FaAngleRight, FaMapPin, FaRegCopy, FaCheck } from "react-icons/fa";
import { copyToClipboard } from "../../utils/CopyToClipboard";
import DisplayName from "../../utils/DisplayName";
import http from "../../services/Interceptor";
import * as p from "../../constants/Permissions";
import * as url from "../../constants/Url";
import useStoreData from "../../hooks/useStoreData";

export default function ModalSeniorMemoT({ show, homeId, userState }) {
	const { t } = useTranslation("common");
	const senior = show.info.senior;
	const { store } = useStoreData({ userState, homeId });
	const [seniorMemo, setSeniorMemo] = useState([]);
	const [copied, setCopied] = useState(0);
	const copyThisNote = (id, note) => {
		setCopied(id);
		copyToClipboard(note);
	};
	const canReadAllMemo = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_READ_ALL_MEMOT]);
	const today = new Date();
	const treeDaysBefore = sub(today, { days: 3 });
	const healthworkers = store.users.healthWorkers;

	useEffect(() => {
		const fromTo = fromToUrl(treeDaysBefore, today);
		if (senior.id && fromTo) {
			if (canReadAllMemo) {
				http
					.get(`${url.SENIORSURL}/${senior.id}/memo_t_notes/${fromTo}`)
					.then(res => {
						const allMemo = res.data.memo_t_notes;
						const data = allMemo.map(function (memo) {
							return {
								...memo,
								healthworker: DisplayName(memo.health_worker_id, healthworkers),
								copy: copied === memo.id ? true : false
							};
						});
						setSeniorMemo(data);
					})
					.catch(() => setSeniorMemo([]));
			} else {
				http
					.get(`${url.BASEURL}/me/seniors/${senior.id}/memo_t_notes/${fromTo}`)
					.then(res => {
						const allMemo = res.data.memo_t_notes;
						const data = allMemo.map(function (memo) {
							return {
								...memo,
								copy: copied === memo.id ? true : false
							};
						});
						setSeniorMemo(data);
					})
					.catch(() => setSeniorMemo([]));
			}
		}
		// eslint-disable-next-line
	}, [senior.id, copied, healthworkers]);

	return seniorMemo.length ? (
		seniorMemo.map(memo => (
			<div key={memo.id}>
				<div className="d-flex justify-content-between">
					<p className="meta">
						<FaMapPin className="mr-2" />
						{handleDate(memo.date, "PPPp", userState.userConnected.locale)}
						<Link to={`/user/healthworker/${memo.health_worker_id}`}>
							<span className="category-outline-badge grey ml-2">{memo.healthworker}</span>
						</Link>
					</p>

					{memo.copy ? (
						<span className="right category-badge green">
							<FaCheck /> {t("memo.__memoCopied__")}
						</span>
					) : (
						<button
							className="right category-outline-badge green"
							onClick={() => copyThisNote(memo.id, memo.note)}
							type="button">
							<FaRegCopy /> <b>{t("memo.__memoCopy__")}</b>
						</button>
					)}
				</div>
				<p>{memo.note}</p>
				<div className="light-separator"></div>
			</div>
		))
	) : (
		<h5>
			<FaAngleRight />
			{t("memo.__noMemo__")}
		</h5>
	);
}
