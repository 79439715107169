import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import MyInputText from "../../../utils/Form/MyInputText";
import MyInputSelect from "../../../utils/Form/MyInputSelect";
import MyInputNumber from "../../../utils/Form/MyInputNumber";
import { displayBadgeType, wantedDays } from "./ValidityTypes";
import RangePicker from "../../../utils/DatePicker/RangePicker";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import * as constant from "./ValidityTypes";
import HandleError from "../../../layout/HandleError";
import SelectHomeInput from "../../../layout/SelectHomeInput";
import { FaTimes } from "react-icons/fa";
import { handleDate } from "@e4ia/e4link_modules.datetime";

export default function EditHealthWorkerBadge({ userState, store, dispatch, badgeId, homeId }) {
	const { t } = useTranslation("common");
	const history = useHistory();
	const methods = useForm();
	const { handleSubmit } = methods;
	const homeBadges = store.users.badges;
	const healthWorkers = store.users.healthWorkers;
	const badge = homeBadges.find(badge => badge.id === parseInt(badgeId));
	const [validityType, setValidityType] = useState(badge ? badge.validity.type : null);
	const [selectedDays, setSelectedDays] = useState(badge ? badge.validity.values : null);
	const [qrCode, setQrCode] = useState(null);
	const [displayQrCodeValue, setDisplayQrCodeValue] = useState(true);
	const [badgeRestrictionError, setBadgeRestrictionError] = useState(false);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	useEffect(() => {
		if (homeBadges.length === 0 && homeId) {
			http
				.get(`${url.HOMESURL}/${homeId}/user_badges`)
				.then(res => {
					dispatch({
						type: "GET_BADGES",
						payload: res.data.user_badges
					});
				})
				.catch(() => {
					dispatch({
						type: "DISPLAY_ERROR",
						payload: t("common.__noData__")
					});
				});
		}
	}, [homeId]);

	useEffect(() => {
		if (badge) {
			if (badge.validity.type === 1) {
				const beginDateTime = badge.validity.values[0];
				const selectedBeginDay = beginDateTime.split(" ");
				const start = selectedBeginDay.join("T");
				const eventStartAt = new Date(start);
				setStartDate(eventStartAt);

				const endDateTime = badge.validity.values[1];
				const selectedEndDay = endDateTime.split(" ");
				const end = selectedEndDay.join("T");
				const eventEndAt = new Date(end);
				setEndDate(eventEndAt);
			}
		}
	}, [badge]);

	const generateQrCode = () => {
		http
			.get(`${url.BASEURL}/user_badges/generate/random/qrcode`)
			.then(res => {
				setDisplayQrCodeValue(false);
				setQrCode(res.data.value);
			})
			.catch(() => setDisplayQrCodeValue(true));
	};

	const handleDays = (e, id) => {
		if (e.target.checked) {
			setSelectedDays([...selectedDays, id]);
		} else {
			setSelectedDays(selectedDays.filter(day => day !== id));
		}
	};

	const onSubmit = data => {
		dispatch({
			type: "HIDE_ERROR"
		});
		let badgeData = {};

		if (validityType === 2) {
			badgeData = {
				user_badge: {
					validity: {
						type: parseInt(validityType),
						values: [parseInt(data.beginHour), parseInt(data.beginMinute), parseInt(data.endHour), parseInt(data.endMinute)]
					},
					value: data.value,
					active: data.active,
					user_id: parseInt(data.healthWorker) === 0 ? null : parseInt(data.healthWorker)
				}
			};
		} else if (validityType === 3) {
			badgeData = {
				user_badge: {
					validity: {
						type: parseInt(validityType),
						values: selectedDays
					},
					value: data.value,
					type_id: badge.type_id,
					user_id: parseInt(data.healthWorker) === 0 ? null : parseInt(data.healthWorker),
					active: data.active
				}
			};
		} else if (validityType === 0) {
			badgeData = {
				user_badge: {
					validity: {
						type: parseInt(validityType),
						values: []
					},
					value: data.value,
					type_id: badge.type_id,
					user_id: parseInt(data.healthWorker) === 0 ? null : parseInt(data.healthWorker),
					active: data.active
				}
			};
		} else if (validityType === 1) {
			const start = handleDate(startDate, "yyyy-MM-dd' 'HH:mm:ss");
			const end = handleDate(endDate, "yyyy-MM-dd' 'HH:mm:ss");

			badgeData = {
				user_badge: {
					validity: {
						type: parseInt(validityType),
						values: [start, end]
					},
					value: data.value,
					type_id: badge.type_id,
					user_id: parseInt(data.healthWorker) === 0 ? null : parseInt(data.healthWorker),
					active: data.active
				}
			};
		}

		http
			.put(`${url.BASEURL}/user_badges/${badgeId}`, badgeData)
			.then(res => {
				dispatch({
					type: "EDIT_BADGE",
					payload: res.data.user_badge
				});
				dispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("badge.__updateSuccess__", { name: res.data.user_badge.value })
				});
				history.push("/user/healthworkerbadges");
			})
			.catch(() => {
				dispatch({
					type: "DISPLAY_ERROR"
				});
			});
	};

	const active = [
		{
			id: true,
			name: t("common.__active__")
		},
		{
			id: false,
			name: t("common.__inactive__")
		}
	];

	return homeId ? (
		<FormProvider {...methods}>
			<h5 className="mt-4 blue">{t("badge.__update__")}</h5>

			<HandleError error={store.es.error} dispatch={dispatch} message={t("common.__editError__")} />

			{badgeRestrictionError ? (
				<p className="error-msg">
					{t("badge.__restrictionError__")}
					<span className="right" role="button" tabIndex="0" onClick={() => setBadgeRestrictionError(false)}>
						<FaTimes />
					</span>
				</p>
			) : null}
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="row">
					<div className="col-md-5 form-group">
						<label className="form-label">{t("common.__type__")}</label>
						<input
							type="text"
							disabled
							defaultValue={badge ? displayBadgeType(badge.type_id) : null}
							className="form-control form-control-sm"
						/>
					</div>
					<div className="col-md-5">
						{badge ? (
							<>
								{badge.type_id === 2 && (
									<div className="form-group">
										<label className="form-label">{t("badge.__value__")}:</label>
										<div className="form-inline mr-2">
											{displayQrCodeValue ? (
												<span className="mr-2">
													{badge.value}{" "}
													<button type="button" className="btn btn-sm btn-outline-primary" onClick={() => generateQrCode()}>
														{t("badge.__generateQrCode__")}
													</button>
												</span>
											) : (
												<input
													type="text"
													name="newQrCode"
													defaultValue={qrCode}
													disabled
													className="form-control form-control-sm"
												/>
											)}
										</div>
									</div>
								)}
								{badge.type_id === 1 && (
									<MyInputText label={t("badge.__value__")} name="value" behavior={{ required: true }} value={badge.value} />
								)}
							</>
						) : null}
					</div>
					<div className="col-md-2">
						<MyInputSelect
							label={t("common.__status__")}
							name="active"
							behavior={{ required: true }}
							value={badge ? badge.active : null}
							list={active}
						/>
					</div>
					<div className="col-12">
						<MyInputSelect
							label={<p className="blue">{t("common.__assignment__")} :</p>}
							name="healthWorker"
							behavior={{ required: false }}
							value={badge.user_id}
							list={[{ id: 0, name: "Non attribué" }, ...healthWorkers]}
						/>
					</div>
					<div className="col-md-12">
						<label className="blue">{t("badge.__restrictionType__")}</label>
						<MyInputSelect
							name="validity"
							behavior={{ required: true }}
							list={constant.validityTypes}
							value={validityType}
							onChange={e => setValidityType(parseInt(e.target.value))}
						/>

						{validityType === 1 ? (
							<>
								<p>{t("badge.__restrictionRangeLabel__")} :</p>
								<RangePicker
									userLocale={userState.userConnected.locale}
									startDate={startDate}
									setStartDate={setStartDate}
									endDate={endDate}
									setEndDate={setEndDate}
								/>
								<div className="space"></div>
							</>
						) : null}

						{badge ? (
							<>
								{validityType === 2 && (
									<div className="row">
										<div className="col-md-6">
											<p className="blue">{t("badge.__timingStart__")} :</p>
										</div>
										<div className="col-md-6">
											<p className="blue">{t("badge.__timingEnd__")} :</p>
										</div>
										<div className="col-md-2 mr-2">
											<MyInputNumber
												label={t("common.__hour__")}
												name="beginHour"
												behavior={{ required: true, maxLength: 2, pattern: /[0-9]{2}/ }}
												value={badge.validity.type === 2 ? badge.validity.values[0] : null}
												option={badge.validity.type === 2 ? badge.validity.values[0] : null}
											/>
										</div>
										<div className="col-md-2">
											<MyInputNumber
												label={t("common.__minute__")}
												name="beginMinute"
												behavior={{ required: true, maxLength: 2, pattern: /[0-9]/ }}
												value={badge.validity.type === 2 ? badge.validity.values[1] : null}
												option={badge.validity.type === 2 ? badge.validity.values[1] : null}
											/>
										</div>
										<div className="col-md-2"></div>
										<div className="col-md-2 mr-2">
											<MyInputNumber
												label={t("common.__hour__")}
												name="endHour"
												behavior={{ required: true, maxLength: 2, pattern: /[0-9]{2}/ }}
												value={badge.validity.values[2]}
												option={badge.validity.values[2]}
											/>
										</div>
										<div className="col-md-2">
											<MyInputNumber
												label={t("common.__minute__")}
												name="endMinute"
												behavior={{ required: true, maxLength: 2, pattern: /[0-9]/ }}
												value={badge.validity.values[3]}
												option={badge.validity.values[3]}
											/>
										</div>
										<div className="col-md-12">
											<div className="space"></div>
										</div>
									</div>
								)}
								{validityType === 3 && (
									<>
										<p>{t("badge.__daysValidity__")} :</p>
										{wantedDays.map(days => (
											<div key={days.id}>
												<input
													type="checkbox"
													key={days.id}
													onChange={e => handleDays(e, days.id)}
													checked={selectedDays.includes(days.id) ? true : false}
												/>
												<span className="label-checkbox ml-2">{days.name}</span>
											</div>
										))}
										<div className="mb-4"></div>
									</>
								)}
							</>
						) : null}
					</div>
				</div>

				<button className="btn btn-outline-secondary btn-sm mt-4 mr-2" type="button" onClick={() => history.goBack()}>
					{t("common.__cancel__")}
				</button>
				<button type="submit" className="btn btn-primary btn-sm mt-4">
					{t("common.__update__")}
				</button>
			</form>
		</FormProvider>
	) : (
		<SelectHomeInput />
	);
}
