import React, { useEffect, useContext } from "react";
import { HomeContext } from "../context/HomeContext";
import { UserInterface } from "../interfaces/UserInterface";
import AccessControl from "../layout/AccessControl";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import AdminSecondaryNav from "../layout/AdminSecondaryNav";
import AddDevice from "../components/Devices/AddDevice";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const Devices_AddDevice = ({ userState, homeId }: Props): JSX.Element => {
	const { devicesStore, devicesDispatch } = useContext(HomeContext);
	const isAdmin = userState.userConnected.is_in([p.SUPER_ADMIN, p.ADMIN]);

	useEffect(() => {
		if (isAdmin) {
			http.get(`${url.BASEURL}/device_serial_number_types`).then(res => {
				devicesDispatch({
					type: "GET_DEVICE_TYPES",
					payload: res.data.device_serial_number_types
				});
			});
		}
	}, [isAdmin]);

	useEffect(() => {
		return function cleanup(): void {
			devicesDispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	return (
		<AccessControl userPermissions={userState.userConnected.permissions} permissionNeeded={[p.SUPER_ADMIN, p.ADMIN]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<AdminSecondaryNav userState={userState} />}
				component={<AddDevice homeId={homeId} devicesStore={devicesStore} devicesDispatch={devicesDispatch} />}
			/>
		</AccessControl>
	);
};
export default Devices_AddDevice;
