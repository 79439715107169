import React, { useState, useEffect } from "react";
import { getActivityAction, getActivityTitle, actionsByCategoryLists } from "../../../utils/Activities/Activities";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import ErrorContainer from "../../../layout/ErrorContainer";
import { useTranslation } from "react-i18next";

export default function FamilyTimelineSettings({ actionsToHide, setActionsToHide, seniorSelected, initialCheckBox }) {
	const { t } = useTranslation("common");
	const [categoriesChecked, setCategoriesChecked] = useState([]);
	const [httpResponse, setHttpResponse] = useState(null);

	useEffect(() => {
		const handleCategoryCheckbox = actionsToHide => {
			const categories = actionsToHide.map(action => getActivityTitle(action));
			const categoriesList = Array.from(new Set(categories));
			setCategoriesChecked(...categoriesChecked, categoriesList);
		};
		if (actionsToHide.length > 0) {
			handleCategoryCheckbox(actionsToHide);
		}
		// eslint-disable-next-line
	}, [initialCheckBox]);

	const defineTimeline = () => {
		const data = {
			timeline_filter: {
				filter: actionsToHide
			}
		};
		http
			.put(`${url.SENIORSURL}/${seniorSelected}/timeline_filters`, data)
			.then(() => {
				setHttpResponse({ error: false });
				window.scrollTo(0, 0);
			})
			.catch(() => setHttpResponse({ error: true }));
	};

	const checkAction = (action, category) => {
		if (!categoriesChecked.includes(category)) {
			setCategoriesChecked([...categoriesChecked, category]);
		}

		if (actionsToHide.includes(action)) {
			setActionsToHide(actionsToHide.filter(actionToHide => actionToHide !== action));
		} else {
			setActionsToHide([...actionsToHide, action]);
		}
	};

	const checkAllActionsFromThisCategory = (name, actions) => {
		if (categoriesChecked.includes(name)) {
			let filteredCategoriesChecked = categoriesChecked.filter(category => category !== name);
			setCategoriesChecked(filteredCategoriesChecked);
			setActionsToHide(actionsToHide.filter(actionToHide => !actions.includes(actionToHide)));
		} else {
			if (actionsToHide.length > 0 && actions.filter(action => actionsToHide.includes(action))) {
				let actionsToAdd = actions.filter(action => !actionsToHide.includes(action));
				setActionsToHide(actionsToHide.concat(actionsToAdd));
				setCategoriesChecked([...categoriesChecked, name]);
			} else {
				setCategoriesChecked([...categoriesChecked, name]);
				setActionsToHide(actionsToHide.concat(actions));
			}
		}
	};

	return (
		<>
			<ErrorContainer
				alert={t("home.__timelineUpdateError__")}
				success={t("home.__timelineUpdated__")}
				httpResponse={httpResponse}
				setHttpResponse={setHttpResponse}
			/>
			{actionsByCategoryLists.map((category, index) => (
				<div key={index} className="header-list-items">
					<div className="row">
						<div className="col-md-6">
							<h5>{category.name}</h5>
						</div>
						<div className="col-md-6">
							<div className="float-right mt-3">
								<input
									type="checkbox"
									checked={categoriesChecked.includes(category.name) ? false : true}
									onChange={() => checkAllActionsFromThisCategory(category.name, category.actions)}
								/>
								<span className="meta ml-2">
									{categoriesChecked.includes(category.name) ? t("common.__selectAll__") : t("common.__deSelectAll__")}
								</span>
							</div>
						</div>
					</div>

					{category.actions.map(action => (
						<div key={action} className="ml-2">
							<input
								type="checkbox"
								key={action}
								checked={actionsToHide.includes(action) ? false : true}
								onChange={() => checkAction(action, category.name)}
							/>
							<span className="label-checkbox ml-2">{getActivityAction(action)}</span>
						</div>
					))}
				</div>
			))}

			<button className="btn btn-primary mt-2" onClick={() => defineTimeline()}>
				{t("common.__save__")}
			</button>
		</>
	);
}
