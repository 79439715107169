import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { Progressbar } from "@e4ia/e4link_modules.progressbar";
import PostManager from "./PostManagement/PostManager";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

export default function AddNews({ blogStore, blogDispatch, homeId, seniorsFromHome, isControlPanel, homeUnits }) {
	const { t } = useTranslation("common");
	const history = useHistory();
	const [title, setTitle] = useState(null);
	const [seniorsSelected, setSeniorsSelected] = useState([]);
	const [uploadSize, setUploadSize] = useState(0);
	const [postContent, setPostContent] = useState("");
	const [contentError, setContentError] = useState(false);
	const [progress, setProgress] = useState({ size: 0, maxSize: 0 });
	const [medias, setMedias] = useState([]);
	const initialDate = handleDate(new Date(), "yyyy-MM-dd");
	const [selectedDate, setSelectedDate] = useState(initialDate);

	const onSubmit = e => {
		e.preventDefault();
		if (postContent.length > 4 && title.length > 3) {
			blogDispatch({
				type: "LOADING_NEWS"
			});
			setContentError(false);
			const postDate = handleDate(selectedDate, "yyyy-MM-dd' 'HH:mm:ss");

			const blogPostData = new FormData();
			for (let i = 0; i < medias.length; i++) {
				blogPostData.append("medias[]", medias[i]);
			}
			blogPostData.append("title", title);
			blogPostData.append("body", postContent);
			blogPostData.append("date", postDate);
			blogPostData.append("home_id", homeId);
			blogPostData.append("senior_ids", JSON.stringify(seniorsSelected));

			http
				.post(`${url.HOMESURL}/blog_posts`, blogPostData, {
					onUploadProgress: progressEvent => {
						setProgress({ size: progressEvent.loaded, maxSize: progressEvent.total + 1500 });
					}
				})
				.then(res => {
					blogDispatch({
						type: "ADD_NEWS",
						payload: res.data.blog_post
					});
					if (isControlPanel) {
						history.push("/controlpanel/manageposts");
					} else {
						history.push("/blog/news");
					}
				})
				.catch(() => {
					blogDispatch({
						type: "ERROR_NEWS"
					});
				});
		} else {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth"
			});
			setContentError(true);
		}
	};

	return (
		<>
			{blogStore.error && <p className="error-msg">{t("news.__addNewsError__")}</p>}
			{contentError ? (
				<p className="error-msg">
					<span className="right" role="button" tabIndex="0" onClick={() => setContentError(false)}>
						<FaTimes />
					</span>
					{t("news.__addNewsContentError__")}
				</p>
			) : null}
			{blogStore.loading ? (
				<div className="header-details center">
					<Progressbar
						size={progress.size}
						maxSize={progress.maxSize}
						isLoader
						alertMessage={t("news.__addNewsLoading__")}
					/>
				</div>
			) : (
				<form onSubmit={onSubmit} encType="multipart/form-data">
					<PostManager
						seniors={seniorsFromHome}
						postContent={postContent}
						setPostContent={setPostContent}
						title={title}
						setTitle={setTitle}
						selectedDate={selectedDate}
						setSelectedDate={setSelectedDate}
						medias={medias}
						setMedias={setMedias}
						setSeniorsSelected={setSeniorsSelected}
						uploadSize={uploadSize}
						setUploadSize={setUploadSize}
						seniorsSelected={seniorsSelected}
						initialSeniorsSelected={[]}
						homeUnits={homeUnits}
						editMedias={true}
						initialMedias={[]}
					/>
				</form>
			)}
		</>
	);
}
