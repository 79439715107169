import React, { Dispatch, useEffect } from "react";
import { UserInterface } from "../interfaces/UserInterface";
import { HomeStoreInterface } from "../interfaces/HomeInterface";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import HomesSecondaryNav from "../layout/HomesSecondaryNav";
import HomesList from "../components/Home/Homes/HomesList";
import http from "../services/Interceptor";
import * as url from "../constants/Url";

interface Props {
	groupId: number | null;
	userState: UserInterface;
	setHomeId: (homeId: number | null) => void;
	homeStore: HomeStoreInterface;
	homeDispatch: Dispatch<any>;
}

const MyGroupHomes = ({ groupId, setHomeId, userState, homeStore, homeDispatch }: Props): JSX.Element => {
	const isGroupManager = userState.userConnected.group_id ? true : false;

	useEffect(() => {
		if (groupId) {
			http
				.get(`${url.BASEURL}/home_groups/${groupId}`)
				.then(res => {
					homeDispatch({
						type: "GET_MY_GROUP",
						payload: res.data.home_group
					});
				})
				.catch(() => {
					homeDispatch({
						type: "GET_MY_GROUP",
						payload: null
					});
				});
		}
		// eslint-disable-next-line
	}, [groupId]);

	useEffect(() => {
		if (isGroupManager && homeStore.home.allHomes.length === 0) {
			http
				.get(`${url.BASEURL}/home_groups/${groupId}?homes=true`)
				.then(res => {
					homeDispatch({
						type: "GET_ALL_HOMES",
						payload: res.data.home_group.homes
					});
				})
				.catch(() => {
					homeDispatch({
						type: "GET_ALL_HOMES",
						payload: []
					});
				});
		}
		// eslint-disable-next-line
	}, [isGroupManager, groupId]);

	return (
		<PageWithSecondaryNavLayout
			secondaryNav={<HomesSecondaryNav userState={userState} />}
			component={
				<HomesList isGroupManager={isGroupManager} groupId={groupId} homeStore={homeStore} setHomeId={setHomeId} />
			}
		/>
	);
};
export default MyGroupHomes;
