import React from "react";
import "./App.scss";
import "./Print.scss";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import ScrollToTop from "../hooks/ScrollToTop";

// CONTEXTS
import { HomeContextProvider } from "../context/HomeContext";
import { UserContextProvider } from "../context/UserContext";
import { NotificationsContextProvider } from "../context/NotificationsContext";
import { BlogContextProvider } from "../context/BlogContext";
import { DiaperContextProvider } from "../context/DiaperContext";

// PUBLIC Pages
import Login from "../pages/Login";
import NewPasswordContainer from "../components/UserSettings/NewPasswordContainer";
import Welcome from "../pages/Welcome";
import ProtectedApp from "./ProtectedApp";

const App: React.FC = () => {
	const queryClient = new QueryClient();
	return (
		<UserContextProvider>
			<HomeContextProvider>
				<NotificationsContextProvider>
					<BlogContextProvider>
						<DiaperContextProvider>
							<BrowserRouter>
								<ScrollToTop />
								<Switch>
									<Route exact path="/" component={Login} />
									<Route exact path="/newpassword/:token" component={NewPasswordContainer} />
									<Route exact path="/welcome/:token" component={Welcome} />
									<QueryClientProvider client={queryClient}>
										<ProtectedApp />
									</QueryClientProvider>
								</Switch>
							</BrowserRouter>
						</DiaperContextProvider>
					</BlogContextProvider>
				</NotificationsContextProvider>
			</HomeContextProvider>
		</UserContextProvider>
	);
};
export default App;
