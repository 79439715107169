import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UserInterface } from "../interfaces/UserInterface";
import { HealthWorkersTabs } from "../utils/Tabs/TabsToDisplay";
import useStoreData from "../hooks/useStoreData";
import AccessControl from "../layout/AccessControl";
import HeaderContainer from "../layout/HeaderContainer";
import SelectHomeInput from "../layout/SelectHomeInput";
import HealthWorkersBadges from "../components/HealthWorkers/Badges/HealthWorkersBadges";
import Tabs from "../utils/Tabs/Tabs";
import DisplayName from "../utils/DisplayName";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const HealthWorker_Badges = ({ homeId, userState }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const { store, dispatch } = useStoreData({ userState, homeId });
	const healthWorkers = store.users.healthWorkers;

	useEffect(() => {
		if (homeId) {
			http.get(`${url.HOMESURL}/${homeId}/user_badges`).then(res => {
				const data = res.data.user_badges;
				const fullBadge = data.map((badge: any) => {
					return {
						...badge,
						affectation: DisplayName(badge.user_id, healthWorkers)
					};
				});
				dispatch({
					type: "GET_BADGES",
					payload: fullBadge
				});
			});
		}
	}, [homeId, healthWorkers]);

	useEffect(() => {
		return function cleanup(): void {
			dispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_USER_AND_BADGE]}>
			<Tabs tabsToDisplay={HealthWorkersTabs} currentTab={"BADGES"} />
			<div className="big-card">
				{homeId ? (
					<>
						<HeaderContainer
							title={t("badge.__badges__")}
							addButton={t("badge.__addBadge__")}
							link={"/user/hwbagde/addbadge/add"}
							permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_USER_AND_BADGE]}
							userState={userState}
						/>
						<HealthWorkersBadges store={store} dispatch={dispatch} />
					</>
				) : (
					<SelectHomeInput />
				)}
			</div>
		</AccessControl>
	);
};
export default HealthWorker_Badges;
