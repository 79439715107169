import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NotificationsContext } from "../context/NotificationsContext";
import { UserInterface } from "../interfaces/UserInterface";
import { ModerationTabs } from "../utils/Tabs/TabsToDisplay";
import useStoreData from "../hooks/useStoreData";
import SelectHomeInput from "../layout/SelectHomeInput";
import AccessControl from "../layout/AccessControl";
import HeaderContainer from "../layout/HeaderContainer";
import HandleError from "../layout/HandleError";
import HandleSuccess from "../layout/HandleSuccess";
import NewsModeration from "../components/Moderation/NewsModeration";
import Tabs from "../utils/Tabs/Tabs";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const Moderation_Blog = ({ homeId, userState }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const { notificationsState, notificationsDispatch } = useContext(NotificationsContext);
	const { store } = useStoreData({ userState, homeId });
	const canModerateFamilyElts = userState.userConnected.is_in([
		p.ADMIN,
		p.SUPER_ADMIN,
		p.CAN_MODERATE_SENIOR_AVATAR,
		p.CAN_MODERATE_SENIOR_LIFE_STORIES,
		p.CAN_MODERATE_PHOTO_FRAME
	]);

	useEffect(() => {
		return function cleanup(): void {
			notificationsDispatch({
				type: "RESET_ES"
			});
		};
	}, []);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.SUPER_ADMIN, p.ADMIN, p.CAN_MODERATE_HOME_BLOG]}>
			{canModerateFamilyElts ? <Tabs tabsToDisplay={ModerationTabs} currentTab={"BLOG_ELTS"} /> : null}
			<div className="big-card">
				<HeaderContainer
					title={t("moderation.__moderation__")}
					addButton={t("common.__params__")}
					link={"/manage/me/myhome"}
					permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_UPDATE_HOME]}
					userState={userState}
				/>
				<HandleError
					error={notificationsState.moderationError}
					dispatch={notificationsDispatch}
					message={t("moderation.__moderationError__")}
					noSpace
				/>
				<HandleSuccess
					success={notificationsState.moderationSuccess}
					dispatch={notificationsDispatch}
					message={t("moderation.__newsSuccess__")}
					noSpace
				/>

				{homeId ? (
					<NewsModeration
						usersFromHome={store.users.healthWorkers}
						notificationsDispatch={notificationsDispatch}
						homeId={homeId}
						userLocale={userState.userConnected.locale}
					/>
				) : (
					<SelectHomeInput />
				)}
			</div>
		</AccessControl>
	);
};
export default Moderation_Blog;
