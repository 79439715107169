import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import http from "../../../services/Interceptor";
import * as constant from "../../../constants/Url";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import MyInputText from "../../../utils/Form/MyInputText";

const EditHomeGroup = ({ editThisGroup, setEditThisGroup, homeDispatch }) => {
	const { t } = useTranslation("common");
	const methods = useForm();
	const { handleSubmit } = methods;
	const group = editThisGroup.group;
	const [error, setError] = useState(false);

	const onSubmit = data => {
		setError(false);
		const groupData = {
			home_group: {
				name: data.homeGroup
			}
		};
		http
			.put(`${constant.BASEURL}/home_groups/${group.id}`, groupData)
			.then(res => {
				homeDispatch({
					type: "EDIT_GROUP",
					payload: res.data.home_group
				});
				homeDispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("home.__updateGroupSuccess__")
				});
			})
			.then(() => setEditThisGroup({ edit: false, group: null }))
			.catch(() => setError(true));
	};

	return (
		<div className="small-form">
			<h5 className="blue">{t("home.__updateGroup__")} :</h5>

			{error && (
				<p className="error-msg mt-2">
					<span className="right" role="button" tabIndex="0" onClick={() => setError(false)}>
						<FaTimes />
					</span>
					{t("common.__errorOccured__")} {t("common.__editError__")}
				</p>
			)}
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<MyInputText label={t("common.__name__")} name="homeGroup" behavior={{ required: true }} value={group.name} />
					<button
						className="btn btn-outline-secondary btn-sm mr-2"
						type="button"
						onClick={() => setEditThisGroup({ edit: false, group: null })}
					>
						{t("common.__cancel__")}
					</button>
					<button className="btn btn-primary btn-sm" type="submit">
						{t("common.__update__")}
					</button>
				</form>
			</FormProvider>
		</div>
	);
};

export default EditHomeGroup;
