import i18n from "../i18n/i18next";

export function eventTypes(type) {
	switch (type) {
		case 1:
			return i18n.t("common:calendar.__workshop__").toUpperCase();
		case 2:
			return i18n.t("common:calendar.__event__").toUpperCase();
		case 11:
			return i18n.t("common:calendar.__visio__").toUpperCase();
		default:
			return null;
	}
}

export const eventRecurringTypes = [
	{
		id: 1,
		name: i18n.t("common:calendar.__noRecurring__")
	},
	{
		id: 2,
		name: i18n.t("common:calendar.__everyDays__")
	},
	{
		id: 3,
		name: i18n.t("common:calendar.__everyWeekDays__")
	},
	{
		id: 4,
		name: i18n.t("common:calendar.__everySelectedDays__")
	},
	{
		id: 5,
		name: i18n.t("common:calendar.__everyMonths__")
	}
];

export const calendarEntriesTypes = [
	{ id: 1, name: i18n.t("common:calendar.__workshop__") },
	{ id: 2, name: i18n.t("common:calendar.__event__") }
];

export function displayDay(day) {
	switch (day) {
		case 1:
			return i18n.t("common:common.__monday__");
		case 2:
			return i18n.t("common:common.__tuesday__");
		case 3:
			return i18n.t("common:common.__wednesday__");
		case 4:
			return i18n.t("common:common.__thursday__");
		case 5:
			return i18n.t("common:common.__friday__");
		case 6:
			return i18n.t("common:common.__saturday__");
		case 7:
			return i18n.t("common:common.__sunday__");
		default:
			return;
	}
}
