import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FaEye, FaRegEyeSlash, FaTimes } from "react-icons/fa";
import { HandleVersion } from "../layout/HandleVersion";
import { UserContext } from "../context/UserContext";
import ForgotPassword from "../components/UserSettings/ForgotPassword";
import Rgpd from "../components/UserSettings/Rgpd";
import * as p from "../constants/Permissions";

interface FormValues {
	login: string;
	password: string;
}

const Login = (): JSX.Element => {
	const { t } = useTranslation("common");
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<FormValues>();
	const { login, userState, userDispatch } = useContext(UserContext);
	const history = useHistory();
	const [lostPassword, setLostPassword] = useState(false);
	const [show, setShow] = useState(false);
	const [viewPassword, setViewPassword] = useState(false);

	const onSubmit = (data: FormValues): void => {
		userDispatch({
			type: "LOADING"
		});
		const loginData = {
			user: {
				login: data.login,
				password: data.password
			}
		};
		login(
			loginData,
			(res: { permissions: number[]; has_accepted_rgpd: boolean; home_id: number | null; group_id: number | null }) => {
				if (res.permissions.includes(p.CONTROL_PANEL)) {
					history.push("/controlpanel/home");
				} else {
					if (!res.has_accepted_rgpd) {
						setShow(true);
					} else {
						if (res.home_id) {
							if (res.permissions.includes(p.DIAPER_APPLICATION)) {
								history.push("/homeboard");
							} else if (res.permissions.includes(p.CAN_MANAGE_HOME_UNIT)) {
								history.push("/manage/mainboard");
							} else {
								history.push("/blog");
							}
						} else if (res.group_id) {
							history.push(`/manage/organisation/myhomes`);
						} else if (res.permissions.includes(p.ADMIN)) {
							history.push("/homeboard");
						} else if (res.permissions.includes(p.SUPER_ADMIN)) {
							history.push("/support/tenant/admintenants");
						} else {
							history.push("/blog");
						}
					}
				}
			}
		);
	};

	interface RgpdModalProps {
		show: boolean;
		setShow: (a: boolean) => void;
	}

	const RgpdModal = ({ show, setShow }: RgpdModalProps): JSX.Element | null => {
		return show ? (
			<div className="overlay">
				<div className="modal-rgpd">
					<p className="close-modal" role="button" onClick={(): void => setShow(false)}>
						<FaTimes />
					</p>
					<Rgpd setShow={setShow} />
				</div>
			</div>
		) : null;
	};

	return (
		<>
			<div className="login-container mx-auto text-center">
				{process.env.REACT_APP_EFORLINK_LOGO === "true" ? (
					<img src="/img/Logo-eforlink.png" alt="eForLink" className="login-logo mb-5" />
				) : (
					<img src="/img/amd/amdlink.png" alt="amd link" className="amd-login-logo mb-5" />
				)}

				{lostPassword ? (
					<ForgotPassword setLostPassword={setLostPassword} fromWelcomePage={false} />
				) : (
					<form onSubmit={handleSubmit(onSubmit)}>
						{userState.loginError ? <p className="error-msg">{t("login.__loginError__")}</p> : null}

						<div className="form-group">
							<label htmlFor="login">{t("common.__login__")}</label>
							<input
								type="text"
								className="form-control"
								id="login"
								aria-describedby="loginHelp"
								{...register("login", { required: true })}
								name="login"
							/>
							<small id="loginHelp" className="form-text text-muted">
								{errors.login && t("common.__required__")}
							</small>
						</div>
						<div className="form-group">
							<div className="input-password">
								<label htmlFor="password">{t("common.__password__")}</label>
								<input
									type={viewPassword ? "text" : "password"}
									className="form-control"
									id="password"
									{...register("password", { required: true })}
									name="password"
									aria-describedby="passwordHelp"
								/>
								<button
									className="view-password"
									type="button"
									onClick={(): void => setViewPassword(prevViewPassword => !prevViewPassword)}>
									{viewPassword ? <FaRegEyeSlash /> : <FaEye />}
								</button>
								<small id="emailHelp" className="form-text text-muted">
									{errors.password && t("common.__required__")}
								</small>
							</div>
						</div>
						<br />
						<p className="form-text button-text" onClick={(): void => setLostPassword(true)}>
							{t("login.__lostPassword__")}
						</p>
						<br />
						<button type="submit" className="btn btn-primary">
							{t("users.__loginBtn__")}
						</button>
					</form>
				)}
			</div>
			<div className="app-version">{HandleVersion()}</div>
			<RgpdModal show={show} setShow={setShow} />
		</>
	);
};

export default Login;
