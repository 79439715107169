import React, { useState } from "react";
import { DisplayVideoModal } from "../NewsMediaModal";
import { BsCameraVideo } from "react-icons/bs";
import { fileSize } from "../../../utils/Blogging";
import { FaTimes } from "react-icons/fa";
import ModalContainer from "../../../utils/ModalContainer";

export default function VideosManager({ setUploadSize, medias, setMedias, initialMedias, setInitialMedias }) {
	const [showVideo, setShowVideo] = useState({ show: false, info: { video: null } });
	const videoFiles = medias.filter(media => media.type.split("/")[0] === "video");
	const existingVideoFiles =
		initialMedias.length > 0 ? initialMedias.filter(media => media.content_type.split("/")[0] === "video") : [];

	const removeInitialVideo = (path, size) => {
		const newMediasList = initialMedias.filter(f => f.path !== path);
		setInitialMedias(newMediasList);
		setUploadSize(uploadSize => uploadSize - size);
	};

	const removeVideo = (name, size) => {
		let newVideoList = medias.filter(f => f.name !== name);
		setMedias(newVideoList);
		setUploadSize(uploadSize => uploadSize - size);
	};

	return (
		<>
			{existingVideoFiles && existingVideoFiles.length > 0
				? existingVideoFiles.map((video, index) => (
						<div className="header-details" key={index}>
							<div className="d-flex justify-content-between">
								<div role="button" onClick={() => setShowVideo({ show: true, info: { video: video } })}>
									<BsCameraVideo className="mr-3 ml-2 blue" style={{ width: "24px", height: "auto" }} />{" "}
									<b className="fake-link blue">{video.filename}</b> ( {fileSize(video.content_length)} )
								</div>
								<button
									type="button"
									className="post-suppr-btn right"
									onClick={() => removeInitialVideo(video.path, video.content_length)}>
									<FaTimes style={{ marginLeft: "-3px", marginTop: "-3px" }} />
								</button>
							</div>
						</div>
				  ))
				: null}
			{videoFiles.length > 0 &&
				videoFiles.map((video, index) => (
					<div key={index} className="header-details">
						<button type="button" className="post-suppr-btn right" onClick={() => removeVideo(video.name, video.size)}>
							<FaTimes style={{ marginLeft: "-3px", marginTop: "-3px" }} />
						</button>
						<BsCameraVideo className="mr-3 ml-2" style={{ width: "24px", height: "auto" }} /> <b>{video.name}</b> ({" "}
						{fileSize(video.size)} )
					</div>
				))}
			<ModalContainer show={showVideo} setShow={setShowVideo}>
				<DisplayVideoModal show={showVideo} />
			</ModalContainer>
		</>
	);
}
