import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MyInputText from "../../../utils/Form/MyInputText";
import MyInputSelect from "../../../utils/Form/MyInputSelect";
import DisplayName from "../../../utils/DisplayName";
import http from "../../../services/Interceptor";
import MyInputNumber from "../../../utils/Form/MyInputNumber";
import HandleError from "../../../layout/HandleError";
import HeaderContainer from "../../../layout/HeaderContainer";
import * as API from "../../../services/API";
import * as url from "../../../constants/Url";
import * as p from "../../../constants/Permissions";

const EditRoom = ({ homeId, roomId, store, homeDevicesStore, dispatch, userState }) => {
	const { t } = useTranslation("common");
	const rooms = store.home.rooms;
	const homeUnits = store.home.homeUnits;
	const seniorsFromHome = store.seniors.seniorsFromHome;

	const history = useHistory();
	const [availableDevices, setAvailableDevices] = useState([]);
	const devicesFromHome = homeDevicesStore.devices.devices;
	const roomDetails = rooms.find(room => parseInt(roomId) === parseInt(room.id));

	const methods = useForm();
	const { handleSubmit } = methods;

	const seniorsWithoutRoom = seniorsFromHome.filter(senior => !senior.room);
	const availableSeniors = roomDetails.senior_id
		? [
				{ id: roomDetails.senior_id, name: DisplayName(roomDetails.senior_id, seniorsFromHome) },
				...seniorsWithoutRoom,
				{ id: 0, name: t("common.__noSenior__") }
		  ]
		: [{ id: 0, name: t("common.__noSenior__") }, ...seniorsWithoutRoom];

	useEffect(() => {
		let mounted = true;
		if (homeId && mounted) {
			API.getAvailableDevices(
				homeId,
				res => {
					const availableDevicesList = res.map(function (device) {
						return {
							...device,
							name: device.serial_number
						};
					});
					if (roomDetails.device_id) {
						setAvailableDevices([
							{ id: roomDetails.device_id, name: DisplayName(roomDetails.device_id, devicesFromHome) },
							...availableDevicesList,
							{ id: 0, name: t("device.__roomWithoutDevice__") }
						]);
					} else {
						setAvailableDevices([{ id: 0, name: t("home.__noDeviceInRoom__") }, ...availableDevicesList]);
					}
				},
				() => {
					setAvailableDevices([{ id: 0, name: t("home.__noDeviceInRoom__") }]);
				}
			);
		}
		return () => (mounted = false);
		// eslint-disable-next-line
	}, [homeId, devicesFromHome, roomDetails]);

	const onSubmit = data => {
		const roomData = {
			room: {
				device_id: parseInt(data.device) === 0 ? null : parseInt(data.device),
				floor: parseInt(data.floor),
				home_id: parseInt(homeId),
				name: data.name,
				senior_id: parseInt(data.seniorId) === 0 ? null : parseInt(data.seniorId),
				setup: data.setup,
				home_unit_id: parseInt(data.homeUnit),
				id: roomId
			}
		};
		http
			.put(`${url.BASEURL}/rooms/${roomId}`, roomData)
			.then(res => {
				dispatch({
					type: "EDIT_ROOM",
					payload: res.data.room
				});
				dispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("home.__editRoomSuccess__", { name: res.data.room.name })
				});
				history.push("/manage/room/rooms");
			})
			.catch(() => {
				dispatch({
					type: "DISPLAY_ERROR"
				});
			});
	};

	return (
		<div className="big-card">
			<HeaderContainer
				title={t("home.__theRooms__")}
				addButton={t("home.__addRoom__")}
				link={"/manage/room/addroom/add"}
				permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_HOME_UNIT]}
				userState={userState}
			/>
			<FormProvider {...methods}>
				<h5 className="blue">
					{t("home.__updateRoom__")} : {roomDetails.name}
				</h5>

				<HandleError error={store.es.error} dispatch={dispatch} message={t("common.__editError__")} />

				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="form-card">
						<div className="row">
							<div className="col-md-6">
								<MyInputText label={t("common.__name__")} name="name" behavior={{ required: true }} value={roomDetails.name} />
							</div>
							<div className="col-md-6">
								<MyInputNumber
									label={t("home.__floor__")}
									name="floor"
									behavior={{ pattern: /[0-9]/ }}
									value={roomDetails.floor}
								/>
							</div>
						</div>
					</div>
					<div className="form-card">
						<h5 className="blue">{t("common.__assignment__")}:</h5>
						{roomDetails.home_unit_id ? (
							<MyInputSelect
								label={t("common.__homeUnit__")}
								name="homeUnit"
								behavior={{ required: true }}
								value={roomDetails.home_unit_id}
								list={homeUnits}
							/>
						) : (
							<MyInputSelect label={t("common.__homeUnit__")} name="homeUnit" behavior={{ required: true }} list={homeUnits} />
						)}
						{roomDetails.senior_id === null ? (
							<MyInputSelect
								label={t("common.__senior__")}
								name="seniorId"
								behavior={{ required: false }}
								list={availableSeniors}
							/>
						) : (
							<MyInputSelect
								label={t("home.__seniorAssignmentToRoom__")}
								name="seniorId"
								behavior={{ required: false }}
								value={roomDetails.senior_id}
								list={availableSeniors}
							/>
						)}

						<div className="row">
							<div className="col-md-6">
								{roomDetails.device_id ? (
									<MyInputSelect
										label={t("common.__device__")}
										name="device"
										behavior={{ required: false }}
										value={roomDetails.device_id}
										list={availableDevices}
									/>
								) : (
									<MyInputSelect
										label={t("common.__device__")}
										name="device"
										behavior={{ required: false }}
										list={availableDevices}
									/>
								)}
							</div>
							<div className="col-md-6">
								<MyInputText
									label={t("home.__deviceSetUp__")}
									name="setup"
									behavior={{ required: false }}
									value={roomDetails.setup}
								/>
							</div>
						</div>
					</div>

					<button className="btn btn-outline-secondary btn-sm mr-2" type="button" onClick={() => history.goBack()}>
						{t("common.__cancel__")}
					</button>
					<button className="btn btn-primary btn-sm" type="submit">
						{t("common.__save__")}
					</button>
				</form>
			</FormProvider>
		</div>
	);
};

export default EditRoom;
