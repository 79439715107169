import i18n from "../i18n/i18next";

const genderList = [
	{
		name: i18n.t("common:common.__miss__"),
		id: "F"
	},
	{
		name: i18n.t("common:common.__mister__"),
		id: "M"
	}
];

export default genderList;
