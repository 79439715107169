import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Table, SelectColumnFilter } from "../../utils/Table/TableWithFilter";
import { FaRegCheckCircle, FaRegBell, FaAngleRight } from "react-icons/fa";
import { handleRulesTypes, getTiming } from "../Home/NotificationsRules/RulesDetails";
import { checkNotification } from "./NotificationsUtils";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import useNotifications from "../../hooks/useNotifications";
import useStoreData from "../../hooks/useStoreData";
import DisplayName from "../../utils/DisplayName";
import SelectHomeInput from "../../layout/SelectHomeInput";
import HeaderWithRangeContainer from "../../layout/HeaderWithRangeContainer";
import useDateBeforeOrAfter from "../../hooks/useDateBeforeOrAfter";
import NoData from "../../layout/NoData";
import * as p from "../../constants/Permissions";
import "./AlertBoard.scss";
import { displayRuleCategory } from "../Home/NotificationsRules/RuleUtils";

export default function NotificationsList({ homeId, userState }) {
	const { t } = useTranslation("common");
	const { store } = useStoreData({ homeId, userState });
	const userLocale = userState.userConnected.locale;
	const users = useMemo(() => (store ? store.users.healthWorkers : []), [store]);
	const canCheckNotifications = useMemo(() => userState.userConnected.is(p.CAN_CHECK_NOTIFICATIONS), []);
	const canManageRules = useMemo(
		() => userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_CHECKER_RULE]),
		[]
	);
	const today = new Date();
	const last3Days = useDateBeforeOrAfter({ isBefore: true, numberOfDays: 3 });
	const [startDate, setStartDate] = useState(last3Days);
	const [endDate, setEndDate] = useState(today);
	const [notificationsStore, notificationsDispatch] = useNotifications({
		store,
		homeId,
		startDate,
		endDate,
		userLocale
	});

	const handleNotificationDetails = (type, when) => {
		switch (type) {
			case 2:
				return when.map((action, index) => (
					<span className="meta" key={index}>
						- {handleDate(action, "Pp", userState.userConnected.locale)}
						<br />
					</span>
				));
			case 5:
				return (
					<span className="meta">
						{t("notifications.__late__")}
						{getTiming(parseInt(when))}
					</span>
				);
			default:
				return;
		}
	};

	const columns = [
		{
			Header: t("common.__date__"),
			accessor: "date",
			Filter: SelectColumnFilter,
			Cell: row => {
				return (
					<>
						{row.row.original.rule.type === 6
							? null
							: row.row.original.rule.type === 2
							? handleDate(row.row.original.info.when[1], "p", userState.userConnected.locale)
							: handleDate(row.row.original.info.when, "p", userState.userConnected.locale)}
						<br />
						<span className="small-meta">
							{row.row.original.rule.type === 6
								? null
								: row.row.original.rule.type === 2
								? handleDate(row.row.original.info.when[1], "PP", userState.userConnected.locale)
								: handleDate(row.row.original.info.when, "PP", userState.userConnected.locale)}
						</span>
					</>
				);
			}
		},
		{
			Header: t("common.__category__"),
			accessor: "category",
			Filter: SelectColumnFilter,
			Cell: row => displayRuleCategory(row.row.values.category)
		},
		{
			Header: t("common.__notification__"),
			accessor: "checker_rule_name",
			Cell: row => {
				return (
					<>
						<strong>{row.row.values.checker_rule_name}</strong>
						<br />
						<span className="red">{handleRulesTypes(row.row.original.rule.type)}</span>
						<br />
						{row.row.original.rule.type === 2
							? handleNotificationDetails(row.row.original.rule.type, row.row.original.info.when)
							: null}
						{row.row.original.rule.type === 5
							? handleNotificationDetails(row.row.original.rule.type, row.row.original.rule.max_seconds_after)
							: null}
					</>
				);
			}
		},
		{
			Header: t("common.__homeUnit__"),
			accessor: "homeUnit",
			Filter: SelectColumnFilter
		},
		{
			Header: t("common.__senior__"),
			accessor: "senior",
			Filter: SelectColumnFilter,
			Cell: row => {
				return (
					<>
						<Link to={`/senior/profile/${row.row.original.senior_id}`}>
							<b>{row.row.values.senior}</b>
						</Link>
						<br />
						<span className="meta">
							{t("common.__room__")} {row.row.original.room}
						</span>
					</>
				);
			}
		},
		{
			Header: t("common.__status__"),
			accessor: "displayCheck",
			Filter: SelectColumnFilter,
			Cell: row => {
				return (
					<>
						{canCheckNotifications ? (
							<>
								{row.row.original.checked ? (
									<>
										<FaRegCheckCircle className="table-button-icon" />
										{DisplayName(row.row.original.checked_by, users)}
										<br />
										<span className="meta">
											{handleDate(row.row.original.checked_when, "Pp", userState.userConnected.locale)}
										</span>
									</>
								) : (
									<span
										className="category-outline-badge red"
										role="button"
										tabIndex="0"
										onClick={() => checkNotification(row.row.original.id, notificationsDispatch)}>
										<FaRegBell className="table-button-icon" /> {t("rules.__todo__")}
									</span>
								)}
							</>
						) : (
							<>
								{row.row.original.checked ? (
									<>
										<FaRegCheckCircle className="table-button-icon" />
										{DisplayName(row.row.original.checked_by, users)}
										<br />
										<span className="meta">
											{handleDate(row.row.original.checked_when, "Pp", userState.userConnected.locale)}
										</span>
									</>
								) : (
									<span className="red center">
										<FaRegBell className="table-button-icon" />
										{t("rules.__todo__")}
									</span>
								)}
							</>
						)}
					</>
				);
			}
		}
	];

	return (
		<div className="big-card">
			<HeaderWithRangeContainer
				title={t("notifications.__notifications__")}
				startDate={startDate}
				setStartDate={setStartDate}
				endDate={endDate}
				setEndDate={setEndDate}
				userLocale={userState.userConnected.locale}
			/>
			<div className="d-flex justify-content-end">
				{canManageRules ? (
					<Link to={`/manage/nr/rule/add`}>
						<button className="mt-2 mr-2 right btn btn-sm btn-primary">{t("rules.__addNotificationRules__")}</button>
					</Link>
				) : null}
				<Link to="/manage/nr/notificationrules">
					<button className="mt-2 right btn btn-sm btn-outline-primary">{t("rules.__notificationRules__")}</button>
				</Link>
			</div>

			{notificationsStore.error ? (
				<NoData />
			) : homeId ? (
				<div className="mt-3">
					{notificationsStore.data.length > 0 ? (
						<Table columns={columns} data={notificationsStore.data} />
					) : (
						<p>
							<FaAngleRight className="mr-2" />
							<b>{t("common.__selectNewDate__")}</b>
						</p>
					)}
				</div>
			) : (
				<SelectHomeInput />
			)}
		</div>
	);
}
