import React from "react";
import { useTranslation } from "react-i18next";
import { FaAngleRight, FaTimesCircle } from "react-icons/fa";
import { NewSeniorInterface } from "../../../interfaces/UserInterface";
import HandleError from "../../../layout/HandleError";
import CreateFamilyUser from "./CreateFamilyUser";
import SearchFamilyUser from "./SearchFamilyUser";

interface Props {
	newFamilyStore: NewSeniorInterface;
	newFamilyDispatch: React.Dispatch<any>;
	isModal: boolean;
}

const CreateFamilyUsersContainer = ({ newFamilyStore, newFamilyDispatch, isModal }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const familiesSelected = newFamilyStore.familyUsersSelected.concat(newFamilyStore.newFamilyUsers);

	const buttons = (): JSX.Element => {
		return (
			<>
				<button
					type="button"
					aria-label="add-family-button"
					onClick={(): void => newFamilyDispatch({ type: "DISPLAY_ADD_BOX" })}
					className={newFamilyStore.displayAddFamilyUser ? "category-badge grey" : "category-outline-badge grey"}>
					{t("senior.__addFamilyUser__")}
				</button>
				<button
					type="button"
					aria-label="search-family-button"
					onClick={(): void => newFamilyDispatch({ type: "DISPLAY_SEARCH_BOX" })}
					className={newFamilyStore.displaySearchFamilyUser ? "category-badge grey" : "category-outline-badge grey"}>
					{t("family.__searchFamilyUser__")}
				</button>
			</>
		);
	};

	return (
		<>
			<HandleError
				error={newFamilyStore.error.showError}
				dispatch={newFamilyDispatch}
				message={newFamilyStore.error.errorMsg}
			/>
			{isModal ? (
				buttons()
			) : (
				<div className="d-flex justify-content-between">
					<p className="mt-4">
						<FaAngleRight /> <b>{t("family.__principals__")}</b>
					</p>
					<div className="mt-3">{buttons()}</div>
				</div>
			)}
			{familiesSelected.length === 0 ? null : (
				<div className="header-details" data-testId="family-users-selected-block">
					{familiesSelected.map(family => (
						<p key={family.email} aria-label="family-name">
							<FaTimesCircle
								className="red mr-3"
								role="button"
								aria-label="remove-family-button"
								onClick={(): void => newFamilyDispatch({ type: "DEL_SELECTED_FAMILY", payload: family })}
							/>
							<b>
								{family.last_name} {family.first_name}
							</b>{" "}
							( {family.email} )
						</p>
					))}
				</div>
			)}
			<SearchFamilyUser newFamilyStore={newFamilyStore} newFamilyDispatch={newFamilyDispatch} />
			<CreateFamilyUser newFamilyStore={newFamilyStore} newFamilyDispatch={newFamilyDispatch} />
		</>
	);
};

export default CreateFamilyUsersContainer;
