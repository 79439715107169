import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Progressbar } from "@e4ia/e4link_modules.progressbar";
import { HealthWorkerTypesInterface, UserFormInterface } from "../../interfaces/UserInterface";
import DisplayName from "../../utils/DisplayName";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import HandleError from "../../layout/HandleError";

interface Props {
	hwFormStore: UserFormInterface;
	hwFormDispatch: React.Dispatch<any>;
	hwTypes: HealthWorkerTypesInterface[];
	dispatch: React.Dispatch<any>;
}

const AddHealthWorkerResume = ({ hwFormStore, hwFormDispatch, dispatch, hwTypes }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const { handleSubmit } = useForm();
	const history = useHistory();
	const [progress, setProgress] = useState({ size: 0, maxSize: 0 });
	const [error, setError] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const onSubmit = (): void => {
		setError(false);
		setErrorMessage(null);
		const data = {
			user: {
				email: hwFormStore.user.email,
				login: hwFormStore.user.login,
				password: hwFormStore.user.password ? hwFormStore.user.password : null,
				first_name: hwFormStore.user.first_name ? hwFormStore.user.first_name : "badge_general",
				last_name: hwFormStore.user.last_name
					? hwFormStore.user.last_name
					: hwFormStore.user.type_id
					? DisplayName(hwFormStore.user.type_id, hwTypes)
					: "badge_general",
				type_id: hwFormStore.user.type_id,
				badges: hwFormStore.role.home_id && hwFormStore.badge ? [hwFormStore.badge.id] : null,
				phone: hwFormStore.user.phone,
				mobile_phone: hwFormStore.user.mobile_phone,
				home_id: hwFormStore.role.home_id,
				group_id: hwFormStore.role.group_id,
				tenant_id: hwFormStore.role.tenant_id,
				permissions: hwFormStore.role.permissions
			}
		};
		http
			.post(`${url.BASEURL}/users`, data, {
				onUploadProgress: progressEvent => {
					setProgress({ size: progressEvent.loaded, maxSize: progressEvent.total + 500 });
				}
			})
			.then((res: any) => {
				hwFormDispatch({
					type: "EMPTY_NEW_USER_STATE"
				});
				dispatch({
					type: "ADD_HEALTHWORKER",
					payload: res.data.user
				});
				history.push(`/user/healthworker/${res.data.user.id}`);
			})
			.catch((err: any) => {
				// console.log(err.response.request.responseText);
				if (
					err.response.request.responseText ===
					JSON.stringify({ errors: { permissions: ["Relation Permissions/Tenant/HomeGroup/Home inconsistency"] } })
				) {
					setError(true);
					setErrorMessage(t("common.__homeSelection__"));
					// dispatch({
					// 	type: "DISPLAY_ERROR",
					// 	payload: t("common.__homeSelection__")
					// });
				} else if (
					err.response.request.responseText === JSON.stringify({ errors: { login: ["has already been taken"] } })
				) {
					setError(true);
					setErrorMessage(t("users.__addUserLoginError__", { name: hwFormStore.user.login }));
					// dispatch({
					// 	type: "DISPLAY_ERROR",
					// 	payload: t("users.__addUserLoginError__", { name: hwFormStore.user.login })
					// });
				} else {
					setError(true);
					setErrorMessage(t("common.__addError__"));
					// dispatch({
					// 	type: "DISPLAY_ERROR",
					// 	payload: t("common.__addError__")
					// });
				}
			});
	};

	const goBack = (): void => {
		setError(false);
		setErrorMessage(null);
		history.goBack();
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<HandleError error={error} setError={setError} message={errorMessage} />
			<h5 className="blue">{t("healthworker.__teamMember__")}</h5>
			{!error && progress.size ? (
				<Progressbar size={progress.size} maxSize={progress.maxSize} isLoader alertMessage={t("common.__loading__")} />
			) : (
				<div className="row">
					<div className="col-4">
						<p>
							<b>{t("common.__name__")}</b>
						</p>
						<p>{t("common.__login__")}</p>
						<p>{t("common.__password__")}</p>
						<p>{t("common.__mail__")}</p>
						<p>{t("common.__phone__")}</p>
						<p>{t("common.__smartphone__")}</p>
					</div>
					<div className="col-8">
						<p>
							<b>
								{hwFormStore.user.first_name === "badge_general" && hwFormStore.user.type_id ? (
									<>
										{t("healthworker.__generalAccount__")} : {DisplayName(hwFormStore.user.type_id, hwTypes)}
									</>
								) : (
									<>
										{hwFormStore.user.first_name} {hwFormStore.user.last_name}
									</>
								)}
							</b>
						</p>
						<p>{hwFormStore.user.login}</p>
						<p className="meta">{hwFormStore.user.password ? "********" : "___"}</p>
						<p>{hwFormStore.user.email}</p>
						<p>{hwFormStore.user.phone}</p>
						<p>{hwFormStore.user.mobile_phone}</p>
					</div>
					<div className="col-4 mt-3">
						<p>
							<b>{t("common.__job__")}</b>
						</p>
						<p>{t("permissions.__permissions__")}</p>
						<p>{t("badge.__badge__")}</p>
					</div>
					<div className="col-8 mt-3">
						<p>
							<b>
								{hwFormStore.role.home_id && hwFormStore.user.type_id ? DisplayName(hwFormStore.user.type_id, hwTypes) : null}
								{hwFormStore.role.group_id ? t("users.__groupManager__") : null}
								{hwFormStore.role.permissions[0] === -1 ? <p>Admin</p> : null}
								{hwFormStore.role.permissions[0] === -10 ? <p>Super Admin</p> : null}
							</b>
						</p>
						<p>
							{hwFormStore.role.permissions.length}{" "}
							{hwFormStore.role.permissions.length > 1 ? t("users.__permissionSelected__") : null}
						</p>
						<p>
							{hwFormStore.badge ? (
								hwFormStore.badge.type_id === 2 ? (
									t("badge.__qrCode__")
								) : (
									<>
										{t("badge.__pinCode__")} : {hwFormStore.badge.value}
									</>
								)
							) : (
								t("badge.__noBadge__")
							)}
						</p>
					</div>
					<div className="col-12">
						<button type="button" className="btn btn-outline-secondary btn-sm mt-3 mb-3 mr-2" onClick={goBack}>
							{t("common.__back__")}
						</button>
						<button type="submit" className="btn btn-primary btn-sm mt-3 mb-3">
							{t("common.__save__")}
						</button>
					</div>
				</div>
			)}
		</form>
	);
};
export default AddHealthWorkerResume;
