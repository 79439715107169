import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { NotificationsContext } from "../context/NotificationsContext";
import { UserInterface } from "../interfaces/UserInterface";
import { CalendarTabs } from "../utils/Tabs/TabsToDisplay";
import Tabs from "../utils/Tabs/Tabs";
import AccessControl from "../layout/AccessControl";
import EditEvent from "../components/CalendarEntries/EditEvent";
import * as p from "../constants/Permissions";
import SelectHomeInput from "../layout/SelectHomeInput";

interface Props {
	userState: UserInterface;
	homeId: number;
}

const CalendarEntries_Edit = ({ userState, homeId }: Props): JSX.Element => {
	const { eventsState, eventsDispatch } = useContext(NotificationsContext);
	const { id } = useParams<{ id: string }>();

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_HOME_CALENDAR]}>
			<>
				<Tabs tabsToDisplay={CalendarTabs} currentTab="MANAGE_EVENTS" />
				<div className="big-card">
					{homeId ? (
						<EditEvent
							eventId={parseInt(id)}
							userState={userState}
							eventsState={eventsState}
							eventsDispatch={eventsDispatch}
						/>
					) : (
						<SelectHomeInput />
					)}
				</div>
			</>
		</AccessControl>
	);
};
export default CalendarEntries_Edit;
