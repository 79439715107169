import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table, SelectColumnFilter } from "../../utils/Table/TableWithFilter";
import { Link } from "react-router-dom";
import { isBefore } from "date-fns";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import {
	FaReply,
	FaCheckCircle,
	FaRegTimesCircle,
	FaUsers,
	FaArrowRight,
	FaAngleDoubleRight,
	FaUser,
	FaPaperPlane
} from "react-icons/fa";
import VisioMsgModal from "./VisioMsgModal";
import MarkAsRead from "../Buttons/MarkAsRead";
import CancelVisioButton from "../Buttons/CancelVisio";
import ModalContainer from "../../utils/ModalContainer";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import * as types from "../../constants/TypesConstants";
import "./Message.scss";

export default function MessagesBoxContainer({
	userState,
	msgDispatch,
	notificationsDispatch,
	msgToDisplay,
	setUpdate
}) {
	const { t } = useTranslation("common");
	const [show, setShow] = useState({ show: false, info: { id: null }, success: false });
	const [showCancelVisio, setShowCancelVisio] = useState({ show: false, info: { id: null }, success: false });
	const [showRecipientsList, setShowRecipientsList] = useState({
		show: false,
		info: { seniors: null },
		title: t("common.__recipients__")
	});

	const markAsRead = useCallback(id => {
		if (id) {
			http.get(`${url.MESSAGES_URL}/${id}/visio/check`).then(() => {
				setUpdate(prevUpdate => !prevUpdate);
				notificationsDispatch({
					type: "UPDATE_MESSAGES_COUNTER"
				});
			});
		}
	}, []);

	const handleActions = (type, id, unread, visioDate) => {
		if (type === types.FAMILY_ASK_FOR_VISIO) {
			return (
				<>
					<FaRegTimesCircle
						className="notification-no"
						role="button"
						onClick={() => setShow({ show: true, info: { id: id }, success: false })}
					/>
					<FaCheckCircle className="notification-ok blue" role="button" onClick={() => visioIsAccepted(id)} />
				</>
			);
		} else if (type === types.MSG_FROM_FAMILY_TO_USER || type === types.MSG_FROM_USER_TO_FAMILY) {
			return (
				<Link to={`/msg/messagesbox/${id}/answer`}>
					<FaReply className="notification-res blue" role="button" />
				</Link>
			);
		} else if (type === types.FAMILY_ACCEPT_VISIO) {
			const visioDateFormated = new Date(visioDate.split(" ").join("T"));
			return (
				<>
					{isBefore(new Date(), visioDateFormated) ? <CancelVisioButton setShow={setShowCancelVisio} id={id} /> : null}
					{unread ? <MarkAsRead markAsRead={markAsRead} id={id} /> : null}
				</>
			);
		} else if (type === types.FAMILY_CANCEL_VISIO || type === types.FAMILY_REJECT_VISIO) {
			if (unread) {
				return <MarkAsRead markAsRead={markAsRead} id={id} />;
			}
		} else {
			return;
		}
	};

	const visioIsAccepted = id => {
		const data = {
			message_id: id
		};
		http
			.post(`${url.BASEURL}/homes/visio/accept`, data)
			.then(res => {
				msgDispatch({
					type: "EDIT_MESSAGE",
					payload: res.data.family_user_message
				});
				notificationsDispatch({
					type: "UPDATE_MESSAGES_COUNTER",
					payload: 1
				});
				msgDispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("mailbox.__visioIsAccepted__")
				});
			})
			.catch(() => {
				msgDispatch({
					type: "DISPLAY_ERROR",
					payload: t("common.__errorOccured__")
				});
			});
	};

	const displayDate = (type, date, unread) => {
		if (date) {
			switch (type) {
				case types.USER_ASK_FOR_VISIO:
				case types.USER_REJECT_VISIO:
				case types.USER_ACCEPT_VISIO:
				case types.USER_CANCEL_VISIO:
				case types.MULTI_MSG_FROM_USER_TO_FAMILY:
				case types.MSG_FROM_USER_TO_FAMILY:
					return (
						<p className="meta">
							<FaPaperPlane className="mr-2" />
							{t("mailbox.__sentOn__")} {handleDate(date, "Pp", userState.userConnected.locale)}
						</p>
					);
				case types.FAMILY_ACCEPT_VISIO:
				case types.FAMILY_ASK_FOR_VISIO:
				case types.FAMILY_CANCEL_VISIO:
				case types.FAMILY_REJECT_VISIO:
				case types.MSG_FROM_FAMILY_TO_USER:
					return unread ? (
						<p className="meta red">
							<FaAngleDoubleRight className="mr-2" />
							<b>
								{t("mailbox.__receivedOn__")} {handleDate(date, "Pp", userState.userConnected.locale)}
							</b>
						</p>
					) : (
						<p className="meta">
							<FaAngleDoubleRight className="mr-2" />
							{t("mailbox.__receivedOn__")} {handleDate(date, "Pp", userState.userConnected.locale)}
						</p>
					);
				default:
					return;
			}
		} else {
			return;
		}
	};

	const RecipientsListModal = ({ show }) => {
		const targets = show.info.seniors;
		return (
			<div className="display-overflow">
				{targets.length > 0 ? (
					targets.map((family, index) => (
						<div key={index}>
							<div className="family-outline-badge marine">
								<div className="row">
									<div className="col-md-6">
										<b className="mr-2">
											{family.family_user.last_name} {family.family_user.first_name}
										</b>
									</div>
									<div className="col-md-6">
										<span className="meta right">
											<FaArrowRight /> {family.senior.last_name} {family.senior.first_name}
										</span>
									</div>
								</div>
							</div>
						</div>
					))
				) : (
					<p className="meta">{t("common.__noData__")}</p>
				)}
			</div>
		);
	};

	const columns = useMemo(
		() => [
			{
				Header: t("common.__senior__"),
				accessor: "seniorName",
				Filter: SelectColumnFilter,
				maxWidth: 125,
				minWidth: 100,
				width: 110,
				Cell: row =>
					row.row.values.seniorName === "group" ? (
						<span
							className="category-outline-badge grey"
							role="button"
							onClick={() =>
								setShowRecipientsList({
									show: true,
									info: { seniors: row.row.original.targets },
									title: t("common.__recipients__")
								})
							}>
							<FaUsers className="mr-2" />
							<b>{t("mailbox.__multiMsg__")}</b>
						</span>
					) : (
						<Link to={`/senior/profile/${row.row.original.senior.id}`}>
							<span className="category-outline-badge blue">
								<b>{row.row.values.seniorName}</b>
							</span>
						</Link>
					)
			},
			{
				Header: t("common.__messages__"),
				accessor: "search",
				minWidth: 250,
				Cell: row => {
					return (
						<div className="mb-3">
							{displayDate(row.row.original.type, row.row.original.updated_at, row.row.original.unread)}

							<p className="mb-3 mt-1 grey-font">
								{row.row.original.type === types.FAMILY_ASK_FOR_VISIO ||
								row.row.original.type === types.FAMILY_ACCEPT_VISIO ||
								row.row.original.type === types.FAMILY_CANCEL_VISIO ||
								row.row.original.type === types.FAMILY_REJECT_VISIO ||
								row.row.original.type === types.MSG_FROM_FAMILY_TO_USER ? (
									<>
										<b>
											<FaUser className="mr-2" />
											Family : {row.row.original.familyName}
										</b>
										<br />
										{row.row.original.user.first_name ? (
											<>
												{t("mailbox.__recipient__")} :{" "}
												{row.row.original.user.id === userState.userConnected.id ? (
													row.row.original.userName
												) : (
													<Link to={`/user/healthworker/${row.row.original.user.id}`}>
														<span className="blue">
															<b>{row.row.original.userName}</b>
														</span>
													</Link>
												)}
											</>
										) : null}
									</>
								) : (
									<>
										<b>
											<FaUser className="mr-2" />
											Family :{" "}
											{row.row.original.type === types.MULTI_MSG_FROM_USER_TO_FAMILY ? (
												<span
													className="fake-link blue"
													role="button"
													onClick={() =>
														setShowRecipientsList({
															show: true,
															info: { seniors: row.row.original.targets },
															title: t("common.__recipients__")
														})
													}>
													<b>{t("mailbox.__showRecipients__")} </b>
												</span>
											) : (
												row.row.original.familyName
											)}
										</b>
										<br />
										{t("common.__begining__")} :{" "}
										{row.row.original.user.id === userState.userConnected.id ? (
											row.row.original.userName
										) : (
											<Link to={`/user/healthworker/${row.row.original.user.id}`}>
												<span className="blue">
													<b>{row.row.original.userName}</b>
												</span>
											</Link>
										)}
									</>
								)}
							</p>
							<div className="mailbox">
								{types.handleContent(
									row.row.original.type,
									row.row.original.subject,
									row.row.original.message,
									row.row.original.visio_datetime,
									row.row.original.id,
									row.row.original.unread,
									userState
								)}
							</div>
						</div>
					);
				}
			},
			{
				Header: "",
				accessor: "id",
				disableFilters: true,
				disableSortBy: true,
				maxWidth: 60,
				minWidth: 50,
				width: 50,
				Cell: row => {
					return (
						<div className="right mr-3">
							{handleActions(
								row.row.original.type,
								row.row.values.id,
								row.row.original.unread,
								row.row.original.visio_datetime ? row.row.original.visio_datetime : null
							)}
						</div>
					);
				}
			}
		],
		[]
	);

	return (
		<>
			{msgToDisplay.length > 0 ? (
				<Table columns={columns} data={msgToDisplay} />
			) : (
				<p className="meta">{t("common.__noData__")}</p>
			)}
			<ModalContainer show={show} setShow={setShow}>
				<VisioMsgModal
					show={show}
					setShow={setShow}
					userState={userState}
					msgDispatch={msgDispatch}
					notificationsDispatch={notificationsDispatch}
				/>
			</ModalContainer>
			<ModalContainer show={showCancelVisio} setShow={setShowCancelVisio}>
				<VisioMsgModal
					show={showCancelVisio}
					setShow={setShowCancelVisio}
					userState={userState}
					msgDispatch={msgDispatch}
					notificationsDispatch={notificationsDispatch}
					type={"cancel"}
				/>
			</ModalContainer>
			<ModalContainer show={showRecipientsList} setShow={setShowRecipientsList}>
				<RecipientsListModal show={showRecipientsList} />
			</ModalContainer>
		</>
	);
}
