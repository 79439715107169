import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table, SelectColumnFilter } from "../../utils/Table/TableWithFilter";
import { useHistory } from "react-router-dom";
import { FaRegEdit, FaRegTimesCircle, FaSimCard } from "react-icons/fa";
import HeaderContainer from "../../layout/HeaderContainer";
import EditDevice from "./EditDevice";
import DeviceDetails from "./DeviceDetails";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import HandleSuccess from "../../layout/HandleSuccess";
import HandleError from "../../layout/HandleError";
import DisplayName from "../../utils/DisplayName";
import ModalContainer from "../../utils/ModalContainer";

const DevicesList = ({ deviceSNType, devicesStore, devicesDispatch, homeId, setHomeId, allHomes }) => {
	const { t } = useTranslation("common");
	const history = useHistory();
	const [show, setShow] = useState({ show: false, info: null, title: " " });
	const [editMode, setEditMode] = useState({ edit: false, id: null });

	useEffect(() => {
		if (homeId) {
			http.get(`${url.HOMESURL}/${homeId}/devices`).then(res => {
				const result = res.data.devices;
				const devices = result.map(device => {
					return {
						...device,
						home: DisplayName(device.home_id, allHomes)
					};
				});
				devicesDispatch({
					type: "GET_DEVICES",
					payload: devices
				});
			});
		} else {
			http.get(`${url.BASEURL}/devices`).then(res => {
				const result = res.data.devices;
				const devices = result.map(device => {
					return {
						...device,
						home: DisplayName(device.home_id, allHomes),
						withSimCard: device.sim_card ? "avec carte SIM" : "sans carte SIM"
					};
				});
				devicesDispatch({
					type: "GET_DEVICES",
					payload: devices
				});
			});
		}
		// eslint-disable-next-line
	}, [homeId]);

	const goToHome = id => {
		setHomeId(id);
		history.push("/manage/me/myhome");
	};

	const allColumns = [
		{
			Header: "SIM card",
			accessor: "withSimCard",
			Filter: SelectColumnFilter,
			width: 65,
			minWidth: 50,
			maxWidth: 80,
			Cell: row => (row.row.original.sim_card ? <FaSimCard /> : null)
		},
		{
			Header: t("common.__home__"),
			accessor: "home",
			Filter: SelectColumnFilter,
			Cell: row => (
				<span className="category-outline-badge grey" role="button" onClick={() => goToHome(row.row.original.home_id)}>
					{DisplayName(row.row.original.home_id, allHomes)}
				</span>
			)
		},
		{
			Header: t("device.__serialNumber__"),
			accessor: "serial_number",
			Cell: row => (
				<span
					className="category-outline-badge blue"
					role="button"
					tabIndex="0"
					onClick={() => setShow({ show: true, info: row.row.values.id, title: t("common.__device__") })}>
					{row.row.values.serial_number}
				</span>
			)
		},
		{
			Header: "",
			accessor: "id",
			disableFilters: true,
			disableSortBy: true,
			width: 50,
			maxWidth: 50,
			Cell: ({ cell: { value } }) => (
				<div className="center">
					<FaRegEdit
						className="table-icon"
						role="button"
						onClick={() => setEditMode({ edit: true, id: value, success: false })}
					/>
					<FaRegTimesCircle className="table-icon" role="button" onClick={() => removeDevice(value)} />
				</div>
			)
		}
	];

	const removeDevice = deviceId => {
		http
			.delete(`${url.BASEURL}/devices/${deviceId}`)
			.then(() => {
				devicesDispatch({
					type: "DELETE_DEVICE",
					payload: deviceId
				});
				devicesDispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("device.__removeSuccess__")
				});
			})
			.catch(() => {
				devicesDispatch({
					type: "DISPLAY_ERROR",
					payload: t("common.__delError__")
				});
			});
	};

	return (
		<div className="big-card">
			<HeaderContainer
				title={t("device.__devices__")}
				addButton={t("device.__addDevice__")}
				link={"/support/device/add"}
			/>

			{editMode.edit ? (
				<EditDevice
					editMode={editMode}
					setEditMode={setEditMode}
					devicesStore={devicesStore}
					devicesDispatch={devicesDispatch}
					deviceSNType={deviceSNType}
				/>
			) : (
				<>
					<HandleError error={devicesStore.es.error} dispatch={devicesDispatch} message={devicesStore.es.msg} />
					<HandleSuccess success={devicesStore.es.success} dispatch={devicesDispatch} message={devicesStore.es.msg} />
					{devicesStore.devices.devices.length > 0 ? (
						<Table columns={allColumns} data={devicesStore.devices.devices} />
					) : (
						<p>{t("common.__noElement__")}</p>
					)}
				</>
			)}
			<ModalContainer show={show} setShow={setShow}>
				<DeviceDetails show={show} devices={devicesStore.devices.devices} />
			</ModalContainer>
		</div>
	);
};

export default DevicesList;
