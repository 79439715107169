import React from "react";
import { useTranslation } from "react-i18next";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

export default function ChangeStatisticsMultiBarChart({
	barChartStats,
	presenceOfStools,
	presenceOfRedness,
	tornOff,
	drawSheetChanged,
	diaperSaturated,
	bringToWc
}) {
	const { t } = useTranslation("common");

	return (
		<ResponsiveContainer width={"100%"} height={400}>
			<BarChart
				data={barChartStats}
				margin={{
					top: 20,
					right: 0,
					left: 0,
					bottom: 20
				}}
				barSize={20}>
				<CartesianGrid strokeDasharray="5 5" />
				<XAxis dataKey="name" />
				<YAxis />
				<Legend verticalAlign="top" height={50} />
				<Tooltip />
				<Bar dataKey="dirtyBed" fill="#fa5456" name={t("diaper.__dirtyBed__")} radius={[5, 5, 0, 0]} fillOpacity={0.85} />
				{diaperSaturated ? (
					<Bar
						dataKey="diaperSaturated"
						fill="#00a0e3"
						name={t("diaper.__diaperSaturated__")}
						radius={[5, 5, 0, 0]}
						fillOpacity={0.85}
					/>
				) : null}
				{presenceOfRedness ? (
					<Bar
						dataKey="presenceOfRedness"
						fill="#ffae42"
						name={t("diaper.__presenceOfRedness__")}
						radius={[5, 5, 0, 0]}
						fillOpacity={0.85}
					/>
				) : null}
				{presenceOfStools ? (
					<Bar
						dataKey="presenceOfStools"
						fill="#50c878"
						name={t("diaper.__presenceOfStools__")}
						radius={[5, 5, 0, 0]}
						fillOpacity={0.85}
					/>
				) : null}
				{drawSheetChanged ? (
					<Bar
						dataKey="drawSheetChanged"
						fill="#a70b22"
						name={t("diaper.__drawSheetChanged__")}
						radius={[5, 5, 0, 0]}
						fillOpacity={0.85}
					/>
				) : null}
				{tornOff ? (
					<Bar
						dataKey="tornOff"
						fill="#b97cfc"
						name={t("diaper.__diaperTornOff__")}
						radius={[5, 5, 0, 0]}
						fillOpacity={0.85}
					/>
				) : null}
				{bringToWc ? (
					<Bar
						dataKey="bringToWc"
						fill="#2748a0"
						name={t("diaper.__bringToWc__")}
						radius={[5, 5, 0, 0]}
						fillOpacity={0.85}
					/>
				) : null}
			</BarChart>
		</ResponsiveContainer>
	);
}
