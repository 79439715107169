import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import EditSenior from "./EditSenior";
import moment from "moment";
import DesactivateSeniorModal from "./DesactivateSeniorModal";
import * as p from "../../constants/Permissions";
import TimelineIcon from "../Buttons/TimelineIcon";
import SeniorMemoIcon from "../Buttons/SeniorMemoIcon";
import SeniorMailboxIcon from "../Buttons/SeniorMailboxIcon";
import SeniorVisioIcon from "../Buttons/SeniorVisioIcon";
import EditIcon from "../Buttons/EditIcon";
import ModalContainer from "../../utils/ModalContainer";
import ModalSeniorMemoT from "../MemoT/ModalSeniorMemoT";
import SelectedFamilyVisioToCome from "../Messages/SelectedFamilyVisioToCome";

const SeniorDetails = ({
	seniorSelected,
	room,
	homeUnit,
	userState,
	homeId,
	store,
	dispatch,
	editMode,
	setEditMode,
	show,
	setShow
}) => {
	const { t } = useTranslation("common");
	const canUpdateSenior = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_UPDATE_SENIOR]);
	const [notesShow, setNotesShow] = useState({ show: false, info: null, title: "" });
	const [showNextVisio, setShowNextVisio] = useState({ show: false, info: null, title: "" });
	const canHandleMailbox = userState.userConnected.is_in([
		p.ADMIN,
		p.SUPER_ADMIN,
		p.ALL_FAMILY_USER_MESSAGES,
		p.MY_FAMILY_USER_MESSAGES
	]);
	const canReadTimeline = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_READ_ACTIVITIES]);

	return (
		<div className="header-details mb-4">
			{editMode.edit ? (
				<EditSenior editMode={editMode} setEditMode={setEditMode} store={store} dispatch={dispatch} />
			) : (
				<div className="row">
					<div className="col-md-3">
						<div className="center">
							{seniorSelected.avatar_updated ? (
								<img
									src={`/storages/seniors/${seniorSelected.id}/avatar`}
									alt="avatar"
									className="senior-avatar-activities img-fluid"
								/>
							) : seniorSelected.gender === "M" ? (
								<img src="/img/senior_male.png" alt="profil" className="senior-avatar-activities" />
							) : (
								<img src="/img/senior_female.png" alt="profil" className="senior-avatar-activities" />
							)}
							<div className="center mt-2 mb-3">
								{canReadTimeline ? <TimelineIcon type="SENIOR" id={seniorSelected.id} /> : null}
								<SeniorMemoIcon senior={seniorSelected} setNotesShow={setNotesShow} />
								{canHandleMailbox ? (
									<>
										<SeniorMailboxIcon senior={seniorSelected} />
										<SeniorVisioIcon senior={seniorSelected} setShowNextVisio={setShowNextVisio} />
									</>
								) : null}
								<EditIcon setEditMode={setEditMode} seniorSelected={seniorSelected} />
							</div>
						</div>
					</div>

					<div className="col-md-6">
						{seniorSelected.active ? (
							<h2 className="senior-title">
								{seniorSelected.last_name} {seniorSelected.first_name}
							</h2>
						) : (
							<h2 className="senior-title red">
								<span className="msg-tag red mr-3">
									<i>
										<b>{t("common.__inactiveAccount__")}</b>
									</i>
								</span>
								{seniorSelected.last_name} {seniorSelected.first_name}
							</h2>
						)}
						<div className="separator"></div>
						<p>
							{room && (
								<Link to={`/manage/room/details/${room.id}`}>
									<b>
										{t("senior.__room__")} {room.name}{" "}
									</b>
								</Link>
							)}
							{homeUnit && <>- {homeUnit}</>}
						</p>
						<p>
							{t("senior.__birthDate__")} :{" "}
							{seniorSelected.birth_date && handleDate(seniorSelected.birth_date, "PPP", userState.userConnected.locale)} ({" "}
							{moment().diff(seniorSelected.birth_date, "years")} {t("senior.__yearsOld__")} )
						</p>
						<p>
							{t("senior.__arrivalDate__")}:{" "}
							{seniorSelected.arrival_date && handleDate(seniorSelected.arrival_date, "PPPP", userState.userConnected.locale)}
						</p>
					</div>
					<div className="col-md-3">
						{canUpdateSenior && seniorSelected.active ? (
							<button
								className="btn btn-sm btn-outline-danger right"
								onClick={() =>
									setShow({ show: true, info: { senior: seniorSelected }, title: t("senior.__seniors__"), success: false })
								}>
								{t("senior.__desactiveSeniorAccount__")}
							</button>
						) : null}
					</div>
				</div>
			)}
			<ModalContainer show={show} setShow={setShow}>
				<DesactivateSeniorModal senior={seniorSelected} show={show} setShow={setShow} />
			</ModalContainer>
			<ModalContainer show={notesShow} setShow={setNotesShow}>
				<ModalSeniorMemoT show={notesShow} homeId={homeId} userState={userState} />
			</ModalContainer>
			<ModalContainer show={showNextVisio} setShow={setShowNextVisio}>
				<SelectedFamilyVisioToCome show={showNextVisio} userState={userState} />
			</ModalContainer>
		</div>
	);
};

export default SeniorDetails;
