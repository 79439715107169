import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import HandleError from "../../../layout/HandleError";

export default function AffectSeniorToSensor({ seniors, show, setShow, diaperDispatch }) {
	const { t } = useTranslation("common");
	const [seniorId, setSeniorId] = useState(null);
	const [seniorError, setSeniorError] = useState(false);
	const [error, setError] = useState(false);
	const sensor = show.info.sensor;
	const seniorsWithoutSensor = seniors.filter(senior => !senior.sensor_id);

	const onSubmit = e => {
		e.preventDefault();
		if (seniorId) {
			setSeniorError(false);
			setError(false);
			const data = {
				diaper_sensor_id: sensor.id,
				senior_id: seniorId
			};
			const selectedSenior = seniors.find(senior => senior.id === parseInt(seniorId));
			http
				.post(`${url.BASEURL}/rht/affect/diaper_sensor/to/senior`, data)
				.then(() => {
					diaperDispatch({
						type: "AFFECT_SENSOR_TO_SENIOR",
						payload: { sensor: sensor, senior: selectedSenior }
					});
					setShow({ show: false, sensor: null });
				})
				.catch(() => setError(true));
		} else {
			setSeniorError(true);
		}
	};

	return (
		<form onSubmit={onSubmit}>
			<HandleError error={seniorError} setError={setSeniorError} message={t("common.__cautionFormIncomplete__")} noSpace />
			<HandleError error={error} setError={setError} message={t("common.__editError__")} />

			<div className="row">
				<div className="col-md-4">
					<p>MAC {t("home.__address__")}</p>
				</div>
				<div className="col-md-8">
					<input type="text" className="form-control form-control-sm mb-2" disabled defaultValue={sensor.mac_address} />
				</div>
				<div className="col-md-4">
					<p>{t("common.__senior__")}</p>
				</div>
				<div className="col-md-8">
					<select name="senior" className="form-control form-control-sm" onChange={e => setSeniorId(e.target.value)}>
						<option value={0}>{t("senior.__selectSenior__")}</option>
						{seniorsWithoutSensor.map(senior => (
							<option key={senior.id} value={senior.id}>
								{senior.name}
							</option>
						))}
					</select>
				</div>
				<div className="col-12 mt-4">
					<button
						type="button"
						className="btn btn-sm btn-outline-secondary mr-2"
						onClick={() => setShow({ show: false, sensor: null })}>
						{t("common.__cancel__")}
					</button>
					<button type="submit" className="btn btn-sm btn-primary">
						{t("common.__save__")}
					</button>
				</div>
			</div>
		</form>
	);
}
