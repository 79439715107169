import React from "react";
import { VisioTabs } from "../utils/Tabs/TabsToDisplay";
import { HealthWorkerInterface, UserInterface } from "../interfaces/UserInterface";
import useStoreData from "../hooks/useStoreData";
import Tabs from "../utils/Tabs/Tabs";
import AccessControl from "../layout/AccessControl";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import HomesSecondaryNav from "../layout/HomesSecondaryNav";
import RecipientsList from "../components/Home/Recipients/RecipientsList";
import SelectHomeInput from "../layout/SelectHomeInput";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const MyHome_Recipients = ({ homeId, userState }: Props): JSX.Element => {
	const { store } = useStoreData({ userState, homeId });
	const users = userState.userConnected.home_id
		? store.users.healthWorkers
		: store.users.healthWorkers.filter((hw: HealthWorkerInterface) => hw.home_id === homeId);
	const canUpdateRecipients = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_UPDATE_HOME]);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_UPDATE_HOME]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<HomesSecondaryNav userState={userState} />}
				component={
					<>
						<Tabs tabsToDisplay={VisioTabs} currentTab={"RECIPIENTS"} />
						<div className="big-card">
							{homeId ? (
								<RecipientsList canUpdateRecipients={canUpdateRecipients} homeId={homeId} users={users} />
							) : (
								<SelectHomeInput />
							)}
						</div>
					</>
				}
			/>
		</AccessControl>
	);
};
export default MyHome_Recipients;
