import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RangePicker from "../../utils/DatePicker/RangePicker";
import { FaAngleRight } from "react-icons/fa";
import HealthWorkerTypeBudgetBarChart from "./reCharts/HealthWorkerTypeBudgetBarChart";
import SelectHomeInput from "../../layout/SelectHomeInput";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import HeaderContainer from "../../layout/HeaderContainer";
import { fromToUrl } from "@e4ia/e4link_modules.datetime";
import useDateBeforeOrAfter from "../../hooks/useDateBeforeOrAfter";
import NoData from "../../layout/NoData";

export default function HealthWorkerTypeBudgetBoard({ userState, homeId, healthWorkerType, healthWorkers }) {
	const { t } = useTranslation("common");
	const lastWeek = useDateBeforeOrAfter({ isBefore: true, numberOfDays: 7 });
	const [startDate, setStartDate] = useState(lastWeek);
	const [endDate, setEndDate] = useState(new Date());
	const [healthWorkerTypeId, setHealthWorkerTypeId] = useState(0);
	const [healthWorkerId, setHealthWorkerId] = useState(0);
	const [budgetData, setBudgetData] = useState(null);
	const [budgetError, setBudgetError] = useState(false);
	const [error, setError] = useState(false);
	const [filteredHwList, setFilteredHwList] = useState(healthWorkers);

	const selectHwType = id => {
		const hwTypeId = parseInt(id);
		setHealthWorkerTypeId(hwTypeId);
		if (hwTypeId === 0) {
			setFilteredHwList(healthWorkers);
		} else {
			const selectedHealthWorkers = healthWorkers.filter(hw => hw.type_id === hwTypeId);
			setFilteredHwList(selectedHealthWorkers);
		}
	};

	const onSubmit = e => {
		e.preventDefault();
		if (healthWorkerId && startDate && endDate) {
			const fromTo = fromToUrl(startDate, endDate);
			getHealthWorkerDataVisualisation(healthWorkerId, fromTo);
		} else if (healthWorkerTypeId && homeId && startDate && endDate) {
			const fromTo = fromToUrl(startDate, endDate);
			getDataVisualisation(homeId, healthWorkerTypeId, fromTo);
		}
	};

	const getDataVisualisation = (homeId, healthWorkerTypeId, fromTo) => {
		http
			.get(
				`${url.BASEURL}/homes/${homeId}/health_worker_budget_distributions/health_worker_type/${healthWorkerTypeId}/compute/${fromTo}`
			)
			.then(res => {
				setError(false);
				setBudgetError(false);
				setBudgetData(res.data);
				setHealthWorkerTypeId(0);
			})
			.catch(err => {
				if (err.response.status === 404) {
					setBudgetError(true);
				} else {
					setError(true);
				}
			});
	};

	const getHealthWorkerDataVisualisation = (healthWorker, fromTo) => {
		http
			.get(`${url.BASEURL}/health_workers/${healthWorker}/health_worker_budget_distributions/compute/${fromTo}`)
			.then(res => {
				setHealthWorkerId(0);
				setError(false);
				setBudgetError(false);
				setBudgetData(res.data);
			})
			.catch(err => {
				if (err.response.status === 404) {
					setBudgetError(true);
				} else {
					setError(true);
				}
			});
	};

	return (
		<>
			<div className="big-card">
				<HeaderContainer
					title={t("hds.__hdsRepartition__")}
					addButton={t("hds.__goals__")}
					link={"/manage/hds/budgetrepartition"}
				/>
			</div>
			<div className="space"></div>
			{homeId ? (
				<div className="big-card">
					<form onSubmit={onSubmit}>
						<div className="row">
							<div className="col-md-12">
								<h5>{t("common.__params__")}</h5>
							</div>
							<div className="col-md-6">
								<select name="hwtId" className="form-control form-control-sm" onChange={e => selectHwType(e.target.value)}>
									<option value={0}>{t("hds.__jobSelection__")}</option>
									{healthWorkerType.map(hwt => (
										<option key={hwt.id} value={hwt.id}>
											{hwt.name}
										</option>
									))}
								</select>
							</div>
							<div className="col-md-6">
								<select
									name="healthworker"
									className="form-control form-control-sm"
									onChange={e => setHealthWorkerId(e.target.value)}>
									<option value={0}>{t("hds.__workerSelection__")}</option>
									{filteredHwList.map(healthworker => (
										<option key={healthworker.id} value={healthworker.id}>
											{healthworker.name}
										</option>
									))}
								</select>
							</div>
							<div className="col-md-12 mt-2">
								<div className="form-inline">
									<p className="mt-3">{t("common.__dates__")} :</p>
									<RangePicker
										startDate={startDate}
										setStartDate={setStartDate}
										endDate={endDate}
										setEndDate={setEndDate}
										userLocale={userState.userConnected.locale}
									/>
									<button className="btn btn-sm btn-primary ml-4" type="submit">
										{t("common.__validate__")}
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			) : (
				<SelectHomeInput />
			)}
			<div className="space"></div>
			<div className="big-card">
				{error ? (
					<NoData />
				) : budgetError ? (
					<h5>
						<FaAngleRight /> {t("hds.__notSaved__")}
					</h5>
				) : budgetData ? (
					<HealthWorkerTypeBudgetBarChart data={budgetData} healthWorkerType={healthWorkerType} />
				) : (
					<h5>
						<FaAngleRight /> {t("hds.__informationMissing__")}
					</h5>
				)}
			</div>
		</>
	);
}
