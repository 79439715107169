import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const JitsiMeet = props => {
	const { t } = useTranslation("common");
	const domain = "dev03.e4-ia.tech";
	const { roomName, subject } = props;
	const { loadingComponent, containerStyles, jitsiContainerStyles } = props;
	const [jitsi, setJitsi] = useState(null);
	const [endVisio, setEndVisio] = useState(false);
	const [loading, setLoading] = useState(true);
	const history = useHistory();

	const options = {
		roomName: roomName,
		subject,
		parentNode: "jitsi-container",
		interfaceConfigOverwrite: {
			SHOW_JITSI_WATERMARK: true,
			SHOW_BRAND_WATERMARK: true,
			SHOW_WATERMARK_FOR_GUESTS: true,
			SHOW_PROMOTIONAL_CLOSE_PAGE: false,
			DISABLE_JOIN_LEAVE_NOTIFICATIONS: false,
			TOOLBAR_ALWAYS_VISIBLE: true,
			//   TOOLBAR_BUTTONS: [
			//     'microphone', 'camera', 'closedcaptions', 'desktop', 'embedmeeting', 'fullscreen',
			//     'fodeviceselection', 'hangup', 'profile', 'chat', 'recording',
			//     'livestreaming', 'etherpad', 'sharedvideo', 'settings', 'raisehand',
			//     'videoquality', 'filmstrip', 'invite', 'feedback', 'stats', 'shortcuts',
			//     'tileview', 'videobackgroundblur', 'download', 'help', 'mute-everyone', 'security'
			// ],
			MAIN_TOOLBAR_BUTTONS: ["microphone", "camera", "desktop"]
		},
		configOverwrite: {
			disableSimulcast: false,
			defaultLanguage: "fr"
		}
	};

	const containerStyle = {
		width: "100%",
		height: "90vh"
	};

	const jitsiContainerStyle = {
		display: loading ? "none" : "block",
		width: "100%",
		height: "100%"
	};

	useEffect(() => {
		if (window.JitsiMeetExternalAPI) {
			options.parentNode = document.getElementById(options.parentNode);
			// eslint-disable-next-line no-undef
			setJitsi(new JitsiMeetExternalAPI(domain, options));
		} else {
			setJitsi({ error: t("mailbox.__noVisio__") });
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (jitsi) {
			setLoading(false);
			jitsi.addEventListener("readyToClose", () => {
				setEndVisio(true);
				jitsi.dispose();
			});
		}
	}, [jitsi]);

	/// onClose we redirect the user to his visioDashboard
	useEffect(() => {
		if (endVisio) {
			history.push("/controlpanel/home");
		}
		// eslint-disable-next-line
	}, [endVisio]);

	return (
		<div className="live-meeting">
			<div style={{ ...containerStyle, ...containerStyles }}>
				{loading && (loadingComponent || <p>{t("common.__loading__")}</p>)}
				<div id="jitsi-container" style={{ ...jitsiContainerStyle, ...jitsiContainerStyles }} />
			</div>
		</div>
	);
};

export default JitsiMeet;
