import React, { useContext, useEffect, useState } from "react";
import { DiaperContext } from "../context/DiaperContext";
import { UserInterface } from "../interfaces/UserInterface";
import AccessControl from "../layout/AccessControl";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import DiaperSecondaryNav from "../layout/DiaperSecondaryNav";
import DiaperCards from "../components/DiaperConnected/Cards/DiaperCards";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
	socket: any;
}

const DiaperAlertSocket = ({ userState, homeId, socket }: Props): JSX.Element => {
	const isDiaperManager = userState.userConnected.is_in([p.SUPER_ADMIN, p.ADMIN, p.DIAPER_APPLICATION]);
	const { diaperStore, diaperDispatch } = useContext(DiaperContext);
	const [refetch, setRefetch] = useState(false);

	useEffect(() => {
		if (socket && isDiaperManager) {
			const diapersChannel = socket.channel(`diapers:${homeId}`);
			console.log("[DEBUG WS] Diapers Channel > ", diapersChannel);

			diapersChannel.onError(() => {
				console.log(" Diapers Channel > : there was an error!");
			});

			diapersChannel.onClose(() => {
				console.log(" Diapers Channel >  the channel has gone away gracefully");
			});

			diapersChannel.on("msg", (msg: any) => {
				console.log(" Diapers Channel > UPDATE MSG >>", msg);
				if (msg.data) {
					setRefetch(prevState => !prevState);
				}
			});

			diapersChannel.on("sensor", (msg: any) => {
				console.log(" Diapers Channel > SENSOR VALUES >>", msg);
				if (msg) {
					diaperDispatch({
						type: "EDIT_SENIORSWITHDIAPER",
						payload: msg
					});
				}
			});
			diapersChannel
				.join()
				.receive("ok", ({ messages }: any) => console.log("diapersChannel: catching up", messages))
				.receive("error", ({ reason }: any) => console.log("diapersChannel: failed join", reason))
				.receive("timeout", () => console.log("Networking issue. Still waiting..."));

			return () => diapersChannel.leave();
		}
		// eslint-disable-next-line
	}, [socket, homeId, isDiaperManager]);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.DIAPER_APPLICATION]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<DiaperSecondaryNav homeId={homeId} userState={userState} />}
				component={
					<DiaperCards
						refetch={refetch}
						userState={userState}
						isDiaperManager={isDiaperManager}
						homeId={homeId}
						diaperStore={diaperStore}
						diaperDispatch={diaperDispatch}
					/>
				}
			/>
		</AccessControl>
	);
};
export default DiaperAlertSocket;
