import React from "react";
import { Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import NewsLikeFamilyFlow from "../../News/NewsLikeFamilyFlow";
import MyPosts from "./MyPosts";
import * as p from "../../../constants/Permissions";
import Tabs from "../../../utils/Tabs/Tabs";
import { BlogPanelTabs } from "../../../utils/Tabs/TabsToDisplay";

export default function FullBlogContainer({ homeId, userState, seniorsFromHome, managerMode }) {
	const isBlogger = userState.userConnected.is_in([
		p.HOME_BLOG_WRITER,
		p.CAN_POST_MODIFY_HOME_BLOG,
		p.CAN_MODERATE_HOME_BLOG
	]);

	return (
		<>
			<Tabs tabsToDisplay={BlogPanelTabs} currentTab={managerMode ? "MANAGE_POSTS" : "BLOG"} />
			<div className="big-card">
				{isBlogger && managerMode ? (
					<MyPosts managerMode={managerMode} userState={userState} />
				) : (
					<NewsLikeFamilyFlow isPanel homeId={homeId} userState={userState} seniorsFromHome={seniorsFromHome} />
				)}
			</div>

			{isBlogger ? (
				<Link to="/controlpanel/addpost">
					<button type="button" className="floating-button">
						<FaPlus className="floating-icon" />
					</button>
				</Link>
			) : null}
		</>
	);
}
