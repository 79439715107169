import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import MyInputText from "../../../utils/Form/MyInputText";
import HandleError from "../../../layout/HandleError";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";

const CreateHealthWorkersType = ({ dispatch, setAddMode, store }) => {
	const { t } = useTranslation("common");
	const methods = useForm();
	const { handleSubmit } = methods;

	useEffect(() => {
		return function cleanup() {
			dispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	const onSubmit = data => {
		dispatch({
			type: "HIDE_ERROR"
		});
		const typeData = {
			health_worker_type: {
				description: data.description,
				name: data.name
			}
		};
		http
			.post(`${url.BASEURL}/health_worker_types`, typeData)
			.then(res => {
				dispatch({
					type: "ADD_HEALTHWORKER_TYPE",
					payload: res.data.health_worker_type
				});
				dispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("healthworker.__typeAdded__")
				});
			})
			.then(() => setAddMode(false))
			.catch(() => {
				dispatch({
					type: "DISPLAY_ERROR"
				});
			});
	};

	return (
		<div className="big-card">
			<h5>{t("healthworker.__addType__")} :</h5>

			<FormProvider {...methods}>
				<HandleError error={store.es.error} dispatch={dispatch} message={t("common.__addError__")} />

				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="form-group">
						<MyInputText label={t("common.__type__")} name="name" behavior={{ required: true }} />
						<MyInputText label={t("common.__description__")} name="description" behavior={{ required: false }} />
					</div>
					<button className="btn btn-primary btn-sm" type="submit">
						{t("common.__save__")}
					</button>
				</form>
			</FormProvider>
		</div>
	);
};

export default CreateHealthWorkersType;
