import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { FaRegTired, FaRegFrown, FaRegMeh, FaRegSmile, FaRegGrin, FaAngleRight } from "react-icons/fa";
import { nutrition, distribution, logistic, timeAfterAction } from "./RulesDetails";
import MyInputSelect from "../../../utils/Form/MyInputSelect";
import MyInputNumber from "../../../utils/Form/MyInputNumber";
import MyInputText from "../../../utils/Form/MyInputText";
import HandleError from "../../../layout/HandleError";
import HeaderContainer from "../../../layout/HeaderContainer";
import http from "../../../services/Interceptor";
import * as constant from "../../../constants/Url";
import * as p from "../../../constants/Permissions";

export default function AddHomeRule({ homeId, store, dispatch, userState }) {
	const { t } = useTranslation("common");
	const homeUnits = store.home.homeUnits;
	const seniorsFromHome = store.seniors.seniorsFromHome;
	const history = useHistory();
	const [choice, setChoice] = useState(0);
	const [selectFrequence, setSelectFrequence] = useState(1);
	const [ruleConcern, setRuleConcern] = useState(0);
	const [nbRepeat, setNbRepeat] = useState(2);
	const [errorHour, setErrorHour] = useState(false);
	const [selectedMood, setSelectedMood] = useState(1);

	const methods = useForm();
	const { handleSubmit } = methods;

	const onSubmit = data => {
		dispatch({
			type: "RESET_ES"
		});
		let dataRule = {};

		if (choice === 1) {
			dataRule = {
				checker_rule: {
					name: data.name,
					home_id: homeId,
					home_unit_id: parseInt(data.homeUnit),
					senior_id: data.senior,
					rule: {
						type: selectFrequence,
						actions: [
							{
								action_id: 140,
								detail_val: 2
							}
						],
						nb_repeat: selectFrequence === 1 ? 1 : nbRepeat
					}
				}
			};
		} else if (choice === 2) {
			dataRule = {
				checker_rule: {
					name: data.name,
					home_id: homeId,
					home_unit_id: parseInt(data.homeUnit),
					senior_id: data.senior,
					rule: {
						type: 1,
						actions: [
							{
								action_id: parseInt(data.actionId),
								detail_val: 2
							}
						],
						nb_repeat: null
					}
				}
			};
		} else if (choice === 3) {
			dataRule = {
				checker_rule: {
					name: data.name,
					home_id: homeId,
					home_unit_id: parseInt(data.homeUnit),
					senior_id: data.senior,
					rule: {
						type: selectFrequence,
						actions: [
							{
								action_id: parseInt(selectedMood),
								detail_val: 0
							}
						],
						nb_repeat: nbRepeat,
						timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
					}
				}
			};
		} else if (choice === 4) {
			let start = "";
			let end = "";

			if (parseInt(data.beginHour) < 10) {
				setErrorHour(false);
				start = 0 + parseInt(data.beginHour);
			} else if (parseInt(data.beginHour) > 23) {
				setErrorHour(true);
			} else {
				setErrorHour(false);
				start = parseInt(data.beginHour);
			}

			if (parseInt(data.endHour) < 10) {
				setErrorHour(false);
				end = 0 + parseInt(data.endHour);
			} else if (parseInt(data.endHour) > 23) {
				setErrorHour(true);
			} else {
				setErrorHour(false);
				end = parseInt(data.endHour);
			}

			let startMns = 0;
			let endMns = 0;

			if (parseInt(data.endMinutes) < 10) {
				setErrorHour(false);
				endMns = 0 + parseInt(data.endMinutes);
			} else if (parseInt(data.endMinutes) > 59) {
				setErrorHour(true);
			} else {
				setErrorHour(false);
				endMns = parseInt(data.endMinutes);
			}

			if (parseInt(data.beginMinutes) < 10) {
				setErrorHour(false);
				startMns = 0 + parseInt(data.beginMinutes);
			} else if (parseInt(data.beginMinutes) > 59) {
				setErrorHour(true);
			} else {
				setErrorHour(false);
				startMns = parseInt(data.beginMinutes);
			}

			let actions = [];

			if (data.actionId === "1") {
				actions = [
					{
						action_id: 100,
						detail_val: 0
					},
					{
						action_id: 102,
						detail_val: 0
					}
				];
			} else if (data.actionId === "2") {
				actions = [
					{
						action_id: 110,
						detail_val: 0
					},
					{
						action_id: 112,
						detail_val: 0
					}
				];
			} else if (data.actionId === "3") {
				actions = [
					{
						action_id: 130,
						detail_val: 0
					},
					{
						action_id: 132,
						detail_val: 0
					}
				];
			}

			dataRule = {
				checker_rule: {
					name: data.name,
					home_id: homeId,
					home_unit_id: parseInt(data.homeUnit),
					senior_id: data.senior,
					rule: {
						type: 3,
						actions: actions,
						nb_repeat: null,
						begin: { hour: start, minute: startMns },
						end: { hour: end, minute: endMns },
						timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
					}
				}
			};
		} else if (choice === 5) {
			let actions = [];
			let initialActions = [];

			if (data.actionId === "1") {
				initialActions = [
					{
						action_id: 100,
						detail_val: 0
					},
					{
						action_id: 102,
						detail_val: 0
					}
				];
				actions = [
					{
						action_id: 101,
						detail_val: 0
					},
					{
						action_id: 103,
						detail_val: 0
					}
				];
			} else if (data.actionId === "2") {
				initialActions = [
					{
						action_id: 110,
						detail_val: 0
					},
					{
						action_id: 112,
						detail_val: 0
					}
				];
				actions = [
					{
						action_id: 111,
						detail_val: 0
					},
					{
						action_id: 113,
						detail_val: 0
					}
				];
			} else if (data.actionId === "3") {
				initialActions = [
					{
						action_id: 130,
						detail_val: 0
					},
					{
						action_id: 132,
						detail_val: 0
					}
				];
				actions = [
					{
						action_id: 131,
						detail_val: 0
					},
					{
						action_id: 133,
						detail_val: 0
					}
				];
			}

			dataRule = {
				checker_rule: {
					name: data.name,
					home_id: homeId,
					home_unit_id: parseInt(data.homeUnit),
					senior_id: data.senior,
					rule: {
						type: 5,
						actions: initialActions,
						after_those_actions: actions,
						nb_repeat: null,
						max_seconds_after: data.timing,
						timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
					}
				}
			};
		} else if (choice === 6) {
			dataRule = {
				checker_rule: {
					name: data.name,
					home_id: homeId,
					home_unit_id: parseInt(data.homeUnit),
					senior_id: parseInt(data.senior),
					rule: {
						type: 4,
						actions: [
							{
								action_id: 13,
								detail_val: 0
							}
						],
						nb_repeat: null,
						begin: {
							hour: parseInt(data.beginHour),
							minute: parseInt(data.beginMinutes)
						},
						end: { hour: parseInt(data.endHour), minute: parseInt(data.endMinutes) },
						timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
					}
				}
			};
		} else if (choice === 7) {
			let start = "";
			let end = "";

			if (parseInt(data.beginHour) < 10) {
				setErrorHour(false);
				start = 0 + parseInt(data.beginHour);
			} else if (parseInt(data.beginHour) > 23) {
				setErrorHour(true);
			} else {
				setErrorHour(false);
				start = parseInt(data.beginHour);
			}

			if (parseInt(data.endHour) < 10) {
				setErrorHour(false);
				end = 0 + parseInt(data.endHour);
			} else if (parseInt(data.endHour) > 23) {
				setErrorHour(true);
			} else {
				setErrorHour(false);
				end = parseInt(data.endHour);
			}

			let startMns = 0;
			let endMns = 0;

			if (parseInt(data.endMinutes) < 10) {
				setErrorHour(false);
				endMns = 0 + parseInt(data.endMinutes);
			} else if (parseInt(data.endMinutes) > 59) {
				setErrorHour(true);
			} else {
				setErrorHour(false);
				endMns = parseInt(data.endMinutes);
			}

			if (parseInt(data.beginMinutes) < 10) {
				setErrorHour(false);
				startMns = 0 + parseInt(data.beginMinutes);
			} else if (parseInt(data.beginMinutes) > 59) {
				setErrorHour(true);
			} else {
				setErrorHour(false);
				startMns = parseInt(data.beginMinutes);
			}
			dataRule = {
				checker_rule: {
					name: data.name,
					home_id: homeId,
					home_unit_id: data.homeUnit,
					senior_id: data.senior,
					rule: {
						type: 6,
						actions: [
							{
								action_id: 200,
								detail_val: 0
							},
							{
								action_id: 201,
								detail_val: 0
							},
							{
								action_id: 202,
								detail_val: 0
							}
						],
						nb_repeat: null,
						begin: { hour: start, minute: startMns },
						end: { hour: end, minute: endMns },
						timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
					}
				}
			};
		}
		if (errorHour === false) {
			http
				.post(`${constant.BASEURL}/checker_rules`, dataRule)
				.then(res => {
					dispatch({
						type: "DISPLAY_SUCCESS",
						payload: t("rules.__addRuleSuccess__", {
							name: res.data.checker_rule.name
						})
					});
					history.push("/manage/nr/notificationrules");
				})
				.catch(() => {
					dispatch({
						type: "DISPLAY_ERROR"
					});
				});
		}
	};

	const handleRules = choice => {
		if (choice === 1) {
			return (
				<>
					<div className="header-details mt-4">
						<p>
							<b>{t("common.__toKnow__")}</b>
							{t("rules.__hydratationInfo__")}
						</p>
					</div>
					<h5>
						<FaAngleRight className="mr-2" />
						{t("rules.__repeatActionOrNot__")}
					</h5>
					<div className="row">
						<div className="col-md-1"></div>
						<div className="col-md-11">
							<div>
								<input
									type="radio"
									id="strict"
									name="frequence"
									value="1"
									className="mr-2"
									onClick={() => handleFrequence(1)}
									defaultChecked
								/>
								<label htmlFor="strict">{t("rules.__eachTime__")}</label>
							</div>
							<div>
								<input
									type="radio"
									id="repeat"
									name="frequence"
									value="2"
									className="mr-2"
									onClick={() => setSelectFrequence(2)}
								/>
								<label htmlFor="repeat">
									{t("rules.__repetitiveAction__")}{" "}
									<input
										type="number"
										min="2"
										disabled={selectFrequence === 2 ? false : true}
										pattern="[0-9]*"
										onChange={e => handleRepetitionNumber(e)}
										placeholder="2"
									/>
								</label>
							</div>
						</div>
					</div>
				</>
			);
		} else if (choice === 2) {
			return (
				<>
					<div className="header-details mt-4">
						<p>
							<b>{t("common.__toKnow__")} </b>
							{t("rules.__appetiteInfo__")}
						</p>
					</div>
					<MyInputSelect
						label={
							<h5>
								<FaAngleRight className="mr-2" />
								{t("rules.__mealChoice__")}
							</h5>
						}
						name="actionId"
						behavior={{ required: true }}
						list={nutrition}
					/>
				</>
			);
		} else if (choice === 3) {
			return (
				<>
					<div className="header-details mt-4">
						<p>
							<b>{t("common.__toKnow__")} </b>
							{t("rules.__moodInfo__")}
						</p>
					</div>
					<h5>
						<FaAngleRight className="mr-2" />
						{t("rules.__moodChoice__")}
					</h5>
					<div className="row">
						<div className="col-1"></div>
						<div className="col-2">
							<FaRegTired className={selectedMood === 1 ? "rule-icon blue" : "rule-icon"} onClick={() => setSelectedMood(1)} />
						</div>
						<div className="col-2">
							<FaRegFrown className={selectedMood === 2 ? "rule-icon blue" : "rule-icon"} onClick={() => setSelectedMood(2)} />
						</div>
						<div className="col-2">
							<FaRegMeh className={selectedMood === 3 ? "rule-icon blue" : "rule-icon"} onClick={() => setSelectedMood(3)} />
						</div>
						<div className="col-2">
							<FaRegSmile className={selectedMood === 4 ? "rule-icon blue" : "rule-icon"} onClick={() => setSelectedMood(4)} />
						</div>
						<div className="col-2">
							<FaRegGrin className={selectedMood === 5 ? "rule-icon blue" : "rule-icon"} onClick={() => setSelectedMood(5)} />
						</div>
						<div className="col-1"></div>
						<div className="col-12 mt-4">
							<h5>
								<FaAngleRight className="mr-2" />
								{t("rules.__repeatActionOrNot__")}
							</h5>
						</div>

						<div className="col-md-1"></div>
						<div className="col-md-11">
							<div>
								<input
									type="radio"
									id="strict"
									name="frequence"
									value="1"
									className="mr-2"
									onClick={() => handleFrequence(1)}
									defaultChecked
								/>
								<label htmlFor="strict">{t("rules.__eachTime__")}</label>
							</div>
							<div>
								<input
									type="radio"
									id="repeat"
									name="frequence"
									value="2"
									className="mr-2"
									onClick={() => setSelectFrequence(2)}
								/>
								<label htmlFor="repeat">
									{t("rules.__repetitiveAction__")}{" "}
									<input
										type="number"
										min="2"
										disabled={selectFrequence === 2 ? false : true}
										pattern="[0-9]*"
										onChange={e => handleRepetitionNumber(e)}
										placeholder="2"
									/>
								</label>
							</div>
						</div>
					</div>
				</>
			);
		} else if (choice === 4) {
			return (
				<>
					<div className="header-details mt-4">
						<p>
							<b>{t("common.__toKnow__")} </b>
							{t("rules.__logicticInfo__")}
						</p>
					</div>
					<div className="space"></div>
					<MyInputSelect
						label={
							<h5>
								<FaAngleRight className="mr-2" />
								{t("rules.__mealChoice__")}
							</h5>
						}
						name="actionId"
						behavior={{ required: true }}
						list={distribution}
					/>
					<div className="space"></div>
					<h5>
						<FaAngleRight className="mr-2" />
						{t("rules.__logicticRange__")}
					</h5>
					{errorHour ? <p className="error-msg">{t("rules.__logicticRangeError__")}</p> : null}
					<div className="row">
						<div className="col-md-1"></div>
						<div className="col-md-4">
							<p>{t("rules.__logicticRangeStart__")}</p>
						</div>
						<div className="col-md-2"></div>
						<div className="col-md-4">
							<p>{t("rules.__logicticRangeEnd__")}</p>
						</div>
						<div className="col-md-1"></div>
						<div className="col-md-1"></div>
						<div className="col-md-2">
							<MyInputNumber
								label={t("common.__hour__")}
								name="beginHour"
								behavior={{ required: true, maxLength: 2, pattern: /[0-9]/ }}
								placeholder="14"
							/>
						</div>
						<div className="col-md-2">
							<MyInputNumber
								label={t("common.__minute__")}
								name="beginMinutes"
								value="00"
								behavior={{ required: false, maxLength: 2, pattern: /[0-9]/ }}
								placeholder="00"
							/>
						</div>
						<div className="col-md-2"></div>
						<div className="col-md-2">
							<MyInputNumber
								label={t("common.__hour__")}
								name="endHour"
								behavior={{ required: true, maxLength: 2, pattern: /[0-9]/ }}
								placeholder="18"
							/>
						</div>
						<div className="col-md-2">
							<MyInputNumber
								label={t("common.__minute__")}
								name="endMinutes"
								value="00"
								behavior={{ required: false, maxLength: 2, pattern: /[0-9]/ }}
								placeholder="00"
							/>
						</div>
						<div className="col-md-1"></div>
					</div>
				</>
			);
		} else if (choice === 5) {
			return (
				<>
					<div className="header-details mt-4">
						<p>
							<b>{t("common.__toKnow__")}</b>
							{t("rules.__deliveryInfo__")}
						</p>
					</div>
					<div className="space"></div>
					<MyInputSelect
						label={
							<h5>
								<FaAngleRight className="mr-2" />
								{t("rules.__mealChoice__")}
							</h5>
						}
						name="actionId"
						behavior={{ required: true }}
						list={logistic}
					/>
					<div className="space"></div>
					<MyInputSelect
						label={
							<h5>
								<FaAngleRight className="mr-2" />
								{t("rules.__deliveryDelay__")}
							</h5>
						}
						name="timing"
						behavior={{ required: true }}
						list={timeAfterAction}
					/>
				</>
			);
		} else if (choice === 6) {
			return (
				<>
					<div className="header-details mt-4">
						<p>
							<b>{t("common.__toKnow__")}</b>
							{t("rules.__authorizedVisitInfo__")}
						</p>
					</div>
					<div className="space"></div>
					<h5>
						<FaAngleRight className="mr-2" />
						Quelles sont les heures de visite autorisées ?
					</h5>
					<div className="row">
						<div className="col-md-1"></div>
						<div className="col-md-4">
							<p>{t("rules.__authorizedVisitRangeStart__")}</p>
						</div>
						<div className="col-md-2"></div>
						<div className="col-md-4">
							<p>{t("rules.__authorizedVisitRangeEnd__")}</p>
						</div>
						<div className="col-md-1"></div>
						<div className="col-md-1"></div>
						<div className="col-md-2">
							<MyInputNumber
								label={t("common.__hour__")}
								name="beginHour"
								behavior={{ required: true, maxLength: 2, pattern: /[0-9]{2}/ }}
								placeholder="14"
							/>
						</div>
						<div className="col-md-2">
							<MyInputNumber
								label={t("common.__minute__")}
								name="beginMinutes"
								value="00"
								behavior={{ required: false, maxLength: 2, pattern: /[0-9]{2}/ }}
								placeholder="00"
							/>
						</div>
						<div className="col-md-2"></div>
						<div className="col-md-2">
							<MyInputNumber
								label={t("common.__hour__")}
								name="endHour"
								behavior={{ required: true, maxLength: 2, pattern: /[0-9]{2}/ }}
								placeholder="18"
							/>
						</div>
						<div className="col-md-2">
							<MyInputNumber
								label={t("common.__minute__")}
								name="endMinutes"
								value="00"
								behavior={{ required: false, maxLength: 2, pattern: /[0-9]{2}/ }}
								placeholder="00"
							/>
						</div>
						<div className="col-md-1"></div>
					</div>
				</>
			);
		} else if (choice === 7) {
			return (
				<>
					<div className="header-details mt-4">
						<p>
							<b>{t("common.__toKnow__")}</b>
							{t("rules.__seniorWash__")}
						</p>
					</div>
					<div className="space"></div>
					<h5>
						<FaAngleRight className="mr-2" />
						{t("rules.__seniorWashRange__")}
					</h5>
					{errorHour ? <p className="error-msg">{t("rules.__logicticRangeError__")}</p> : null}
					<div className="row">
						<div className="col-md-1"></div>
						<div className="col-md-4">
							<p>{t("rules.__seniorWashRangeStart__")}</p>
						</div>
						<div className="col-md-2"></div>
						<div className="col-md-4">
							<p>{t("rules.__seniorWashRangeEnd__")}</p>
						</div>
						<div className="col-md-1"></div>
						<div className="col-md-1"></div>
						<div className="col-md-2">
							<MyInputNumber
								label={t("common.__hour__")}
								name="beginHour"
								behavior={{ required: true, maxLength: 2, pattern: /[0-9]/ }}
								placeholder="14"
							/>
						</div>
						<div className="col-md-2">
							<MyInputNumber
								label={t("common.__minute__")}
								name="beginMinutes"
								value="00"
								behavior={{ required: false, maxLength: 2, pattern: /[0-9]/ }}
								placeholder="00"
							/>
						</div>
						<div className="col-md-2"></div>
						<div className="col-md-2">
							<MyInputNumber
								label={t("common.__hour__")}
								name="endHour"
								behavior={{ required: true, maxLength: 2, pattern: /[0-9]/ }}
								placeholder="18"
							/>
						</div>
						<div className="col-md-2">
							<MyInputNumber
								label={t("common.__minute__")}
								name="endMinutes"
								value="00"
								behavior={{ required: false, maxLength: 2, pattern: /[0-9]/ }}
								placeholder="00"
							/>
						</div>
						<div className="col-md-1"></div>
					</div>
				</>
			);
		}
	};

	const handleFrequence = value => {
		setSelectFrequence(value);
		setNbRepeat(null);
	};

	const handleRepetitionNumber = e => {
		setNbRepeat(parseInt(e.target.value));
	};

	return (
		<div className="big-card">
			<HeaderContainer
				title={t("rules.__notificationRules__")}
				addButton={t("rules.__addNotificationRules__")}
				link={"/manage/nr/rule/add"}
				permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_CHECKER_RULE]}
				userState={userState}
			/>

			<h5 className="blue">{t("rules.__addNotificationRules__")}</h5>
			<HandleError error={store.es.error} dispatch={dispatch} message={t("common.__addError__")} />

			<h5 className="mb-3">
				<FaAngleRight className="mr-2" />
				{t("rules.__selectNotificationRules__")}
			</h5>
			<button
				className={choice === 1 ? "category-badge blue mb-2" : "category-outline-badge blue mb-2"}
				onClick={() => setChoice(1)}>
				{t("rules.__hydratationRule__")}
			</button>
			<button
				className={choice === 2 ? "category-badge red mb-2" : "category-outline-badge red mb-2"}
				onClick={() => setChoice(2)}>
				{t("rules.__mealRule__")}
			</button>
			<button
				className={choice === 3 ? "category-badge green mb-2" : "category-outline-badge green mb-2"}
				onClick={() => setChoice(3)}>
				{t("rules.__moodRule__")}
			</button>
			<button
				className={choice === 7 ? "category-badge darkred mb-2" : "category-outline-badge darkred mb-2"}
				onClick={() => setChoice(7)}>
				{t("rules.__hygieneRule__")}
			</button>
			<button
				className={choice === 4 ? "category-badge purple mb-2" : "category-outline-badge purple mb-2"}
				onClick={() => setChoice(4)}>
				{t("rules.__mealDistributionRule__")}
			</button>
			<button
				className={choice === 5 ? "category-badge purple mb-2" : "category-outline-badge purple mb-2"}
				onClick={() => setChoice(5)}>
				{t("rules.__mealReturnRule__")}
			</button>
			<button
				className={choice === 6 ? "category-badge marine mb-2" : "category-outline-badge marine mb-2"}
				onClick={() => setChoice(6)}>
				{t("rules.__visitsRule__")}
			</button>
			{choice !== 0 ? (
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(onSubmit)}>
						{handleRules(choice)}

						<div className="space"></div>
						<h5>
							<FaAngleRight className="mr-2" />
							{t("rules.__isRuleFor__")} :
						</h5>
						<div className="row">
							<div className="col-md-1"></div>
							<div className="col-md-11">
								<div className="row">
									<div className="col-md-4">
										<input
											type="radio"
											id="home"
											name="concern"
											value="0"
											className="mr-2"
											onClick={() => setRuleConcern(0)}
											defaultChecked
										/>
										<label htmlFor="home">{t("common.__aHome__")}</label>
									</div>
									<div className="col-md-4">
										<input
											type="radio"
											id="homeUnit"
											name="concern"
											value="1"
											className="mr-2"
											onClick={() => setRuleConcern(1)}
										/>
										<label htmlFor="homeUnit">{t("common.__aHomeUnit__")}</label>
									</div>
									<div className="col-md-4">
										<input type="radio" id="senior" name="concern" value="2" className="mr-2" onClick={() => setRuleConcern(2)} />
										<label htmlFor="senior">{t("common.__aSenior__")}</label>
									</div>
								</div>
								{ruleConcern === 1 ? (
									<div className="mt-3">
										<MyInputSelect
											label={t("rules.__homeUnitConcerned__")}
											name="homeUnit"
											behavior={{ required: true }}
											list={homeUnits}
										/>
									</div>
								) : ruleConcern === 2 ? (
									<MyInputSelect
										label={t("rules.__seniorConcerned__")}
										name="senior"
										behavior={{ required: true }}
										list={seniorsFromHome}
									/>
								) : null}
							</div>
						</div>
						<div className="mt-3"></div>
						<MyInputText
							label={
								<h5>
									<FaAngleRight className="mr-2" />
									{t("rules.__addRuleName__")}
								</h5>
							}
							name="name"
							behavior={{ required: true }}
						/>
						<button type="submit" className="btn btn-sm btn-primary">
							{t("common.__save__")}
						</button>
					</form>
				</FormProvider>
			) : null}
		</div>
	);
}
