import i18n from "../../i18n/i18next";

export const SeniorTabs = [
	{
		id: "SENIORS",
		title: i18n.t("common:senior.__seniors__"),
		path: "/senior/seniors"
	},
	{
		id: "FAMILIES",
		title: i18n.t("common:family.__families__"),
		path: "/senior/family"
	}
];

export const VisioTabs = [
	{
		id: "RECIPIENTS",
		title: i18n.t("common:common.__recipients__"),
		path: "/manage/recipients/list"
	},
	{
		id: "CONSTRAINTS",
		title: i18n.t("common:common.__visioTiming__"),
		path: "/manage/recipients/visioconfig"
	}
];

export const TabletTabs = [
	{
		id: "TIMELINE",
		title: i18n.t("common:permissions.__timeline__"),
		path: "/tablet/hometimeline"
	},
	{
		id: "MEMO",
		title: i18n.t("common:memo.__memo__"),
		path: "/tablet/memo"
	}
];

export const MailBoxTabs = [
	{
		id: "MESSAGES",
		title: i18n.t("common:common.__mailbox__"),
		path: "/msg/messagesbox"
	},
	{
		id: "INITIATE_MESSAGE",
		title: i18n.t("common:mailbox.__newMessage__"),
		path: "/msg/newmessage"
	},
	{
		id: "INITIATE_VISIO",
		title: i18n.t("common:mailbox.__createVisio__"),
		path: "/msg/createlivemeeting"
	}
];

export const HealthWorkersTabs = [
	{
		id: "HEALTHWORKERS",
		title: i18n.t("common:healthworker.__team__"),
		path: "/user/hw/healthworkers"
	},
	{
		id: "BADGES",
		title: i18n.t("common:badge.__badges__"),
		path: "/user/healthworkerbadges"
	}
];

export const AmdDiaperSettingsTabs = [
	{
		id: "RECIPIENTS",
		title: i18n.t("common:notifications.__notifications__"),
		path: "/inco/inco/settings"
	},
	{
		id: "AMD_SYNC",
		title: i18n.t("common:common.__sync__"),
		path: "/inco/inco/syncsettings"
	},
	{
		id: "BLUETOOTH",
		title: i18n.t("common:diaper.__connectedDiapers__"),
		path: "/inco/inco/blesettings"
	}
];

export const DiaperSettingsTabs = [
	{
		id: "RECIPIENTS",
		title: i18n.t("common:notifications.__notifications__"),
		path: "/inco/inco/settings"
	},
	{
		id: "BLUETOOTH",
		title: i18n.t("common:diaper.__connectedDiapers__"),
		path: "/inco/inco/blesettings"
	}
];

export const BlogTabs = [
	{
		id: "BLOG",
		title: i18n.t("common:news.__news__"),
		path: "/blog"
	},
	{
		id: "MANAGE_POST",
		title: i18n.t("common:news.__handleNews__"),
		path: "/blog/news"
	}
];

export const CalendarTabs = [
	{
		id: "CALENDAR",
		title: i18n.t("common:calendar.__calendar__"),
		path: "/planner/calendar"
	},
	{
		id: "MANAGE_EVENTS",
		title: i18n.t("common:calendar.__handleEvents__"),
		path: "/planner/events"
	}
];

export const ModerationTabs = [
	{
		id: "FAMILY_ELTS",
		title: i18n.t("common:homeBoard.__family__"),
		path: "/moderation/family"
	},
	{
		id: "BLOG_ELTS",
		title: i18n.t("common:common.__blog__"),
		path: "/moderation/blog"
	}
];

export const BlogPanelTabs = [
	{
		id: "BLOG",
		title: i18n.t("common:news.__news__"),
		path: "/controlpanel/blog"
	},
	{
		id: "MANAGE_POSTS",
		title: i18n.t("common:news.__handleNews__"),
		path: "/controlpanel/manageposts"
	}
];

export const DevicePreferencesTabs = [
	{
		id: "PHOTOFRAME",
		title: i18n.t("common:device.__photoFrame__"),
		path: "/manage/pref/devicepreferences"
	}
];
