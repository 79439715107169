import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import ErrorContainer from "../../../layout/ErrorContainer";

const HomesListFromGroup = ({ myGroup, homesFromGroup, setHomeId }) => {
	const { t } = useTranslation("common");
	const [httpResponse, setHttpResponse] = useState(null);
	const history = useHistory();

	const handleHome = id => {
		setHomeId(id);
		history.push(`/manage/me/myhome`);
	};

	return (
		<>
			<ErrorContainer
				alert={t("common.__delError__")}
				success={t("home.__removeHomeSuccess__")}
				httpResponse={httpResponse}
				setHttpResponse={setHttpResponse}
			/>

			<p className="meta">{t("home.__allHomes__")}</p>
			<div className="header-list-items">
				<p>
					<span className="meta">{t("home.__group__")}</span>
					{myGroup ? (
						<>
							<br />
							<b>{myGroup.name}</b>
						</>
					) : null}
				</p>
			</div>
			{homesFromGroup.map(home => (
				<div className="small-list-items ml-4" key={home.id} role="button" onClick={() => handleHome(home.id)}>
					{home.name}
					<span className="float-right">
						<FaAngleRight className="table-icon" />
					</span>
				</div>
			))}
		</>
	);
};

export default HomesListFromGroup;
