import React, { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { BlogContext } from "../context/BlogContext";
import { UserInterface } from "../interfaces/UserInterface";
import { BlogTabs } from "../utils/Tabs/TabsToDisplay";
import BlogHeader from "../layout/BlogHeader";
import Loader from "../layout/Loader";
import SelectedPost from "../components/News/SelectedPost";
import Tabs from "../utils/Tabs/Tabs";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import * as p from "../constants/Permissions";

interface Props {
	userState: UserInterface;
}

const SocialNews_BlogPost = ({ userState }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const { postId } = useParams<{ postId?: string }>();
	const { blogStore, blogDispatch } = useContext(BlogContext);
	const displayAddPostButton = userState.userConnected.is_in([
		p.HOME_BLOG_WRITER,
		p.CAN_POST_MODIFY_HOME_BLOG,
		p.SUPER_ADMIN,
		p.ADMIN
	]);

	useEffect(() => {
		if (postId) {
			blogDispatch({
				type: "LOADING_NEWS"
			});
			http.get(`${url.HOMESURL}/blog_posts/${postId}`).then(res => {
				blogDispatch({
					type: "GET_POST",
					payload: res.data.blog_post
				});
			});
		}
	}, [postId]);

	return (
		<>
			<Tabs tabsToDisplay={BlogTabs} currentTab={"BLOG"} />
			<div className="big-card">
				<BlogHeader displayAddPostButton={displayAddPostButton} title={t("news.__news__")} />
				{blogStore.loading ? (
					<Loader />
				) : (
					<SelectedPost postId={postId} blogStore={blogStore} blogDispatch={blogDispatch} userState={userState} />
				)}
			</div>
		</>
	);
};
export default SocialNews_BlogPost;
