import React from "react";
import { useTranslation } from "react-i18next";
import { displayDay } from "../../constants/EventType";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { FaAngleDoubleRight, FaAngleRight } from "react-icons/fa";

export default function HandleRecurringEvents({ recurringData, userLocale }) {
	const { t } = useTranslation("common");

	const handleDays = days => {
		if (days) {
			if (days.length === 7) {
				return t("calendar.__everyDays__");
			} else if (days.length === 1) {
				return (
					<>
						{t("calendar.__thisDay__")} <strong>{displayDay(days[0])}</strong>
					</>
				);
			} else {
				return (
					<>
						{t("calendar.__thisDays__")}
						{days.map(day => (
							<span key={day} className="mr-2">
								{displayDay(day)}
							</span>
						))}
					</>
				);
			}
		}
	};

	return (
		<div className="col-12">
			<h3>{t("calendar.__recurring__")} :</h3>
			<p className="ml-3">{t("calendar.__eventDates__")}</p>
			<p className="ml-3">
				<FaAngleDoubleRight className="mr-2" />
				{recurringData.recurring_type === 2 ? (
					<b>{t("calendar.__dayInMonth__", { day: recurringData.day_of_month })}</b>
				) : (
					handleDays(recurringData.days_of_week)
				)}
			</p>
			<p className="ml-3">
				<FaAngleDoubleRight className="mr-2" />
				{recurringData.begin_time ? (
					<>
						{recurringData.begin_time.substring(0, 5)}
						{recurringData.end_time && (
							<>
								<FaAngleRight className="mr-2 ml-2" />
								{recurringData.end_time.substring(0, 5)}
							</>
						)}
					</>
				) : null}
			</p>
			{recurringData.end_date ? (
				<p className="ml-3">
					<FaAngleDoubleRight className="mr-2" />
					{t("calendar.__until__")} : {handleDate(recurringData.end_date, "PPPP", userLocale)}
				</p>
			) : (
				<p className="ml-3">
					<FaAngleDoubleRight className="mr-2" />
					{t("calendar.__noEnd__")}
				</p>
			)}
		</div>
	);
}
