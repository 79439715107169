import React, { useContext, useEffect } from "react";
import { UserInterface } from "../interfaces/UserInterface";
import { UserContext } from "../context/UserContext";
import AccessControl from "../layout/AccessControl";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import HomesSecondaryNav from "../layout/HomesSecondaryNav";
import EditHome from "../components/Home/Homes/EditHome";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	tenantId: number | null;
	userState: UserInterface;
}

const MyHome_EditHome = ({ userState, homeId, tenantId }: Props): JSX.Element => {
	const { homeStore, homeDispatch } = useContext(UserContext);
	const isAdmin = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN]);
	const isSuperAdmin = userState.userConnected.is(p.SUPER_ADMIN);
	const canUpdateHome = userState.userConnected.is(p.CAN_UPDATE_HOME);

	useEffect(() => {
		if (homeId) {
			if (canUpdateHome || isAdmin) {
				http
					.get(`${url.BASEURL}/homes/${homeId}`)
					.then(res => {
						homeDispatch({
							type: "GET_MY_HOME",
							payload: res.data.home
						});
					})
					.catch(() => {
						homeDispatch({
							type: "GET_MY_HOME",
							payload: {}
						});
					});
			}
		}
		// eslint-disable-next-line
	}, [homeId, canUpdateHome, isAdmin]);

	useEffect(() => {
		return function cleanup(): void {
			homeDispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_UPDATE_HOME]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<HomesSecondaryNav userState={userState} />}
				component={
					<EditHome
						homeId={homeId}
						homeStore={homeStore}
						homeDispatch={homeDispatch}
						isAdmin={isAdmin}
						isSuperAdmin={isSuperAdmin}
						tenantId={tenantId}
					/>
				}
			/>
		</AccessControl>
	);
};
export default MyHome_EditHome;
