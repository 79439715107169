import React from "react";
import { useParams } from "react-router-dom";
import { HealthWorkerInterface, UserInterface } from "../interfaces/UserInterface";
import { VisioTabs } from "../utils/Tabs/TabsToDisplay";
import Tabs from "../utils/Tabs/Tabs";
import useStoreData from "../hooks/useStoreData";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import HomesSecondaryNav from "../layout/HomesSecondaryNav";
import AccessControl from "../layout/AccessControl";
import HandleVisioRecipients from "../components/Home/Recipients/HandleVisioRecipients";
import HandleMsgRecipients from "../components/Home/Recipients/HandleMsgRecipients";
import * as p from "../constants/Permissions";
import SelectHomeInput from "../layout/SelectHomeInput";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const MyHome_UpdateRecipients = ({ homeId, userState }: Props): JSX.Element => {
	const { store } = useStoreData({ userState, homeId });
	const { type } = useParams<{ type?: string }>();
	const users = userState.userConnected.home_id
		? store.users.healthWorkers
		: store.users.healthWorkers.filter((hw: HealthWorkerInterface) => hw.home_id === homeId);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_UPDATE_HOME]}>
			{homeId ? (
				type === "visio" ? (
					<PageWithSecondaryNavLayout
						secondaryNav={<HomesSecondaryNav userState={userState} />}
						component={
							<>
								<Tabs tabsToDisplay={VisioTabs} currentTab={"RECIPIENTS"} />
								<HandleVisioRecipients homeId={homeId} users={users} type={type} />
							</>
						}
					/>
				) : (
					<PageWithSecondaryNavLayout
						secondaryNav={<HomesSecondaryNav userState={userState} />}
						component={
							<>
								<Tabs tabsToDisplay={VisioTabs} currentTab={"RECIPIENTS"} />
								<HandleMsgRecipients homeId={homeId} users={users} type={type} />
							</>
						}
					/>
				)
			) : (
				<SelectHomeInput />
			)}
		</AccessControl>
	);
};
export default MyHome_UpdateRecipients;
