import { useContext, useEffect } from "react";
import { DiaperContext } from "../context/DiaperContext";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import { UserInterface } from "../interfaces/UserInterface";

interface Props {
	userState: UserInterface;
	homeId: number | null;
	refetch?: boolean;
}

const useAmdGoals = ({ userState, homeId, refetch }: Props): any => {
	const { diaperStore, diaperDispatch } = useContext(DiaperContext);
	const isHomeManager = userState.userConnected.home_id;

	const checkAmdGoals = (homeId: number): void => {
		if (process.env.REACT_APP_FOR_GOHY === "false")
			http
				.get(`${url.HOMESURL}/${homeId}/amd_profile/last_management_tool`)
				.then(res => diaperDispatch({ type: "AMD_GOALS", payload: { isAmdCustomer: true, goals: res.data } }))
				.catch(() => diaperDispatch({ type: "AMD_GOALS", payload: { isAmdCustomer: false, goals: null } }));
	};

	useEffect(() => {
		if (homeId) {
			if (isHomeManager && !diaperStore.hasAmdGoals) {
				checkAmdGoals(homeId);
			} else {
				checkAmdGoals(homeId);
			}
		}
	}, [homeId, isHomeManager, refetch]);

	return diaperStore.hasAmdGoals;
};
export default useAmdGoals;
