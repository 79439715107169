import React, { useEffect, useState } from "react";
import useDiaperStore from "../../hooks/useDiaperStore";
import ControlPanelComponent from "./ControlPanelComponent";

export default function ControlPanelSocket({
	homeId,
	userState,
	socket,
	isDiaperManager,
	isMessageManager,
	display,
	seniorsFromHome,
	homeUnits,
	post
}) {
	const [fetchMessages, setFetchMessages] = useState(false);
	const [fetchCalendar, setFetchCalendar] = useState(false);
	const [refetch, setRefetch] = useState(false);
	const { diaperStore, diaperDispatch } = useDiaperStore({ userState, homeId, refetch });

	useEffect(() => {
		if (socket && homeId) {
			let channel = socket.channel(`controlpanel:${homeId}`);
			console.log("[DEBUG WS] Control panel channel > ", channel);

			channel.onError(() => {
				console.log("Control panel channel > there was an error!");
			});

			channel.onClose(() => {
				console.log("Control panel channel > the channel has gone away gracefully");
			});

			channel.on("controlpanel", msg => {
				console.log("Control panel channel > ", msg);
				if (msg.data === "CALENDAR") {
					setFetchCalendar(prevState => !prevState);
				}
				if (msg.data === "MESSAGE") {
					setFetchMessages(prevState => !prevState);
				}
			});

			channel
				.join()
				.receive("ok", ({ messages }) => console.log("Control panel channel > catching up", messages))
				.receive("error", ({ reason }) => console.log("Control panel channel > failed join", reason))
				.receive("timeout", () => console.log("Control panel channel > Networking issue. Still waiting..."));
		}
	}, [socket, homeId]);

	useEffect(() => {
		if (socket && homeId && isDiaperManager) {
			let diapersChannel = socket.channel(`diapers:${homeId}`);
			console.log("[DEBUG WS] Diapers channel > ", diapersChannel);

			diapersChannel.onError(() => {
				console.log("Diapers channel > there was an error!");
			});

			diapersChannel.onClose(() => {
				console.log("Diapers channel > has gone away gracefully");
			});

			diapersChannel.on("msg", msg => {
				console.log("Diapers channel > on", msg);
				if (msg.data) {
					setRefetch(prevState => !prevState);
				}
			});
			diapersChannel.on("sensor", msg => {
				console.log(" Diapers Channel > sensor >>", msg);
				if (msg) {
					diaperDispatch({
						type: "EDIT_SENIORSWITHDIAPER",
						payload: msg
					});
				}
			});

			diapersChannel
				.join()
				.receive("ok", ({ messages }) => console.log("Diapers channel >  catching up", messages))
				.receive("error", ({ reason }) => console.log("Diapers channel >  failed join", reason))
				.receive("timeout", () => console.log("Diapers channel > Networking issue. Still waiting..."));
		}
	}, [socket, homeId, isDiaperManager]);

	return (
		<ControlPanelComponent
			homeId={homeId}
			fetchMessages={fetchMessages}
			fetchCalendar={fetchCalendar}
			refetch={refetch}
			userState={userState}
			isDiaperManager={isDiaperManager}
			display={display}
			diaperStore={diaperStore}
			diaperDispatch={diaperDispatch}
			seniorsFromHome={seniorsFromHome}
			homeUnits={homeUnits}
			isMessageManager={isMessageManager}
			post={post}
		/>
	);
}
