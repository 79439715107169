import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EditHealtWorkerTypeBudget from "./EditHealthWorkerTypeBudget";
import AddHealtWorkerTypeBudget from "./AddHealthWorkerTypeBudget";
import SelectHomeInput from "../../layout/SelectHomeInput";
import ErrorContainer from "../../layout/ErrorContainer";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import HeaderContainer from "../../layout/HeaderContainer";
import NoData from "../../layout/NoData";

export default function HealtWorkerTypeBudget({ homeId, healthWorkerType }) {
	const { t } = useTranslation("common");
	const [budget, setBudget] = useState([]);
	const [budgetError, setBudgetError] = useState(false);
	const [httpResponse, setHttpResponse] = useState(null);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		if (homeId) {
			http
				.get(`${url.HOMESURL}/${homeId}/health_worker_budget_distributions`)
				.then(res => {
					setBudgetError(false);
					setBudget(res.data.health_worker_budget_distributions);
				})
				.catch(() => setBudgetError(true));
		}
	}, [homeId, update]);

	const ids = budget.map(b => b.health_worker_type_id);
	const healthWorkerTypeToComplete = healthWorkerType.filter(hwt => !ids.includes(hwt.id));

	return (
		<div className="big-card">
			<HeaderContainer
				title={t("hds.__hdsRepartition__")}
				addButton={t("hds.__statistics__")}
				link={"/manage/hds/hwbudget"}
			/>
			<p className="meta">{t("hds.__defineHds__")}</p>
			<ErrorContainer
				alert={t("common.__updateError__")}
				success={t("hds.__saved__")}
				httpResponse={httpResponse}
				setHttpResponse={setHttpResponse}
			/>
			{!homeId ? (
				<SelectHomeInput />
			) : budgetError ? (
				<NoData />
			) : (
				<>
					<div className="row">
						<div className="col-md-3">
							<p>{t("common.__job__")}</p>
							<hr />
						</div>
						<div className="col-md-9">
							<div className="row">
								<div className="col-md-3">
									<p>{t("hds.__accomodation__")}</p>
									<hr />
								</div>
								<div className="col-md-3">
									<p>{t("hds.__dependency__")}</p>
									<hr />
								</div>
								<div className="col-md-3">
									<p>{t("hds.__care__")}</p>
									<hr />
								</div>
								<div className="col-md-3"></div>
							</div>
						</div>
					</div>
					{budget.map(data => (
						<div key={data.id} className="mt-2">
							<EditHealtWorkerTypeBudget
								update={update}
								setUpdate={setUpdate}
								httpResponse={httpResponse}
								setHttpResponse={setHttpResponse}
								hwTypeId={data.id}
								healthWorkerType={healthWorkerType}
								hwType={data.health_worker_type_id}
								care={data.care}
								accomodation={data.accomodation}
								dependency={data.dependency}
							/>
						</div>
					))}

					{healthWorkerTypeToComplete.map(data => (
						<div key={data.id} className="mt-2">
							<AddHealtWorkerTypeBudget
								update={update}
								setUpdate={setUpdate}
								httpResponse={httpResponse}
								setHttpResponse={setHttpResponse}
								homeId={homeId}
								hwType={data}
							/>
						</div>
					))}
				</>
			)}
		</div>
	);
}
