import React, { useReducer, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SeniorInterface } from "../../../interfaces/UserInterface";
import Select from "react-select";
import PreferencesReducer from "./PreferencesReducer";
import PhotoFramePreferences from "./PhotoFramePreferences";
import SeniorPreferences from "./SeniorPreferences";
import AddPhotoFramePreferences from "./AddPhotoFramePreferences";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import ModalContainer from "../../../utils/ModalContainer";
import { FaUserCog } from "react-icons/fa";

interface Props {
	homeId: number;
	userLocale: string;
	seniorsWithDevice: SeniorInterface[];
}

const HomePreferences = ({ homeId, userLocale, seniorsWithDevice }: Props): JSX.Element => {
	const { t } = useTranslation("common");

	const initialState = {
		home: {
			photoframe: [],
			error: false,
			msg: null
		},
		seniorSelected: {
			photoframe: [],
			error: false,
			msg: null
		}
	};

	const [preferences, dispatch] = useReducer(PreferencesReducer, initialState);
	const [addHomePhotoPreference, setAddHomePhotoPreference] = useState({
		show: false,
		info: null,
		title: t("device.__addPhotoframeTiming__")
	});
	const [addSeniorPhotoPreference, setAddSeniorPhotoPreference] = useState({
		show: false,
		info: null,
		title: t("device.__addPhotoframeTiming__")
	});
	const [seniorSelected, setSeniorSelected] = useState<SeniorInterface | null>(null);

	useEffect(() => {
		if (homeId) {
			http.get(`${url.HOMESURL}/${homeId}/device_config`).then(res => {
				dispatch({
					type: "GET_HOME_PHOTOFRAME_PREFERENCES",
					payload: res.data.PHOTO_FRAME_ACTIVATION_RANGES
				});
			});
		}
	}, [homeId]);

	useEffect(() => {
		return function cleanup() {
			dispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<div className="big-card">
				<div className="header-details mt-4">
					<div className="d-flex justify-content-between">
						<h2>{t("common.__home__")}</h2>
						<div>
							<button
								type="button"
								className="btn btn-sm btn-outline-primary mt-1 right"
								onClick={(): void =>
									setAddHomePhotoPreference({ show: true, info: null, title: t("device.__addPhotoframeTiming__") })
								}>
								{t("device.__addPhotoframeTiming__")}
							</button>
						</div>
					</div>
					{preferences.home.photoframe.length > 0 ? (
						<PhotoFramePreferences preferences={preferences.home} dispatch={dispatch} homeId={homeId} />
					) : (
						<p>{t("common.__noData__")}</p>
					)}
				</div>

				<div className="header-details mt-4">
					<h2>{t("senior.__seniorsWithDevice__", { name: process.env.REACT_APP_NAME })}</h2>

					<Select
						placeholder={t("senior.__selectSenior__")}
						options={seniorsWithDevice}
						classNamePrefix="react-select"
						className="mt-3"
						onChange={(e: any): void => (e ? setSeniorSelected(e) : setSeniorSelected(null))}
						isClearable
					/>
					{seniorSelected ? (
						<>
							<SeniorPreferences seniorId={seniorSelected.id} preferences={preferences} dispatch={dispatch} />
							<button
								type="button"
								className="btn btn-sm btn-outline-primary mt-1 mb-3"
								onClick={(): void =>
									setAddSeniorPhotoPreference({ show: true, info: null, title: t("device.__addPhotoframeTiming__") })
								}>
								<FaUserCog className="mr-3" />
								{t("device.__addPhotoframeTiming__")}
							</button>
						</>
					) : null}
				</div>
			</div>
			<ModalContainer show={addHomePhotoPreference} setShow={setAddHomePhotoPreference}>
				<AddPhotoFramePreferences
					setAddPreference={setAddHomePhotoPreference}
					preferences={preferences}
					dispatch={dispatch}
					homeId={homeId}
					userLocale={userLocale}
					senior={null}
				/>
			</ModalContainer>
			<ModalContainer show={addSeniorPhotoPreference} setShow={setAddSeniorPhotoPreference}>
				<AddPhotoFramePreferences
					setAddPreference={setAddSeniorPhotoPreference}
					preferences={preferences}
					dispatch={dispatch}
					homeId={homeId}
					userLocale={userLocale}
					senior={seniorSelected}
				/>
			</ModalContainer>
		</>
	);
};
export default HomePreferences;
