import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import {
	FaHeart,
	FaUsersCog,
	FaRegCheckCircle,
	FaExclamationTriangle,
	FaRegClock,
	FaDownload,
	FaRegCommentAlt,
	FaRegHeart,
	FaRegTimesCircle
} from "react-icons/fa";
import VideoPlayer from "../../../utils/VideoPlayer";
import WhoLike from "./WhoLike";
import Comments from "../../News/Comments/Comments";
import Parse from "html-react-parser";
import LazyloadImg from "../../../layout/LazyloadImg";
import Tabs from "../../../utils/Tabs/Tabs";
import { BlogPanelTabs } from "../../../utils/Tabs/TabsToDisplay";
import NoData from "../../../layout/NoData";

export default function FullBlogPost({ post, userState, blogDispatch }) {
	const { t } = useTranslation("common");

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const displayMedias = medias => {
		const video = medias.filter(media => media.content_type === "video/mp4");
		const pdfFiles = medias.filter(media => media.content_type === "application/pdf");
		const pictures = medias.filter(media => media.content_type.split("/")[0] === "image");
		return (
			<>
				{pdfFiles.length > 0 &&
					pdfFiles.map(pdf => (
						<div key={pdf.path} className="header-details">
							<a href={`/storages/blog_media${pdf.path}`} download={`${pdf.filename}`}>
								<FaDownload className="mr-3" style={{ height: "18px", width: "auto" }} />
								<b>{pdf.filename}</b> ({t("common.__pdfFile__")})
							</a>
						</div>
					))}
				{video.length > 0 &&
					video.map(v => (
						<div className="video-container" key={v.path}>
							<VideoPlayer uriVideo={v.path} contentType={v.content_type} />
						</div>
					))}
				{pictures.length > 0 &&
					pictures.map(pict => (
						<div className="center mt-3" key={pict.path}>
							<LazyloadImg path={`/storages/blog_media${pict.path}`} display="full-pict" />
							{/* <img src={`/storages/blog_media${pict.path}`} className="full-pict" alt="blog illustration" /> */}
						</div>
					))}
			</>
		);
	};

	return (
		<>
			<Tabs tabsToDisplay={BlogPanelTabs} currentTab={"BLOG"} />
			{post ? (
				<div className="big-card">
					<div className="social-block">
						<h2>{post.title}</h2>
						<FaRegClock className="mr-2 ml-1 meta" style={{ height: "16px", width: "auto" }} />{" "}
						<span className="meta">{handleDate(post.date, "P", userState.userConnected.locale)}</span>
						{post.accepted && (
							<b className="green">
								<FaRegCheckCircle className="mr-2 ml-4" style={{ height: "16px", width: "auto" }} />
								{t("news.__moderationOk__")}
							</b>
						)}
						{post.accepted === null && (
							<Link to="/moderation/blog">
								<b className="blue">
									<FaExclamationTriangle className="mr-2 ml-4" style={{ height: "16px", width: "auto" }} />
									{t("news.__moderationPending__")}
								</b>
							</Link>
						)}
						{post.accepted === false && (
							<span className="red">
								<FaRegTimesCircle className="mr-2 ml-4" style={{ height: "16px", width: "auto" }} />
								{t("news.__moderationRefused__")}
							</span>
						)}
						{post.like_counter > 0 ? (
							<span className="fake-link red">
								<FaHeart className="mr-2 ml-4" style={{ height: "16px", width: "auto" }} /> <b>{post.like_counter}</b>
							</span>
						) : (
							<span className="meta">
								<FaRegHeart className="red mr-2 ml-4" style={{ height: "16px", width: "auto" }} /> 0
							</span>
						)}
						{post.comment_counter > 0 ? (
							<span className="blue">
								<FaRegCommentAlt className="blue mr-2 ml-4" style={{ height: "16px", width: "auto" }} />{" "}
								<b>{post.comment_counter}</b>
							</span>
						) : (
							<span className="blue">
								<FaRegCommentAlt className="mr-2 ml-4" style={{ height: "16px", width: "auto" }} /> 0
							</span>
						)}
						{post.focused_on ? (
							<div className="mt-3 ml-2">
								<FaUsersCog className="mr-2" style={{ height: "1rem", width: "auto" }} />
								{post.seniors_focused_on.map(senior => (
									<span key={senior.id} className="msg-tag blue mr-2">
										{senior.last_name.toUpperCase()} {senior.first_name}
									</span>
								))}
							</div>
						) : null}
					</div>
					<div className="mt-2">
						<div className="mt-5">{Parse(post.body)}</div>
						<div className="mt-5">{displayMedias(post.medias)}</div>
						{post.accepted ? (
							<>
								<WhoLike postId={post.id} />
								<hr />
								<Comments
									postId={post.id}
									commentsCounter={post.comment_counter}
									userState={userState}
									blogDispatch={blogDispatch}
								/>
							</>
						) : null}
					</div>
				</div>
			) : (
				<NoData />
			)}
		</>
	);
}
