import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Table, SelectColumnFilter } from "../../utils/Table/TableWithFilter";
import { FaRegCopy, FaCheck, FaAngleRight } from "react-icons/fa";
import { copyToClipboard } from "../../utils/CopyToClipboard";
import { useTranslation } from "react-i18next";
import { handleDate } from "@e4ia/e4link_modules.datetime";

export default function MemoContainer({ homeId, userState, allMemo, setCopied }) {
	const { t } = useTranslation("common");

	const copyThisNote = (id, note) => {
		setCopied(id);
		copyToClipboard(note);
	};

	const columns = useMemo(
		() => [
			{
				Header: t("common.__date__"),
				accessor: "day",
				Filter: SelectColumnFilter,
				width: 60,
				minWidth: 50,
				Cell: row => {
					return (
						<p className="meta">
							{row.row.values.day}
							<br />
							{handleDate(row.row.original.date, "p", userState.userConnected.locale)}
						</p>
					);
				}
			},
			{
				Header: t("common.__homeUnit__"),
				accessor: "homeUnit",
				Filter: SelectColumnFilter,
				width: 100,
				minWidth: 90,
				Cell: row => (
					<>
						<b>{row.row.values.homeUnit}</b>
						{row.row.original.room}
					</>
				)
			},
			{
				Header: t("common.__senior__"),
				accessor: "senior",
				Filter: SelectColumnFilter,
				width: 100,
				minWidth: 90,
				Cell: row => (
					<Link to={`/senior/profile/${row.row.original.senior_id}`}>
						<span className="category-outline-badge blue">
							<b>{row.row.values.senior}</b>
						</span>
					</Link>
				)
			},
			{
				Header: t("healthworker.__teamMember__"),
				accessor: "healthworker",
				Filter: SelectColumnFilter,
				width: 100,
				minWidth: 90,
				Cell: row =>
					row.row.original.health_worker_id === userState.userConnected.id ? (
						<b>{row.row.values.healthworker}</b>
					) : (
						<Link to={`/user/healthworker/${row.row.original.health_worker_id}`}>
							<span className="category-outline-badge grey">
								<b>{row.row.values.healthworker}</b>
							</span>
						</Link>
					)
			},
			{
				Header: t("memo.__memo__"),
				accessor: "note",
				Cell: row => (
					<>
						{row.row.original.copy ? (
							<span className="right category-badge green">
								<FaCheck /> {t("memo.__memoCopied__")}
							</span>
						) : (
							<button
								className="right category-badge blue"
								onClick={() => copyThisNote(row.row.original.id, row.row.values.note)}
								type="button">
								<FaRegCopy /> {t("memo.__memoCopy__")}
							</button>
						)}
						{row.row.values.note}
					</>
				)
			}
		],
		// eslint-disable-next-line
		[homeId]
	);

	return (
		<div className="mt-3">
			{allMemo.length > 0 ? (
				<Table columns={columns} data={allMemo} />
			) : (
				<p className="mt-4 meta">
					<FaAngleRight /> {t("memo.__noMemo__")} {t("common.__selectNewDate__")}
				</p>
			)}
		</div>
	);
}
