import React from "react";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import { FaCamera, FaRegFilePdf, FaVideo } from "react-icons/fa";
import { Link } from "react-router-dom";
import DisplayName from "../../utils/DisplayName";
import i18n from "../../i18n/i18next";

const handlePostStatus = accepted => {
	switch (accepted) {
		case false:
			return i18n.t("common:news.__moderationRefused__");
		case true:
			return i18n.t("common:news.__moderationOk__");
		default:
			return i18n.t("common:news.__moderationPending__");
	}
};

export const handlePosts = (posts, locale, healthWorkers) => {
	const data = posts.map(post => {
		const video = post.medias.filter(media => media.content_type === "video/mp4");
		const pdfFiles = post.medias.filter(media => media.content_type === "application/pdf");
		return {
			...post,
			photoCounter: post.medias.length - (video.length + pdfFiles.length),
			eventDate: handleDate(post.date, "P", locale),
			author: healthWorkers ? DisplayName(post.added_by, healthWorkers) : null,
			published: handlePostStatus(post.accepted),
			video: video,
			pdfFiles: pdfFiles
		};
	});
	return data;
};

export const displayMeta = medias => {
	const video = medias.filter(media => media.content_type.split("/")[0] === "video");
	const pdf = medias.filter(media => media.content_type === "application/pdf");
	const videoCounter = video.length;
	const pdfCounter = pdf.length;
	const pictCounter = medias.length - (videoCounter + pdfCounter);
	return (
		<b>
			<FaVideo className={`social-meta-icon mr-2 ${videoCounter > 0 ? "blue" : "darkgrey"}`} />
			<span className={videoCounter > 0 ? "blue" : "darkgrey"}>{videoCounter}</span>
			<FaCamera className={`social-meta-icon ml-4 mr-2 ${pictCounter > 0 ? "blue" : "darkgrey"}`} />
			<span className={pictCounter > 0 ? "blue" : "darkgrey"}>{pictCounter}</span>
			<FaRegFilePdf className={`social-meta-icon ml-4 mr-2 ${pdfCounter > 0 ? "red" : "darkgrey"}`} />
			<span className={pdfCounter > 0 ? "red" : "darkgrey"}>{pdfCounter}</span>
		</b>
	);
};

export const handleEditRemoveButtons = (
	accepted,
	authorId,
	postId,
	title,
	isBlogger,
	canRemovePost,
	userId,
	isModerator,
	setShowRemoveConfirmation
) => {
	if (canRemovePost) {
		if (accepted !== false) {
			return (
				<>
					<Link to={`/blog/news/editpost/${postId}`}>
						<BsPencilSquare role="button" className="big-kpi-icon regular" />
					</Link>
					<BsTrash
						className="big-kpi-icon regular"
						role="button"
						onClick={() =>
							setShowRemoveConfirmation({
								show: true,
								info: {
									postId: postId,
									postTitle: title
								},
								title: i18n.t("common:news.__removePostWarningTitle__")
							})
						}
					/>
				</>
			);
		} else {
			return (
				<BsTrash
					className="big-kpi-icon regular"
					role="button"
					onClick={() =>
						setShowRemoveConfirmation({
							show: true,
							info: {
								postId: postId,
								postTitle: title
							},
							title: i18n.t("common:news.__removePostWarningTitle__")
						})
					}
				/>
			);
		}
	} else if (isModerator) {
		if (accepted === null) {
			return (
				<Link to={`/blog/news/editpost/${postId}`}>
					<BsPencilSquare role="button" className="big-kpi-icon regular" />
				</Link>
			);
		}
	} else if (isBlogger && authorId === userId) {
		return (
			<Link to={`/blog/news/editpost/${postId}`}>
				<BsPencilSquare role="button" className="big-kpi-icon regular" />
			</Link>
		);
	} else {
		return null;
	}
};
