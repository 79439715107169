const NewsReducer = (state, action) => {
	switch (action.type) {
		case "LOADING_NEWS":
			return {
				...state,
				loading: true,
				error: false,
				success: false
			};

		case "GET_NEWS":
			return {
				...state,
				loading: false,
				news: action.payload,
				error: false
			};

		case "ADD_NEWS":
			return {
				...state,
				loading: false,
				news: [action.payload, ...state.news],
				error: false
			};

		case "GET_POST":
			return {
				...state,
				post: action.payload,
				loading: false
			};

		case "EDIT_NEWS": {
			const updatedNews = action.payload;
			const newsUpdated = state.news.map(n => {
				if (n.id === updatedNews.id) {
					return updatedNews;
				}
				return n;
			});
			return {
				...state,
				news: newsUpdated,
				error: false,
				loading: false
			};
		}

		case "EDIT_POST_COUNTER": {
			const post = state.post ? state.post : state.news.find(p => p.id === action.payload);
			const postUpdated = post ? { ...post, comment_counter: post.comment_counter + 1 } : {};
			const newsUpdated = state.news.map(n => {
				if (n.id === action.payload) {
					return {
						...n,
						comment_counter: n.comment_counter + 1
					};
				}
				return n;
			});
			return {
				...state,
				post: postUpdated,
				news: newsUpdated,
				error: false,
				loading: false
			};
		}

		case "DELETE_NEWS":
			return {
				...state,
				news: state.news.filter(n => n.id !== action.payload),
				error: false,
				loading: false,
				success: true
			};

		case "ERROR_NEWS":
			return {
				...state,
				error: true,
				loading: false
			};

		case "RESET_ES":
			return {
				...state,
				error: false,
				success: false
			};

		default:
			return state;
	}
};

export default NewsReducer;
