import React from "react";
import { useTranslation } from "react-i18next";
import { FaRegListAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Tooltip } from "./Tooltip";

interface Props {
	id: number;
	type: string;
}

const TimelineIcon = ({ type, id }: Props): JSX.Element | null => {
	const { t } = useTranslation("common");

	return id && type === "SENIOR" ? (
		<Tooltip text={t("permissions.__timeline__")}>
			<Link to={`/senior/senioractivities/${id}`}>
				<FaRegListAlt className="board-card-icon" role="button" aria-label="senior-timeline" />
			</Link>
		</Tooltip>
	) : id && type === "HEALTHWORKER" ? (
		<Tooltip text={t("permissions.__timeline__")}>
			<Link to={`/user/healthworkeractivities/${id}`}>
				<FaRegListAlt className="table-icon" role="button" aria-label="healthworker-timeline" />
			</Link>
		</Tooltip>
	) : null;
};
export default TimelineIcon;
