import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import DateTimePicker from "../../utils/DatePicker/DateTimePicker";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import HandleError from "../../layout/HandleError";

export default function ControlProtectionToSenior({ userLocale, senior, setShow }) {
	const { t } = useTranslation("common");
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [control, setControl] = useState({ bring_to_wc: 0 });
	const [error, setError] = useState(false);

	const onSubmit = e => {
		e.preventDefault();
		setError(false);
		const changeDate = handleDate(selectedDate, "yyyy-MM-dd' 'HH:mm:ss", userLocale);
		const data = {
			diaper_change: {
				diaper_id: 0,
				senior_id: senior.id,
				done_at: changeDate,
				diaper_sensor_id: senior.sensor_id,
				dirty_bed: 0,
				diaper_saturated: 0,
				presence_of_stools: 0,
				presence_of_redness: 0,
				torn_off: 0,
				draw_sheet_changed: 0,
				bring_to_wc: control.bring_to_wc
			}
		};
		http
			.post(`${url.BASEURL}/rht/senior/diaper/change`, data)
			.then(() => setShow({ show: false, resident: null, success: true }))
			.catch(() => setError(true));
	};

	return (
		<form onSubmit={onSubmit}>
			<HandleError error={error} setError={setError} />
			<div className="row">
				<div className="col-5">{t("diaper.__bringToWc__")}</div>
				<div className="col-7">
					<div className="btn-group btn-group-sm mb-1" role="group" aria-label="mise aux WC">
						<button
							type="button"
							onClick={() => setControl({ bring_to_wc: 0 })}
							className={control.bring_to_wc === 0 ? "btn btn-primary" : "btn btn-outline-secondary"}>
							{t("common.__no__")}
						</button>{" "}
						<button
							type="button"
							className={control.bring_to_wc === 1 ? "btn btn-primary" : "btn btn-outline-secondary"}
							onClick={() => setControl({ bring_to_wc: 1 })}>
							{t("common.__yes__")}
						</button>
					</div>
				</div>
				<div className="col-5">{t("common.__date__")} :</div>
				<div className="col-7">
					<DateTimePicker userLocale={userLocale} startDate={selectedDate} setStartDate={setSelectedDate} />
				</div>
			</div>

			<button className="btn btn-primary btn-sm mt-2" type="submit">
				{t("common.__save__")}
			</button>
		</form>
	);
}
