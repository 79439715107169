import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import AlertCardsContainer from "./AlertCardsContainer";
import Toggle from "../../../utils/Toggle";
import ModalContainer from "../../../utils/ModalContainer";
import RemoveSensorToSenior from "../Sensors/RemoveSensorToSenior";
import AddOrControlProtectionToSenior from "../AddOrControlProtectionToSenior";
import AffectSensorToSenior from "../Sensors/AffectSensorToSenior";

export default function AlertCards({
	showDiaperChange,
	setShowDiaperChange,
	showRemoveSensor,
	setShowRemoveSensor,
	userState,
	homeUnits,
	floors,
	diaperStore,
	alerts,
	showAffectSensor,
	setShowAffectSensor,
	setUpdate
}) {
	const { t } = useTranslation("common");
	const searchInput = useRef(null);
	const diapers = diaperStore.diapers;
	const seniors = diaperStore.seniors;
	const seniorsWithoutAlert = useMemo(() => seniors.filter(data => !data.alert), [seniors]);
	const seniorsList = useMemo(() => [...alerts, ...seniorsWithoutAlert], [alerts, seniorsWithoutAlert]);
	const [homeUnitSelected, setHomeUnitSelected] = useState(null);
	const [floorSelected, setFloorSelected] = useState(null);
	const [withSensor, setWithSensor] = useState(false);
	const [seniorsToDisplay, setSeniorsToDisplay] = useState(seniorsList);

	useEffect(() => {
		const handleCardsToDisplay = (withSensor, homeUnitSelected, floorSelected) => {
			searchInput.current.value = "";
			if (withSensor) {
				const seniorsWithSensors = seniorsList.filter(senior => senior.sensor_id);
				if (homeUnitSelected && floorSelected) {
					const floor = floorSelected === t("common.__rdc__") ? 0 : floorSelected;
					setSeniorsToDisplay(
						seniorsWithSensors.filter(senior => senior.home_unit_id === homeUnitSelected && senior.room_floor === floor)
					);
				} else if (homeUnitSelected) {
					setSeniorsToDisplay(seniorsWithSensors.filter(senior => senior.home_unit_id === homeUnitSelected));
				} else if (floorSelected) {
					const floor = floorSelected === t("common.__rdc__") ? 0 : floorSelected;
					setSeniorsToDisplay(seniorsWithSensors.filter(senior => senior.room_floor === floor));
				} else {
					setSeniorsToDisplay(seniorsWithSensors);
				}
			} else {
				if (homeUnitSelected && floorSelected) {
					const floor = floorSelected === t("common.__rdc__") ? 0 : floorSelected;
					setSeniorsToDisplay(
						seniorsList.filter(senior => senior.home_unit_id === homeUnitSelected && senior.room_floor === floor)
					);
				} else if (homeUnitSelected) {
					setSeniorsToDisplay(seniorsList.filter(senior => senior.home_unit_id === homeUnitSelected));
				} else if (floorSelected) {
					const floor = floorSelected === t("common.__rdc__") ? 0 : floorSelected;
					setSeniorsToDisplay(seniorsList.filter(senior => senior.room_floor === floor));
				} else {
					setSeniorsToDisplay(seniorsList);
				}
			}
		};
		handleCardsToDisplay(withSensor, homeUnitSelected, floorSelected);
	}, [seniorsList, homeUnitSelected, floorSelected, withSensor]);

	const sensorsAlreadyUsed = seniors.filter(senior => senior.sensor_id);
	const sensorsIdsAlreadyUsed = sensorsAlreadyUsed.map(senior => senior.sensor_id);
	const sensorsAvailable = diaperStore.sensors.filter(sensor => !sensorsIdsAlreadyUsed.includes(sensor.id));

	const handleSearch = e => {
		setHomeUnitSelected(null);
		setFloorSelected(null);
		const seniors = seniorsList.filter(senior => senior.name.toLowerCase().includes(e.toLowerCase()));
		setSeniorsToDisplay(seniors);
	};

	const toggleFilter = value => {
		setWithSensor(!value);
	};

	return (
		<>
			<div className="d-flex justify-content-between">
				<p className="meta mt-3">
					{t("common.__filterBy__")} {t("common.__senior__")} :
				</p>
				<Toggle
					isToggle={withSensor}
					value={withSensor}
					action={toggleFilter}
					textActif={` ${t("diaper.__withSensor__")} (${seniorsToDisplay.length})`}
					textInactif={` ${t("common.__all__")} (${seniorsToDisplay.length})`}
					meta
				/>
			</div>
			<div className="dont-print-this">
				<input
					type="text"
					placeholder={t("mailbox.__searchBySenior__")}
					onChange={e => handleSearch(e.target.value)}
					className="form-control form-control-sm mt-2 mb-2"
					ref={searchInput}
				/>

				{homeUnits.length > 1 ? (
					<div className="mt-3">
						<p className="meta">
							{t("common.__filterBy__")} {t("common.__homeUnit__")} :
						</p>
						<span
							className={homeUnitSelected ? "category-outline-badge grey" : "category-badge blue"}
							role="button"
							onClick={() => setHomeUnitSelected(null)}>
							{t("common.__all__")}
						</span>
						{homeUnits.map(homeUnit => (
							<span
								key={homeUnit.id}
								role="button"
								className={homeUnitSelected === homeUnit.id ? "category-badge blue" : "category-outline-badge grey"}
								onClick={() => setHomeUnitSelected(homeUnit.id)}>
								{homeUnit.name}
							</span>
						))}
					</div>
				) : null}
				{floors.length > 1 ? (
					<div className="mt-3">
						<p className="meta">
							{t("common.__filterBy__")} {t("home.__floor__")} :
						</p>
						<span
							className={floorSelected ? "category-outline-badge grey" : "category-badge blue"}
							role="button"
							onClick={() => setFloorSelected(null)}>
							{t("common.__all__")}
						</span>
						{floors.map((floor, index) => (
							<span
								key={index}
								role="button"
								className={floorSelected === floor.name ? "category-badge blue" : "category-outline-badge grey"}
								onClick={() => setFloorSelected(floor.name)}>
								{floor.name === t("common.__rdc__") ? null : t("home.__floor__")} {floor.name}
							</span>
						))}
					</div>
				) : null}
			</div>

			<div className="d-flex flex-row flex-wrap mt-3">
				<AlertCardsContainer
					setShowDiaperChange={setShowDiaperChange}
					setShowRemoveSensor={setShowRemoveSensor}
					setShowAffectSensor={setShowAffectSensor}
					userState={userState}
					seniorsToDisplay={seniorsToDisplay}
				/>
			</div>
			<ModalContainer show={showDiaperChange} setShow={setShowDiaperChange}>
				<AddOrControlProtectionToSenior
					diapers={diapers}
					userLocale={userState.userConnected.locale}
					show={showDiaperChange}
					setShow={setShowDiaperChange}
				/>
			</ModalContainer>
			<ModalContainer show={showRemoveSensor} setShow={setShowRemoveSensor}>
				<RemoveSensorToSenior show={showRemoveSensor} setShow={setShowRemoveSensor} />
			</ModalContainer>
			<ModalContainer show={showAffectSensor} setShow={setShowAffectSensor}>
				<AffectSensorToSenior
					show={showAffectSensor}
					setShow={setShowAffectSensor}
					sensorsAvailable={sensorsAvailable}
					setUpdate={setUpdate}
				/>
			</ModalContainer>
		</>
	);
}
