import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaRegEdit, FaRegTimesCircle, FaTimes, FaUserCog, FaUserSlash } from "react-icons/fa";
import { Table, SelectColumnFilter } from "../../../utils/Table/TableWithFilter";
import HandleError from "../../../layout/HandleError";
import SelectHomeInput from "../../../layout/SelectHomeInput";
import HeaderContainer from "../../../layout/HeaderContainer";
import ModalContainer from "../../../utils/ModalContainer";
import EditSensor from "./EditSensor";
import AffectSeniorToSensor from "./AffectSeniorToSensor";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import * as p from "../../../constants/Permissions";

export default function DiaperSensorsList({ homeId, isAdmin, diaperStore, diaperDispatch, userState }) {
	const { t } = useTranslation("common");
	const [showEditSensor, setShowEditSensor] = useState({ show: false, info: { sensor: null }, success: false });
	const [showAffectSenior, setShowAffectSenior] = useState({
		show: false,
		info: { sensor: null, title: t("diaper.__affectSensor__") }
	});

	useEffect(() => {
		if (homeId && diaperStore.seniors.length > 0) {
			const seniorsWithSensor = diaperStore.seniors.filter(senior => senior.sensor_id);
			http.get(`${url.HOMESURL}/${homeId}/rht/diaper_sensors`).then(res => {
				diaperDispatch({
					type: "GET_SENSORS_WITH_SENIOR",
					payload: { sensors: res.data.diaper_sensors, seniors: seniorsWithSensor }
				});
			});
		}
		// eslint-disable-next-line
	}, [homeId, diaperStore.seniors]);

	const data =
		diaperStore.sensors.length > 0
			? diaperStore.sensors.map(sensor => {
				return {
					...sensor,
					seniorName: sensor.senior ? `${sensor.senior.name}` : t("common.__noAssignment__")
				};
			})
			: [];

	const removeSensor = id => {
		http
			.delete(`${url.BASEURL}/rht/diaper_sensor/${id}`)
			.then(() => {
				diaperDispatch({
					type: "DELETE_SENSOR",
					payload: parseInt(id)
				});
			})
			.catch(() => {
				diaperDispatch({
					type: "DISPLAY_ERROR",
					payload: t("common.__delError__")
				});
			});
	};

	const removeSensorToSenior = (sensorId, seniorId) => {
		const data = {
			diaper_sensor_id: sensorId,
			senior_id: seniorId
		};
		http
			.post(`${url.BASEURL}/rht/unaffect/diaper_sensor/from/senior`, data)
			.then(() => {
				diaperDispatch({
					type: "UNAFFECT_SENSOR_TO_SENIOR",
					payload: seniorId
				});
			})
			.catch(() => {
				diaperDispatch({
					type: "DISPLAY_ERROR",
					payload: t("common.__updateError__")
				});
			});
	};

	const columns = [
		{
			Header: t("common.__type__"),
			accessor: "type",
			Filter: SelectColumnFilter,
			minWidth: 25,
			width: 30,
			maxWidth: 35
		},
		{
			Header: "MAC",
			accessor: "mac_address",
			minWidth: 30,
			width: 35,
			maxWidth: 40,
			Cell: row => (
				<>
					<p>
						<b>{row.row.values.mac_address}</b>
					</p>
					{isAdmin ? (
						<>
							<p className="meta">
								Cal. Temp (°C) : {row.row.original.delta_t !== 0 ? <strong>{row.row.original.delta_t}</strong> : <span>{row.row.original.delta_t}</span>}
							</p>
							<p className="meta">
								Cal. HR (%) : {row.row.original.delta_h !== 0 ? <strong>{row.row.original.delta_h}</strong> : <span>{row.row.original.delta_h}</span>}
							</p>
						</>
					) : null}
				</>
			)
		},
		{
			Header: t("common.__senior__"),
			accessor: "seniorName",
			Filter: SelectColumnFilter,
			Cell: row => (
				<p>
					{row.row.values.seniorName === t("common.__noAssignment__") ? null : (
						<Link to={`/senior/profile/${row.row.original.senior.id}`} className="category-outline-badge blue">
							<b>{row.row.values.seniorName}</b>
						</Link>
					)}
				</p>
			)
		},
		{
			Header: "",
			accessor: "id",
			disableFilters: true,
			maxWidth: 70,
			minWidth: 55,
			width: 65,
			Cell: row => (
				<div className="d-inline-block float-right mr-2">
					{row.row.original.senior ? (
						<FaUserSlash
							className="table-icon red"
							role="button"
							onClick={() => removeSensorToSenior(row.row.values.id, row.row.original.senior.id)}
						/>
					) : (
						<FaUserCog
							className="table-icon"
							role="button"
							onClick={() =>
								setShowAffectSenior({ show: true, info: { sensor: row.row.original }, title: t("diaper.__affectSensor__") })
							}
						/>
					)}
					{isAdmin ? (
						<>
							<FaRegEdit
								className="table-icon ml-2"
								role="button"
								onClick={() => setShowEditSensor({ show: true, info: { sensor: row.row.original }, success: false })}
							/>
							<FaRegTimesCircle role="button" className="table-icon ml-2" onClick={() => removeSensor(row.row.values.id)} />
						</>
					) : null}
				</div>
			)
		}
	];

	return (
		<div className="big-card">
			<HeaderContainer
				title={t("diaper.__sensors__")}
				addButton={t("common.__add__")}
				link={"/inco/sensor/diapersensor/add"}
				permissionNeeded={[p.SUPER_ADMIN, p.ADMIN]}
				userState={userState}
			/>

			{homeId ? (
				<>
					<HandleError error={diaperStore.error} dispatch={diaperDispatch} message={diaperStore.msg} noSpace />
					{showEditSensor.success ? (
						<p className="success-msg mt-2">
							<span
								className="right"
								role="button"
								tabIndex="0"
								onClick={() => setShowEditSensor({ show: false, sensor: null, success: false })}>
								<FaTimes />
							</span>
							{t("common.__eltEditSuccess__")}
						</p>
					) : (
						<br />
					)}
					{data.length > 0 ? <Table columns={columns} data={data} /> : <p>{t("diaper.__noSensorAvailable__")}</p>}
					<ModalContainer show={showEditSensor} setShow={setShowEditSensor}>
						<EditSensor diaperDispatch={diaperDispatch} show={showEditSensor} setShow={setShowEditSensor} />
					</ModalContainer>
					<ModalContainer show={showAffectSenior} setShow={setShowAffectSenior}>
						<AffectSeniorToSensor
							show={showAffectSenior}
							setShow={setShowAffectSenior}
							seniors={diaperStore.seniors}
							diaperDispatch={diaperDispatch}
						/>
					</ModalContainer>
				</>
			) : (
				<SelectHomeInput />
			)}
		</div>
	);
}
