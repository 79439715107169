import { useState, useEffect } from "react";

export default function useDetectOutsideClick(el, initialState) {
	const [isActive, setIsActive] = useState(initialState);

	useEffect(() => {
		const pageClickEvent = e => {
			// If the active element exists and is clicked outside of
			if (el.current && !el.current.contains(e.target)) {
				setIsActive(prevState => !prevState);
			}
		};

		// If the item is active (ie open) then listen for clicks
		if (isActive) {
			window.addEventListener("click", pageClickEvent);
		}

		return () => {
			window.removeEventListener("click", pageClickEvent);
		};
	}, [isActive, el]);

	return [isActive, setIsActive];
}
