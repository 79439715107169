import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";
import { HealthWorkerInterface, StoreInterface, UserInterface } from "../../interfaces/UserInterface";
import { HomeStoreInterface } from "../../interfaces/HomeInterface";
import MyInputText from "../../utils/Form/MyInputText";
import MyInputEmail from "../../utils/Form/MyInputEmail";
import MyInputSelect from "../../utils/Form/MyInputSelect";
import HandleError from "../../layout/HandleError";
import AssignUserRole from "./AssignUserRole";
import AssignHomeToGroupManager from "./AssignHomeToGroupManager";
import * as API from "../../services/API";
import * as p from "../../constants/Permissions";

interface Props {
	userState: UserInterface;
	store: StoreInterface;
	dispatch: React.Dispatch<any>;
	userToEdit: HealthWorkerInterface | null;
	homeStore: HomeStoreInterface;
}

const EditHealthWorker = ({ userState, store, dispatch, userToEdit, homeStore }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const history = useHistory();
	const methods = useForm();
	const { handleSubmit } = methods;
	const isAdminOrSuperAdmin = userState.userConnected.is_in([p.SUPER_ADMIN, p.ADMIN]);
	const isAdmin = userState.userConnected.is(p.ADMIN);
	const isGroupManager = userState.userConnected.group_id ? true : false;
	const isHomeManager = userState.userConnected.home_id ? true : false;
	const healthWorkerType = store.users.healthWorkerTypes;
	const [editUserRole, setEditUserRole] = useState<string | null>(null);

	useEffect(() => {
		if (userToEdit && !isHomeManager) {
			if (userToEdit.home_id) {
				setEditUserRole("HOME_MANAGER");
			} else if (userToEdit.group_id) {
				setEditUserRole("GROUP_MANAGER");
			} else if (userToEdit.tenant_id) {
				setEditUserRole("ADMIN");
			} else if (userToEdit.permissions.includes(p.SUPER_ADMIN)) {
				setEditUserRole("SUPER_ADMIN");
			} else {
				setEditUserRole(null);
			}
		}
	}, [userToEdit, isHomeManager]);

	const handlePermissions = (userToEdit: HealthWorkerInterface, newRole: string): number[] => {
		const userToEditInitialRole = userToEdit.permissions.includes(-10)
			? "SUPER_ADMIN"
			: userToEdit.permissions.includes(-1)
			? "ADMIN"
			: userToEdit.group_id
			? "GROUP_MANAGER"
			: userToEdit.home_id
			? "HOME_MANAGER"
			: null;
		if (userToEditInitialRole) {
			if (userToEditInitialRole === newRole) {
				return userToEdit.permissions;
			} else {
				switch (userToEditInitialRole) {
					case "SUPER_ADMIN":
						if (newRole === "ADMIN") {
							return [-1];
						} else {
							return [];
						}
					case "ADMIN":
						if (newRole === "SUPER_ADMIN") {
							return [-10];
						} else {
							return [];
						}
					case "GROUP_MANAGER":
						if (newRole === "SUPER_ADMIN") {
							return [-10];
						} else if (newRole === "ADMIN") {
							return [-1];
						} else {
							return userToEdit.permissions;
						}
					case "HOME_MANAGER":
						if (newRole === "SUPER_ADMIN") {
							return [-10];
						} else if (newRole === "ADMIN") {
							return [-1];
						} else {
							return userToEdit.permissions;
						}
					default:
						return userToEdit.permissions;
				}
			}
		} else {
			return userToEdit.permissions;
		}
	};

	const onSubmit = (values: any): void => {
		dispatch({
			type: "HIDE_ERROR"
		});
		if (userToEdit) {
			const data = {
				user: {
					email: values.email,
					first_name: values.firstname ? values.firstname : userToEdit.first_name,
					last_name: values.lastname ? values.lastname : userToEdit.last_name,
					phone: values.phone,
					mobile_phone: values.mobile,
					type_id: parseInt(values.type),
					login: values.login,
					group_id:
						isGroupManager && editUserRole === "GROUP_MANAGER"
							? userState.userConnected.group_id
							: isAdminOrSuperAdmin && editUserRole === "GROUP_MANAGER"
							? parseInt(values.group)
							: null,
					home_id: isHomeManager
						? userState.userConnected.home_id
						: editUserRole === "HOME_MANAGER"
						? parseInt(values.home)
						: null,
					tenant_id:
						isAdmin && editUserRole === "ADMIN"
							? userState.userConnected.tenant_id
							: editUserRole === "ADMIN"
							? parseInt(values.tenant)
							: null,
					permissions: editUserRole ? handlePermissions(userToEdit, editUserRole) : userToEdit.permissions
				}
			};
			API.updateHealthWorker(
				userToEdit.id,
				data,
				(res: any) => {
					dispatch({
						type: "EDIT_HEALTHWORKER",
						payload: res
					});
					dispatch({
						type: "DISPLAY_SUCCESS",
						payload: t("healthworker.__updateSuccess__")
					});
					history.push(`/user/healthworker/${userToEdit.id}`);
				},
				() => {
					dispatch({
						type: "DISPLAY_ERROR"
					});
				}
			);
		} else {
			dispatch({
				type: "DISPLAY_ERROR"
			});
		}
	};

	return (
		<FormProvider {...methods}>
			<HandleError error={store.es.error} dispatch={dispatch} message={t("common.__editError__")} />
			{userToEdit ? (
				isAdmin && userToEdit.tenant_id ? (
					<p className="meta">{t("common.__accessError__")}</p>
				) : (
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row">
							{userToEdit.first_name === "badge_general" ? (
								<div className="col-12">
									<h5 className="blue">{t("healthworker.__generalAccount__")}</h5>
								</div>
							) : (
								<>
									<div className="col-md-6">
										<MyInputText
											label={t("common.__firstname__")}
											name="firstname"
											behavior={{ required: true }}
											value={userToEdit.first_name}
										/>
									</div>
									<div className="col-md-6">
										<MyInputText
											label={t("common.__name__")}
											name="lastname"
											behavior={{ required: true }}
											value={userToEdit.last_name}
										/>
									</div>
								</>
							)}
							<div className="col-md-6">
								<MyInputText
									label={t("common.__login__")}
									name="login"
									behavior={{ required: true }}
									value={userToEdit.login}
								/>
							</div>
							<div className="col-md-6">
								<MyInputEmail
									label={t("common.__mail__")}
									name="email"
									behavior={{ required: true, pattern: /^\S+@\S+\.\S+$/ }}
									value={userToEdit.email}
								/>
							</div>
							<div className="col-md-6">
								<MyInputText
									label={t("common.__phone__")}
									name="phone"
									behavior={{ required: false }}
									value={userToEdit.phone}
								/>
							</div>
							<div className="col-md-6">
								<MyInputText
									label={t("common.__smartphone__")}
									name="mobile"
									behavior={{ required: false }}
									value={userToEdit.mobile_phone}
								/>
							</div>
							<div className="col-md-6">
								<MyInputSelect
									label={t("common.__job__")}
									name="type"
									behavior={{ required: true }}
									value={userToEdit.type_id}
									list={healthWorkerType}
								/>
							</div>
							<div className="col-md-6"></div>
							<div className="col-12">
								{isGroupManager && editUserRole ? (
									<AssignHomeToGroupManager
										newUserRole={editUserRole}
										setNewUserRole={setEditUserRole}
										homeId={userToEdit.home_id}
										homesFromGroup={homeStore.home.allHomes.length > 0 ? homeStore.home.allHomes : []}
									/>
								) : null}
								{isAdminOrSuperAdmin ? (
									<AssignUserRole
										homeId={userToEdit.home_id}
										groupId={userToEdit.group_id}
										tenantId={userToEdit.tenant_id}
										isSuperAdmin={userState.userConnected.is(p.SUPER_ADMIN)}
										newUserRole={editUserRole}
										setNewUserRole={setEditUserRole}
										homeStore={homeStore}
										editMode
									/>
								) : null}
							</div>
							<div className="col-12">
								<Link to="/user/hw/healthworkers">
									<button type="button" className="btn btn-outline-secondary btn-sm mr-2">
										{t("common.__cancel__")}
									</button>
								</Link>
								<button type="submit" className="btn btn-primary btn-sm">
									{t("common.__save__")}
								</button>
							</div>
						</div>
					</form>
				)
			) : null}
		</FormProvider>
	);
};
export default EditHealthWorker;
