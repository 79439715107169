import React from "react";
import { useParams } from "react-router-dom";
import { UserInterface } from "../interfaces/UserInterface";
import useStoreData from "../hooks/useStoreData";
import useDevices from "../hooks/useDevices";
import AccessControl from "../layout/AccessControl";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import HomesSecondaryNav from "../layout/HomesSecondaryNav";
import EditRoom from "../components/Home/Rooms/EditRoom";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const MyHome_EditRoom = ({ homeId, userState }: Props): JSX.Element => {
	const { id } = useParams<{ id: string }>();
	const { store, dispatch } = useStoreData({ userState, homeId });
	const { devicesStore } = useDevices({ homeId });

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.SUPER_ADMIN, p.ADMIN, p.CAN_MANAGE_HOME_UNIT]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<HomesSecondaryNav userState={userState} />}
				component={
					<EditRoom
						userState={userState}
						store={store}
						homeId={homeId}
						dispatch={dispatch}
						roomId={parseInt(id)}
						homeDevicesStore={devicesStore}
					/>
				}
			/>
		</AccessControl>
	);
};
export default MyHome_EditRoom;
