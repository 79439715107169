import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { BsCameraVideo, BsCardImage } from "react-icons/bs";
import { onUploadFiles, onUploadVideo } from "../../../utils/Blogging";
import PicturesManager from "./PicturesManager";
import VideosManager from "./VideosManager";
import PdfsManager from "./PdfsManager";
import { Seniorsidentificationmanager } from "@e4ia/e4link_modules.seniorsidentificationmanager";
import { RichEditor } from "@e4ia/e4link_modules.richeditor";
import { Progressbar } from "@e4ia/e4link_modules.progressbar";
import { displayMeta } from "../BlogUtils";

export default function PostManager({
	editMedias,
	setEditMedias,
	seniors,
	initialSeniorsSelected,
	setSeniorsSelected,
	postContent,
	setPostContent,
	title,
	setTitle,
	selectedDate,
	setSelectedDate,
	medias,
	setMedias,
	initialMedias,
	setInitialMedias,
	uploadSize,
	setUploadSize,
	seniorsSelected,
	homeUnits
}) {
	const { t } = useTranslation("common");
	const history = useHistory();

	return (
		<div className="row mt-3">
			{!editMedias && setEditMedias ? (
				<div className="col-12">
					<div className="header-details d-flex justify-content-between">
						<div className="social-info">{displayMeta(initialMedias)}</div>
						<div className="header-details-button">
							<button type="button" className="btn btn-sm btn-primary" onClick={() => setEditMedias(true)}>
								{t("news.__addEditMedias__")}
							</button>
						</div>
					</div>
				</div>
			) : null}
			<div className="col-12 mb-3">
				<label htmlFor="title">{t("news.__title__")}</label>
				<input
					type="text"
					name="title"
					id="title"
					aria-describedby="titleHelp"
					className="form-control  form-control-lg"
					onChange={e => setTitle(e.target.value)}
					defaultValue={title}
				/>
				<small id="titlelHelp" className="form-text text-muted right">
					{t("common.__required__")}
				</small>
			</div>

			{editMedias ? (
				<>
					<div className="col-6">
						<label htmlFor="blog-pictures" type="file" className="fake-link header-details btn-block center">
							<span className="blue">
								<BsCardImage className="mr-3" style={{ width: "24px", height: "auto" }} />
								<b>{t("news.__addPictsOrPdf__")}</b>
							</span>
						</label>
						<input
							type="file"
							id="blog-pictures"
							name="file"
							accept="image/*,application/pdf"
							style={{ display: "none" }}
							onChange={e => onUploadFiles(e, medias, setMedias, uploadSize, setUploadSize)}
							multiple
						/>
					</div>

					<div className="col-6">
						<label htmlFor="video" className="fake-link header-details btn-block center">
							<span className="blue">
								<BsCameraVideo className="mr-3" style={{ width: "24px", height: "auto" }} />
								<b>{t("news.__addVideo__")} (mp4)</b>
							</span>
							<input
								type="file"
								id="video"
								name="video"
								accept="video/mp4"
								style={{ display: "none" }}
								onChange={e => onUploadVideo(e, medias, setMedias, uploadSize, setUploadSize)}
							/>
						</label>
					</div>

					{uploadSize ? (
						<div className="col-12 mt-3 mb-3">
							<label>{t("common.__limitSize__")}:</label>
							<Progressbar
								size={uploadSize}
								maxSize={250000000}
								alertSize={200000000}
								isLoader={false}
								alertMessage={t("common.__limitSizeReached__")}
							/>
						</div>
					) : null}

					<div className="col-12">
						<VideosManager
							medias={medias}
							setMedias={setMedias}
							setUploadSize={setUploadSize}
							initialMedias={initialMedias}
							setInitialMedias={setInitialMedias}
						/>

						<PdfsManager
							medias={medias}
							setMedias={setMedias}
							setUploadSize={setUploadSize}
							initialMedias={initialMedias}
							setInitialMedias={setInitialMedias}
						/>

						<PicturesManager
							medias={medias}
							setMedias={setMedias}
							setUploadSize={setUploadSize}
							initialMedias={initialMedias}
							setInitialMedias={setInitialMedias}
						/>
					</div>
				</>
			) : null}
			<div className="col-12">
				<RichEditor initialContent={postContent} setPostContent={setPostContent} />

				<Seniorsidentificationmanager
					seniors={seniors}
					initialSeniorsSelected={initialSeniorsSelected}
					seniorsSelected={seniorsSelected}
					setSeniorsSelected={setSeniorsSelected}
					homeUnits={homeUnits}
					text={{
						homeUnitSelection: t("common.__homeUnitSelection__"),
						senior: t("common.__senior__"),
						seniors: t("senior.__seniors__"),
						noSeniorSelected: t("news.__noSeniorSelected__"),
						warningSeniorFocused: t("news.__warningSeniorFocused__"),
						selectSenior: t("news.__selectSenior__"),
						homeUnit: t("common.__homeUnit__"),
						filterBy: t("common.__filterBy__"),
						selectedSeniors: t("senior.__selectSenior__")
					}}
				/>
			</div>
			<div className="col-12">
				<label htmlFor="selectedDate">{t("common.__date__")} :</label>
				<input
					type="date"
					name="selectedDate"
					id="selectedDate"
					aria-describedby="titleHelp"
					className="form-control  form-control-lg"
					defaultValue={selectedDate}
					onChange={e => setSelectedDate(e.target.value)}
				/>
				<small id="titlelHelp" className="form-text text-muted">
					{t("common.__required__")}
				</small>
			</div>
			<div className="col-12 mt-4 mb-4">
				<button className="btn btn-outline-primary mr-2" onClick={() => history.goBack()} type="button">
					{t("common.__back__")}
				</button>
				<button className="btn btn-primary" type="submit">
					{t("common.__save__")}
				</button>
			</div>
		</div>
	);
}
