import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import MyInputTextarea from "../../../utils/Form/MyInputTextarea";
import ErrorContainer from "../../../layout/ErrorContainer";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";

export default function VisioRefusedModal({ show, setShow }) {
	const { t } = useTranslation("common");
	const methods = useForm();
	const { handleSubmit } = methods;
	const [httpResponse, setHttpResponse] = useState(null);

	const onSubmit = values => {
		const data = {
			message_id: show.info,
			message: values.message
		};
		http
			.post(`${url.BASEURL}/homes/visio/reject`, data)
			.then(() => setShow({ display: false, info: null, title: " " }))
			.catch(() => setHttpResponse({ error: true }));
	};

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<ErrorContainer
					alert={t("mailbox.__msgNotSent__")}
					success={t("mailbox.__msgSent__")}
					httpResponse={httpResponse}
					setHttpResponse={setHttpResponse}
				/>

				<MyInputTextarea label={t("mailbox.__addComment__")} name="message" behavior={{ required: true }} rows="5" />
				<button type="submit" className="btn btn-primary">
					{t("common.__send__")}
				</button>
			</form>
		</FormProvider>
	);
}
