import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { FaTimes } from "react-icons/fa";
import MyInputText from "../../../utils/Form/MyInputText";
import MyInputSelect from "../../../utils/Form/MyInputSelect";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";

const AddHomeGroup = ({ setAddMode, homeDispatch, tenantId, isSuperAdmin }) => {
	const { t } = useTranslation("common");
	const methods = useForm();
	const { handleSubmit } = methods;
	const [error, setError] = useState(false);
	const [tenants, setTenants] = useState([]);

	useEffect(() => {
		if (isSuperAdmin) {
			http
				.get(`${url.BASEURL}/tenants`)
				.then(res => setTenants(res.data.tenants))
				.catch(() => setTenants([]));
		}
	}, [isSuperAdmin]);

	const onSubmit = data => {
		setError(false);
		const homeGroupData = {
			home_group: {
				name: data.homeGroup,
				tenant_id: isSuperAdmin ? data.tenant : tenantId
			}
		};
		http
			.post(`${url.BASEURL}/home_groups`, homeGroupData)
			.then(res => {
				homeDispatch({
					type: "ADD_GROUP",
					payload: res.data.home_group
				});
				homeDispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("home.__addGroupSuccess__")
				});
			})
			.then(() => setAddMode(false))
			.catch(() => setError(true));
	};

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<h5 className="blue">{t("home.__addGroup__")}</h5>

				{error ? (
					<p className="error-msg mt-2">
						<span className="right" role="button" tabIndex="0" onClick={() => setError(false)}>
							<FaTimes />
						</span>
						{t("common.__errorOccured__")} {t("common.__addError__")}
					</p>
				) : (
					<br />
				)}

				<div className="form-group">
					<MyInputText label={t("common.__name__")} name="homeGroup" behavior={{ required: true }} />
					{isSuperAdmin ? (
						<MyInputSelect label={t("common.__tenant__")} name="tenant" behavior={{ required: true }} list={tenants} />
					) : null}
				</div>
				<button className="btn btn-primary btn-sm" type="submit">
					{t("common.__save__")}
				</button>
			</form>
		</FormProvider>
	);
};

export default AddHomeGroup;
