import React, { useContext, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { UserInterface } from "../interfaces/UserInterface";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import AdminSecondaryNav from "../layout/AdminSecondaryNav";
import AccessControl from "../layout/AccessControl";
import HomesTypes from "../components/Home/HomeTypes/HomeTypes";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import * as p from "../constants/Permissions";

interface Props {
	userState: UserInterface;
}

const MyHome_Types = ({ userState }: Props): JSX.Element => {
	const { homeStore, homeDispatch } = useContext(UserContext);
	const isAdmin = userState.userConnected.is(p.SUPER_ADMIN);

	useEffect(() => {
		if (homeStore.home.homeTypes.length === 0 && isAdmin) {
			http
				.get(`${url.BASEURL}/home_types`)
				.then(res => {
					homeDispatch({
						type: "GET_HOME_TYPES",
						payload: res.data.home_types
					});
				})
				.catch(() => {
					homeDispatch({
						type: "GET_HOME_TYPES",
						payload: []
					});
				});
		}
		// eslint-disable-next-line
	}, [isAdmin]);

	useEffect(() => {
		return function cleanup(): void {
			homeDispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	return (
		<AccessControl userPermissions={userState.userConnected.permissions} permissionNeeded={[p.SUPER_ADMIN]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<AdminSecondaryNav userState={userState} />}
				component={<HomesTypes homeStore={homeStore} homeDispatch={homeDispatch} />}
			/>
		</AccessControl>
	);
};
export default MyHome_Types;
