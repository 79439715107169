import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Table, SelectColumnFilter } from "../../utils/Table/TableWithFilter";
import { Link } from "react-router-dom";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import SelectHomeInput from "../../layout/SelectHomeInput";

export default function DevicesStatus({ devicesStatus, userLocale, homeId }) {
	const { t } = useTranslation("common");
	const data = useMemo(
		() =>
			devicesStatus.map(status => {
				return {
					...status,
					lastComm: handleDate(status.last_comm_datetime, "Pp", userLocale),
					senior: status.senior_id ? `${status.senior_last_name} ${status.senior_first_name}` : t("common.__noSenior__")
				};
			}),
		[devicesStatus, userLocale]
	);

	const columns = useMemo(
		() => [
			{
				Header: t("common.__senior__"),
				accessor: "senior",
				Filter: SelectColumnFilter,
				Cell: row =>
					row.row.original.senior_id ? (
						<Link to={`/senior/profile/${row.row.original.senior_id}`}>
							<span className="category-outline-badge blue">
								{row.row.original.senior_last_name} {row.row.original.senior_first_name}
							</span>
						</Link>
					) : null
			},
			{
				Header: t("common.__room__"),
				accessor: "room_name"
			},
			{
				Header: t("common.__device__"),
				accessor: "device_id",
				Cell: row => (
					<>
						SN : {row.row.original.serial_number}
						<br />
						<span className="meta">ID : {row.row.values.device_id}</span>
					</>
				)
			},
			{
				Header: t("common.__doneAt__"),
				accessor: "lastComm",
				Cell: row => (
					<>
						{row.row.values.lastComm}
						<br />
						<span className="meta">Index : {row.row.original.activities_index}</span>
					</>
				)
			}
		],
		[data]
	);

	return (
		<div className="big-card">
			<h2>{t("device.__devicesStatus__")}</h2>
			{data.length > 0 ? (
				<Table columns={columns} data={data} />
			) : homeId ? (
				<p className="red mt-3">{t("common.__noDataAvailable__")}</p>
			) : (
				<SelectHomeInput />
			)}
		</div>
	);
}
