import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import MyInputTextarea from "../../utils/Form/MyInputTextarea";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import { useTranslation } from "react-i18next";
import HandleError from "../../layout/HandleError";

export default function ResponseForm({ msgId, msgState, msgDispatch, setResponseMode }) {
	const methods = useForm();
	const { handleSubmit } = methods;
	const { t } = useTranslation("common");

	const onSubmit = values => {
		const data = {
			targets: [{ senior_id: 1, family_id: 1 }],
			message_id: msgId,
			message: values.message
		};
		http
			.post(`${url.MESSAGES_URL}/reply_to`, data)
			.then(() => {
				msgDispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("mailbox.__visioIsAccepted__")
				});
				msgDispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("mailbox.__msgSent__")
				});
				setResponseMode(false);
			})
			.catch(() => {
				msgDispatch({
					type: "DISPLAY_ERROR",
					payload: t("mailbox.__msgNotSent__")
				});
			});
	};

	return (
		<FormProvider {...methods}>
			<HandleError error={msgState.error} dispatch={msgDispatch} message={msgState.msg} />
			<form onSubmit={handleSubmit(onSubmit)}>
				<MyInputTextarea label={t("mailbox.__answer__")} name="message" behavior={{ required: true }} rows="5" />
				<button type="submit" className="btn btn-primary btn-sm mt-3">
					{t("common.__send__")}
				</button>
				<div className="space"></div>
			</form>
		</FormProvider>
	);
}
