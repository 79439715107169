import React from "react";
import { RiDropFill, RiDropLine, RiContrastDropFill, RiContrastDrop2Fill } from "react-icons/ri";

const displayGreyDrop = size => {
	return <RiDropLine style={{ color: "grey", height: size, width: "auto" }} />;
};

export const handleModelIcon = (diaper, small) => {
	const size = small ? "0.9em" : "1rem";
	if (diaper.brand === "amd") {
		switch (diaper.level) {
			case "1":
				return (
					<>
						<RiDropFill style={{ color: diaper.color, height: size, width: "auto" }} />
						{displayGreyDrop(size)}
						{displayGreyDrop(size)}
						{displayGreyDrop(size)}
						{displayGreyDrop(size)}
					</>
				);
			case "2":
				return (
					<>
						<RiDropFill style={{ color: diaper.color, height: size, width: "auto" }} />
						<RiDropFill style={{ color: diaper.color, height: size, width: "auto" }} />
						{displayGreyDrop(size)}
						{displayGreyDrop(size)}
						{displayGreyDrop(size)}
					</>
				);
			case "3":
				return (
					<>
						<RiDropFill style={{ color: diaper.color, height: size, width: "auto" }} />
						<RiDropFill style={{ color: diaper.color, height: size, width: "auto" }} />
						<RiDropFill style={{ color: diaper.color, height: size, width: "auto" }} />
						{displayGreyDrop(size)}
						{displayGreyDrop(size)}
					</>
				);
			case "4":
				return (
					<>
						<RiDropFill style={{ color: diaper.color, height: size, width: "auto" }} />
						<RiDropFill style={{ color: diaper.color, height: size, width: "auto" }} />
						<RiDropFill style={{ color: diaper.color, height: size, width: "auto" }} />
						<RiDropFill style={{ color: diaper.color, height: size, width: "auto" }} />
						{displayGreyDrop(size)}
					</>
				);
			case "5":
				return (
					<>
						<RiDropFill style={{ color: diaper.color, height: size, width: "auto" }} />
						<RiDropFill style={{ color: diaper.color, height: size, width: "auto" }} />
						<RiDropFill style={{ color: diaper.color, height: size, width: "auto" }} />
						<RiDropFill style={{ color: diaper.color, height: size, width: "auto" }} />
						<RiDropFill style={{ color: diaper.color, height: size, width: "auto" }} />
					</>
				);
			case "5+":
				return (
					<>
						<RiDropFill style={{ color: diaper.color, height: size, width: "auto" }} />
						<RiDropFill style={{ color: diaper.color, height: size, width: "auto" }} />
						<RiDropFill style={{ color: diaper.color, height: size, width: "auto" }} />
						<RiDropFill style={{ color: diaper.color, height: size, width: "auto" }} />
						<RiDropFill style={{ color: diaper.color, height: size, width: "auto" }} />+
					</>
				);
			case "1/2":
				return (
					<>
						<RiContrastDropFill style={{ color: "grey", height: size, width: "auto" }} />
						{displayGreyDrop(size)}
						{displayGreyDrop(size)}
						{displayGreyDrop(size)}
						{displayGreyDrop(size)}
					</>
				);
			default:
				return;
		}
	} else if (diaper.brand === "tena") {
		switch (diaper.level) {
			case "5":
				return (
					<>
						<RiDropFill style={{ color: "#F6CC81", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#F6CC81", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#F6CC81", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#F6CC81", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#F6CC81", height: size, width: "auto" }} />
						{displayGreyDrop(size)}
						{displayGreyDrop(size)}
						{displayGreyDrop(size)}
					</>
				);
			case "5+":
				return (
					<>
						<RiDropFill style={{ color: "#edd295", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#edd295", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#edd295", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#edd295", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#edd295", height: size, width: "auto" }} />
						<RiContrastDrop2Fill style={{ color: "#edd295", height: size, width: "auto" }} />
						{displayGreyDrop(size)}
						{displayGreyDrop(size)}
					</>
				);
			case "6":
				return (
					<>
						<RiDropFill style={{ color: "#5AA4E2", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#5AA4E2", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#5AA4E2", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#5AA4E2", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#5AA4E2", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#5AA4E2", height: size, width: "auto" }} />
						{displayGreyDrop(size)}
						{displayGreyDrop(size)}
					</>
				);
			case "6+":
				return (
					<>
						<RiDropFill style={{ color: "#ECD657", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#ECD657", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#ECD657", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#ECD657", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#ECD657", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#ECD657", height: size, width: "auto" }} />
						<RiContrastDrop2Fill style={{ color: "#ECD657", height: size, width: "auto" }} />
						{displayGreyDrop(size)}
					</>
				);
			case "7":
				return (
					<>
						<RiDropFill style={{ color: "#88D09C", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#88D09C", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#88D09C", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#88D09C", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#88D09C", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#88D09C", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#88D09C", height: size, width: "auto" }} />
						{displayGreyDrop(size)}
					</>
				);
			case "8":
				return (
					<>
						<RiDropFill style={{ color: "#A17FD5", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#A17FD5", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#A17FD5", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#A17FD5", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#A17FD5", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#A17FD5", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#A17FD5", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#A17FD5", height: size, width: "auto" }} />
					</>
				);
			case "9":
				return (
					<>
						<RiDropFill style={{ color: "#D2CEB6", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#D2CEB6", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#D2CEB6", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#D2CEB6", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#D2CEB6", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#D2CEB6", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#D2CEB6", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#D2CEB6", height: size, width: "auto" }} />
						<RiDropFill style={{ color: "#D2CEB6", height: size, width: "auto" }} />
					</>
				);
			default:
				return;
		}
	} else if (diaper.brand === "gohy") {
		if (diaper.ref === "384") {
			return (
				<>
					<RiDropFill style={{ color: "#62929C", height: size, width: "auto" }} />
					<RiDropFill style={{ color: "#62929C", height: size, width: "auto" }} />
					<RiDropFill style={{ color: "#62929C", height: size, width: "auto" }} />
					<RiDropFill style={{ color: "#62929C", height: size, width: "auto" }} />
					{displayGreyDrop(size)}
				</>
			);
		} else if (diaper.ref === "151") {
			return (
				<>
					<RiDropFill style={{ color: "#BCB9B9", height: size, width: "auto" }} />
					<RiDropFill style={{ color: "#BCB9B9", height: size, width: "auto" }} />
					<RiDropFill style={{ color: "#BCB9B9", height: size, width: "auto" }} />
					<RiDropFill style={{ color: "#BCB9B9", height: size, width: "auto" }} />
					<RiDropFill style={{ color: "#BCB9B9", height: size, width: "auto" }} />
				</>
			);
		} else {
			switch (diaper.model) {
				case "Normal":
					return (
						<>
							<RiDropFill style={{ color: "#078BCC", height: size, width: "auto" }} />
							<RiDropFill style={{ color: "#078BCC", height: size, width: "auto" }} />
							{displayGreyDrop(size)}
							{displayGreyDrop(size)}
							{displayGreyDrop(size)}
						</>
					);
				case "Extra":
					return (
						<>
							<RiDropFill style={{ color: "#F7AA04", height: size, width: "auto" }} />
							<RiDropFill style={{ color: "#F7AA04", height: size, width: "auto" }} />
							<RiDropFill style={{ color: "#F7AA04", height: size, width: "auto" }} />
							{displayGreyDrop(size)}
							{displayGreyDrop(size)}
						</>
					);
				case "Super":
					return (
						<>
							<RiDropFill style={{ color: "#5BA254", height: size, width: "auto" }} />
							<RiDropFill style={{ color: "#5BA254", height: size, width: "auto" }} />
							<RiDropFill style={{ color: "#5BA254", height: size, width: "auto" }} />
							<RiDropFill style={{ color: "#5BA254", height: size, width: "auto" }} />
							{displayGreyDrop(size)}
						</>
					);
				case "Maxi":
					return (
						<>
							<RiDropFill style={{ color: "#9360A5", height: size, width: "auto" }} />
							<RiDropFill style={{ color: "#9360A5", height: size, width: "auto" }} />
							<RiDropFill style={{ color: "#9360A5", height: size, width: "auto" }} />
							<RiDropFill style={{ color: "#9360A5", height: size, width: "auto" }} />
							<RiDropFill style={{ color: "#9360A5", height: size, width: "auto" }} />
						</>
					);
				case "Maxi +":
					return (
						<>
							<RiDropFill style={{ color: "#A5A1A0", height: size, width: "auto" }} />
							<RiDropFill style={{ color: "#A5A1A0", height: size, width: "auto" }} />
							<RiDropFill style={{ color: "#A5A1A0", height: size, width: "auto" }} />
							<RiDropFill style={{ color: "#A5A1A0", height: size, width: "auto" }} />
							<RiDropFill style={{ color: "#A5A1A0", height: size, width: "auto" }} />
							<b>+</b>
						</>
					);
				default:
					return;
			}
		}
	} else {
		return;
	}
};

export const handleIllustration = (brand, line, ref, small) => {
	const imgSize = small ? "3rem" : "4rem";
	if (brand === "amd") {
		switch (line) {
			case "Slip":
				return <img src="/img/diaper/amd-slip.png" alt="Slip" style={{ height: imgSize, width: "auto" }} />;
			case "Form":
				return (
					<img src="/img/diaper/amd-form.png" alt="Protection anatomique" style={{ height: imgSize, width: "auto" }} />
				);
			case "Pant":
				return <img src="/img/diaper/amd-pant.png" alt="Protection Pant" style={{ height: imgSize, width: "auto" }} />;
			case "Lady":
				return <img src="/img/diaper/amd-lady.jpg" alt="Protection Femme" style={{ height: imgSize, width: "auto" }} />;
			default:
				return <div style={{ height: imgSize, width: "auto", display: "inline-block" }}></div>;
		}
	} else if (brand === "gohy") {
		return <img src={`/img/gohy/gohy_${ref}.png`} alt="Gohy protection" style={{ height: imgSize, width: "auto" }} />;
	} else if (brand === "tena") {
		switch (line) {
			case "Slip":
				return (
					<img
						src="/img/tena/tena_slip.png"
						alt="Tena Protection Slip"
						style={{ height: "3rem", width: "auto", borderRadius: "8px" }}
					/>
				);
			case "Comfort":
				return (
					<img
						src="/img/tena/tena_comfort.png"
						alt="Tena Protection Comfort"
						style={{ height: "3rem", width: "auto", borderRadius: "8px" }}
					/>
				);
			case "Fix":
				return (
					<img
						src="/img/tena/tena_fix.png"
						alt="Tena Protection Fix"
						style={{ height: "3rem", width: "auto", borderRadius: "8px" }}
					/>
				);
			case "Pants":
				return (
					<img
						src="/img/tena/tena_pants.png"
						alt="Tena Protection Pant"
						style={{ height: "3rem", width: "auto", borderRadius: "8px" }}
					/>
				);
			case "Flex":
				return (
					<img
						src="/img/tena/tena_flex.png"
						alt="Tena Protection Flex"
						style={{ height: "3rem", width: "auto", borderRadius: "8px" }}
					/>
				);
			default:
				return <div style={{ height: "3rem", width: "auto", display: "inline-block" }}></div>;
		}
	} else {
		return;
	}
};

export const displayDiaper = diaper => {
	return (
		<p>
			<b className="mr-2">{diaper.name}</b>
			<br />
			{handleModelIcon(diaper)}
		</p>
	);
};
