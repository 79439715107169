import React from "react";
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from "recharts";

export default function PieChartContainer({ data, dataKey, height }) {
	const COLORS = ["#00a0e3", "#fa5456", "#ffae42", "#50c878"];

	return (
		<ResponsiveContainer width={"100%"} height={height}>
			<PieChart>
				<Legend verticalAlign="top" />
				<Pie data={data} dataKey={dataKey} innerRadius={45} outerRadius={85} paddingAngle={4} label>
					{data.map((entry, index) => (
						<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
					))}
				</Pie>
			</PieChart>
		</ResponsiveContainer>
	);
}
