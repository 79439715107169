export const SENIOR_LIFE_STORIES_MODERATED = 0;
export const SENIOR_AVATAR_MODERATED = 1;
export const SENIOR_PHOTO_FRAME_ITEMS_MODERATED = 2;
export const HOME_BLOG_POSTS_MODERATED = 3;
export const DISPLAY_HW_IN_FAMILY_TIMELINE = 4;
export const DISPLAY_MOOD_IN_FAMILY = 5;

export const fields = [
	"senior_life_stories_moderated",
	"senior_avatar_moderated",
	"senior_photo_frame_items_moderated",
	"home_blog_posts_moderated",
	"display_hw_in_family_timeline",
	"display_mood_in_family"
];

// export function isSeniorLifeStoriesModerated(configChoices)     { return configChoices[SENIOR_LIFE_STORIES_MODERATED]; }
// export function isSeniorAvatarModerated(configChoices)          { return configChoices[SENIOR_AVATAR_MODERATED]; }
// export function isSeniorPhotoFrameItemsModerated(configChoices) { return configChoices[SENIOR_PHOTO_FRAME_ITEMS_MODERATED]; }
// export function isHomeBlogPostsModerated(configChoices)         { return configChoices[HOME_BLOG_POSTS_MODERATED]; }
