import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaRegEnvelope } from "react-icons/fa";
import { Tooltip } from "./Tooltip";
import { SeniorInterface } from "../../interfaces/UserInterface";

interface Props {
	senior: SeniorInterface;
	unreadCounter?: number;
}

const SeniorMailboxIcon = ({ senior, unreadCounter }: Props): JSX.Element => {
	const { t } = useTranslation("common");

	return (
		<Tooltip text={t("common.__messages__")}>
			<Link
				to={{
					pathname: "/msg/familymessages",
					state: { senior: senior }
				}}>
				<span className="board-alert">
					<FaRegEnvelope className="board-card-icon" />
					{unreadCounter ? unreadCounter === 0 ? null : <span className="board-card-icon-notification"></span> : null}
				</span>
			</Link>
		</Tooltip>
	);
};
export default SeniorMailboxIcon;
