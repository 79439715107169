import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { UserFormInterface, UserInterface } from "../../interfaces/UserInterface";
import PermissionsManager from "./PermissionsManager";
import AssignHomeToGroupManager from "./AssignHomeToGroupManager";
import AssignUserRole from "./AssignUserRole";
import * as p from "../../constants/Permissions";
import { HomeStoreInterface } from "../../interfaces/HomeInterface";

interface Props {
	homeId: number | null;
	groupId: number | null;
	tenantId: number | null;
	hwFormStore: UserFormInterface;
	hwFormDispatch: React.Dispatch<any>;
	userState: UserInterface;
	homeStore: HomeStoreInterface;
}

const AddHealthWorkerStepTwo = ({
	hwFormStore,
	hwFormDispatch,
	homeId,
	groupId,
	userState,
	tenantId,
	homeStore
}: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const history = useHistory();
	const methods = useForm();
	const { handleSubmit } = methods;
	const isHomeManager = userState.userConnected.home_id;
	const isGroupManager = userState.userConnected.group_id;
	const isAdmin = userState.userConnected.tenant_id;
	const isSuperAdmin = userState.userConnected.is(p.SUPER_ADMIN);
	const [permissions, setPermissions] = useState(hwFormStore.role.permissions);
	const [newUserRole, setNewUserRole] = useState("HOME_MANAGER");

	const onSubmit = (values: { home?: string; group?: string; tenant?: string }): void => {
		const data = {
			home_id: isHomeManager
				? userState.userConnected.home_id
				: newUserRole === "HOME_MANAGER"
				? values.home
					? parseInt(values.home)
					: homeId
				: null,
			group_id:
				newUserRole === "GROUP_MANAGER"
					? isGroupManager
						? userState.userConnected.group_id
						: values.group
						? parseInt(values.group)
						: groupId
					: null,
			tenant_id:
				newUserRole === "ADMIN"
					? isAdmin
						? userState.userConnected.tenant_id
						: values.tenant
						? parseInt(values.tenant)
						: tenantId
					: null,
			permissions: newUserRole === "SUPER_ADMIN" ? [-10] : newUserRole === "ADMIN" ? [-1] : permissions
		};
		hwFormDispatch({
			type: "SAVE_ROLE",
			payload: data
		});
		history.push("/user/adduser/badge");
	};

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				{isHomeManager ? null : (
					<div data-testid="add-user-perimeter-block">
						<h5 className="mt-2 mb-3">{t("healthworker.__addAffectation__")} :</h5>
						{isGroupManager ? (
							<AssignHomeToGroupManager
								homeId={homeId}
								newUserRole={newUserRole}
								setNewUserRole={setNewUserRole}
								homesFromGroup={
									homeStore.home.allHomes.length > 0
										? groupId
											? homeStore.home.allHomes.filter(home => home.group_id === groupId)
											: homeStore.home.allHomes
										: []
								}
							/>
						) : null}
						{isAdmin || isSuperAdmin ? (
							<AssignUserRole
								homeId={homeId}
								groupId={groupId}
								tenantId={tenantId}
								isSuperAdmin={isSuperAdmin}
								newUserRole={newUserRole}
								setNewUserRole={setNewUserRole}
								homeStore={homeStore}
							/>
						) : null}
					</div>
				)}

				{newUserRole === "SUPER_ADMIN" || newUserRole === "ADMIN" ? null : (
					<PermissionsManager
						permissions={permissions}
						setPermissions={setPermissions}
						isAdminOrSuperAdmin={isAdmin || isSuperAdmin}
					/>
				)}
				<div className="d-flex justify-content-end mb-3">
					<button type="submit" className="btn btn-primary btn-sm">
						{t("common.__nextStep__")}
					</button>
				</div>
			</form>
		</FormProvider>
	);
};

export default AddHealthWorkerStepTwo;
