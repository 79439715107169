import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FaMinus, FaPlus } from "react-icons/fa";
import { handleIllustration, handleModelIcon } from "../Illustrations";
import HandleError from "../../../layout/HandleError";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";

export default function HomeDiapersListUpdate({
	homeId,
	diaperStore,
	diaperDispatch,
	homeDiapersList,
	diapersNotReferenced,
	diapersReferenced,
	setDiapersReferenced,
	diapersToReference,
	setDiapersToReference
}) {
	const { t } = useTranslation("common");
	const history = useHistory();

	const handleDiapersReferencedList = (diaper, action) => {
		switch (action) {
			case "ADD":
				setDiapersReferenced([...diapersReferenced, diaper]);
				setDiapersToReference(diapersToReference.filter(diaperToRemove => diaperToRemove.id !== diaper.id));
				break;
			case "REMOVE":
				setDiapersReferenced(diapersReferenced.filter(diaperReferenced => diaperReferenced.id !== diaper.id));
				setDiapersToReference([...diapersToReference, diaper]);
				break;
			default:
				return;
		}
	};

	const backToTheInitialChoice = () => {
		setDiapersReferenced(homeDiapersList);
		setDiapersToReference(diapersNotReferenced);
	};

	const referenceThisDiapersList = () => {
		const diapersSelectedIds = diapersReferenced.map(diaper => diaper.id);
		const data = {
			home_diapers_list: diapersSelectedIds
		};
		http
			.put(`${url.HOMESURL}/${homeId}/home_diapers_list`, data)
			.then(res => {
				diaperDispatch({
					action: "UPDATE_HOME_DIAPERS_LIST",
					payload: res.data.home_diapers_list
				});
				history.push("/inco/diaper/homediapers");
			})
			.catch(() => {
				window.scrollTo(0, 0);
				diaperDispatch({
					type: "DISPLAY_ERROR",
					payload: t("diaper.__referencesNotSaved__")
				});
			});
	};

	return (
		<div className="big-card">
			<div className="row">
				<div className="col-6">
					<h2>{t("diaper.__diapers__")}</h2>
				</div>
				<div className="col-6">
					{homeDiapersList.length > 0 ? (
						<div className="right mt-1">
							<button type="button" className="btn btn-sm btn-outline-secondary" onClick={() => history.goBack()}>
								{t("common.__back__")}
							</button>
						</div>
					) : null}
				</div>
			</div>
			<HandleError error={diaperStore.error} dispatch={diaperDispatch} message={diaperStore.msg} />
			<div className="header-details">
				<h3>{t("diaper.__referencedDiapers__")} :</h3>
				<div className="d-flex flex-wrap ">
					{diapersReferenced.map(diaper => (
						<div
							key={diaper.id}
							className="referenced-diapers-list blue"
							onClick={() => handleDiapersReferencedList(diaper, "REMOVE")}>
							<div className="d-flex justify-content-between">
								<div className="d-flex">
									{handleIllustration(diaper.brand, diaper.line, diaper.ref, true)}
									<div className="mt-1 ml-2">
										<p>
											<b>{diaper.name}</b>
											<br />
											{handleModelIcon(diaper, true)}
										</p>
									</div>
								</div>
								<div>
									<FaMinus className="icon mt-3" />
								</div>
							</div>
						</div>
					))}
				</div>
				<div className="mt-4 mb-3">
					<button type="button" className="btn btn-sm btn-outline-secondary mr-2" onClick={() => backToTheInitialChoice()}>
						{t("common.__cancel__")}
					</button>
					<button type="button" className="btn btn-sm btn-primary" onClick={() => referenceThisDiapersList()}>
						{t("common.__saveMySelection__")}
					</button>
				</div>
			</div>
			<h3 className="mt-3">{t("diaper.__otherDiapers__")} :</h3>
			<div className="d-flex flex-wrap">
				{diapersToReference.map(diaper => (
					<div
						key={diaper.id}
						className="referenced-diapers-list grey"
						onClick={() => handleDiapersReferencedList(diaper, "ADD")}>
						<div className="d-flex justify-content-between">
							<div className="d-flex">
								{handleIllustration(diaper.brand, diaper.line, diaper.ref, true)}
								<div className="mt-1 ml-2">
									<p>
										<b>{diaper.name}</b>
										<br />
										{handleModelIcon(diaper, true)}
									</p>
								</div>
							</div>
							<div>
								<FaPlus className="icon mt-3" />
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
