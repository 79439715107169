import React from "react";
import { FaRegBell, FaFolderOpen, FaRegCalendarAlt, FaUsersCog, FaRegHospital, FaClinicMedical } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as p from "../../constants/Permissions";

export default function Shortcuts({ userState }) {
	const { t } = useTranslation("common");
	const canManageRules = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_CHECKER_RULE]);
	const canManageEvents = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_HOME_CALENDAR]);
	const isHomeManager = userState.userConnected.home_id ? true : false;

	return isHomeManager ? (
		<>
			<h5 className="mt-1">{t("homeBoard.__whatToDoToday__")}</h5>
			<div className="row">
				<div className="col center">
					<Link to="/manage/mainboard">
						<button className="manager-big-button" type="button">
							<FaClinicMedical className="manager-icon-button mt-4" />
							<h3>{t("common.__home__")}</h3>
							<p className="meta">{t("homeBoard.__handleSeniorAndDevice__")}</p>
						</button>
					</Link>
				</div>
				{canManageEvents ? (
					<div className="col center">
						<Link to="/planner/events">
							<button className="manager-big-button" type="button">
								<FaRegCalendarAlt className="manager-icon-button mt-4" />
								<h3>{t("calendar.__handleEvents__")}</h3>
								<p className="meta">{t("calendar.__addNewEvent__")}</p>
							</button>
						</Link>
					</div>
				) : (
					<div className="col center">
						<Link to="/planner/calendar">
							<button className="manager-big-button" type="button">
								<FaRegCalendarAlt className="manager-icon-button mt-4" />
								<h3>{t("calendar.__animations__")}</h3>
								<p className="meta">{t("calendar.__seeCalendar__")}</p>
							</button>
						</Link>
					</div>
				)}
				{canManageRules ? (
					<div className="col center">
						<Link to="/manage/nr/rule/add">
							<button className="manager-big-button" type="button">
								<FaRegBell className="manager-icon-button mt-4" />
								<h3>{t("notifications.__notifications__")}</h3>
								<p className="meta">{t("notifications.__addNewRule__")}</p>
							</button>
						</Link>
					</div>
				) : (
					<div className="col center">
						<Link to="/manage/hd/documents">
							<button className="manager-big-button" type="button">
								<FaFolderOpen className="manager-icon-button mt-4" />
								<h3>{t("homeBoard.__documents__")}</h3>
								<p className="meta">{t("documents.__seeDocuments__")}</p>
							</button>
						</Link>
					</div>
				)}
				{/* {canManageHDS ? (
					<div className="col center">
						<Link to="/manage/hds/hwbudget">
							<button className="manager-big-button" type="button">
								<FaChartPie className="manager-icon-button mt-4" />
								<h3>{t("hds.__hdsRepartition__")}</h3>
								<p className="meta">{t("hds.__dataVisualisation__")}</p>
							</button>
						</Link>
					</div>
				) : null } */}
			</div>
		</>
	) : (
		<>
			<p className="title mt-4 mb-2">{t("homeBoard.__whatToDoToday__")}</p>
			<div className="row">
				<div className="col center">
					<Link to="/support/homegroups">
						<button className="manager-big-button" type="button">
							<FaRegHospital className="manager-icon-button mt-4" />
							<h3>Groupes</h3>
							<p className="meta">Gérer / ajouter / éditer un groupe</p>
						</button>
					</Link>
				</div>
				<div className="col center">
					<Link to="/support/organisation/allhomes">
						<button className="manager-big-button" type="button">
							<FaRegHospital className="manager-icon-button mt-4" />
							<h3>Etablissements</h3>
							<p className="meta">Gérer / ajouter / éditer un établissement</p>
						</button>
					</Link>
				</div>
				<div className="col center">
					<Link to="/support/adminuserdebug">
						<button className="manager-big-button" type="button">
							<FaUsersCog className="manager-icon-button mt-4" />
							<h3>Utilisateurs</h3>
							<p className="meta">Gérer les comptes des utilisateurs</p>
						</button>
					</Link>
				</div>
			</div>
		</>
	);
}
