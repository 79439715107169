import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, Link } from "react-router-dom";
import NewPassword from "../components/UserSettings/NewPassword";
import ForgotPassword from "../components/UserSettings/ForgotPassword";
import http from "../services/Interceptor";
import * as url from "../constants/Url";

const Welcome = (): JSX.Element => {
	const { t } = useTranslation("common");
	const { token } = useParams<{ token?: string }>();
	const [displayNewPassword, setDisplayNewPassword] = useState(token ? true : false);

	useEffect(() => {
		if (token) {
			http
				.post(`${url.BASEURL}/lost_password/token/validity`, { token: token })
				.then(res => setDisplayNewPassword(res.data.is_valid))
				.catch(() => setDisplayNewPassword(false));
		}
	}, [token]);

	return (
		<>
			<div className="center">
				<Link to="/">
					<img src="/img/Logo-eforlink.png" alt="e4link" className="welcome-logo" />
				</Link>
			</div>

			<div className="big-card center">
				<h5>{t("login.__welcome__")}</h5>
			</div>
			<div className="space"></div>
			<div className="row big-card pb-5">
				<div className="col-md-6">
					<img src="/img/eForLink-Access.png" alt="connexion" style={{ width: "100%" }} />
				</div>
				<div className="col-md-6">
					{displayNewPassword ? (
						<NewPassword token={token} title={t("login.__createPassword__")} />
					) : (
						<ForgotPassword fromWelcomePage />
					)}
				</div>
			</div>
		</>
	);
};

export default Welcome;
