import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { NotificationsContext } from "../../context/NotificationsContext";
import CustomCalendar from "../../utils/CustomCalendar";

export default function CalendarForPanel({ homeId, seniorsFromHome, locale }) {
	const { t } = useTranslation("common");
	const history = useHistory();
	const { eventsState, eventsDispatch } = useContext(NotificationsContext);

	return (
		<div className="big-card">
			<div className="row">
				<div className="col-6">
					<h2>{t("calendar.__calendar__")}</h2>
				</div>
				<div className="col-6">
					<button
						type="button"
						className="btn btn-sm btn-outline-secondary rounded-0 right"
						onClick={() => history.goBack()}
					>
						{t("common.__back__")}
					</button>
				</div>
			</div>
			<div className="calendar-container">
				<CustomCalendar
					homeId={homeId}
					eventsDispatch={eventsDispatch}
					eventsState={eventsState}
					locale={locale}
					seniorsFromHome={seniorsFromHome}
				/>
			</div>
		</div>
	);
}
