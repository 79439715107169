import React, { useReducer } from "react";
import { useTranslation } from "react-i18next";
import { UserInterface } from "../interfaces/UserInterface";
import { Link } from "react-router-dom";
import { DotationReducer } from "../components/DiaperConnected/DotationStatistics/DotationReducer";
import {
	DotationFilters,
	DotationFiltersInitialState,
	DotationFiltersReducer
} from "../components/DiaperConnected/DotationStatistics/DotationFilters";
import useDiaperStore from "../hooks/useDiaperStore";
import useStoreData from "../hooks/useStoreData";
import AccessControl from "../layout/AccessControl";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import DiaperSecondaryNav from "../layout/DiaperSecondaryNav";
import DotationStatistics from "../components/DiaperConnected/DotationStatistics/DotationStatistics";
import AmdGoals from "../components/DiaperConnected/AmdStatistics/AmdGoals";
import SelectHomeInput from "../layout/SelectHomeInput";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const initialState = {
	loading: false,
	ratio: null,
	average: null,
	delta: null,
	filteredDelta: null,
	filter: "NO_FILTER"
};

const DiaperDotationStatistics = ({ homeId, userState }: Props): JSX.Element | null => {
	const { t } = useTranslation("common");
	const [filtersStore, filtersDispatch] = useReducer(DotationFiltersReducer, DotationFiltersInitialState);
	const [statisticStore, statisticDispatch] = useReducer(DotationReducer, initialState);
	const { store } = useStoreData({ userState, homeId });
	const { diaperStore } = useDiaperStore({ userState, homeId });
	const seniorsWithDotation = diaperStore.seniors.filter((senior: any) => senior.provisions.length > 0);
	const seniors = seniorsWithDotation.map((senior: any) => {
		return {
			label: senior.name,
			value: senior.id
		};
	});
	const userLocale = userState.userConnected.locale;
	const healthWorkers = store.users.healthWorkers;

	return process.env.REACT_APP_FOR_GOHY === "true" ? null : (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.DIAPER_APPLICATION]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<DiaperSecondaryNav homeId={homeId} userState={userState} />}
				component={
					homeId ? (
						<>
							<DotationFilters
								filtersStore={filtersStore}
								filtersDispatch={filtersDispatch}
								store={store}
								userLocale={userLocale}
								seniors={seniors}
							/>
							<br />
							{diaperStore.hasAmdGoals && diaperStore.hasAmdGoals.isAmdCustomer && seniorsWithDotation.length > 0 ? (
								<>
									<AmdGoals homeId={homeId} userLocale={userLocale} />
									<br />
									<DotationStatistics
										homeId={homeId}
										filtersStore={filtersStore}
										userLocale={userLocale}
										diapers={diaperStore.diapers}
										healthWorkers={healthWorkers}
										statisticStore={statisticStore}
										statisticDispatch={statisticDispatch}
									/>
								</>
							) : (
								<div className="big-card">
									<h5>{t("common.__noElement__")}</h5>
									<Link to="/inco/inco/syncsettings">{t("diaper.__provisionMonitoringWarning__")}</Link>
								</div>
							)}
						</>
					) : (
						<SelectHomeInput />
					)
				}
			/>
		</AccessControl>
	);
};
export default DiaperDotationStatistics;
