import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Table, SelectColumnFilter } from "../../utils/Table/TableWithFilter";
import { FaAngleRight, FaRegEdit, FaRegTimesCircle, FaExclamationTriangle } from "react-icons/fa";
import HandleError from "../../layout/HandleError";
import HandleSuccess from "../../layout/HandleSuccess";
import ModalContainer from "../../utils/ModalContainer";
import DisplayName from "../../utils/DisplayName";
import UpdateFamilyUser from "../Seniors/Family/UpdateFamilyUser";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

const FamilyListForAdmin = ({
	homeStore,
	homeDispatch,
	adminStore,
	adminDispatch,
	update,
	setUpdate,
	editFamily,
	setEditFamily,
	homeId,
	isSuperAdmin
}) => {
	const { t } = useTranslation("common");
	const families = adminStore.allFamilies;
	const allHomes = homeStore.home.allHomes;
	const [familyList, setFamilyList] = useState(families);
	const [affiliates, setAffiliates] = useState(families.filter(family => !family.principal));
	const [principalCounter, setPrincipalCounter] = useState(0);

	const filterFamilyList = filter => {
		if (homeId) {
			if (filter === "affiliate") {
				const familiesByHome = families.filter(family => family.homeId.includes(parseInt(homeId)));
				setFamilyList(familiesByHome.filter(family => !family.principal));
			} else {
				const familiesByHome = families.filter(family => family.homeId.includes(parseInt(homeId)));
				setFamilyList(familiesByHome.filter(family => family.principal));
			}
		} else {
			if (filter === "affiliate") {
				setFamilyList(families.filter(family => !family.principal));
			} else {
				setFamilyList(families.filter(family => family.principal));
			}
		}
	};

	useEffect(() => {
		if (homeId) {
			const familiesByHome = families.filter(family => family.homeId.includes(parseInt(homeId)));
			setFamilyList(familiesByHome);
			setAffiliates(familiesByHome.filter(family => !family.principal));
			const principals = familiesByHome.filter(family => family.principal);
			setPrincipalCounter(principals.length);
		} else {
			setFamilyList(families);
			setAffiliates(families.filter(family => !family.principal));
			setPrincipalCounter(families.length);
		}
	}, [families, homeId, update]);

	const unlock = id => {
		if (id) {
			http.put(`${url.BASEURL}/family_users/${parseInt(id)}/unlock`).then(() => {
				setUpdate(prevState => !prevState);
			});
		}
	};

	const removeFamilyAccount = id => {
		homeDispatch({
			type: "RESET_ES"
		});
		http
			.delete(`${url.BASEURL}/family_users/${id}`)
			.then(() => {
				adminDispatch({
					type: "DELETE_FAMILY",
					payload: id
				});
				homeDispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("healthworker.__removeSuccess__")
				});
				setUpdate(prevState => !prevState);
			})
			.catch(() => {
				homeDispatch({
					type: "DISPLAY_ERROR",
					payload: t("common.__delError__")
				});
			});
	};

	const resetRgpd = familyId => {
		homeDispatch({
			type: "RESET_ES"
		});
		http
			.put(`${url.BASEURL}/family_users/${familyId}/reset/rgpd`)
			.then(() => {
				setUpdate(prevState => !prevState);
			})
			.catch(() => {
				homeDispatch({
					type: "DISPLAY_ERROR",
					payload: t("common.__editError__")
				});
			});
	};

	const columns = useMemo(
		() => [
			{
				Header: t("common.__name__"),
				accessor: "name",
				Cell: row => (
					<Link to={`/senior/family/${row.row.values.id}`}>
						<span className={row.row.original.principal ? "category-badge grey" : "category-badge yellow"}>
							{row.row.original.last_name.toUpperCase()} {row.row.original.first_name}
						</span>
					</Link>
				)
			},
			{
				Header: t("common.__senior__"),
				accessor: "seniors",
				Cell: row =>
					row.row.values.seniors.map(senior => (
						<div key={senior.id} className="mb-3">
							<Link to={`/senior/profile/${senior.id}`}>
								<b>
									{senior.last_name.toUpperCase()} {senior.first_name}
								</b>
							</Link>
							<br />
							<span className="meta">{DisplayName(senior.home_id, allHomes)}</span>
						</div>
					))
			},
			{
				Header: t("common.__login__"),
				accessor: "login",
				Cell: row => (
					<>
						<p>{row.row.values.login}</p>
						{row.row.original.lastSignInDate ? (
							<p>
								<span className="meta">{t("healthworker.__lastSignIn__")} :</span>
								<br />
								{row.row.original.lastSignInDate}
							</p>
						) : null}
						<p>
							<span className="meta">{t("healthworker.__signInCount__")} :</span> {row.row.original.sign_in_count}
						</p>
						{row.row.original.has_accepted_rgpd ? (
							<p className="category-outline-badge grey" role="button" onClick={() => resetRgpd(row.row.values.id)}>
								{t("common.__resetRgpd__")}
							</p>
						) : (
							<p className="red">
								<FaExclamationTriangle className="mr-2" />
								{t("healthworker.__rgpdNotAccepted__")}
							</p>
						)}
					</>
				)
			},
			{
				Header: t("healthworker.__locked__"),
				accessor: "locked",
				Filter: SelectColumnFilter,
				Cell: row => (
					<>
						{row.row.original.locked_at ? (
							<>
								<span className="red">
									<b>{row.row.original.locked}</b>
								</span>
								<br />
								{row.row.original.lockedDate}
								<br />
								{t("healthworker.__failedAttemps__")} : {row.row.original.failed_attempts}
								<button
									type="button"
									className="category-outline-badge red fake-link mt-2"
									onClick={() => unlock(row.row.values.id)}>
									Débloquer
								</button>
							</>
						) : null}
					</>
				)
			},
			{
				Header: "",
				accessor: "id",
				disableFilters: true,
				width: 70,
				Cell: row => (
					<>
						<FaRegEdit
							className="table-icon"
							onClick={() => setEditFamily({ show: true, info: row.row.original, title: "Compte Family", success: false })}
						/>
						{isSuperAdmin ? (
							<FaRegTimesCircle className="table-icon" onClick={() => removeFamilyAccount(row.row.values.id)} />
						) : null}
					</>
				)
			}
		],
		[isSuperAdmin, allHomes, familyList]
	);

	return (
		<div className="big-card">
			<h2>{t("family.__families__")}</h2>
			{adminStore.allFamilies.length === 0 ? (
				<p className="meta mt-4">
					<FaAngleRight className="mr-2" />
					{t("common.__noElement__")}
				</p>
			) : (
				<>
					<HandleError error={homeStore.es.error} dispatch={homeDispatch} message={homeStore.es.msg} />
					<HandleSuccess success={homeStore.es.success} dispatch={homeDispatch} message={homeStore.es.msg} />
					<div className="row">
						<div className="col-6">
							<p className="colored-tag grey" role="button" onClick={() => filterFamilyList("principal")}>
								<span className="mr-2">
									{t("family.__principal__")} : {principalCounter}
								</span>
							</p>
						</div>
						<div className="col-6">
							<p className="colored-tag yellow" role="button" onClick={() => filterFamilyList("affiliate")}>
								<span className="mr-2">
									{t("family.__affiliate__")} : {affiliates.length}
								</span>
							</p>
						</div>
					</div>
					<Table columns={columns} data={familyList} />
				</>
			)}
			<ModalContainer show={editFamily} setShow={setEditFamily}>
				<UpdateFamilyUser show={editFamily} setShow={setEditFamily} />
			</ModalContainer>
		</div>
	);
};

export default FamilyListForAdmin;
