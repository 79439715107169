import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaRegBell } from "react-icons/fa";
import NotificationsToast from "./NotificationsToast";

export default function SocketComponent({ homeId, socket }) {
	const [newNotification, setNewNotification] = useState(null);
	const [bell, setBell] = useState({ new: false, read: true });
	const history = useHistory();

	useEffect(() => {
		let mounted = true;

		if (mounted) {
			if (socket && homeId) {
				setBell({ new: false, read: true });

				let channel = socket.channel(`notifications:${homeId}`);
				console.log("[DEBUG WS] channel notifications", channel);

				channel.onError(() => {
					setBell({ new: false, read: true });
					setNewNotification(null);
					console.log("Channel notifications > there was an error!");
				});

				channel.onClose(() => {
					setBell({ new: false, read: true });
					setNewNotification(null);
					console.log("Channel notifications > the channel has gone away gracefully");
				});

				channel.on("notification", msg => {
					setNewNotification(msg);
					setBell({ new: true, read: false });
					const handleNotification = () => {
						setBell({ new: false, read: false });
					};
					setTimeout(handleNotification, 4100);
				});

				channel
					.join()
					.receive("ok", ({ messages }) => console.log("Channel notifications > ok", messages))
					.receive("error", ({ reason }) => console.log("Channel notifications > error", reason))
					.receive("timeout", () => console.log("Channel notifications > Networking issue. Still waiting..."));
			}
		}

		return () => (mounted = false);
	}, [socket, homeId]);

	useEffect(() => {
		if (bell.new) {
			toast(<NotificationsToast newNotification={newNotification} />);
		}
	}, [bell.new, newNotification]);

	const handleNotificationBell = () => {
		setBell({ new: false, read: true });
		history.push(`/notificationsboard`);
	};

	return (
		<div className="nav-header-notification" onClick={() => handleNotificationBell()} role="button" tabIndex="0">
			<FaRegBell className="nav-icon" />

			{bell.new ? (
				<>
					<span className="nav-notification-icon">
						<i></i>
					</span>
					<ToastContainer position="bottom-right" pauseOnHover />
				</>
			) : !bell.read ? (
				<span className="nav-notification-icon"></span>
			) : null}
		</div>
	);
}
