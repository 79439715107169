import React from "react";
import { useTranslation } from "react-i18next";

export default function AccessControl({ userPermissions, permissionNeeded, children }) {
	const { t } = useTranslation("common");

	const checkPermission = (userPermissions, permissionNeeded) => {
		if (permissionNeeded.length > 0) {
			for (const p of permissionNeeded) {
				if (userPermissions.has(p)) {
					return true;
				}
			}
		}
		return false;
	};
	const accessAllowed = checkPermission(userPermissions, permissionNeeded);

	return accessAllowed ? (
		children
	) : (
		<div className="big-card">
			<h2>{t("common.__accessDenied__")}</h2>
			<p className="error-msg mt-2">{t("common.__accessDeniedInfo__")}</p>
			<div className="space"></div>
		</div>
	);
}
