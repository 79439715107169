import React, { useEffect } from "react";
import HomeUnitsFromHome from "./HomeUnitsFromHome";
import HeaderContainer from "../../../layout/HeaderContainer";
import SelectHomeInput from "../../../layout/SelectHomeInput";
import useStoreData from "../../../hooks/useStoreData";
import HandleError from "../../../layout/HandleError";
import * as p from "../../../constants/Permissions";
import { useTranslation } from "react-i18next";

const HomeUnit = ({ homeId, userState }) => {
	const { t } = useTranslation("common");
	const { store, dispatch } = useStoreData({ userState, homeId });
	const canManageHomeUnit = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_HOME_UNIT]);

	useEffect(() => {
		return function cleanup() {
			dispatch({
				type: "RESET_ES"
			});
		};
	}, []);

	return (
		<div className="big-card">
			<HeaderContainer
				title={t("home.__homeUnits__")}
				addButton={t("home.__addHomeUnit__")}
				link={"/manage/hu/addhomeunit/add"}
				permissionNeeded={[p.SUPER_ADMIN, p.ADMIN, p.CAN_MANAGE_HOME_UNIT]}
				userState={userState}
			/>
			<HandleError error={store.es.error} dispatch={dispatch} message={t("home.__addHuError__")} />

			{homeId ? (
				<>
					<HomeUnitsFromHome dispatch={dispatch} store={store} canManageHomeUnit={canManageHomeUnit} />
				</>
			) : (
				<SelectHomeInput />
			)}
		</div>
	);
};

export default HomeUnit;
