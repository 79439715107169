import React from "react";
import DatePicker from "react-datepicker";
import HandleLocale from "../HandleLocale";

const DateTimePicker = ({ startDate, setStartDate, userLocale, label }) => {
	const locale = HandleLocale(userLocale);

	return (
		<div>
			{label ? <label>{label}</label> : null}
			<DatePicker
				selected={startDate}
				onChange={date => setStartDate(date)}
				locale={locale}
				showTimeSelect
				timeFormat="p"
				timeIntervals={15}
				dateFormat="Pp"
				timeCaption="Heure"
				className="form-control form-control-sm"
				// popperPlacement="bottom"
			/>
		</div>
	);
};
export default DateTimePicker;
