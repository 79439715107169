import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Table } from "../../utils/Table/TableWithFilter";
import { FaAngleRight } from "react-icons/fa";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { formatDistanceToNowStrict } from "date-fns";
import HeaderContainer from "../../layout/HeaderContainer";
import HandleSuccess from "../../layout/HandleSuccess";
import TimelineIcon from "../Buttons/TimelineIcon";
import SeniorMailboxIcon from "../Buttons/SeniorMailboxIcon";
import SeniorVisioIcon from "../Buttons/SeniorVisioIcon";
import SeniorMemoIcon from "../Buttons/SeniorMemoIcon";
import HandleLocale from "../../utils/HandleLocale";
import ModalContainer from "../../utils/ModalContainer";
import SelectedFamilyVisioToCome from "../Messages/SelectedFamilyVisioToCome";
import ModalSeniorMemoT from "../MemoT/ModalSeniorMemoT";
import * as p from "../../constants/Permissions";

export default function HandleSeniors({ store, dispatch, homeId, userState }) {
	const { t } = useTranslation("common");
	const [notesShow, setNotesShow] = useState({ show: false, info: null, title: "" });
	const [showNextVisio, setShowNextVisio] = useState({ show: false, info: null, title: "" });
	const seniorsFromHome = store.seniors.seniorsFromHome;
	const canHandleMailbox = userState.userConnected.is_in([
		p.ADMIN,
		p.SUPER_ADMIN,
		p.ALL_FAMILY_USER_MESSAGES,
		p.MY_FAMILY_USER_MESSAGES
	]);
	const canReadTimeline = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_READ_ACTIVITIES]);

	const columns = useMemo(
		() => [
			{
				Header: t("common.__senior__"),
				accessor: "label",
				Cell: row => {
					return (
						<Link to={`/senior/profile/${row.row.original.id}`}>
							<span className="category-outline-badge blue">
								<b>{row.row.values.label}</b>
							</span>
						</Link>
					);
				}
			},
			{
				Header: t("senior.__arrivalDate__"),
				accessor: "arrival_date",
				Cell: ({ cell: { value } }) => handleDate(value, "P")
			},
			{
				Header: t("senior.__age__"),
				accessor: "birth_date",
				Cell: ({ cell: { value } }) => (
					<>
						{formatDistanceToNowStrict(new Date(value), { locale: HandleLocale(userState.userConnected.locale) })}{" "}
						<span className="meta">({handleDate(value, "P", userState.userConnected.locale)})</span>
					</>
				)
			},
			{
				Header: "",
				accessor: "id",
				disableFilters: true,
				Cell: row => {
					return (
						<div className="d-inline-block float-right mr-2">
							{canReadTimeline ? <TimelineIcon type="SENIOR" id={row.row.values.id} /> : null}
							<SeniorMemoIcon senior={row.row.original} setNotesShow={setNotesShow} />
							{canHandleMailbox ? (
								<>
									<SeniorMailboxIcon senior={row.row.original} />
									<SeniorVisioIcon senior={row.row.original} setShowNextVisio={setShowNextVisio} />
								</>
							) : null}
						</div>
					);
				}
			}
		],
		[seniorsFromHome, userState]
	);

	return (
		<>
			<HeaderContainer
				title={t("senior.__seniors__")}
				addButton={t("senior.__addSenior__")}
				link={"/senior/addsenior/add"}
				permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_CREATE_SENIOR]}
				userState={userState}
			/>
			{seniorsFromHome.length > 0 ? (
				<>
					<p className="meta right">
						{seniorsFromHome.length} {t("homeBoard.__seniors__")}
					</p>
					<HandleSuccess success={store.es.success} dispatch={dispatch} message={store.es.msg} />
					<Table columns={columns} data={seniorsFromHome} />
				</>
			) : (
				<div className="mt-3">
					<h5>
						<FaAngleRight className="mr-2" />
						{t("common.__noElement__")}
					</h5>
				</div>
			)}
			<ModalContainer show={showNextVisio} setShow={setShowNextVisio}>
				<SelectedFamilyVisioToCome show={showNextVisio} userState={userState} />
			</ModalContainer>
			<ModalContainer show={notesShow} setShow={setNotesShow}>
				<ModalSeniorMemoT show={notesShow} homeId={homeId} userState={userState} />
			</ModalContainer>
		</>
	);
}
