import React from "react";
import { setLink } from "./set-links";
import {
	BsTypeH1,
	BsTypeH2,
	BsTypeH3,
	BsTypeItalic,
	BsTypeStrikethrough,
	BsTypeBold,
	BsListOl,
	BsListUl,
	BsBlockquoteLeft,
	BsParagraph,
	BsArrowCounterclockwise,
	BsArrowClockwise,
	BsDashLg,
	BsLightningFill,
	BsLink45Deg
} from "react-icons/bs";

export const Toolbar = ({ editor }) => {
	const basicStyles = editor => {
		return (
			<>
				<button
					type="button"
					onClick={() => editor.chain().focus().toggleBold().run()}
					className={editor.isActive("bold") ? "is-active" : ""}
				>
					<BsTypeBold className="editor-icon" />
				</button>
				<button
					type="button"
					onClick={() => editor.chain().focus().toggleItalic().run()}
					className={editor.isActive("italic") ? "is-active" : ""}
				>
					<BsTypeItalic className="editor-icon" />
				</button>
				<button
					type="button"
					onClick={() => editor.chain().focus().toggleHighlight().run()}
					className={editor.isActive("highlight") ? "is-active" : ""}
				>
					<BsLightningFill className="editor-icon" />
				</button>
				<button type="button" className="icon" onClick={() => setLink(editor)}>
					<BsLink45Deg className="editor-icon" />
				</button>
			</>
		);
	};

	return editor ? (
		<>
			{basicStyles(editor)}
			<button
				type="button"
				onClick={() => editor.chain().focus().setParagraph().run()}
				className={editor.isActive("paragraph") ? "is-active" : ""}
			>
				<BsParagraph className="editor-icon" />
			</button>
			<button
				type="button"
				onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
				className={editor.isActive("heading", { level: 1 }) ? "is-active" : ""}
			>
				<BsTypeH1 className="editor-icon" />
			</button>
			<button
				type="button"
				onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
				className={editor.isActive("heading", { level: 2 }) ? "is-active" : ""}
			>
				<BsTypeH2 className="editor-icon" />
			</button>
			<button
				type="button"
				onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
				className={editor.isActive("heading", { level: 3 }) ? "is-active" : ""}
			>
				<BsTypeH3 className="editor-icon" />
			</button>
			<button
				type="button"
				onClick={() => editor.chain().focus().toggleStrike().run()}
				className={editor.isActive("strike") ? "is-active" : ""}
			>
				<BsTypeStrikethrough className="editor-icon" />
			</button>
			<button
				type="button"
				onClick={() => editor.chain().focus().toggleBulletList().run()}
				className={editor.isActive("bulletList") ? "is-active" : ""}
			>
				<BsListUl className="editor-icon" />
			</button>
			<button
				type="button"
				onClick={() => editor.chain().focus().toggleOrderedList().run()}
				className={editor.isActive("orderedList") ? "is-active" : ""}
			>
				<BsListOl className="editor-icon" />
			</button>
			<button
				type="button"
				onClick={() => editor.chain().focus().toggleBlockquote().run()}
				className={editor.isActive("blockquote") ? "is-active" : ""}
			>
				<BsBlockquoteLeft className="editor-icon" />
			</button>
			<button type="button" onClick={() => editor.chain().focus().setHorizontalRule().run()}>
				<BsDashLg className="editor-icon" />
			</button>
			<button type="button" onClick={() => editor.chain().focus().undo().run()}>
				<BsArrowCounterclockwise className="editor-icon" />
			</button>
			<button type="button" onClick={() => editor.chain().focus().redo().run()}>
				<BsArrowClockwise className="editor-icon" />
			</button>
		</>
	) : null;
};
