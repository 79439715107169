import React, { createContext, useReducer } from "react";
import NewsReducer from "./reducers/NewsReducer";

const initialState = {
	loading: false,
	news: [],
	error: false,
	success: false,
	msg: null,
	post: null
};

export const BlogContext = createContext();

export const BlogContextProvider = ({ children }) => {
	const [blogStore, blogDispatch] = useReducer(NewsReducer, initialState);

	return (
		<BlogContext.Provider
			value={{
				blogStore,
				blogDispatch
			}}
		>
			{children}
		</BlogContext.Provider>
	);
};

export const BlogContextConsumer = BlogContext.Consumer;
