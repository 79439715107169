import { useContext, useEffect } from "react";
import { HomeContext } from "../context/HomeContext";
import http from "../services/Interceptor";
import * as url from "../constants/Url";

export default function useDevices({ homeId }) {
	const { devicesStore, devicesDispatch } = useContext(HomeContext);

	useEffect(() => {
		let mounted = true;
		if (mounted && homeId) {
			http
				.get(`${url.HOMESURL}/${homeId}/devices`)
				.then(res => {
					let response = res.data.devices;
					let devices = response.map(function (device) {
						return {
							...device,
							name: device.serial_number
						};
					});
					devicesDispatch({
						type: "GET_DEVICES",
						payload: devices
					});
				})
				.catch(() => {
					devicesDispatch({
						type: "GET_DEVICES",
						payload: []
					});
				});
		}
		return () => (mounted = false);
		// eslint-disable-next-line
	}, [homeId]);

	return { devicesStore };
}
