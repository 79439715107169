import React, { useState, useReducer, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaRegCommentAlt } from "react-icons/fa";
import AddCommentForm from "./AddCommentForm";
import CommentsList from "./CommentsList";
import CommentsReducer from "./CommentsReducer";
import * as p from "../../../constants/Permissions";
import "./Comment.scss";

export default function Comments({ postId, commentsCounter, userState, blogDispatch }) {
	const { t } = useTranslation("common");
	const [addComment, setAddComment] = useState(commentsCounter > 0 ? false : true);
	const [answerComment, setAnswerComment] = useState({ answer: false, id: null });
	const canComment = userState.userConnected.is_in([
		p.HOME_BLOG_WRITER,
		p.CAN_MODERATE_HOME_BLOG,
		p.CAN_POST_MODIFY_HOME_BLOG,
		p.ADMIN,
		p.SUPER_ADMIN
	]);

	const initialState = {
		comments: [],
		error: false,
		success: false
	};

	const [commentStore, commentDispatch] = useReducer(CommentsReducer, initialState);

	useEffect(() => {
		return function cleanup() {
			commentDispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	return (
		<div className="row">
			<div className="col-md-6">
				<p className="meta mt-1">
					<FaRegCommentAlt className="mr-2" style={{ height: "18px", width: "auto" }} /> {commentsCounter}{" "}
					{commentsCounter > 1 ? t("news.__comments__") : t("news.__comment__")}
				</p>
			</div>
			<div className="col-md-6">
				{canComment ? (
					addComment ? null : (
						<button type="button" className="category-badge blue right" onClick={() => setAddComment(true)}>
							{t("news.__addComment__")}
						</button>
					)
				) : null}
			</div>
			<div className="col-12 mt-2">
				{addComment ? (
					<AddCommentForm
						postId={postId}
						commentStore={commentStore}
						commentDispatch={commentDispatch}
						userState={userState}
						addComment={addComment}
						setAddComment={setAddComment}
						blogDispatch={blogDispatch}
						answerComment={answerComment}
						setAnswerComment={setAnswerComment}
						canComment={canComment}
					/>
				) : null}
			</div>
			<div className="col-12">
				<CommentsList
					postId={postId}
					commentStore={commentStore}
					commentDispatch={commentDispatch}
					userState={userState}
					blogDispatch={blogDispatch}
					answerComment={answerComment}
					setAnswerComment={setAnswerComment}
					canComment={canComment}
				/>
			</div>
		</div>
	);
}
