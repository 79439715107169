import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import HandleError from "../../../layout/HandleError";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";

export default function AffectSensorToSenior({ sensorsAvailable, show, setShow, setUpdate }) {
	const { t } = useTranslation("common");
	const [sensorId, setSensorId] = useState(null);
	const [error, setError] = useState({ error: false, msg: null });
	const senior = show.info.senior;

	const onSubmit = e => {
		e.preventDefault();
		setError({ error: false, msg: null });
		if (sensorId) {
			const data = {
				diaper_sensor_id: sensorId,
				senior_id: senior.id
			};
			http
				.post(`${url.BASEURL}/rht/affect/diaper_sensor/to/senior`, data)
				.then(() => {
					setUpdate(prevState => !prevState);
					setShow({ show: false, info: { senior: null }, title: t("diaper.__affectSensor__"), success: true });
				})
				.catch(() => setError({ error: true, msg: null }));
		} else {
			setError({ error: true, msg: t("diaper.__pleaseSelectSensor__") });
		}
	};

	return (
		<form onSubmit={onSubmit} className="mb-2">
			{senior ? (
				<h5 className="blue mt-3">
					{t("common.__senior__")} : {senior.name}
				</h5>
			) : null}
			<HandleError error={error.error} setError={setError} message={error.msg} />
			{sensorsAvailable.length > 0 ? (
				<>
					<select name="senior" className="form-control form-control-sm" onChange={e => setSensorId(e.target.value)}>
						<option value={0}>{t("diaper.__selectSensor__")}</option>
						{sensorsAvailable.map(elt => (
							<option key={elt.id} value={elt.id}>
								{elt.mac_address}
							</option>
						))}
					</select>
					<button type="submit" className="btn btn-sm btn-primary mt-3">
						{t("common.__save__")}
					</button>
				</>
			) : (
				t("diaper.__noSensorAvailable__")
			)}
		</form>
	);
}
