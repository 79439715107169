import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaAngleRight } from "react-icons/fa";
import { FamilyInterface, SeniorInterface } from "../../../interfaces/UserInterface";
import FilteredFamilyList from "./FilteredFamilyList";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import FamilyStatistics from "./FamilyStatistics";

interface Props {
	seniorsFromHome: SeniorInterface[];
	homeId: number;
	isAdmin: boolean;
	userLocale: string;
}

const FamilyList = ({ seniorsFromHome, homeId, isAdmin, userLocale }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const [filter, setFilter] = useState("ALL");
	const [rawFamilyList, setRawFamilyList] = useState([]);

	const families =
		filter === "AFFILIATES"
			? rawFamilyList.filter((family: FamilyInterface) => !family.principal)
			: filter === "PRINCIPALS"
			? rawFamilyList.filter((family: FamilyInterface) => family.principal)
			: rawFamilyList;

	useEffect(() => {
		if (homeId) {
			http
				.get(`${url.HOMESURL}/${homeId}/family_users?seniors=active`)
				.then(res => {
					const allFamilies = res.data.family_users;
					const sortedFamilies = allFamilies.sort((a: any, b: any) => a.last_name.localeCompare(b.last_name));
					const allFamiliesWithSenior = sortedFamilies.map((family: FamilyInterface) => {
						return {
							...family,
							identity: `${family.last_name.toUpperCase()} ${family.first_name}`,
							rgpd: family.has_accepted_rgpd ? t("healthworker.__rgpdAccepted__") : t("healthworker.__rgpdNotAccepted__"),
							seniorNames: family.seniors
								.map((senior: SeniorInterface) => `${senior.first_name} ${senior.last_name}`)
								.toString()
						};
					});
					setRawFamilyList(allFamiliesWithSenior);
					// const affiliateFamilies = allFamiliesWithSenior.filter(family => !family.principal);
					// setAffiliates(affiliateFamilies);
					// const principalFamilies = allFamiliesWithSenior.filter(family => family.principal);
					// const hasAffiliates = seniorId => {
					// 	const affiliates = affiliateFamilies.filter(family => family.seniorId === seniorId);
					// 	if (affiliates.length > 0) {
					// 		return affiliates;
					// 	} else {
					// 		return null;
					// 	}
					// };

					// const familiesWithAffiliateSubRows = principalFamilies.map(function (family) {
					// 	return {
					// 		...family,
					// 		subRows: hasAffiliates(family.seniorId)
					// 	};
					// });
					// setFamiliesToDisplay(familiesWithAffiliateSubRows);
					// setFamilies(familiesWithAffiliateSubRows);
				})
				.catch(() => setRawFamilyList([]));
		}
		// eslint-disable-next-line
	}, [homeId, seniorsFromHome]);

	// const columns = [
	// 	{
	// 		// Build our expander column
	// 		id: "id", // Make sure it has an ID
	// 		width: 30,
	// 		Header: ({
	// 			getToggleAllRowsExpandedProps
	// 			// , isAllRowsExpanded
	// 		}) => (
	// 			<span {...getToggleAllRowsExpandedProps()}>
	// 				{/* {isAllRowsExpanded ? <FaUsers className="regular-icon" /> : <FaUsers className="regular-icon" />} */}
	// 				<FaUsers className="regular-icon" />
	// 			</span>
	// 		),
	// 		Cell: ({ row }) =>
	// 			row.canExpand ? (
	// 				<span {...row.getToggleRowExpandedProps()} role="button">
	// 					{row.isExpanded ? <FaAngleDoubleUp className="regular-icon" /> : <FaAngleDoubleRight className="regular-icon" />}
	// 				</span>
	// 			) : null
	// 	},
	// 	{
	// 		Header: t("homeBoard.__family__"),
	// 		accessor: "identity",
	// 		Cell: row =>
	// 			row.row.original.active ? (
	// 				isAdmin ? (
	// 					row.row.original.principal ? (
	// 						<Link to={`/senior/family/${row.row.original.id}`}>
	// 							<span className="category-badge green">{row.row.values.identity}</span>
	// 						</Link>
	// 					) : (
	// 						<Link to={`/senior/family/${row.row.original.id}`}>
	// 							<span className="category-badge yellow">{row.row.values.identity}</span>
	// 						</Link>
	// 					)
	// 				) : row.row.original.principal ? (
	// 					<span className="category-badge green">{row.row.values.identity}</span>
	// 				) : (
	// 					<span className="category-badge yellow">{row.row.values.identity}</span>
	// 				)
	// 			) : (
	// 				<span className="msg-tag green">{row.row.values.identity}</span>
	// 			)
	// 	},
	// 	{
	// 		Header: t("common.__contactDetails__"),
	// 		accessor: "email",
	// 		Cell: row => (
	// 			<>
	// 				<a href={`mailto:${row.row.values.email}`}>{row.row.values.email}</a>
	// 				{row.row.original.phone ? (
	// 					<>
	// 						<br />
	// 						{t("common.__phone__")} : {row.row.original.phone}
	// 					</>
	// 				) : null}
	// 			</>
	// 		)
	// 	},
	// 	{
	// 		Header: t("common.__senior__"),
	// 		accessor: "seniors",
	// 		Cell: row =>
	// 			row.row.values.seniors.map(senior => (
	// 				<p className="d-block" key={senior.id}>
	// 					{senior.active ? (
	// 						isAdmin ? (
	// 							<Link to={`/senior/profile/${senior.id}`} className="category-outline-badge blue">
	// 								{senior.last_name.toUpperCase()} {senior.first_name}
	// 							</Link>
	// 						) : senior.home_id === homeId ? (
	// 							<Link to={`/senior/profile/${senior.id}`} className="category-outline-badge blue">
	// 								{senior.last_name.toUpperCase()} {senior.first_name}
	// 							</Link>
	// 						) : (
	// 							<span className="colored-tag grey">
	// 								{senior.last_name.toUpperCase()} {senior.first_name}
	// 							</span>
	// 						)
	// 					) : (
	// 						<span className="colored-tag grey">
	// 							{senior.last_name.toUpperCase()} {senior.first_name}
	// 						</span>
	// 					)}
	// 				</p>
	// 			))
	// 	},
	// 	{
	// 		Header: t("healthworker.__signInCount__"),
	// 		accessor: "sign_in_count",
	// 		Filter: SelectColumnFilter,
	// 		Cell: row => (
	// 			<>
	// 				<p>{row.row.values.sign_in_count}</p>
	// 				{row.row.original.inserted_at ? (
	// 					<p className="meta">
	// 						{t("common.__createdAt__")} : {handleDate(row.row.original.inserted_at, "Pp", userLocale)}
	// 					</p>
	// 				) : null}
	// 				{row.row.original.last_sign_in_at ? (
	// 					<p className="meta">
	// 						{t("healthworker.__lastSignIn__")} : {handleDate(row.row.original.last_sign_in_at, "Pp", userLocale)}
	// 					</p>
	// 				) : null}
	// 			</>
	// 		)
	// 	},
	// 	{
	// 		Header: t("family.__rgpd__"),
	// 		accessor: "rgpd",
	// 		Filter: SelectColumnFilter,
	// 		width: 30,
	// 		Cell: row =>
	// 			row.row.original.has_accepted_rgpd ? (
	// 				<div className="center">
	// 					<FaCheck />
	// 				</div>
	// 			) : null
	// 	}
	// ];

	return (
		<div className="big-card">
			{rawFamilyList.length > 0 ? (
				<>
					<FamilyStatistics families={rawFamilyList} seniorLength={seniorsFromHome.length} userLocale={userLocale} />
					<div className="d-flex justify-content-between mt-4 mb-2">
						<div>
							<span className="meta mr-2">{t("common.__filterBy__")} :</span>
							<span
								className={`${filter === "PRINCIPALS" ? "category-badge" : "category-outline-badge"} marine mr-2`}
								role="button"
								onClick={(): void => setFilter("PRINCIPALS")}>
								{t("family.__principals__")}
							</span>
							<span
								className={`${filter === "AFFILIATES" ? "category-badge" : "category-outline-badge"} grey mr-2`}
								role="button"
								onClick={(): void => setFilter("AFFILIATES")}>
								{t("family.__affiliates__")}
							</span>
							<span
								className={`${filter === "ALL" ? "category-badge" : "category-outline-badge"} grey`}
								role="button"
								onClick={(): void => setFilter("ALL")}>
								X
							</span>
						</div>
						<p className="meta mt-1">
							{families.length} {t("common.__families__")}
						</p>
					</div>

					<FilteredFamilyList families={families} homeId={homeId} isAdmin={isAdmin} userLocale={userLocale} />
				</>
			) : (
				<div className="mt-3">
					<h5>
						<FaAngleRight className="mr-2" />
						{t("common.__noElement__")}
					</h5>
				</div>
			)}
		</div>
	);
};
export default FamilyList;
