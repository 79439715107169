import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../context/UserContext";
import { handleRulesTypes } from "../Home/NotificationsRules/RulesDetails";
import { getActivityAction } from "../../utils/Activities/Activities";
import DisplayName from "../../utils/DisplayName";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import useStoreData from "../../hooks/useStoreData";

export default function NotificationsToast({ newNotification }) {
	const { t } = useTranslation("common");
	const { userState } = useContext(UserContext);
	const [senior, setSenior] = useState({});
	const [seniorError, setSeniorError] = useState(false);
	const seniorId = newNotification.senior_id;
	const homeId = newNotification.home_id;

	const { store } = useStoreData({ userState, homeId });
	const rooms = store.home.rooms;
	const homeUnits = store.home.homeUnits;

	useEffect(() => {
		if (seniorId) {
			http
				.get(`${url.SENIORSURL}/${seniorId}`)
				.then(res => {
					setSeniorError(false);
					setSenior(res.data.senior);
				})
				.catch(() => setSeniorError(true));
		}
		// eslint-disable-next-line
	}, [seniorId]);

	return (
		<div className="row">
			<div className="col-md-4">
				{seniorError ? null : senior.avatar_updated ? (
					<img src={`/storages/seniors/${senior.id}/avatar`} alt="avatar" className="senior-avatar img-fluid" />
				) : senior.gender === "M" ? (
					<img src="/img/senior_male.png" alt="profil" className="senior-avatar" />
				) : (
					<img src="/img/senior_female.png" alt="profil" className="senior-avatar" />
				)}
			</div>
			<div className="col-md-8">
				{seniorError ? (
					<p className="meta">{t("common.__noData__")}</p>
				) : (
					<p className="mt-2">
						<b>
							{senior.last_name} {senior.first_name}
						</b>
					</p>
				)}
				<p className="meta">
					{DisplayName(newNotification.home_unit_id, homeUnits)}
					<br />
					{t("common.__room__")} {DisplayName(newNotification.room_id, rooms)}
				</p>
			</div>
			<div className="col-md-12">
				<div className="center colored-tag red">{handleRulesTypes(newNotification.rule.type)}</div>
				<p className="center">{getActivityAction(newNotification.rule.actions[0].action_id)}</p>
			</div>
		</div>
	);
}
