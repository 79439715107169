import React from "react";
import { UserInterface } from "../interfaces/UserInterface";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import AdminSecondaryNav from "../layout/AdminSecondaryNav";
import AccessControl from "../layout/AccessControl";
import Tenants from "../components/Admin/Tenants/Tenants";
import * as p from "../constants/Permissions";

interface Props {
	userState: UserInterface;
	tenantId: number | null;
	setHomeId: (homeId: number) => void;
	setTenantId: (tenantId: number) => void;
}

const AdminTenants = ({ userState, tenantId, setTenantId, setHomeId }: Props): JSX.Element => {
	return (
		<AccessControl userPermissions={userState.userConnected.permissions} permissionNeeded={[p.SUPER_ADMIN]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<AdminSecondaryNav userState={userState} />}
				component={<Tenants userState={userState} tenantId={tenantId} setTenantId={setTenantId} setHomeId={setHomeId} />}
			/>
		</AccessControl>
	);
};
export default AdminTenants;
