import {
	HomeDataInterface,
	HomeInterface,
	HomeTypesInterface,
	GroupInterface,
	TenantInterface
} from "../../interfaces/HomeInterface";

const HomeDataReducer = (state: HomeDataInterface, action: { type: string; payload?: any }): HomeDataInterface => {
	switch (action.type) {
		case "GET_HOME_TYPES":
			return {
				...state,
				homeTypes: action.payload
			};

		case "ADD_HOME_TYPE":
			return {
				...state,
				homeTypes: [...state.homeTypes, action.payload]
			};

		case "EDIT_HOME_TYPE": {
			const updatedHomeType = action.payload;
			const homeTypesUpdated = state.homeTypes.map((homeType: HomeTypesInterface) => {
				if (homeType.id === updatedHomeType.id) {
					return updatedHomeType;
				}
				return homeType;
			});
			return {
				...state,
				homeTypes: homeTypesUpdated
			};
		}

		case "DELETE_HOME_TYPE":
			return {
				...state,
				homeTypes: state.homeTypes.filter((homeType: HomeTypesInterface) => homeType.id !== action.payload)
			};

		case "GET_TENANTS": {
			const tenants = action.payload;
			const data = tenants.map((tenant: TenantInterface) => {
				return {
					...tenant,
					value: tenant.id,
					label: tenant.name
				};
			});
			return {
				...state,
				tenants: data
			};
		}

		case "GET_GROUPS": {
			const groups = action.payload;
			const data = groups.map((group: GroupInterface) => {
				return {
					...group,
					value: group.id,
					label: group.name
				};
			});
			return {
				...state,
				groups: data
			};
		}

		case "ADD_GROUP":
			return {
				...state,
				groups: [...state.groups, action.payload]
			};

		case "EDIT_GROUP": {
			const updatedGroup = {
				...action.payload,
				value: action.payload.id,
				label: action.payload.name
			};
			const groupUpdated = state.groups.map((group: GroupInterface) => {
				if (group.id === updatedGroup.id) {
					return updatedGroup;
				}
				return group;
			});
			return {
				...state,
				groups: groupUpdated
			};
		}

		case "DELETE_GROUP":
			return {
				...state,
				groups: state.groups.filter((group: GroupInterface) => group.id !== action.payload)
			};

		case "GET_MY_GROUP":
			return {
				...state,
				myGroup: action.payload
			};

		case "GET_ALL_HOMES": {
			const homes = action.payload;
			const data = homes.map((home: HomeInterface) => {
				return {
					...home,
					value: home.id,
					label: home.name
				};
			});
			return {
				...state,
				allHomes: data
			};
		}

		case "ADD_NEW_HOME": {
			const newHome = {
				...action.payload,
				value: action.payload.id,
				label: action.payload.name
			};
			return {
				...state,
				allHomes: [...state.allHomes, newHome]
			};
		}
		case "REMOVE_HOME":
			return {
				...state,
				allHomes: state.allHomes.filter((home: HomeInterface) => home.id !== action.payload)
			};

		case "GET_MY_HOME":
			return {
				...state,
				myHome: action.payload
			};

		case "UPDATE_HOME": {
			const updatedHome = {
				...action.payload,
				value: action.payload.id,
				label: action.payload.name
			};
			const homeUpdated = state.allHomes.map((home: HomeInterface) => {
				if (home.id === updatedHome.id) {
					return updatedHome;
				}
				return home;
			});
			return {
				...state,
				allHomes: homeUpdated
			};
		}

		case "LOGOUT":
			return {
				homeTypes: [],
				groups: [],
				myGroup: null,
				allHomes: [],
				myHome: null,
				tenants: []
			};

		default:
			return state;
	}
};

export default HomeDataReducer;
