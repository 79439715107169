import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import HandleError from "../../../layout/HandleError";
import DisplayBadge from "./DisplayBadge";
import * as API from "../../../services/API";

export default function AddBadge({ userState, show, setShow, store, dispatch, homeId, healthWorkerBadges, setUpdate }) {
	const { t } = useTranslation("common");
	const userId = show.info;
	const ids = healthWorkerBadges.map(badge => badge.id);
	const [badgesAvailable, setBadgesAvailable] = useState([]);
	const [badgeSelected, setBadgeSelected] = useState([]);

	useEffect(() => {
		if (homeId) {
			API.getBadgesAvailable(
				homeId,
				res => {
					const badges = res.map(function (badge) {
						return { ...badge, name: `${badge.value}` };
					});
					setBadgesAvailable(badges);
				},
				() => setBadgesAvailable([])
			);
		}
		// eslint-disable-next-line
	}, [homeId]);

	const onSubmit = e => {
		e.preventDefault();
		if (badgeSelected.length > 0) {
			dispatch({
				type: "HIDE_ERROR"
			});
			const data = {
				user: {
					badges: ids.concat(badgeSelected)
				}
			};
			API.updateHealthWorker(
				userId,
				data,
				res => {
					dispatch({
						type: "EDIT_HEALTHWORKER",
						payload: res
					});
					setUpdate(prevState => !prevState);
					setShow({ show: false, info: null, title: " " });
				},
				() => {
					dispatch({
						type: "DISPLAY_ERROR"
					});
				}
			);
		}
	};

	return (
		<form onSubmit={onSubmit}>
			<HandleError error={store.es.error} dispatch={dispatch} message={t("badge.__badgeError__")} />
			{badgesAvailable.length > 0 ? (
				<>
					<p>{t("badge.__badgeSelection__")} :</p>
					{badgesAvailable.map(badge => (
						<div key={badge.id}>
							<DisplayBadge
								badge={badge}
								badgeSelected={badgeSelected}
								setBadgeSelected={setBadgeSelected}
								userState={userState}
							/>
						</div>
					))}
					<button type="submit" className="btn btn-primary btn-sm">
						{t("common.__save__")}
					</button>
				</>
			) : (
				<>
					<p>{t("badge.__noBadgeAvailable__")}</p>
					<Link to="/user/hwbagde/addbadge/add">
						<button type="button" className="btn btn-sm btn-outline-primary">
							{t("badge.__addBadge__")}
						</button>
					</Link>
				</>
			)}
		</form>
	);
}
