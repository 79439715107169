import React, { useEffect, useState } from "react";
import "./Documents.scss";
import SendSeniorDocuments from "./SendSeniorDocument";
import { useTranslation } from "react-i18next";
import * as p from "../../../constants/Permissions";
import AccessControl from "../../../layout/AccessControl";
import { FaRegTimesCircle, FaTimes } from "react-icons/fa";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import HandleSuccess from "../../../layout/HandleSuccess";

export default function Documents({ seniorId, userState }) {
	const { t } = useTranslation("common");
	const [documents, setDocuments] = useState([]);
	const [addMode, setAddMode] = useState(false);
	const [update, setUpdate] = useState(false);
	const [success, setSuccess] = useState(null);

	const canManageSeniorDocuments = userState.userConnected.is_in([
		p.ADMIN,
		p.SUPER_ADMIN,
		p.CAN_MANAGE_SENIOR_DOCUMENTS
	]);
	const [removeError, setRemoveError] = useState(false);

	useEffect(() => {
		if (seniorId && canManageSeniorDocuments) {
			http
				.get(`${url.SENIORSURL}/${seniorId}/documents`)
				.then(res => {
					const documents = res.data.senior_documents.reverse();
					setDocuments(documents);
				})
				.catch(() => setDocuments([]));
		}
		// eslint-disable-next-line
	}, [seniorId, success, update]);

	const deleteDocument = docId => {
		if (docId && canManageSeniorDocuments) {
			setRemoveError(false);
			setAddMode(false);
			http
				.delete(`${url.SENIOR_DOCUMENT}/documents/${docId}`)
				.then(() => setUpdate(true))
				.catch(() => setRemoveError(true));
		}
	};

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_SENIOR_DOCUMENTS]}>
			<div className="row">
				<div className="col-md-6">
					<h2>{t("senior.__documents__")}</h2>
				</div>
				<div className="col-md-6">
					<button className="btn btn-sm btn-outline-primary float-right" onClick={() => setAddMode(true)}>
						{t("senior.__newDocument__")}
					</button>
				</div>
			</div>
			<HandleSuccess success={success} setSuccess={setSuccess} message={t("documents.__docSent__")} />
			{addMode ? (
				<SendSeniorDocuments
					seniorId={seniorId}
					addMode={addMode}
					setAddMode={setAddMode}
					success={success}
					setSuccess={setSuccess}
					canManageSeniorDocuments={canManageSeniorDocuments}
					setRemoveError={setRemoveError}
				/>
			) : (
				<>
					{documents.length > 0 ? (
						<>
							<div className="header-details">{t("documents.__homeDocInfo__")}</div>
							{removeError ? (
								<p className="error-msg mt-2">
									<span className="right" role="button" tabIndex="0" onClick={() => setRemoveError(false)}>
										<FaTimes />
									</span>
									{t("common.__delError__")}
								</p>
							) : null}
							<div className="d-flex flex-wrap">
								{documents.map(document => (
									<div className="document-border center" key={document.id}>
										<div className="document-container">
											<img
												src={`/storages/seniors/documents/${document.id}/thumb`}
												alt={document.title}
												className="document-thumb"
											/>

											<a href={`/storages/seniors/documents/${document.id}`} target="_blanck">
												<p>
													<strong>{document.title}</strong> -{" "}
													<span className="small-meta">{document.content_type.split("/")[1]}</span>
													<br />
													<span className="small-meta">{handleDate(document.datetime, "P", userState.userConnected.locale)}</span>
												</p>
											</a>

											<FaRegTimesCircle className="suppr-btn" role="button" onClick={() => deleteDocument(document.id)} />
										</div>
									</div>
								))}
							</div>
						</>
					) : (
						<p className="meta">{t("common.__noElement__")}</p>
					)}
				</>
			)}
		</AccessControl>
	);
}
