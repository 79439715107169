import React from "react";
import { FaBullhorn, FaBell, FaSignOutAlt } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import http from "../../services/Interceptor";
import * as p from "../../constants/Permissions";
import * as url from "../../constants/Url";

export default function HeaderPanel({ userState, userDispatch, dispatch }) {
	const history = useHistory();
	const isBlogger = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.HOME_BLOG_WRITER]);

	const handleLogout = () => {
		sessionStorage.clear();
		localStorage.clear();
		userDispatch({
			type: "LOGOUT"
		});
		dispatch({
			type: "LOGOUT_SENIORS"
		});
		dispatch({
			type: "LOGOUT_ROOMS_UNITS"
		});
		http.post(`${url.BASEURL}/logout`);
		history.push("/");
	};

	return (
		<div className="header-panel row">
			<div className="col-6">
				<Link to={"/controlpanel/home"}>
					{process.env.REACT_APP_EFORLINK_LOGO === "true" ? (
						<img src="/img/white-logo-e4link.png" alt="logo eForLink" className="white-logo mt-2" />
					) : (
						<img src="/img/amd/amdlink-white.png" alt="logo AMD link" className="amd-white-logo" />
					)}
				</Link>
			</div>
			<div className="col-6">
				<div className="right">
					<Link to="/controlpanel/home">
						<FaBell className="control-panel-icon" role="button" />
					</Link>
					{isBlogger ? (
						<Link to={"/controlpanel/blog"}>
							<FaBullhorn className="control-panel-icon" role="button" />
						</Link>
					) : null}
					<FaSignOutAlt className="control-panel-icon fake-link" role="button" onClick={handleLogout} />
				</div>
			</div>
		</div>
	);
}
