import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { FaUpload } from "react-icons/fa";
import MyInputText from "../../utils/Form/MyInputText";
import http from "../../services/Interceptor";
import * as constant from "../../constants/Url";
import SelectHomeInput from "../../layout/SelectHomeInput";
import HandleError from "../../layout/HandleError";

const DocumentForm = ({ setAddMode, esState, esDispatch, homeId }) => {
	const { t } = useTranslation("common");
	const methods = useForm();
	const { handleSubmit } = methods;
	const [file, setFile] = useState(null);
	const [filename, setFilename] = useState(null);

	useEffect(() => {
		return function cleanup() {
			esDispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	const onSelectFile = e => {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader();
			reader.readAsDataURL(e.target.files[0]);
			setFile(e.target.files[0]);
			setFilename(e.target.files[0].name);
		}
	};

	const onSubmit = data => {
		const formData = new FormData();
		formData.append("document", file);
		formData.append("title", data.title);

		http
			.post(`${constant.UPLOAD_URL}/${homeId}/documents`, formData)
			.then(() => {
				esDispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("documents.__docSent__")
				});
				setAddMode(false);
			})
			.catch(() => {
				esDispatch({
					type: "DISPLAY_ERROR",
					payload: t("documents.__docNotSent__")
				});
			});
	};

	return (
		<>
			{!homeId ? (
				<SelectHomeInput />
			) : (
				<FormProvider {...methods}>
					<HandleError error={esState.error} dispatch={esDispatch} message={esState.msg} noSpace />
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="form-group">
							{file === null ? (
								<div className="center">
									<label htmlFor="sendDocument" id="document" className="btn btn-secondary">
										<FaUpload className="download-icon" /> {t("documents.__import__")}
									</label>
									<input
										type="file"
										id="sendDocument"
										name="document"
										accept="image/jpeg,image/png,application/pdf"
										className="form-control-file"
										onChange={onSelectFile}
										style={{ display: "none" }}
									/>
								</div>
							) : (
								<div className="form-card">
									<p className="center">
										{t("documents.__docSelected__")} :<br />
										<span className="success">{filename}</span>
									</p>
								</div>
							)}
						</div>

						<MyInputText label={t("common.__name__")} name="title" behavior={{ required: true }} />

						<button type="submit" className="btn btn-primary btn-sm">
							{t("common.__send__")}
						</button>
					</form>
				</FormProvider>
			)}
		</>
	);
};

export default DocumentForm;
