import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaRegEdit } from "react-icons/fa";
import AddFamilyUser from "./AddFamilyUserToSenior";
import UpdateFamilyUser from "./UpdateFamilyUser";
import NoData from "../../../layout/NoData";
import ModalContainer from "../../../utils/ModalContainer";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import { FamilyInterface } from "../../../interfaces/UserInterface";

interface Props {
	seniorId: number;
	canEditFamily: boolean;
}

export default function FamilyUser({ seniorId, canEditFamily }: Props): JSX.Element {
	const { t } = useTranslation("common");
	const [error, setError] = useState(false);
	const [familyUser, setFamilyUser] = useState([]);
	const [addFamily, setAddFamily] = useState<{ show: boolean; info: null | number }>({
		show: false,
		info: null
	});
	const [editFamily, setEditFamily] = useState<{
		show: boolean;
		info: null | FamilyInterface;
		title: string;
		success?: boolean;
	}>({
		show: false,
		info: null,
		title: t("common.__family__"),
		success: false
	});

	useEffect(() => {
		if (seniorId) {
			http
				.get(`${url.SENIORSURL}/${seniorId}/family_users/principal/`)
				.then(res => {
					setError(false);
					setFamilyUser(res.data.family_users);
				})
				.catch(() => setError(true));
		}
		// eslint-disable-next-line
	}, [seniorId, editFamily.success, addFamily.show]);

	return (
		<div className="row ">
			<div className="col-6">
				<h2>{familyUser.length > 1 ? t("family.__principals__") : t("family.__principal__")}</h2>
			</div>
			<div className="col-6">
				{canEditFamily ? (
					<button
						type="button"
						className="btn btn-sm btn-outline-primary mt-2 right"
						onClick={(): void => setAddFamily({ show: true, info: seniorId })}>
						{t("senior.__addFamilyUser__")}
					</button>
				) : null}
			</div>

			<div className="col-12 mb-3">
				{error ? (
					<NoData />
				) : familyUser.length === 0 ? (
					<p className="meta mb-5 mt-2">{t("senior.__noFamilyUser__")}</p>
				) : (
					familyUser.map(
						(family: FamilyInterface): JSX.Element => (
							<div key={family.id} className="form-card d-flex justify-content-between">
								<div>
									<p>
										<b>
											{family.last_name.toUpperCase()} {family.first_name}
										</b>
									</p>
									<p>
										{t("common.__login__")}: {family.login} - {t("common.__mail__")} :{" "}
										<a href={`mailto:${family.email}`}>{family.email}</a> - {t("common.__phone__")} : {family.phone}
									</p>
								</div>
								{canEditFamily ? (
									<FaRegEdit
										role="button"
										className="table-icon"
										onClick={(): void => setEditFamily({ show: true, info: family, title: "Compte Family", success: false })}
									/>
								) : null}
							</div>
						)
					)
				)}
			</div>
			<ModalContainer show={addFamily} setShow={setAddFamily}>
				<AddFamilyUser addFamily={addFamily} setAddFamily={setAddFamily} isModal />
			</ModalContainer>
			<ModalContainer show={editFamily} setShow={setEditFamily}>
				<UpdateFamilyUser show={editFamily} setShow={setEditFamily} />
			</ModalContainer>
		</div>
	);
}
