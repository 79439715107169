import User from "../UserClass";

const UserReducer = (state, action) => {
	switch (action.type) {
		case "LOADING":
			return {
				...state,
				loading: true,
				loginError: false
			};

		case "LOGIN":
			return {
				...state,
				loading: false,
				isLogged: true,
				loginError: false,
				userConnected: new User(
					action.payload.first_name,
					action.payload.last_name,
					action.payload.type_id,
					action.payload.permissions,
					action.payload.tenant_id,
					action.payload.group_id,
					action.payload.home_id,
					action.payload.id,
					action.payload.locale,
					action.payload.timezone
				)
			};

		case "UPDATE_MY_ACCOUNT":
			return {
				...state,
				loading: false,
				isLogged: true,
				loginError: false,
				userConnected: new User(
					action.payload.first_name,
					action.payload.last_name,
					action.payload.type_id,
					action.payload.permissions,
					action.payload.tenant_id,
					action.payload.group_id,
					action.payload.home_id,
					action.payload.id,
					action.payload.locale,
					action.payload.timezone
				)
			};

		case "LOGIN_ERROR":
			return {
				...state,
				loading: false,
				loginError: true,
				isLogged: false
			};

		case "LOGOUT":
			return {
				...state,
				loading: false,
				isLogged: false,
				loginError: false
			};

		default:
			return state;
	}
};

export default UserReducer;
