import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { FaTimes } from "react-icons/fa";
import { calendarEntriesTypes } from "../../constants/EventType";
import MyInputText from "../../utils/Form/MyInputText";
import MyInputSelect from "../../utils/Form/MyInputSelect";
import HandleError from "../../layout/HandleError";
import HandleRecurringEvents from "./HandleRecurringEvents";
import NoData from "../../layout/NoData";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

export default function EditEvent({ eventsState, eventsDispatch, eventId, userState }) {
	const { t } = useTranslation("common");
	const methods = useForm();
	const { handleSubmit } = methods;
	const [errorDate, setErrorDate] = useState(false);
	const [recurringData, setRecurringData] = useState({});
	const history = useHistory();
	const events = eventsState.events;
	const event = events.find(e => parseInt(e.id) === parseInt(eventId));

	useEffect(() => {
		if (eventId && event) {
			if (event.recurring_entry_id) {
				http
					.get(`${url.BASEURL}/home_calendar_entries/${event.recurring_entry_id}`)
					.then(res => setRecurringData(res.data.calendar_entry.recurring_data))
					.catch(() => setRecurringData({}));
			}
		}
	}, [eventId, event]);

	const onSubmit = data => {
		const eventData = {
			home_calendar_entry: {
				detail: data.detail,
				entry_type_id: parseInt(data.type),
				info: data.info
			}
		};
		http
			.put(`${url.BASEURL}/home_calendar_entries/${event.id}`, eventData)
			.then(res => {
				eventsDispatch({
					type: "EDIT_EVENT",
					payload: res.data.calendar_entry
				});
				eventsDispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("calendar.__editSuccess__", {
						name: res.data.calendar_entry.info
					})
				});
				history.push("/planner/events");
			})
			.catch(() => {
				eventsDispatch({
					type: "ERROR_EVENTS"
				});
			});
	};

	return (
		<FormProvider {...methods}>
			<div className="d-flex justify-content-between">
				<h2>{t("calendar.__updateEvent__")}</h2>
				<Link to={"/planner/addevent"}>
					<button type="button" className="btn btn-sm btn-primary">
						{t("calendar.__addAnimation__")}
					</button>
				</Link>
			</div>
			<HandleError error={eventsState.error} dispatch={eventsDispatch} message={t("common.__updateError__")} />
			{event ? (
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="row">
						<div className="col-md-12">
							{errorDate ? (
								<p className="error-msg">
									{t("calendar.__dateError__")}
									<span className="right" role="button" tabIndex="0" onClick={() => setErrorDate(false)}>
										<FaTimes />
									</span>
								</p>
							) : (
								<br />
							)}
						</div>
						<div className="col-md-6">
							<MyInputText label={t("calendar.__eventName__")} name="info" behavior={{ required: true }} value={event.info} />
						</div>
						<div className="col-md-6">
							<MyInputSelect
								label={t("calendar.__eventType__")}
								name="type"
								behavior={{ required: true }}
								list={calendarEntriesTypes}
								value={event.entry_type_id}
							/>
						</div>
						<div className="col-md-12">
							<MyInputText
								label={t("calendar.__eventDetails__")}
								name="detail"
								behavior={{ required: false }}
								value={event.detail}
							/>
						</div>

						{event.recurring_entry_id && (
							<HandleRecurringEvents recurringData={recurringData} userLocale={userState.userConnected.locale} />
						)}

						<div className="col-md-12 mt-4">
							<button className="btn btn-outline-secondary mr-2 btn-sm" onClick={() => history.goBack()} type="button">
								{t("common.__cancel__")}
							</button>
							<button className="btn btn-primary btn-sm" type="submit">
								{t("common.__save__")}
							</button>
						</div>
					</div>
				</form>
			) : (
				<NoData />
			)}
		</FormProvider>
	);
}
