import React, { useContext, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { UserInterface } from "../interfaces/UserInterface";
import { UserContext } from "../context/UserContext";
import AccessControl from "../layout/AccessControl";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import AdminSecondaryNav from "../layout/AdminSecondaryNav";
import FamilyListForAdmin from "../components/Admin/FamilyListForAdmin";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import * as p from "../constants/Permissions";

interface Props {
	userState: UserInterface;
	homeId: number | null;
}

const AdminHealthWorkers = ({ userState, homeId }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const { adminStore, adminDispatch, homeStore, homeDispatch } = useContext(UserContext);
	const userLocale = useMemo(() => userState.userConnected.locale, [userState.userConnected]);
	const isAdmin = useMemo(() => userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN]), [userState.userConnected]);
	const isSuperAdmin = useMemo(() => userState.userConnected.is(p.SUPER_ADMIN), [userState.userConnected]);
	const [update, setUpdate] = useState(false);
	const [editFamily, setEditFamily] = useState({
		show: false,
		info: null,
		title: "Compte Family",
		success: false
	});

	useEffect(() => {
		if (isAdmin) {
			http.get(`${url.BASEURL}/family_users?seniors=true`).then(res => {
				const result = res.data.family_users;
				const allFamilies = result.map(function (family: any) {
					return {
						...family,
						type: family.principal ? t("family.__principal__") : t("family.__affiliate__"),
						activeAccount: family.active ? t("common.__active__") : t("common.__inactive__"),
						lastSignInDate: family.last_sign_in_at ? handleDate(family.last_sign_in_at, "Pp", userLocale) : null,
						lockedDate: family.locked_at ? handleDate(family.locked_at, "Pp", userLocale) : null,
						locked: family.locked_at ? t("healthworker.__locked__") : t("healthworker.__unLocked__"),
						homeId: family.seniors.map((senior: any) => senior.home_id)
					};
				});
				adminDispatch({
					type: "GET_ALL_FAMILIES",
					payload: allFamilies
				});
			});
		}
		// eslint-disable-next-line
	}, [isAdmin, update, editFamily.success]);

	return (
		<AccessControl userPermissions={userState.userConnected.permissions} permissionNeeded={[p.ADMIN, p.SUPER_ADMIN]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<AdminSecondaryNav userState={userState} />}
				component={
					<FamilyListForAdmin
						homeStore={homeStore}
						homeDispatch={homeDispatch}
						adminStore={adminStore}
						adminDispatch={adminDispatch}
						update={update}
						setUpdate={setUpdate}
						editFamily={editFamily}
						setEditFamily={setEditFamily}
						homeId={homeId}
						isSuperAdmin={isSuperAdmin}
					/>
				}
			/>
		</AccessControl>
	);
};
export default AdminHealthWorkers;
