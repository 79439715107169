import React, { useMemo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaUserPlus } from "react-icons/fa";
import { Table } from "../../utils/Table/TableWithFilter";
import "./MainBoard.scss";
import { useTranslation } from "react-i18next";
import DeviceIcon from "../Buttons/DeviceIcon";
import TimelineIcon from "../Buttons/TimelineIcon";
import SeniorRemoveIcon from "../Buttons/SeniorRemoveIcon";
import SeniorMailboxIcon from "../Buttons/SeniorMailboxIcon";
import SeniorVisioIcon from "../Buttons/SeniorVisioIcon";
import SeniorMemoIcon from "../Buttons/SeniorMemoIcon";
import ModalContainer from "../../utils/ModalContainer";
import AddSeniorInARoom from "./AddSeniorInARoom";
import HandleDeviceInRoom from "../Devices/HandleDeviceInRoom";
import RemoveSeniorConfirmation from "./RemoveSeniorConfirmation";
import ModalSeniorMemoT from "../MemoT/ModalSeniorMemoT";
import SelectedFamilyVisioToCome from "../Messages/SelectedFamilyVisioToCome";
import * as p from "../../constants/Permissions";

export default function MainBoardTableContainer({
	mainBoard,
	show,
	setShow,
	showAddSeniorModal,
	setShowAddSeniorModal,
	homeId,
	store,
	dispatch,
	homeUnitId,
	userState,
	notesShow,
	setNotesShow,
	showDevice,
	setShowDevice,
	showNextVisio,
	setShowNextVisio
}) {
	const { t } = useTranslation("common");
	const [homeUnitData, setHomeUnitData] = useState([]);
	const [supervisors, setSupervisors] = useState([]);
	const canManageHomeUnit = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_HOME_UNIT]);
	const canHandleMailbox = userState.userConnected.is_in([
		p.ADMIN,
		p.SUPER_ADMIN,
		p.ALL_FAMILY_USER_MESSAGES,
		p.MY_FAMILY_USER_MESSAGES
	]);
	const canReadTimeline = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_READ_ACTIVITIES]);

	const allHomeUnits = mainboard => {
		let allRooms = [];

		mainboard.map(homeUnit => {
			const rooms = homeUnit.sortedRooms;
			return (allRooms = [...allRooms.concat(rooms)]);
		});
		setHomeUnitData(allRooms);
	};

	useEffect(() => {
		allHomeUnits(mainBoard);
	}, [mainBoard]);

	useEffect(() => {
		const displayHomeUnitMainBoard = homeUnitId => {
			const id = parseInt(homeUnitId);
			if (id === 0) {
				allHomeUnits(mainBoard);
			} else {
				for (let i = 0; i < mainBoard.length; i++) {
					const homeUnit = mainBoard[i];
					if (id === homeUnit.id) {
						setHomeUnitData(homeUnit.rooms);
						setSupervisors(homeUnit.supervisors);
					}
				}
			}
		};
		displayHomeUnitMainBoard(homeUnitId);
	}, [homeUnitId]);

	const homeUnit = homeUnitData.map(function (room) {
		return {
			...room,
			seniorName: room.senior_id ? `${room.senior.last_name.toUpperCase()} ${room.senior.first_name}` : null,
			seniorId: room.senior_id ? room.senior_id : null
		};
	});

	const columns = useMemo(
		() => [
			{
				Header: t("senior.__room__"),
				accessor: "name",
				minWidth: 25,
				width: 30,
				maxWidth: 40,
				Cell: row => {
					return (
						<Link to={`/manage/room/details/${row.row.original.id}`}>
							<b>{row.row.values.name}</b>
						</Link>
					);
				}
			},
			{
				Header: t("common.__senior__"),
				accessor: "seniorName",
				Cell: row => {
					return row.row.original.senior_id ? (
						<Link to={`/senior/profile/${row.row.original.senior_id}`}>
							<span className="category-outline-badge blue">{row.row.values.seniorName}</span>
						</Link>
					) : canManageHomeUnit ? (
						<span
							className="add-senior-badge"
							role="button"
							tabIndex="0"
							onClick={() =>
								setShowAddSeniorModal({
									show: true,
									info: { room: row.row.original, homeId: homeId },
									title: `${t("common.__room__")} ${row.row.original.name}`
								})
							}>
							<FaUserPlus className="mr-2" /> {t("senior.__assignSenior__")}
						</span>
					) : null;
				}
			},
			{
				Header: "",
				accessor: "seniorId",
				disableFilters: true,
				disableSortBy: true,
				Cell: row => {
					return row.row.original.senior_id ? (
						<div className="right mr-2">
							<DeviceIcon room={row.row.original} canManageHomeUnit={canManageHomeUnit} setShowDevice={setShowDevice} />
							{canReadTimeline ? <TimelineIcon type="SENIOR" id={row.row.original.senior_id} /> : null}
							<SeniorMemoIcon senior={row.row.original.senior} setNotesShow={setNotesShow} />
							{canHandleMailbox ? (
								<>
									<SeniorMailboxIcon
										senior={row.row.original.senior}
										unreadCounter={row.row.original.senior.unread_message_count}
									/>
									<SeniorVisioIcon
										senior={row.row.original.senior}
										unreadCounter={row.row.original.senior.visio_count}
										setShowNextVisio={setShowNextVisio}
									/>
								</>
							) : null}
							{canManageHomeUnit ? <SeniorRemoveIcon setShow={setShow} room={row.row.original} /> : null}
						</div>
					) : null;
				}
			}
		],
		[]
	);

	return (
		<>
			{homeUnitId ? (
				<p>
					{supervisors.map(supervisor => (
						<Link to={`/user/healthworker/${supervisor.id}`} key={supervisor.id} className="category-outline-badge grey mt-2">
							{supervisor.first_name === "badge_general" ? (
								supervisor.last_name
							) : (
								<>
									{supervisor.last_name} {supervisor.first_name}
								</>
							)}
						</Link>
					))}
				</p>
			) : null}
			<Table columns={columns} data={homeUnit} />
			<ModalContainer show={notesShow} setShow={setNotesShow}>
				<ModalSeniorMemoT show={notesShow} homeId={homeId} userState={userState} />
			</ModalContainer>
			<ModalContainer show={showAddSeniorModal} setShow={setShowAddSeniorModal}>
				<AddSeniorInARoom show={showAddSeniorModal} setShow={setShowAddSeniorModal} store={store} dispatch={dispatch} />
			</ModalContainer>
			<ModalContainer show={showDevice} setShow={setShowDevice}>
				<HandleDeviceInRoom show={showDevice} setShow={setShowDevice} store={store} dispatch={dispatch} homeId={homeId} />
			</ModalContainer>
			<ModalContainer show={show} setShow={setShow}>
				<RemoveSeniorConfirmation show={show} setShow={setShow} dispatch={dispatch} store={store} />
			</ModalContainer>
			<ModalContainer show={showNextVisio} setShow={setShowNextVisio}>
				<SelectedFamilyVisioToCome show={showNextVisio} userState={userState} />
			</ModalContainer>
		</>
	);
}
