import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserInterface } from "../interfaces/UserInterface";
import useStoreData from "../hooks/useStoreData";
import useDevices from "../hooks/useDevices";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import HomesSecondaryNav from "../layout/HomesSecondaryNav";
import RoomDetails from "../components/Home/Rooms/RoomDetails";
import http from "../services/Interceptor";
import * as url from "../constants/Url";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const MyHome_RoomDetails = ({ homeId, userState }: Props): JSX.Element => {
	const { id } = useParams<{ id: string }>();
	const { store, dispatch } = useStoreData({ userState, homeId });
	const { devicesStore } = useDevices({ homeId });
	const [roomDetails, setRoomDetails] = useState({});

	useEffect(() => {
		if (id) {
			http
				.get(`${url.BASEURL}/rooms/${id}`)
				.then(res => setRoomDetails(res.data.room))
				.catch(() => {
					dispatch({
						type: "DISPLAY_ERROR"
					});
				});
		}
		// eslint-disable-next-line
	}, [id]);

	useEffect(() => {
		return function cleanup(): void {
			dispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	return (
		<PageWithSecondaryNavLayout
			secondaryNav={<HomesSecondaryNav userState={userState} />}
			component={
				<RoomDetails
					store={store}
					devicesFromHome={devicesStore.devices.devices}
					dispatch={dispatch}
					roomDetails={roomDetails}
					userState={userState}
				/>
			}
		/>
	);
};
export default MyHome_RoomDetails;
