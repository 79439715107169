const RoomsReducer = (state, action) => {
	switch (action.type) {
		case "GET_ROOMS":
			return {
				...state,
				rooms: action.payload
			};

		case "ADD_ROOM":
			return {
				...state,
				rooms: [...state.rooms, action.payload]
			};

		case "EDIT_ROOM": {
			const updatedRoom = action.payload;
			const roomUpdated = state.rooms.map(room => {
				if (room.id === updatedRoom.id) {
					return updatedRoom;
				}
				return room;
			});
			return {
				...state,
				rooms: roomUpdated
			};
		}

		case "DELETE_ROOM":
			return {
				...state,
				rooms: state.rooms.filter(room => room.id !== action.payload)
			};

		case "GET_HOMEUNITS":
			return {
				...state,
				homeUnits: action.payload
			};

		case "DELETE_HOMEUNIT":
			return {
				...state,
				homeUnits: state.homeUnits.filter(homeUnit => homeUnit.id !== action.payload)
			};

		case "ADD_HOMEUNIT":
			return {
				...state,
				homeUnits: [...state.homeUnits, action.payload]
			};

		case "EDIT_HOMEUNIT": {
			const updatedHomeUnit = action.payload;
			const homeUnitUpdated = state.homeUnits.map(homeUnit => {
				if (homeUnit.id === updatedHomeUnit.id) {
					return updatedHomeUnit;
				}
				return homeUnit;
			});
			return {
				...state,
				homeUnits: homeUnitUpdated
			};
		}
		case "LOGOUT_ROOMS_UNITS":
			return {
				homeUnits: [],
				rooms: []
			};
		default:
			return state;
	}
};

export default RoomsReducer;
