import React from "react";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";

interface Props {
	success: boolean;
	message?: string | null;
	dispatch?: React.Dispatch<any>;
	setSuccess?: (arg: boolean) => void;
	noSpace?: boolean;
}

const HandleSuccess = ({ success, setSuccess, message, dispatch, noSpace }: Props): JSX.Element | null => {
	const { t } = useTranslation("common");

	const closeSuccess = (): void => {
		if (setSuccess) {
			setSuccess(false);
		} else if (dispatch) {
			dispatch({ type: "RESET_ES" });
		} else {
			return;
		}
	};

	return success ? (
		<p className="success-msg mt-2">
			<span className="right" role="button" tabIndex={0} onClick={closeSuccess}>
				<FaTimes />
			</span>
			{message ? message : t("common.__actionSuccess__")}
		</p>
	) : noSpace ? null : (
		<br />
	);
};
export default HandleSuccess;
