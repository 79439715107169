import React from "react";
import { Redirect } from "react-router-dom";
import { UserInterface } from "../interfaces/UserInterface";
import Footer from "./Footer";
import HeaderPanel from "../components/ControlPanel/HeaderPanel";

interface Props {
	userState: UserInterface;
	userDispatch: React.Dispatch<any>;
	dispatch: React.Dispatch<any>;
	homeId: number | null;
	children: React.ReactNode;
}

const ControlPanelLayout = ({ userState, userDispatch, homeId, dispatch, children }: Props): JSX.Element => {
	const redirectToLogin = (): JSX.Element => {
		return <Redirect to="/" />;
	};

	return userState.isLogged ? (
		<>
			<HeaderPanel userState={userState} userDispatch={userDispatch} dispatch={dispatch} />
			{homeId ? children : null}
			<Footer controlPanel userState={userState} userDispatch={userDispatch} />
		</>
	) : (
		redirectToLogin()
	);
};
export default ControlPanelLayout;
