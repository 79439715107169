import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { fromToUrl, handleDate } from "@e4ia/e4link_modules.datetime";
import { sub } from "date-fns";
import { FaAngleRight } from "react-icons/fa";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import * as type from "../../constants/TypesConstants";
import * as p from "../../constants/Permissions";

export default function SelectedFamilyVisioToCome({ userState, show }) {
	const { t } = useTranslation("common");
	const seniorId = show.info.senior.id;
	const canManageAllFamilyMessage = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.ALL_FAMILY_USER_MESSAGES]);
	const canManageMyFamilyMessage = userState.userConnected.is(p.MY_FAMILY_USER_MESSAGES);
	const today = new Date();
	const last35Days = sub(today, { days: 35 });
	const [visioToCome, setVisioToCome] = useState([]);

	useEffect(() => {
		let mounted = true;
		if (seniorId && mounted) {
			const fromTo = fromToUrl(last35Days, today);
			const getUrl = canManageAllFamilyMessage
				? `${url.SENIORSURL}/${seniorId}/messages/${fromTo}`
				: canManageMyFamilyMessage
				? `${url.MESSAGES_URL}/titles/${fromTo}`
				: null;
			if (getUrl) {
				http.get(getUrl).then(res => {
					const messages = res.data.family_user_messages;
					const seniorMessages = messages.filter(msg => msg.senior.id === parseInt(seniorId));
					const seniorMessagesToDisplay = seniorMessages.map(function (message) {
						return {
							...message,
							category: `${type.displayMessageCategory(message.type)}`
						};
					});
					const seniorVisio = seniorMessagesToDisplay.filter(msg => msg.category === t("calendar.__visio__"));
					const visioAccepted = seniorVisio.filter(
						visio => visio.type === type.FAMILY_ACCEPT_VISIO || visio.type === type.USER_ACCEPT_VISIO
					);
					const now = handleDate(today, "yyyy-MM-dd' '00:00:00");
					const visios = visioAccepted.filter(visio => visio.visio_datetime > now);
					setVisioToCome(visios);
				});
			}
		}
		return function cleanup() {
			mounted = false;
		};
		// eslint-disable-next-line
	}, [seniorId, canManageMyFamilyMessage, canManageAllFamilyMessage]);

	return visioToCome.length === 0 ? (
		<p className="mt-4">
			<FaAngleRight className="mr-2" />
			{t("mailbox.__noVisioPlanned__")}
		</p>
	) : (
		visioToCome.map(visio => (
			<div key={visio.id} className="visio-box">
				<p className="meta">
					<strong>
						{t("mailbox.__from__")} : {visio.family_user.last_name} {visio.family_user.first_name}
					</strong>
				</p>
				<div className="mailbox">
					{type.handleContent(
						visio.type,
						visio.subject,
						visio.message,
						visio.visio_datetime,
						visio.id,
						visio.unread,
						userState
					)}
				</div>
				<div className="light-separator"></div>
			</div>
		))
	);
}
