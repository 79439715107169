import React from "react";
import { useTranslation } from "react-i18next";
import { HomeUnitInterface } from "../../../interfaces/UserInterface";
import useDateBeforeOrAfter from "../../../hooks/useDateBeforeOrAfter";
import OnlyDatePicker from "../../../utils/DatePicker/OnlyDatePicker";
import Select from "react-select";
import PrintIcon from "../../Buttons/PrintIcon";

export interface FiltersProps {
	startDate: Date;
	endDate: Date;
	homeUnitId: number | null;
	floorId: number | null;
	senior: { label: string; value: number }[] | null;
	filterSelected: string;
}

export const DotationFiltersInitialState: FiltersProps = {
	startDate: useDateBeforeOrAfter({ isBefore: true, numberOfDays: 1 }),
	endDate: useDateBeforeOrAfter({ isBefore: true, numberOfDays: 1 }),
	homeUnitId: null,
	floorId: null,
	senior: null,
	filterSelected: "HOME"
};

interface Props {
	store: any;
	userLocale: string;
	seniors: { label: string; value: number }[];
	filtersStore: FiltersProps;
	filtersDispatch: React.Dispatch<any>;
}

export const DotationFiltersReducer = (state: FiltersProps, action: { type: string; payload?: any }): any => {
	switch (action.type) {
		case "START_DATE":
			return {
				...state,
				startDate: action.payload
			};
		case "END_DATE":
			return {
				...state,
				endDate: action.payload
			};
		case "SET_SENIOR":
			return {
				...state,
				senior: action.payload,
				filterSelected: "SENIOR"
			};
		case "SET_HOME_UNIT":
			return {
				...state,
				homeUnitId: action.payload,
				filterSelected: "HOME_UNIT"
			};
		case "SET_FLOOR":
			return {
				...state,
				floorId: action.payload,
				filterSelected: "FLOOR"
			};
		case "RESET":
			return {
				...state,
				homeUnitId: null,
				floorId: null,
				senior: null,
				filterSelected: "HOME"
			};
		default:
			return state;
	}
};

export const DotationFilters = ({ store, userLocale, seniors, filtersStore, filtersDispatch }: Props): JSX.Element => {
	const { t } = useTranslation("common");

	const homeUnits = store.home.homeUnits;
	const allFloors = store.home.rooms.map((room: any) => room.floor);
	const allFloorsValues = allFloors.filter((floor: any) => floor !== null);
	const floorsList = [...new Set(allFloorsValues)].sort((a: any, b: any) => a - b);
	const floors = floorsList.map(floor => {
		return { id: floor, name: floor === 0 ? t("common.__rdc__") : floor };
	});

	const handleSeniorSelectionChange = (e: { label: string; value: number }[]): void => {
		if (e.length > 0) {
			filtersDispatch({ type: "SET_SENIOR", payload: e });
		} else {
			filtersDispatch({ type: "RESET" });
		}
	};

	return (
		<div className="big-card dont-print-this">
			<div className="d-flex justify-content-between">
				<h2>{t("diaper.__provisionMonitoring__")}</h2>
				<div className="mt-2 dont-print-this">
					<PrintIcon />
				</div>
			</div>
			<div className="row mt-4">
				<div className="col-sm-2">
					<p>
						<b>{t("common.__dates__")} :</b>
					</p>
				</div>
				<div className="col-sm-5">
					<OnlyDatePicker
						startDate={filtersStore.startDate}
						dispatch={filtersDispatch}
						type={"START"}
						userLocale={userLocale}
						filter={"BEFORE_TODAY"}
					/>
				</div>
				<div className="col-sm-5">
					<OnlyDatePicker
						startDate={filtersStore.endDate}
						dispatch={filtersDispatch}
						type={"END"}
						userLocale={userLocale}
						filter={"BEFORE_TODAY"}
					/>
				</div>
				{homeUnits.length > 1 ? (
					<>
						<div className="col-sm-2 mt-2">
							<p className="meta mt-1">{t("common.__homeUnit__")} :</p>
						</div>
						<div className="col-sm-10 mt-2">
							<span
								className={filtersStore.filterSelected === "HOME_UNIT" ? "category-outline-badge grey" : "category-badge blue"}
								role="button"
								onClick={(): void => filtersDispatch({ type: "RESET" })}>
								{t("common.__all__")}
							</span>
							{homeUnits.map((homeUnit: HomeUnitInterface) => (
								<span
									key={homeUnit.id}
									role="button"
									className={
										filtersStore.filterSelected === "HOME_UNIT" && filtersStore.homeUnitId === homeUnit.id
											? "category-badge blue"
											: "category-outline-badge grey"
									}
									onClick={(): void => filtersDispatch({ type: "SET_HOME_UNIT", payload: homeUnit.id })}>
									{homeUnit.name}
								</span>
							))}
						</div>
					</>
				) : null}
				{floors.length > 1 ? (
					<>
						<div className="col-sm-2 mt-3">
							<p className="meta mt-1">{t("home.__floor__")} :</p>
						</div>
						<div className="col-sm-10 mt-3">
							<span
								className={filtersStore.filterSelected === "FLOOR" ? "category-outline-badge grey" : "category-badge blue"}
								role="button"
								onClick={(): void => filtersDispatch({ type: "RESET" })}>
								{t("common.__all__")}
							</span>
							{floors.map((floor, index) => (
								<span
									key={index}
									role="button"
									className={
										filtersStore.filterSelected === "FLOOR" && filtersStore.floorId === floor.id
											? "category-badge blue"
											: "category-outline-badge grey"
									}
									onClick={(): void => filtersDispatch({ type: "SET_FLOOR", payload: floor.id })}>
									<>
										{floor.name === t("common.__rdc__") ? null : t("home.__floor__")} {floor.name}
									</>
								</span>
							))}
						</div>
					</>
				) : null}
				<div className="col-sm-2 mt-3">
					<p className="meta mt-3">{t("common.__senior__")} :</p>
				</div>
				<div className="col-sm-10 mt-1">
					<Select
						placeholder={t("senior.__selectSenior__")}
						name="seniors"
						options={seniors}
						className="basic-multi-select mb-2 mt-3"
						classNamePrefix="select"
						onChange={(e: any): void => handleSeniorSelectionChange(e)}
						isClearable
						isMulti
						value={filtersStore.filterSelected === "SENIOR" ? filtersStore.senior : null}
					/>
				</div>
			</div>
		</div>
	);
};
