import React, { useReducer, useEffect } from "react";
import http from "../services/Interceptor";
import { handleDate, fromToUrl } from "@e4ia/e4link_modules.datetime";
import { getActivityAction } from "../utils/Activities/Activities";
import {
	displayCategory,
	check,
	handleRulesTypes,
	getTiming
} from "../components/Home/NotificationsRules/RulesDetails";
import NotificationsListReducer from "./NotificationsListReducer";
import DisplayName from "../utils/DisplayName";
import * as url from "../constants/Url";

export default function useNotifications({ store, homeId, last, startDate, endDate, userLocale }) {
	const initialState = {
		data: [],
		error: false
	};

	const [notificationsStore, notificationsDispatch] = useReducer(NotificationsListReducer, initialState);
	const homeUnits = store.home.homeUnits;
	const rooms = store.home.rooms;
	const seniors = store.seniors.seniorsFromHome;

	useEffect(() => {
		const getNotifications = homeId => {
			const fromTo = startDate && endDate ? fromToUrl(startDate, endDate) : null;
			const getAllNotifications = `${url.HOMESURL}/${homeId}/notifications/${fromTo}`;
			const getLatestNotifications = `${url.HOMESURL}/${homeId}/notifications/last/7`;
			const getUrl = last ? getLatestNotifications : getAllNotifications;

			http
				.get(getUrl)
				.then(res => {
					const notifications = res.data.notifications;
					const fullData = notifications.map(function (notification) {
						return {
							...notification,
							activity: getActivityAction(notification.rule.actions[0].action_id),
							category: displayCategory(notification.rule.actions[0].action_id, notification.rule.type),
							actionId: notification.rule.actions[0].action_id,
							displayCheck: check(notification.checked),
							details: handleNotificationDetails(notification.type, notification.info.when),
							notificationType: handleRulesTypes(notification.rule.type),
							senior: DisplayName(notification.senior_id, seniors),
							room: DisplayName(notification.room_id, rooms),
							homeUnit: DisplayName(notification.home_unit_id, homeUnits)
						};
					});
					notificationsDispatch({
						type: "GET_NOTIFICATIONS",
						payload: fullData
					});
				})
				.catch(() => {
					notificationsDispatch({
						type: "ERROR"
					});
				});
		};
		if (homeId && seniors.length > 0 && rooms.length > 0 && homeUnits.length > 0) {
			getNotifications(homeId);
		}
	}, [homeId, last, startDate, endDate, seniors, rooms, homeUnits]);

	const handleNotificationDetails = (type, when) => {
		switch (type) {
			case 2:
				return when.map((action, index) => (
					<span className="meta" key={index}>
						- {handleDate(action, "Pp", userLocale)}
						<br />
					</span>
				));
			case 5:
				return <span className="meta">Retard de {getTiming(parseInt(when))}</span>;
			default:
				return;
		}
	};

	return [notificationsStore, notificationsDispatch];
}
