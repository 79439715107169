import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Table, SelectColumnFilter } from "../../utils/Table/TableWithFilter";
import { FaRegTimesCircle, FaToggleOn } from "react-icons/fa";
import DisplayName from "../../utils/DisplayName";
import HandleError from "../../layout/HandleError";
import HandleSuccess from "../../layout/HandleSuccess";
import Loader from "../../layout/Loader";
import EditHealthWorkerButton from "../Buttons/EditHealthWorkerIcon";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import * as p from "../../constants/Permissions";

const HealthWorkersListForAdmin = ({ store, dispatch, homeId, groupId, userState, setHomeId }) => {
	const { t } = useTranslation("common");
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const healthWorkers = store.users.healthWorkers;
	const [filteredHw, setFilteredHw] = useState(healthWorkers);
	const [listType, setListType] = useState("all");
	const healthWorkerType = store.users.healthWorkerTypes;

	const displayFilteredUsers = filter => {
		switch (filter) {
			case "superAdmin":
				setFilteredHw(healthWorkers.filter(hw => !hw.home_id && !hw.group_id && !hw.tenant_id));
				setListType("superAdmin");
				break;
			case "admin":
				setFilteredHw(healthWorkers.filter(hw => !hw.home_id && !hw.group_id && hw.tenant_id));
				setListType("admin");
				break;
			case "groupUser":
				setFilteredHw(healthWorkers.filter(hw => !hw.home_id && hw.group_id && !hw.tenant_id));
				setListType("groupUser");
				break;
			case "homeUser":
				setFilteredHw(healthWorkers.filter(hw => hw.home_id && !hw.group_id && !hw.tenant_id));
				setListType("homeUser");
				break;
			case "all":
				setFilteredHw(healthWorkers);
				setListType("all");
				break;
			default:
				setFilteredHw(healthWorkers);
				setListType("all");
		}
	};

	const unlock = id => {
		if (id) {
			http.put(`${url.BASEURL}/users/${parseInt(id)}/unlock`).then(res => {
				dispatch({
					type: "EDIT_HEALTHWORKER",
					payload: res.data.user
				});
			});
		}
	};

	useEffect(() => {
		if (homeId) {
			if (homeId && groupId) {
				const hwByHome = healthWorkers.filter(hw => hw.home_id === parseInt(homeId));
				setFilteredHw(hwByHome);
				setIsLoading(false);
				setListType("");
			} else if (!homeId && groupId) {
				const hwByGroup = healthWorkers.filter(hw => hw.group_id === parseInt(groupId));
				setFilteredHw(hwByGroup);
				setIsLoading(false);
				setListType("");
			} else if (homeId && !groupId) {
				const hwByHome = healthWorkers.filter(hw => hw.home_id === parseInt(homeId));
				setFilteredHw(hwByHome);
				setIsLoading(false);
				setListType("");
			}
		} else {
			setFilteredHw(healthWorkers);
			setListType("all");
		}
	}, [homeId, groupId, healthWorkers]);

	const removeHealthWorker = id => {
		dispatch({
			type: "RESET_ES"
		});
		http
			.delete(`${url.BASEURL}/users/${id}`)
			.then(() => {
				dispatch({
					type: "DELETE_HEALTHWORKER",
					payload: id
				});
				dispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("healthworker.__removeSuccess__")
				});
			})
			.catch(() => {
				dispatch({
					type: "DISPLAY_ERROR"
				});
			});
	};

	const resetRgpd = userId => {
		dispatch({
			type: "RESET_ES"
		});
		http
			.put(`${url.BASEURL}/users/${userId}/reset/rgpd`)
			.then(res => {
				dispatch({
					type: "EDIT_HEALTHWORKER",
					payload: res.data.user
				});
			})
			.catch(() => {
				dispatch({
					type: "DISPLAY_ERROR",
					msg: t("common.__errorOccured__")
				});
			});
	};

	const toggleHealthWorker = id => {
		http
			.put(`${url.BASEURL}/users/${id}/toggle`)
			.then(res => {
				dispatch({
					type: "EDIT_HEALTHWORKER",
					payload: res.data.user
				});
			})
			.catch(() => {
				dispatch({
					type: "DISPLAY_ERROR",
					msg: t("common.__errorOccured__")
				});
			});
	};

	const redirectToUserAndHome = (user, home) => {
		setHomeId(home);
		history.push(`/user/healthworker/${user}`);
	};

	const columns = [
		{
			Header: t("common.__name__"),
			accessor: "name",
			Cell: row => (
				<>
					<div
						className="category-outline-badge blue fake-link"
						onClick={() => redirectToUserAndHome(row.row.values.id, row.row.original.home_id)}>
						{row.row.original.first_name === "badge_general" ? (
							row.row.original.last_name
						) : (
							<>
								{row.row.original.first_name} {row.row.original.last_name},{" "}
								{DisplayName(row.row.original.type_id, healthWorkerType)}
							</>
						)}
					</div>
					<br />
					<span className="meta">{DisplayName(row.row.original.type_id, healthWorkerType)}</span>
					{row.row.original.groupName ? (
						<b>
							<br />
							{row.row.original.groupName}
						</b>
					) : null}
					{row.row.original.homeName ? (
						<b>
							<br />
							{row.row.original.homeName}
						</b>
					) : null}
				</>
			)
		},
		{
			Header: t("common.__login__"),
			accessor: "login",
			Cell: row => (
				<>
					{row.row.values.login}
					{row.row.original.has_accepted_rgpd ? (
						<>
							<br />
							<span className="meta">{t("healthworker.__rgpdAccepted__")}</span>
							<br />
							<span className="category-outline-badge grey mt-2" role="button" onClick={() => resetRgpd(row.row.values.id)}>
								{t("common.__resetRgpd__")}
							</span>
						</>
					) : (
						<>
							<br />
							<span className="meta red">{t("healthworker.__rgpdNotAccepted__")}</span>
						</>
					)}
				</>
			)
		},
		{
			Header: t("healthworker.__locked__"),
			accessor: "locked",
			Filter: SelectColumnFilter,
			Cell: row =>
				row.row.original.locked_at ? (
					<>
						<span className="red">
							<b>{row.row.original.locked}</b>
						</span>
						<br />
						{row.row.original.lockedDate}
						<br />
						{t("healthworker.__failedAttemps__")} : {row.row.original.failed_attempts}
						<button
							type="button"
							className="category-outline-badge red fake-link mt-2"
							onClick={() => unlock(row.row.original.id)}>
							{t("common.__unlock__")}
						</button>
					</>
				) : null
		},
		{
			Header: t("common.__status__"),
			accessor: "deleted",
			Filter: SelectColumnFilter,
			Cell: row => (
				<>
					{row.row.original.active ? (
						<span onClick={() => toggleHealthWorker(row.row.values.id)} className=" fake-link">
							<FaToggleOn className="blue mr-2" />
							{t("common.__active__")}
						</span>
					) : (
						<span onClick={() => toggleHealthWorker(row.row.values.id)} className=" fake-link">
							<FaToggleOn className="red mr-2" />
							{t("common.__inactive__")}
						</span>
					)}
					{row.row.original.deleted_at ? (
						<>
							<br />
							<p className="red">
								<b>{t("healthworker.__deletedAccount__")}</b> : {row.row.original.deletedDate}
							</p>
							<span className="meta">
								{t("common.__by__")} : {DisplayName(row.row.original.deleted_by, healthWorkers)}
							</span>
						</>
					) : (
						row.row.original.lastSignInDate && (
							<>
								<br />
								{t("healthworker.__signInCount__")} : {row.row.original.sign_in_count}
								<br />
								<b>{t("healthworker.__lastSignIn__")}</b>
								<br />
								{row.row.original.lastSignInDate}
							</>
						)
					)}
				</>
			)
		},
		{
			Header: "",
			accessor: "id",
			width: 30,
			disableFilters: true,
			Cell: row => (
				<>
					<EditHealthWorkerButton userState={userState} userToEdit={row.row.original} />
					<FaRegTimesCircle className="table-icon" role="button" onClick={() => removeHealthWorker(row.row.values.id)} />
				</>
			)
		}
	];

	return (
		<div className="big-card">
			<div className="row">
				<div className="col-6">
					<h2>{t("users.__users__")}</h2>
				</div>
				<div className="col-6">
					<Link to="/user/adduser/newuser">
						<button type="button" className="btn btn-sm btn-outline-primary right">
							{t("healthworker.__addAccount__")}
						</button>
					</Link>
				</div>
			</div>
			{userState.userConnected.is(p.SUPER_ADMIN) ? (
				<span
					className={listType === "superAdmin" ? "category-badge red" : "category-outline-badge red fake-link"}
					onClick={() => displayFilteredUsers("superAdmin")}>
					Super Admin
				</span>
			) : null}
			<span
				className={listType === "admin" ? "category-badge blue" : "category-outline-badge blue fake-link"}
				onClick={() => displayFilteredUsers("admin")}>
				Admin
			</span>
			<span
				className={listType === "groupUser" ? "category-badge green" : "category-outline-badge green fake-link"}
				onClick={() => displayFilteredUsers("groupUser")}>
				{t("users.__groupManagers__")}
			</span>
			<span
				className={listType === "homeUser" ? "category-badge grey" : "category-outline-badge grey fake-link"}
				onClick={() => displayFilteredUsers("homeUser")}>
				{t("users.__users__")}
			</span>
			<span
				className={listType === "all" ? "category-badge purple" : "category-outline-badge purple fake-link"}
				onClick={() => displayFilteredUsers("all")}>
				{t("common.__all__")}
			</span>
			{isLoading ? (
				<Loader />
			) : filteredHw.length > 0 ? (
				<>
					<HandleError error={store.es.error} dispatch={dispatch} message={t("common.__delError__")} />
					<HandleSuccess success={store.es.success} dispatch={dispatch} message={store.es.msg} />
					<br />
					<Table columns={columns} data={filteredHw} />
				</>
			) : (
				<p className="mt-4">{t("common.__noElement__")}</p>
			)}
		</div>
	);
};

export default HealthWorkersListForAdmin;
