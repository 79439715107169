import React from "react";
import { useTranslation } from "react-i18next";
import { FaAngleDoubleRight } from "react-icons/fa";

const NoData = (): JSX.Element => {
	const { t } = useTranslation("common");

	return (
		<div className="alert alert-danger" role="alert">
			<FaAngleDoubleRight className="mr-2" />
			{t("common.__noData__")}
		</div>
	);
};
export default NoData;
