import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { HealthWorkersTabs } from "../utils/Tabs/TabsToDisplay";
import { UserInterface } from "../interfaces/UserInterface";
import useStoreData from "../hooks/useStoreData";
import HealthWorkerProfile from "../components/HealthWorkers/HealthWorkerProfile";
import Tabs from "../utils/Tabs/Tabs";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const HealthWorkers_Details = ({ homeId, userState }: Props): JSX.Element => {
	const { id } = useParams<{ id: string }>();
	const { store, dispatch } = useStoreData({ userState, homeId });

	useEffect(() => {
		return function cleanup(): void {
			dispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<Tabs tabsToDisplay={HealthWorkersTabs} currentTab={"HEALTHWORKERS"} />
			<div className="big-card">
				<HealthWorkerProfile
					id={parseInt(id)}
					homeId={homeId}
					healthWorkerTypes={store.users.healthWorkerTypes}
					store={store}
					dispatch={dispatch}
					userState={userState}
				/>
			</div>
		</>
	);
};
export default HealthWorkers_Details;
