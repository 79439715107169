import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import useDevices from "../../hooks/useDevices";
import MyInputSelect from "../../utils/Form/MyInputSelect";
import HandleError from "../../layout/HandleError";
import DisplayName from "../../utils/DisplayName";
import http from "../../services/Interceptor";
import * as API from "../../services/API";
import * as url from "../../constants/Url";

const HandleDeviceInRoom = ({ store, dispatch, setShow, show, homeId }) => {
	const { t } = useTranslation("common");
	const methods = useForm();
	const { handleSubmit } = methods;
	const { devicesStore } = useDevices({ homeId });
	const devicesFromHome = devicesStore.devices.devices;
	const [availableDevices, setAvailableDevices] = useState([]);
	const roomId = show.info.room.id;
	const deviceId = show.info.room.device_id;

	useEffect(() => {
		let mounted = true;
		if (homeId && mounted && devicesFromHome.length > 0) {
			API.getAvailableDevices(
				homeId,
				res => {
					const availableDevicesList = res.map(function (device) {
						return {
							...device,
							name: device.serial_number
						};
					});
					if (deviceId) {
						setAvailableDevices([
							{ id: deviceId, name: DisplayName(deviceId, devicesFromHome) },
							...availableDevicesList,
							{ id: 0, name: t("device.__roomWithoutDevice__") }
						]);
					} else {
						if (availableDevicesList.length > 0) {
							setAvailableDevices([{ id: 0, name: t("home.__noDeviceInRoom__") }, ...availableDevicesList]);
						} else {
							setAvailableDevices(availableDevicesList);
						}
					}
				},
				() => setAvailableDevices([{ id: 0, name: t("home.__noDeviceInRoom__") }])
			);
		}
		return () => (mounted = false);
		// eslint-disable-next-line
	}, [homeId, devicesFromHome]);

	const onSubmit = data => {
		const roomData = {
			room: {
				device_id: parseInt(data.device) === 0 ? null : parseInt(data.device),
				id: roomId
			}
		};
		http
			.put(`${url.BASEURL}/rooms/${roomId}`, roomData)
			.then(res => {
				dispatch({
					type: "EDIT_ROOM",
					payload: res.data.room
				});
				dispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("home.__editRoomSuccess__", { name: res.data.room.name })
				});
				setShow({ show: false, info: null, title: t("common.__device__"), success: true });
			})
			.catch(() => {
				dispatch({
					type: "DISPLAY_ERROR"
				});
			});
	};

	return (
		<FormProvider {...methods}>
			<HandleError error={store.es.error} dispatch={dispatch} message={t("common.__editError__")} />

			{availableDevices.length === 0 ? (
				<p className="mt-4">{t("device.__noDeviceAvailable__")}</p>
			) : (
				<form onSubmit={handleSubmit(onSubmit)} className="mt-4">
					<MyInputSelect
						label={t("home.__selectDevice__")}
						name="device"
						behavior={{ required: false }}
						list={availableDevices}
					/>
					<button
						className="btn btn-outline-secondary btn-sm mr-2"
						type="button"
						onClick={() => setShow({ show: false, info: null, title: "", success: false })}>
						{t("common.__cancel__")}
					</button>
					<button className="btn btn-primary btn-sm" type="submit">
						{t("common.__save__")}
					</button>
				</form>
			)}
		</FormProvider>
	);
};

export default HandleDeviceInRoom;
