import i18n from "../../i18n/i18next";
import { eventTypes } from "../../constants/EventType";
import { handleDate } from "@e4ia/e4link_modules.datetime";

const EventReducer = (state, action) => {
	switch (action.type) {
		case "LOADING_EVENTS":
			return {
				...state,
				loading: true,
				error: false,
				success: false
			};
		case "GET_EVENTS": {
			const events = action.payload.data;
			const data = events.map(function (event) {
				return {
					...event,
					type: eventTypes(event.entry_type_id),
					timing: handleDate(event.begin_datetime, "PPPp", action.payload.locale)
				};
			});
			return {
				...state,
				events: data,
				loading: false
			};
		}

		case "ADD_EVENT": {
			const newEvent = {
				...action.payload,
				type: eventTypes(action.payload.entry_type_id)
			};
			return {
				...state,
				events: [...state.events, newEvent]
			};
		}

		case "EDIT_EVENT": {
			const updatedEvent = action.payload;
			const eventUpdated = state.events.map(event => {
				if (event.id === updatedEvent.id) {
					return {
						...updatedEvent,
						type: eventTypes(updatedEvent.entry_type_id)
					};
				}
				return event;
			});
			return {
				...state,
				events: eventUpdated
			};
		}

		case "ERROR_EVENTS":
			return {
				...state,
				error: true,
				success: false,
				loading: false,
				msg: action.payload
			};

		case "DISPLAY_SUCCESS":
			return {
				...state,
				error: false,
				success: true,
				msg: action.payload
			};

		case "DELETE_EVENT":
			return {
				...state,
				events: state.events.filter(event => event.id !== action.payload),
				error: false,
				success: true,
				msg: i18n.t("common:calendar.__removeAnimation__")
			};
		case "START_DATE":
			return {
				...state,
				startDate: action.payload
			};
		case "END_DATE":
			return {
				...state,
				endDate: action.payload
			};
		case "RESET_ES":
			return {
				...state,
				error: false,
				success: false,
				msg: null
			};

		case "RESET_ERROR":
			return {
				...state,
				error: false,
				msg: null
			};

		default:
			return state;
	}
};

export default EventReducer;
