import React, { useContext, useEffect } from "react";
import { UserContext } from "../context/UserContext";
import { UserInterface } from "../interfaces/UserInterface";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import HomesSecondaryNav from "../layout/HomesSecondaryNav";
import HomeDetails from "../components/Home/Homes/HomeDetails";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import SelectHomeInput from "../layout/SelectHomeInput";

interface Props {
	homeId: number | null;
	groupId: number | null;
	tenantId: number | null;
	userState: UserInterface;
}

const MyHomeDetails = ({ userState, homeId, groupId, tenantId }: Props): JSX.Element => {
	const { homeStore, homeDispatch } = useContext(UserContext);

	useEffect(() => {
		if (homeId) {
			http.get(`${url.BASEURL}/homes/${homeId}`).then(res => {
				homeDispatch({
					type: "GET_MY_HOME",
					payload: res.data.home
				});
			});
		}
		// eslint-disable-next-line
	}, [homeId]);

	useEffect(() => {
		return function cleanup(): void {
			homeDispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	return (
		<PageWithSecondaryNavLayout
			secondaryNav={<HomesSecondaryNav userState={userState} />}
			component={
				homeId ? (
					<HomeDetails
						homeId={homeId}
						groupId={groupId}
						tenantId={tenantId}
						userState={userState}
						homeStore={homeStore}
						homeDispatch={homeDispatch}
					/>
				) : (
					<SelectHomeInput />
				)
			}
		/>
	);
};
export default MyHomeDetails;
