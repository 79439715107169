import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { Link } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";
import MyInputSelect from "../../utils/Form/MyInputSelect";
import DisplayName from "../../utils/DisplayName";
import HandleError from "../../layout/HandleError";
import http from "../../services/Interceptor";
import * as API from "../../services/API";
import * as constant from "../../constants/Url";

const AddSeniorInARoom = ({ show, setShow, store, dispatch }) => {
	const { t } = useTranslation("common");
	const [availableSeniors, setAvailableSeniors] = useState([]);
	const methods = useForm();
	const { handleSubmit } = methods;
	const homeId = show.info.homeId;
	const room = show.info.room;

	useEffect(() => {
		if (homeId && room) {
			API.getAvailableSeniors(
				homeId,
				res => {
					const availableSeniorsList = res.map(function (senior) {
						return { ...senior, name: `${senior.last_name.toUpperCase()} ${senior.first_name}` };
					});
					setAvailableSeniors(availableSeniorsList);
				},
				() => setAvailableSeniors([])
			);
		}
		// eslint-disable-next-line
	}, [homeId, room]);

	const onSubmit = data => {
		const seniorSelected = DisplayName(parseInt(data.seniorId), availableSeniors);
		const roomData = {
			room: {
				home_id: parseInt(homeId),
				senior_id: parseInt(data.seniorId),
				id: room.id
			}
		};
		http
			.put(`${constant.BASEURL}/rooms/${room.id}`, roomData)
			.then(res => {
				dispatch({
					type: "EDIT_ROOM",
					payload: res.data.room
				});
				dispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("mainboard.__seniorIsAssignedToRoom__", { seniorSelected })
				});
			})
			.then(() => {
				setShow({ show: false, info: room, title: t("common.__room__"), success: true });
			})
			.catch(() => {
				dispatch({
					type: "DISPLAY_ERROR"
				});
			});
	};

	return (
		<FormProvider {...methods}>
			<HandleError error={store.es.error} dispatch={dispatch} message={t("common.__editError__")} />
			<form onSubmit={handleSubmit(onSubmit)}>
				{availableSeniors.length > 0 ? (
					<>
						<p className="blue">
							<b>{t("senior.__assignSeniorToRoom__")} </b>
						</p>
						<MyInputSelect name="seniorId" behavior={{ required: true }} list={availableSeniors} />
						<button className="btn btn-primary btn-sm" type="submit">
							{t("common.__save__")}
						</button>
					</>
				) : (
					<>
						<p className="red mt-3">
							<b>{t("senior.__noAssignmentPossible__")}</b>
						</p>
						<div className="header-details">
							<p>
								<FaInfoCircle className="mr-2" />
								{t("senior.__assignmentInfo__")}
							</p>
						</div>
						<Link to="/senior/addsenior/add">
							<button type="button" className="btn btn-sm btn-outline-primary">
								{t("senior.__addSenior__")}
							</button>
						</Link>
					</>
				)}
			</form>
		</FormProvider>
	);
};

export default AddSeniorInARoom;
