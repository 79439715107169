import React from "react";
import { useTranslation } from "react-i18next";
import { Table, SelectColumnFilter } from "../../../utils/Table/TableWithFilter";
import { FaArrowCircleRight, FaBell } from "react-icons/fa";
import { Link } from "react-router-dom";
import { handleDate } from "@e4ia/e4link_modules.datetime";

export default function SeniorsSaturationsList({ saturationValues, userState }) {
	const { t } = useTranslation("common");

	const data = saturationValues.map(value => {
		return {
			...value,
			resident: `${value.last_name} ${value.first_name}`,
			lastChangeDate: value.change ? handleDate(value.change, "P", userState.userConnected.locale) : null,
			lastSaturationDate: value.saturation ? handleDate(value.saturation, "P", userState.userConnected.locale) : null,
			lastChangeDateTime: value.change ? handleDate(value.change, "Pp", userState.userConnected.locale) : null,
			lastSaturationDateTime: value.saturation ? handleDate(value.saturation, "Pp", userState.userConnected.locale) : null,
			timing: value.delta ? (
				<>
					{value.delta.h ? `${value.delta.h}h` : null} {value.delta.m ? `${value.delta.m}mn` : null}{" "}
					{value.delta.s ? `${value.delta.s}s` : null}
				</>
			) : null
		};
	});

	const columns = [
		{
			Header: t("diaper.__lastSaturation__"),
			accessor: "lastSaturationDate",
			Filter: SelectColumnFilter,
			Cell: row =>
				row.row.values.lastSaturationDate ? (
					<>
						<FaBell style={{ height: "0.85rem", width: "auto", verticalAlign: "middle", marginRight: "0.5rem" }} />
						{row.row.original.lastSaturationDateTime}
					</>
				) : null
		},
		{
			Header: t("diaper.__lastChange__"),
			accessor: "lastChangeDate",
			Filter: SelectColumnFilter,
			Cell: row =>
				row.row.values.lastChangeDate ? (
					<>
						<FaArrowCircleRight
							style={{ marginRight: "0.5rem", color: "#00a0e3", height: "1rem", width: "auto", verticalAlign: "middle" }}
						/>
						{row.row.original.lastChangeDateTime}
					</>
				) : (
					<p className="red">
						<FaBell style={{ marginRight: "0.5rem", height: "1rem", width: "auto", verticalAlign: "middle" }} />
						<b>{t("diaper.__saturationAlert__")}</b>
					</p>
				)
		},
		{
			Header: t("common.__senior__"),
			accessor: "resident",
			Filter: SelectColumnFilter,
			Cell: row => (
				<Link to={`/inco/sat/saturationdiapers/${row.row.original.senior_id}`}>
					<span className="category-outline-badge blue">{row.row.values.resident}</span>
				</Link>
			)
		},
		{
			Header: t("common.__timeLimit__"),
			accessor: "timing",
			Filter: SelectColumnFilter
		}
	];

	return <Table columns={columns} data={data} />;
}
