import React from "react";
import UpdateLanguage from "../components/ControlPanel/UpdateLanguage";
import { UserInterface } from "../interfaces/UserInterface";
import { HandleVersion } from "./HandleVersion";

interface Props {
	controlPanel: boolean;
	userState: UserInterface;
	userDispatch: React.Dispatch<any>;
}

const Footer = ({ controlPanel, userState, userDispatch }: Props): JSX.Element => {
	return controlPanel ? (
		<>
			<div className="social-separator mb-3"></div>
			<div className="d-flex justify-content-between">
				<div style={{ width: "50vw", margin: "0.5rem" }}>
					<UpdateLanguage userState={userState} userDispatch={userDispatch} />
				</div>
				<div style={{ margin: "0.5rem" }}>{HandleVersion()}</div>
			</div>
		</>
	) : (
		<div className="footer-container">
			<div className="footer-content">{HandleVersion()}</div>
		</div>
	);
};
export default Footer;
