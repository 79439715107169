import React, { useState } from "react";
import useDateBeforeOrAfter from "../../hooks/useDateBeforeOrAfter";
import HandleLocale from "../../utils/HandleLocale";
import RangePicker from "../../utils/DatePicker/RangePicker";
import RangeTypeSelection from "../Buttons/RangeTypeSelection";
import VisioStatistics from "./VisioStatistics";
import BlogStatistics from "./BlogStatistics";

export default function Statistics({ homeId, userState }) {
	const userLocale = HandleLocale(userState.userConnected.locale);
	const today = new Date();
	const last3Month = useDateBeforeOrAfter({ isBefore: true, numberOfDays: 90 });
	const [startDate, setStartDate] = useState(last3Month);
	const [endDate, setEndDate] = useState(today);

	return (
		<div className="big-card mt-3 mb-3">
			<div className="ml-2">
				<RangeTypeSelection
					userLocale={userLocale}
					today={today}
					last3Month={last3Month}
					startDate={startDate}
					setStartDate={setStartDate}
					endDate={endDate}
					setEndDate={setEndDate}
				/>
			</div>
			<RangePicker
				userLocale={userState.userConnected.locale}
				startDate={startDate}
				setStartDate={setStartDate}
				endDate={endDate}
				setEndDate={setEndDate}
			/>
			<div className="row mt-5">
				<VisioStatistics homeId={homeId} startDate={startDate} endDate={endDate} />
				<BlogStatistics homeId={homeId} startDate={startDate} endDate={endDate} />
			</div>
		</div>
	);
}
