import React from "react";
import { useTranslation } from "react-i18next";
import { FaUserMinus } from "react-icons/fa";
import { Tooltip } from "./Tooltip";
import { RoomInterface } from "../../interfaces/HomeInterface";

interface Props {
	room: RoomInterface;
	setShow: any;
}

const SeniorRemoveIcon = ({ setShow, room }: Props): JSX.Element => {
	const { t } = useTranslation("common");

	return (
		<Tooltip text={t("common.__disaffect__")}>
			<FaUserMinus
				className="board-card-icon"
				onClick={(): void =>
					setShow({ show: true, info: { room: room }, title: t("mainboard.__removeSeniorFromRoom__"), success: false })
				}
			/>
		</Tooltip>
	);
};
export default SeniorRemoveIcon;
