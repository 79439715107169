import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaRegCalendarAlt } from "react-icons/fa";
import { isBefore, add, isAfter } from "date-fns";
import { sub } from "date-fns/esm";
import { fromToUrl } from "@e4ia/e4link_modules.datetime";
import VisioButton from "./VisioButton";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

export default function ControlPanelAppointments({ seniorsFromHome, fetchCalendar, homeId }) {
	const { t } = useTranslation("common");
	const today = new Date();
	const [eventsOfTheDay, setEventsOfTheDay] = useState([]);

	useEffect(() => {
		if (homeId) {
			const fromTo = fromToUrl(today, today);
			http
				.get(`${url.HOMESURL}/${homeId}/home_calendar_entries/${fromTo}`)
				.then(res => {
					const events = res.data.calendar_entries;
					const sortedEvents =
						events.length > 0 ? events.sort((a, b) => a.begin_datetime.localeCompare(b.begin_datetime)) : [];
					setEventsOfTheDay(sortedEvents);
				})
				.catch(() => setEventsOfTheDay([]));
		}
		// eslint-disable-next-line
	}, [homeId, fetchCalendar]);

	const displayCalendarOfTheDay = event => {
		if (event.entry_type_id === 11) {
			return displayVisioAppointments(event);
		} else {
			return displayEvent(event, false, false);
		}
	};

	const displayVisioAppointments = event => {
		const formatedDate = event.begin_datetime.split(" ").join("T");
		const timeToDisplayVisioButton = sub(new Date(formatedDate), { minutes: 5 });
		const timeToDisableVisioButton = add(new Date(formatedDate), {
			minutes: 30
		});
		const isTooEarly = isBefore(new Date(), timeToDisplayVisioButton);
		const isTooLate = isAfter(new Date(), timeToDisableVisioButton);
		if (isTooEarly) {
			return displayEvent(event, false, true);
		} else if (isTooLate) {
			return displayEvent(event, false, false);
		} else {
			return displayEvent(event, true, false);
		}
	};

	const displayEvent = (event, red, isTooEarly) => {
		return (
			<div className="row">
				<div className="col-md-1">
					<p className="meta">{displayTiming(event.begin_datetime)}</p>
				</div>
				<div className="col-md-11">
					<div
						className={
							event.entry_type_id === 11 ? (red ? "calendar-card visio" : "calendar-card alreadyDone") : "calendar-card event"
						}>
						<p className="type">{displayCategory(event.entry_type_id)}</p>
						{event.entry_type_id === 11 ? (
							<>
								<p>
									<b>{seniorsFromHome.length > 0 && seniorsFromHome.find(senior => event.senior_id === senior.id).label}</b>
								</p>
								{isTooEarly ? null : <VisioButton visioData={event} red={red} />}
							</>
						) : (
							<p className="info">{event.info}</p>
						)}
					</div>
				</div>
			</div>
		);
	};

	const displayCategory = type => {
		switch (type) {
			case 1:
				return t("calendar.__workshop__");
			case 2:
				return t("calendar.__event__");
			case 11:
				return t("calendar.__visio__");
			default:
				return;
		}
	};

	const displayTiming = dateTime => {
		const time = dateTime.split(" ")[1];
		const timeToDisplay = time.split(":")[0] + ":" + time.split(":")[1];
		return timeToDisplay;
	};

	return (
		<div className="big-card">
			<div className="row">
				<div className="col-6">
					<h2>{t("calendar.__appointments__")}</h2>
				</div>
				<div className="col-6">
					<Link to={"/controlpanel/calendar"}>
						<button type="button" className="btn btn-sm btn-outline-secondary rounded-0 right mt-1">
							<FaRegCalendarAlt className="mr-2" />
							{t("calendar.__calendar__")}
						</button>
					</Link>
				</div>
			</div>
			<br />
			{eventsOfTheDay.length === 0 ? (
				<p className="meta mt-3">{t("common.__noAppointment__")}</p>
			) : (
				eventsOfTheDay.map(event => <div key={event.id}>{displayCalendarOfTheDay(event)}</div>)
			)}
		</div>
	);
}
