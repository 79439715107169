import React, { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { NotificationsContext } from "../context/NotificationsContext";
import { ModerationTabs } from "../utils/Tabs/TabsToDisplay";
import { UserInterface } from "../interfaces/UserInterface";
import useStoreData from "../hooks/useStoreData";
import LifestoriesModeration from "../components/Moderation/LifestoriesModeration";
import AvatarModeration from "../components/Moderation/AvatarModeration";
import PhotoBoxModeration from "../components/Moderation/PhotoBoxModeration";
import AccessControl from "../layout/AccessControl";
import Tabs from "../utils/Tabs/Tabs";
import HeaderContainer from "../layout/HeaderContainer";
import HandleError from "../layout/HandleError";
import HandleSuccess from "../layout/HandleSuccess";
import SelectHomeInput from "../layout/SelectHomeInput";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const Moderation_Family = ({ homeId, userState }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const { notificationsState, notificationsDispatch } = useContext(NotificationsContext);
	const { store } = useStoreData({ userState, homeId });
	const canModerateAvatar = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_MODERATE_SENIOR_AVATAR]);
	const canModerateLifeStories = userState.userConnected.is_in([
		p.ADMIN,
		p.SUPER_ADMIN,
		p.CAN_MODERATE_SENIOR_LIFE_STORIES
	]);
	const canModeratePhotoFrame = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_MODERATE_PHOTO_FRAME]);
	const canModerateBlog = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_MODERATE_HOME_BLOG]);

	useEffect(() => {
		return function cleanup(): void {
			notificationsDispatch({
				type: "RESET_ES"
			});
		};
	}, []);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[
				p.SUPER_ADMIN,
				p.ADMIN,
				p.CAN_MODERATE_SENIOR_AVATAR,
				p.CAN_MODERATE_SENIOR_LIFE_STORIES,
				p.CAN_MODERATE_PHOTO_FRAME
			]}>
			{canModerateBlog ? <Tabs tabsToDisplay={ModerationTabs} currentTab={"FAMILY_ELTS"} /> : null}
			<div className="big-card">
				<HeaderContainer
					title={t("moderation.__moderation__")}
					addButton={t("common.__params__")}
					link={"/manage/me/myhome"}
					permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_UPDATE_HOME]}
					userState={userState}
				/>
				<HandleError
					error={notificationsState.moderationError}
					dispatch={notificationsDispatch}
					message={t("moderation.__moderationError__")}
					noSpace
				/>
				<HandleSuccess
					success={notificationsState.moderationSuccess}
					dispatch={notificationsDispatch}
					message={t("moderation.__newsSuccess__")}
					noSpace
				/>
			</div>

			{homeId ? (
				<>
					{canModerateLifeStories ? (
						<div className="moderation-card">
							<LifestoriesModeration
								seniorsFromHome={store.seniors.seniorsFromHome}
								notificationsDispatch={notificationsDispatch}
								homeId={homeId}
							/>
						</div>
					) : null}
					{canModerateAvatar ? (
						<div className="moderation-card">
							<AvatarModeration
								seniorsFromHome={store.seniors.seniorsFromHome}
								notificationsDispatch={notificationsDispatch}
								homeId={homeId}
							/>
						</div>
					) : null}
					{canModeratePhotoFrame ? (
						<div className="moderation-card">
							<PhotoBoxModeration
								seniorsFromHome={store.seniors.seniorsFromHome}
								notificationsDispatch={notificationsDispatch}
								homeId={homeId}
							/>
						</div>
					) : null}
				</>
			) : (
				<SelectHomeInput />
			)}
		</AccessControl>
	);
};
export default Moderation_Family;
