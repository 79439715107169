import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import PieChartContainer from "../../../utils/PieChartContainer";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";

export default function SaturationStatistics({
	startDate,
	endDate,
	startTime,
	endTime,
	homeId,
	delay,
	setDelay,
	repartition,
	setSaturationValues,
	setStatistics
}) {
	const { t } = useTranslation("common");
	const [repartitionData, setRepartitionData] = useState([]);

	const onSubmit = e => {
		e.preventDefault();
		const startDateFormatted = handleDate(startDate, "yyyy-MM-dd");
		const endDateFormatted = handleDate(endDate, "yyyy-MM-dd");
		const startTimeFormatted = handleDate(startTime, "HH:mm:ss");
		const endTimeFormatted = handleDate(endTime, "HH:mm:ss");

		const data = {
			from: `${startDateFormatted} ${startTimeFormatted}`,
			to: `${endDateFormatted} ${endTimeFormatted}`,
			home_id: homeId,
			separators: delay.sort((a, b) => a - b)
		};
		http
			.post(`${url.BASEURL}/rht/report/average_delay`, data)
			.then(res => {
				setSaturationValues(res.data.values);
				setStatistics(res.data);
			})
			.catch(() => {
				setSaturationValues([]);
				setStatistics([]);
			});
	};

	useEffect(() => {
		if (repartition) {
			const labels = [...delay, `> ${delay[delay.length - 1]}`];
			const data = repartition.map((stat, index) => {
				return {
					name: labels[index],
					value: stat
				};
			});
			setRepartitionData(data);
		}
	}, [repartition, delay]);

	const handleDelay = e => {
		const position = parseInt(e.target.name.split("_")[1]);
		const tempDelay = delay;
		tempDelay.splice(position, 1, parseInt(e.target.value));
		setDelay(tempDelay);
	};

	return (
		<div className="big-card mt-2 mb-3">
			<div className="row">
				<div className="col-md-8">
					<h5>{t("diaper.__statsRepartition__")}</h5>
					<PieChartContainer data={repartitionData} dataKey={"value"} height={320} />
				</div>
				<div className="col-md-4">
					<form onSubmit={onSubmit} className="header-details">
						<label className="mb-3">{t("diaper.__selectRepartitionDelay__")} :</label>
						{delay.map((elt, index) => (
							<input
								type="text"
								pattern="[0-9]*"
								name={`delay_${index}`}
								className="form-control form-control-sm mb-3"
								defaultValue={elt}
								onChange={e => handleDelay(e)}
								key={index}
							/>
						))}

						<button type="submit" className="btn btn-sm btn-outline-primary mb-3">
							{t("common.__save__")}
						</button>
					</form>
				</div>
			</div>
		</div>
	);
}
