import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import MyInputText from "../../../utils/Form/MyInputText";
import MyInputSelect from "../../../utils/Form/MyInputSelect";
import SelectHomeInput from "../../../layout/SelectHomeInput";
import http from "../../../services/Interceptor";
import * as constant from "../../../constants/Url";
import * as API from "../../../services/API";
import MyInputNumber from "../../../utils/Form/MyInputNumber";
import HandleError from "../../../layout/HandleError";
import HeaderContainer from "../../../layout/HeaderContainer";
import * as p from "../../../constants/Permissions";
import { useTranslation } from "react-i18next";

const AddRoom = ({ homeId, store, dispatch, userState }) => {
	const { t } = useTranslation("common");
	const methods = useForm();
	const { handleSubmit } = methods;
	const history = useHistory();
	const [availableSeniors, setAvailableSeniors] = useState([]);
	const [availableDevices, setAvailableDevices] = useState([]);
	const homeUnits = store.home.homeUnits;

	useEffect(() => {
		if (homeId) {
			API.getAvailableSeniors(
				homeId,
				res => {
					const availableSeniorsList = res.map(function (senior) {
						return { ...senior, name: `${senior.last_name.toUpperCase()} ${senior.first_name}` };
					});
					setAvailableSeniors([{ id: 0, name: "Aucun résident" }, ...availableSeniorsList]);
				},
				() => setAvailableSeniors([{ id: 0, name: "Aucun résident" }])
			);
			API.getAvailableDevices(
				homeId,
				res => {
					const availableDevicesList = res.map(function (device) {
						return { ...device, name: `${device.serial_number}` };
					});
					setAvailableDevices([{ id: 0, name: "Aucune tablette" }, ...availableDevicesList]);
				},
				() => setAvailableDevices([{ id: 0, name: "Aucune tablette" }])
			);
		}
		// eslint-disable-next-line
	}, [homeId]);

	const onSubmit = data => {
		const roomData = {
			room: {
				name: data.name,
				home_id: parseInt(homeId),
				floor: data.floor,
				home_unit_id: parseInt(data.homeUnit),
				setup: data.setup,
				device_id: parseInt(data.device) === 0 ? null : parseInt(data.device),
				senior_id: parseInt(data.senior) === 0 ? null : parseInt(data.senior)
			}
		};

		http
			.post(`${constant.BASEURL}/rooms`, roomData)
			.then(res => {
				dispatch({
					type: "ADD_ROOM",
					payload: res.data.room
				});
				dispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("home.__addRoomSuccess__", { name: res.data.room.name })
				});
				history.push("/manage/room/rooms");
			})
			.catch(() => {
				dispatch({
					type: "DISPLAY_ERROR"
				});
			});
	};

	return (
		<div className="big-card">
			<HeaderContainer
				title={t("home.__theRooms__")}
				addButton={t("home.__addRoom__")}
				link={"/manage/room/addroom/add"}
				permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_HOME_UNIT]}
				userState={userState}
			/>
			<FormProvider {...methods}>
				<HandleError error={store.es.error} dispatch={dispatch} message={t("common.__addError__")} />

				{homeId ? (
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="form-card">
							<div className="row">
								<div className="col-md-12">
									<h5 className="blue">{t("home.__addRoom__")}:</h5>
								</div>
								<div className="col-md-6">
									<MyInputText label={t("common.__name__")} name="name" behavior={{ required: true }} />
								</div>
								<div className="col-md-6">
									<MyInputNumber label={t("home.__floor__")} name="floor" behavior={{ pattern: /[0-9]/ }} />
								</div>
							</div>
						</div>
						<div className="form-card">
							<h5 className="blue">{t("common.__assignment__")} :</h5>
							<MyInputSelect label={t("common.__homeUnit__")} name="homeUnit" behavior={{ required: true }} list={homeUnits} />
							<MyInputSelect
								label={t("common.__senior__")}
								name="senior"
								behavior={{ required: false }}
								list={availableSeniors}
							/>
							<div className="row">
								<div className="col-md-6">
									<MyInputSelect
										label={t("common.__device__")}
										name="device"
										behavior={{ required: false }}
										list={availableDevices}
									/>
								</div>
								<div className="col-md-6">
									<MyInputText label={t("home.__deviceSetUp__")} name="setup" behavior={{ required: false }} />
								</div>
							</div>
						</div>
						<button className="btn btn-primary btn-sm mt-2" type="submit">
							{t("common.__save__")}
						</button>
					</form>
				) : (
					<>
						<p className="error-msg">{t("common.__noHome__")}</p>
						<SelectHomeInput />
					</>
				)}
			</FormProvider>
		</div>
	);
};

export default AddRoom;
