import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaRegClock, FaToggleOff, FaToggleOn } from "react-icons/fa";

export default function VisioTimingDayConstraintForm({
	day,
	daysValues,
	setDaysValues,
	daysToDisplay,
	setDaysToDisplay
}) {
	const { t } = useTranslation("common");
	const hours = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0, 1, 2, 3, 4, 5, 6];
	const minutes = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];

	const initialValues = daysValues.length > 0 ? daysValues.find(elt => elt.day === day.id) : null;
	const initialRange = initialValues ? initialValues.ranges[0] : null;

	const [startingHour, setStartingHour] = useState(initialRange ? initialRange[0] : 7);
	const [startingMinute, setStartingMinute] = useState(initialRange ? initialRange[1] : 0);
	const [endingHour, setEndingHour] = useState(initialRange ? initialRange[2] : 19);
	const [endingMinute, setEndingMinute] = useState(initialRange ? initialRange[3] : 0);
	const [endingHoursToDisplay, setEndingHoursToDisplay] = useState(hours);

	useEffect(() => {
		if (startingHour) {
			// modify the ending hours input to always be after the starting hour
			const indexOfStartingHour = hours.indexOf(parseInt(startingHour));
			const listOfEndingHours = hours.slice(indexOfStartingHour, 17);
			setEndingHoursToDisplay(listOfEndingHours);
		}
	}, [startingHour]);

	useEffect(() => {
		if (initialRange) {
			setStartingHour(initialRange[0]);
			setStartingMinute(initialRange[1]);
			setEndingHour(initialRange[2]);
			setEndingMinute(initialRange[3]);
		}
	}, [initialRange]);

	const handleDay = day => {
		if (daysToDisplay.includes(day.id)) {
			const selectedDays = daysToDisplay.filter(d => d !== day.id);
			setDaysToDisplay(selectedDays);
			setStartingHour(null);
			setStartingMinute(null);
			setEndingHour(null);
			setEndingMinute(null);
			if (daysValues.map(elt => elt.day === day.id)) {
				setDaysValues(() => daysValues.filter(elt => elt.day !== day.id));
			}
		} else {
			setDaysToDisplay(() => [...daysToDisplay, day.id]);
		}
	};

	useEffect(() => {
		if (
			daysToDisplay.includes(day.id) &&
			startingHour >= 0 &&
			startingMinute >= 0 &&
			endingHour >= 0 &&
			endingMinute >= 0
		) {
			const values = [parseInt(startingHour), parseInt(startingMinute), parseInt(endingHour), parseInt(endingMinute)];
			if (daysValues.find(elt => elt.day === day.id)) {
				const valuesWithoutThisDays = daysValues.filter(elt => elt.day !== day.id);
				setDaysValues([...valuesWithoutThisDays, { day: day.id, ranges: [values] }]);
			} else {
				setDaysValues([...daysValues, { day: day.id, ranges: [values] }]);
			}
		}
	}, [startingHour, startingMinute, endingHour, endingMinute, day, daysToDisplay]);

	return (
		<>
			{daysToDisplay.includes(day.id) ? (
				<p>
					<FaToggleOn
						className="blue mr-2"
						style={{ width: "auto", height: "1.25rem", cursor: "pointer" }}
						onClick={() => handleDay(day)}
					/>
					<b>{day.name}</b>
				</p>
			) : (
				<p>
					<FaToggleOff
						className="grey mr-2"
						style={{ width: "auto", height: "1.25rem", cursor: "pointer" }}
						onClick={() => handleDay(day)}
					/>
					{day.name} :{" "}
					<span className="meta">
						<i>{t("mailbox.__noVisio__")}</i>
					</span>
				</p>
			)}
			{daysToDisplay.includes(day.id) ? (
				<div className="row mb-4">
					<div className="col-md-6">
						<div className="input-group input-group-sm">
							<div className="input-group-prepend">
								<span className="input-group-text" id="">
									<FaRegClock />
								</span>
							</div>
							<select
								name="start"
								className="form-control "
								onChange={e => setStartingHour(e.target.value)}
								value={startingHour}
							>
								<option>{t("common.__hour__")}</option>
								{hours.map(hour => (
									<option key={hour} value={hour}>
										{hour}
									</option>
								))}
							</select>
							<select
								name="start"
								className="form-control "
								value={startingMinute}
								onChange={e => setStartingMinute(e.target.value)}
							>
								<option>{t("common.__minute__")}</option>
								{minutes.map(minute => (
									<option key={minute} value={minute}>
										{minute}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="col-md-6">
						<div className="input-group input-group-sm">
							<div className="input-group-prepend">
								<span className="input-group-text" id="">
									<FaRegClock />
								</span>
							</div>
							<select name="start" className="form-control " onChange={e => setEndingHour(e.target.value)} value={endingHour}>
								<option>{t("common.__hour__")}</option>
								{endingHoursToDisplay.map(hour => (
									<option key={hour} value={hour}>
										{hour}
									</option>
								))}
							</select>
							<select
								name="start"
								className="form-control "
								value={endingMinute}
								onChange={e => setEndingMinute(e.target.value)}
							>
								<option>{t("common.__minute__")}</option>
								{minutes.map(minute => (
									<option key={minute} value={minute}>
										{minute}
									</option>
								))}
							</select>
						</div>
					</div>
				</div>
			) : null}
		</>
	);
}
