import React, { useEffect } from "react";
import { UserInterface } from "../interfaces/UserInterface";
import useStoreData from "../hooks/useStoreData";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import HomesSecondaryNav from "../layout/HomesSecondaryNav";
import HomeRulesContainer from "../components/Home/NotificationsRules/HomeRulesContainer";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const MyHome_NotificationRules = ({ homeId, userState }: Props): JSX.Element => {
	const { store, dispatch } = useStoreData({ userState, homeId });

	useEffect(() => {
		return function cleanup(): void {
			dispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	return (
		<PageWithSecondaryNavLayout
			secondaryNav={<HomesSecondaryNav userState={userState} />}
			component={<HomeRulesContainer store={store} dispatch={dispatch} homeId={homeId} userState={userState} />}
		/>
	);
};
export default MyHome_NotificationRules;
