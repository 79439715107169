import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { NotificationsContext } from "../context/NotificationsContext";
import { BlogContext } from "../context/BlogContext";
import { UserInterface } from "../interfaces/UserInterface";
import { BlogTabs } from "../utils/Tabs/TabsToDisplay";
import useStoreData from "../hooks/useStoreData";
import AccessControl from "../layout/AccessControl";
import SelectHomeInput from "../layout/SelectHomeInput";
import BlogHeader from "../layout/BlogHeader";
import NoData from "../layout/NoData";
import EditNews from "../components/News/EditNews";
import Tabs from "../utils/Tabs/Tabs";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const SocialNews_EditPost = ({ homeId, userState }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const { blogStore, blogDispatch } = useContext(BlogContext);
	const { notificationsDispatch } = useContext(NotificationsContext);
	const { store } = useStoreData({ userState, homeId });
	const { postId } = useParams<{ postId?: string }>();
	const [postToEdit, setPostToEdit] = useState({});
	const [accessError, setAccessError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const isBlogModerator = userState.userConnected.is(p.CAN_MODERATE_HOME_BLOG);
	const seniorsFromHome = store.seniors.seniorsFromHome;
	const displayAddPostButton = userState.userConnected.is_in([
		p.HOME_BLOG_WRITER,
		p.CAN_POST_MODIFY_HOME_BLOG,
		p.SUPER_ADMIN,
		p.ADMIN
	]);

	useEffect(() => {
		if (postId) {
			setIsLoading(true);
			http
				.get(`${url.HOMESURL}/blog_posts/${postId}`)
				.then(res => {
					setPostToEdit(res.data.blog_post);
					setIsLoading(false);
				})
				.catch(err => {
					setIsLoading(false);
					if (err.response.status === 403) {
						setAccessError(true);
					} else {
						setPostToEdit({});
					}
				});
		}
	}, [postId]);

	useEffect(() => {
		return function cleanup(): void {
			notificationsDispatch({
				type: "RESET_ES"
			});
		};
	}, []);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[
				p.ADMIN,
				p.SUPER_ADMIN,
				p.HOME_BLOG_WRITER,
				p.CAN_MODERATE_HOME_BLOG,
				p.CAN_POST_MODIFY_HOME_BLOG
			]}>
			<Tabs tabsToDisplay={BlogTabs} currentTab={"MANAGE_POST"} />
			<div className="big-card">
				<BlogHeader displayAddPostButton={displayAddPostButton} title={t("common.__update__")} />
				{homeId ? (
					isLoading ? (
						<>
							<div className="spinner-border text-primary mr-3" role="status"></div>
							<span>
								<b>{t("news.__addNewsLoading__")}</b>
							</span>
						</>
					) : accessError ? (
						<p className="error-msg mt-3">{t("common.__accessError__")}</p>
					) : "title" in postToEdit ? (
						<EditNews
							userState={userState}
							post={postToEdit}
							blogStore={blogStore}
							blogDispatch={blogDispatch}
							homeId={homeId}
							seniorsFromHome={seniorsFromHome}
							isBlogModerator={isBlogModerator}
							notificationsDispatch={notificationsDispatch}
							homeUnits={store.home.homeUnits}
						/>
					) : (
						<NoData />
					)
				) : (
					<SelectHomeInput />
				)}
			</div>
		</AccessControl>
	);
};
export default SocialNews_EditPost;
