import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { FaPrint, FaRegEnvelope, FaTag, FaTimes } from "react-icons/fa";
import { displayValidityTypes, displayBadgeType } from "./ValidityTypes";
import { handleValidityDetails } from "../../../utils/BadgeRestrictions";
import Toggle from "../../../utils/Toggle";
import DisplayName from "../../../utils/DisplayName";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import NoData from "../../../layout/NoData";

const BadgeDetails = ({ badgeId, store, dispatch, userState }) => {
	const { t } = useTranslation("common");
	const [update, setUpdate] = useState(false);
	const [badge, setBadge] = useState({});
	const [badgeError, setBadgeError] = useState({});
	const [errorActivation, setErrorActivation] = useState(false);
	const [qrCodeSent, setQrCodeSent] = useState(false);
	const [errorQrCodeSent, setErrorQrCodeSent] = useState(false);
	const history = useHistory();
	const healthWorkers = store.users.healthWorkers;
	const userLocale = userState.userConnected.locale;

	const sendQrCode = badgeId => {
		setQrCodeSent(false);
		setErrorQrCodeSent(false);
		http
			.get(`${url.BASEURL}/user_badges/${badgeId}/send/email`)
			.then(() => setQrCodeSent(true))
			.then(() => setErrorQrCodeSent(true));
	};

	useEffect(() => {
		setBadgeError(false);
		if (badgeId) {
			http
				.get(`${url.BASEURL}/user_badges/${badgeId}`)
				.then(res => {
					const result = res.data.user_badge;
					const badgeDetails = {
						...result,
						type: `${displayBadgeType(result.type_id)}`,
						restriction: `${displayValidityTypes(result.validity.type)}`,
						validityType: parseInt(result.validity.type),
						validityValues: result.validity.values
					};
					setBadge(badgeDetails);
				})
				.catch(() => setBadgeError(true));
		}
		// eslint-disable-next-line
	}, [badgeId, update]);

	const toggleBadge = id => {
		setErrorActivation(false);
		http
			.put(`${url.BASEURL}/user_badges/${id}/toggle`)
			.then(res => {
				dispatch({
					type: "TOGGLE_BADGE",
					payload: res.data.user_badge
				});
				setUpdate(prevUpdate => !prevUpdate);
			})
			.catch(() => setErrorActivation(true));
	};

	return (
		<>
			{errorActivation && (
				<p className="error-msg">
					{t("common.__errorOccured__")}
					<span className="right" role="button" tabIndex="0" onClick={() => setErrorActivation(false)}>
						<FaTimes />
					</span>
				</p>
			)}
			{qrCodeSent && <p className="success-msg">{t("badge.__qrCodeSent__")}</p>}
			{errorQrCodeSent && <p className="error-msg">{t("badge.__qrCodeSentError__")}</p>}

			<div className="header-details mt-5">
				{badgeError ? (
					<NoData />
				) : (
					<>
						<div className="row">
							<div className="col-md-4">
								<p>{t("common.__status__")}:</p>
							</div>
							<div className="col-md-8">
								<Toggle isToggle={badge.active} value={badge.id} action={toggleBadge} meta />
							</div>
							<div className="col-md-4">
								<p>{badge.type_id === 1 ? t("badge.__pinCode__") : t("badge.__qrCode__")}</p>
							</div>
							<div className="col-md-8">
								<p>{badge.value}</p>
							</div>
							<div className="col-md-4">
								<p>{t("common.__assignment__")} : </p>
							</div>
							<div className="col-md-8">
								{!badge.user_id ? (
									<p>{t("badge.__availables__")}</p>
								) : (
									<Link to={`/user/healthworker/${badge.user_id}`}>
										<p className="blue">
											<b>{DisplayName(badge.user_id, healthWorkers)}</b>
										</p>
									</Link>
								)}
							</div>
							<div className="col-md-4">
								<p>{t("badge.__restrictions__")} </p>
							</div>
							<div className="col-md-8">
								<p>{badge.restriction}</p>
								{badge.validityType ? handleValidityDetails(badge.validityType, badge.validityValues, userLocale) : null}
							</div>
						</div>
					</>
				)}

				{badge.type_id === 2 ? (
					<div className="row">
						<div className="col-12">
							<hr />
						</div>
						<div className="col-md-4">
							<img src={`${url.BASEURL}/user_badges/generate/png/${badge.value}`} alt="qrCode" className="img-fluid qr-code" />
						</div>
						<div className="col-md-8">
							<a href={`${url.BASEURL}/user_badges/generate/pdf/${badge.value}`} download={`badge-${badge.value}`}>
								<button type="button" className="category-outline-badge blue mt-2 fake-link">
									<FaPrint className="mr-2" /> {t("common.__print__")} ({t("common.__pdf__")})
								</button>
							</a>
							<br />
							<a href={`${url.BASEURL}/user_badges/generate/pdf/dymo/${badge.value}`} download={`badge-${badge.value}`}>
								<button type="button" className="category-outline-badge blue mt-2 fake-link">
									<FaTag className="mr-2" /> {t("badge.__dymo__")} ({t("common.__pdf__")})
								</button>
							</a>
							<br />
							<button
								type="button"
								className="category-outline-badge blue mt-2 fake-link"
								onClick={() => sendQrCode(badge.id)}>
								<FaRegEnvelope className="mr-2" /> {t("common.__send__")} ({t("common.__mail__")})
							</button>
						</div>
					</div>
				) : null}
			</div>
			<button className="btn btn-sm btn-outline-secondary mr-2" type="button" onClick={() => history.goBack()}>
				{t("common.__back__")}
			</button>
			<Link to={`/user/hwbagde/edit/${badge.id}`}>
				<button className="btn btn-sm btn-outline-primary" type="button">
					{t("common.__update__")}
				</button>
			</Link>
		</>
	);
};

export default BadgeDetails;
