import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { FaCheck, FaPhoneAlt, FaRegEnvelope, FaUserCircle } from "react-icons/fa";
import { Table, SelectColumnFilter } from "../../../utils/Table/TableWithFilter";
import { FamilyInterface, SeniorInterface } from "../../../interfaces/UserInterface";

interface Props {
	homeId: number;
	isAdmin: boolean;
	families: FamilyInterface[];
	userLocale: string;
}

const FilteredFamilyList = ({ homeId, isAdmin, families, userLocale }: Props): JSX.Element => {
	const { t } = useTranslation("common");

	const columns = [
		{
			Header: t("homeBoard.__family__"),
			accessor: "identity",
			Cell: (row: any) =>
				row.row.original.active ? (
					isAdmin ? (
						row.row.original.principal ? (
							<Link to={`/senior/family/${row.row.original.id}`}>
								<span className="category-outline-badge marine">{row.row.values.identity}</span>
							</Link>
						) : (
							<Link to={`/senior/family/${row.row.original.id}`}>
								<span className="category-outline-badge grey">{row.row.values.identity}</span>
							</Link>
						)
					) : row.row.original.principal ? (
						<span className="category-outline-badge marine">{row.row.values.identity}</span>
					) : (
						<span className="category-outline-badge grey">{row.row.values.identity}</span>
					)
				) : (
					<span className="msg-tag green">{row.row.values.identity}</span>
				)
		},
		{
			Header: t("common.__contactDetails__"),
			accessor: "email",
			Cell: (row: any) => (
				<>
					<p>
						<FaUserCircle className="mr-2" /> {row.row.original.login}
					</p>
					{row.row.original.phone ? (
						<p>
							<FaPhoneAlt className="mr-2" />
							{row.row.original.phone}
						</p>
					) : null}
					<p>
						<FaRegEnvelope className="mr-2" />
						<a href={`mailto:${row.row.values.email}`}>{row.row.values.email}</a>
					</p>
				</>
			)
		},
		{
			Header: t("common.__senior__"),
			accessor: "seniorNames",
			Cell: (row: any) =>
				row.row.original.seniors.map((senior: SeniorInterface) => (
					<p className="d-block" key={senior.id}>
						{senior.active ? (
							isAdmin ? (
								<Link to={`/senior/profile/${senior.id}`} className="category-outline-badge blue">
									{senior.last_name.toUpperCase()} {senior.first_name}
								</Link>
							) : senior.home_id === homeId ? (
								<Link to={`/senior/profile/${senior.id}`} className="category-outline-badge blue">
									{senior.last_name.toUpperCase()} {senior.first_name}
								</Link>
							) : (
								<span className="category-outline-badge grey">
									{senior.last_name.toUpperCase()} {senior.first_name}
								</span>
							)
						) : (
							<span className="category-outline-badge grey">
								{senior.last_name.toUpperCase()} {senior.first_name}
							</span>
						)}
					</p>
				))
		},
		{
			Header: t("healthworker.__signInCount__"),
			accessor: "sign_in_count",
			Filter: SelectColumnFilter,
			Cell: (row: any) => (
				<>
					<p>{row.row.values.sign_in_count}</p>
					{row.row.original.inserted_at ? (
						<p className="meta">
							<b>{t("common.__createdAt__")} :</b> {handleDate(row.row.original.inserted_at, "Pp", userLocale)}
						</p>
					) : null}
					{row.row.original.last_sign_in_at ? (
						<p className="meta">
							<b>{t("healthworker.__lastSignIn__")} :</b> {handleDate(row.row.original.last_sign_in_at, "Pp", userLocale)}
						</p>
					) : null}
				</>
			)
		},
		{
			Header: t("family.__rgpd__"),
			accessor: "rgpd",
			Filter: SelectColumnFilter,
			width: 75,
			Cell: (row: any) =>
				row.row.original.has_accepted_rgpd ? (
					<div className="center">
						<FaCheck />
					</div>
				) : null
		}
	];

	return <Table columns={columns} data={families} />;
};
export default FilteredFamilyList;
