import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { v4 as uuidv4 } from "uuid";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import HandleError from "../../../layout/HandleError";
import MyInputTextarea from "../../../utils/Form/MyInputTextarea";
import DisplayName from "../../../utils/DisplayName";
import useStoreData from "../../../hooks/useStoreData";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import { UserContext } from "../../../context/UserContext";

export default function AddCommentForm({
	postId,
	userState,
	commentStore,
	commentDispatch,
	addComment,
	setAddComment,
	answerComment,
	setAnswerComment,
	blogDispatch,
	canComment
}) {
	const { t } = useTranslation("common");
	const methods = useForm();
	const { handleSubmit } = methods;
	const { homeId } = useContext(UserContext);
	const { store } = useStoreData({ userState, homeId });
	const healthWorkerType = store.users.healthWorkerTypes;
	const userJob = healthWorkerType.length > 0 ? DisplayName(userState.userConnected.type_id, healthWorkerType) : null;
	const queryClient = useQueryClient();

	const onSubmit = async values => {
		commentDispatch({
			type: "RESET_ES"
		});
		let data = {};
		if (answerComment.answer) {
			data = {
				comment: {
					blog_id: postId,
					content: values.comment,
					user: {
						id: userState.userConnected.id,
						type: userJob,
						first_name: userState.userConnected.first_name,
						last_name: userState.userConnected.last_name
					},
					datetime: handleDate(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
					is_family: false,
					is_after: true,
					after_comment_id: answerComment.id,
					senior_id: null,
					id: uuidv4()
				}
			};
		} else {
			data = {
				comment: {
					blog_id: postId,
					content: values.comment,
					user: {
						id: userState.userConnected.id,
						type: userJob,
						first_name: userState.userConnected.first_name,
						last_name: userState.userConnected.last_name
					},
					datetime: handleDate(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
					is_family: false,
					is_after: false,
					after_comment_id: null,
					senior_id: null,
					id: uuidv4(),
					answers: []
				}
			};
		}
		await mutate(data);
	};

	const { mutate } = useMutation(data => http.put(`${url.HOMESURL}/blog_posts/${postId}/comments`, data), {
		onSuccess: res => {
			queryClient.invalidateQueries("blog_posts");
			commentDispatch({
				type: "ADD_COMMENT",
				payload: res.data
			});
			blogDispatch({
				type: "EDIT_POST_COUNTER",
				payload: postId
			});
			if (addComment) {
				setAddComment(false);
			}
			if (answerComment) {
				setAnswerComment({ answer: false, id: null });
			}
		},
		onError: () => {
			commentDispatch({
				type: "DISPLAY_ERROR"
			});
		}
	});

	return canComment ? (
		<FormProvider {...methods}>
			<HandleError error={commentStore.error} dispatch={commentDispatch} message={t("common.__addError__")} noSpace />
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="mb-4">
					<MyInputTextarea name="comment" behavior={{ required: true }} rows="4" />
					<button type="submit" className="category-outline-badge blue">
						<b>{t("common.__send__")}</b>
					</button>
				</div>
			</form>
		</FormProvider>
	) : null;
}
