import React from "react";
import RoomsFromHome from "./RoomsFromHome";
import HeaderContainer from "../../../layout/HeaderContainer";
import SelectHomeInput from "../../../layout/SelectHomeInput";
import HandleSuccess from "../../../layout/HandleSuccess";
import * as p from "../../../constants/Permissions";
import { useTranslation } from "react-i18next";

const Rooms = ({ homeId, userState, dispatch, store }) => {
	const { t } = useTranslation("common");
	const canManageHomeUnit = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_HOME_UNIT]);

	return (
		<div className="big-card">
			<HeaderContainer
				title={t("home.__theRooms__")}
				addButton={t("home.__addRoom__")}
				link={"/manage/room/addroom/add"}
				permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_HOME_UNIT]}
				userState={userState}
			/>
			<HandleSuccess success={store.es.success} dispatch={dispatch} message={store.es.msg} />

			{homeId ? (
				<RoomsFromHome homeId={homeId} store={store} dispatch={dispatch} canManageHomeUnit={canManageHomeUnit} />
			) : (
				<SelectHomeInput />
			)}
		</div>
	);
};

export default Rooms;
