import React, { useEffect, useState } from "react";
import { format, sub } from "date-fns";
import { SeniorInterface, UserInterface } from "../interfaces/UserInterface";
import useDiaperStore from "../hooks/useDiaperStore";
import AccessControl from "../layout/AccessControl";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import DiaperSecondaryNav from "../layout/DiaperSecondaryNav";
import SelectHomeInput from "../layout/SelectHomeInput";
import SeniorsSaturationStatistics from "../components/DiaperConnected/SaturationStatistics/SeniorsSaturationStatistics";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const DiaperSaturationStatistics = ({ homeId, userState }: Props): JSX.Element => {
	const isDiaperManager = userState.userConnected.is_in([p.SUPER_ADMIN, p.ADMIN, p.DIAPER_APPLICATION]);

	const today = new Date();
	const [startDate, setStartDate] = useState(sub(today, { days: 1 }));
	const [endDate, setEndDate] = useState(today);
	const [startTime, setStartTime] = useState(sub(today, { days: 1 }));
	const [endTime, setEndTime] = useState(today);
	const [selectedSenior, setSelectedSenior] = useState<SeniorInterface | null>(null);
	const [delay, setDelay] = useState([5, 10, 15]);
	const [saturationValues, setSaturationValues] = useState([]);
	const [statistics, setStatistics] = useState({});

	const { diaperStore } = useDiaperStore({ userState, homeId });
	const seniorsWithSensor = diaperStore.seniors.filter((senior: any) => senior.sensor_id);
	const seniors = seniorsWithSensor.map((senior: any) => {
		return {
			label: senior.name,
			value: senior.id
		};
	});

	useEffect(() => {
		window.scrollTo(0, 0);
		if (homeId && isDiaperManager) {
			const startDateFormatted = format(startDate, "yyyy-MM-dd");
			const endDateFormatted = format(endDate, "yyyy-MM-dd");
			const startTimeFormatted = format(startTime, "HH:mm:ss");
			const endTimeFormatted = format(endTime, "HH:mm:ss");

			if (selectedSenior) {
				const data = {
					from: `${startDateFormatted} ${startTimeFormatted}`,
					to: `${endDateFormatted} ${endTimeFormatted}`,
					senior_id: selectedSenior.value,
					separators: delay
				};
				http
					.post(`${url.BASEURL}/rht/report/average_delay`, data)
					.then(res => {
						setSaturationValues(res.data.values);
						setStatistics(res.data);
					})
					.catch(() => {
						setSaturationValues([]);
						setStatistics([]);
					});
			} else {
				const data = {
					from: `${startDateFormatted} ${startTimeFormatted}`,
					to: `${endDateFormatted} ${endTimeFormatted}`,
					home_id: homeId,
					separators: delay
				};
				http
					.post(`${url.BASEURL}/rht/report/average_delay`, data)
					.then(res => {
						setSaturationValues(res.data.values);
						setStatistics(res.data);
					})
					.catch(() => {
						setSaturationValues([]);
						setStatistics([]);
					});
			}
		}
		// eslint-disable-next-line
	}, [homeId, startDate, endDate, selectedSenior, startTime, endTime, isDiaperManager]);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.SUPER_ADMIN, p.ADMIN, p.DIAPER_APPLICATION]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<DiaperSecondaryNav homeId={homeId} userState={userState} />}
				component={
					homeId ? (
						<SeniorsSaturationStatistics
							userState={userState}
							saturationValues={saturationValues}
							startDate={startDate}
							setStartDate={setStartDate}
							endDate={endDate}
							setEndDate={setEndDate}
							statistics={statistics}
							homeId={homeId}
							delay={delay}
							setDelay={setDelay}
							setSaturationValues={setSaturationValues}
							setStatistics={setStatistics}
							seniorsWithSensor={seniors}
							selectedSenior={selectedSenior}
							setSelectedSenior={setSelectedSenior}
							startTime={startTime}
							setStartTime={setStartTime}
							endTime={endTime}
							setEndTime={setEndTime}
						/>
					) : (
						<SelectHomeInput />
					)
				}
			/>
		</AccessControl>
	);
};
export default DiaperSaturationStatistics;
