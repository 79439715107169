import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { HealthWorkersTabs } from "../utils/Tabs/TabsToDisplay";
import { UserInterface } from "../interfaces/UserInterface";
import useStoreData from "../hooks/useStoreData";
import AccessControl from "../layout/AccessControl";
import SelectHomeInput from "../layout/SelectHomeInput";
import HeaderContainer from "../layout/HeaderContainer";
import EditHealthWorkerBadge from "../components/HealthWorkers/Badges/EditHealthWorkerBadge";
import Tabs from "../utils/Tabs/Tabs";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const HealthWorker_EditBadge = ({ userState, homeId }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const { id } = useParams<{ id: string }>();
	const { store, dispatch } = useStoreData({ userState, homeId });

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_USER_AND_BADGE]}>
			<Tabs tabsToDisplay={HealthWorkersTabs} currentTab={"BADGES"} />
			<div className="big-card">
				{homeId ? (
					<>
						<HeaderContainer
							title={t("badge.__badges__")}
							addButton={t("badge.__addBadge__")}
							link={"/user/hwbagde/addbadge/add"}
							permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_USER_AND_BADGE]}
							userState={userState}
						/>
						<EditHealthWorkerBadge
							userState={userState}
							badgeId={parseInt(id)}
							store={store}
							dispatch={dispatch}
							homeId={homeId}
						/>
					</>
				) : (
					<SelectHomeInput />
				)}
			</div>
		</AccessControl>
	);
};
export default HealthWorker_EditBadge;
