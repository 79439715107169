import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import HandleError from "../../../layout/HandleError";

export default function RemoveSensorToSenior({ show, setShow }) {
	const { t } = useTranslation("common");
	const [error, setError] = useState(false);
	const senior = show.info.senior;

	const onSubmit = e => {
		e.preventDefault();
		setError(false);
		const data = {
			diaper_sensor_id: parseInt(senior.sensor_id),
			senior_id: parseInt(senior.id)
		};
		http
			.post(`${url.BASEURL}/rht/unaffect/diaper_sensor/from/senior`, data)
			.then(() => setShow({ show: false, info: { senior: null }, success: true }))
			.catch(() => setError(true));
	};

	return (
		<form onSubmit={onSubmit} className="mb-2 mt-3">
			<HandleError error={error} setError={setError} message={t("common.__editError__")} />
			<p className="mb-3">
				{t("diaper.__removeSensorToSenior__")} : <b>{senior.name}.</b>
			</p>
			<button
				type="button"
				className="btn btn-sm btn-outline-secondary mr-2"
				onClick={() => setShow({ show: false, senior: null, success: false })}>
				{t("common.__cancel__")}
			</button>
			<button type="submit" className="btn btn-sm btn-danger">
				{t("common.__validate__")}
			</button>
		</form>
	);
}
