import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FaCheck, FaAngleRight, FaTimesCircle } from "react-icons/fa";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import { FamilyInterface, NewSeniorInterface } from "../../../interfaces/UserInterface";

interface Props {
	newFamilyStore: NewSeniorInterface;
	newFamilyDispatch: React.Dispatch<any>;
}

const SearchFamilyUser = ({ newFamilyStore, newFamilyDispatch }: Props): JSX.Element | null => {
	const { t } = useTranslation("common");
	const [userFound, setUserFound] = useState<null | string | FamilyInterface>(null);
	const inputRef = useRef<any>();

	const searchUser = (): void => {
		if (inputRef.current && inputRef.current.value && inputRef.current.value.match(/^\S+@\S+\.\S+$/)) {
			http
				.get(`${url.BASEURL}/search/family_users?email=${inputRef.current.value}`)
				.then(res => setUserFound(res.data.family_user))
				.catch(err => {
					if (err.response.status === 403) {
						setUserFound("AFFILIATE_FOUND");
					} else {
						setUserFound("NO_ACCOUNT");
					}
				});
		} else {
			setUserFound("ERROR");
		}
	};

	const selectThisAccount = (family: FamilyInterface): void => {
		newFamilyDispatch({
			type: "SET_EXISTING_FAMILY",
			payload: family
		});
		setUserFound(null);
	};

	return newFamilyStore.displaySearchFamilyUser ? (
		<div className="header-details">
			<FaTimesCircle
				className="grey right"
				role="button"
				onClick={(): void => newFamilyDispatch({ type: "HIDE_SEARCH_BOX" })}
			/>
			{userFound && typeof userFound !== "string" && userFound.id ? (
				<>
					<p className="ml-1">
						<FaAngleRight /> {t("family.__userFound__")} :
					</p>
					<p
						className={
							newFamilyStore.existingFamilyUserIds.includes(userFound.id)
								? "board-supervisor-badge-selected"
								: "board-supervisor-badge"
						}
						role="button"
						onClick={(): void => selectThisAccount(userFound)}>
						<b>
							{userFound.last_name.toUpperCase()} {userFound.first_name}
						</b>
						<span className="right grey">
							<FaCheck style={{ width: "auto", height: 18 }} />
						</span>
					</p>
				</>
			) : (
				<>
					<p className="mt-2 mb-3">{t("family.__searchFamilyAccount__")}</p>
					<div className="d-flex justify-content-between">
						<input
							type="text"
							aria-label="search-family-input"
							className="form-control form-control-sm mr-3"
							placeholder={t("common.__mail__")}
							ref={inputRef}
						/>
						<button type="button" onClick={searchUser} className="btn btn-sm btn-outline-primary">
							{t("common.__search__")}
						</button>
					</div>
					{userFound ? (
						userFound === "ERROR" ? (
							<p className="error-msg">
								<b>{t("users.__forgotPasswordError__")}</b>
							</p>
						) : userFound === "AFFILIATE_FOUND" ? (
							<p className="error-msg">
								<b>{t("family.__isAffiliateUser__")}</b>
							</p>
						) : userFound === "NO_ACCOUNT" ? (
							<p className="red mb-2">
								<b>{t("family.__noUserFound__")}</b>
							</p>
						) : null
					) : null}
				</>
			)}
		</div>
	) : null;
};
export default SearchFamilyUser;
