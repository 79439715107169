import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
	FaHeart,
	FaCheckCircle,
	FaExclamationTriangle,
	FaTimesCircle,
	FaRegClock,
	FaRegHeart,
	FaDownload,
	FaCommentAlt,
	FaRegCommentAlt,
	FaTimes
} from "react-icons/fa";
import { DisplayRemovePostModal } from "./NewsMediaModal";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { handleEditRemoveButtons } from "./BlogUtils";
import Parse from "html-react-parser";
import LazyloadImg from "../../layout/LazyloadImg";
import Comments from "./Comments/Comments";
import VideoPlayer from "../../utils/VideoPlayer";
import ModalContainer from "../../utils/ModalContainer";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import * as p from "../../constants/Permissions";

export default function SelectedPost({ blogStore, blogDispatch, userState, postId }) {
	const { t } = useTranslation("common");
	const post = blogStore.post;
	const [familyLikes, setFamilyLikes] = useState([]);
	const [error, setError] = useState(false);
	const [showRemoveConfirmation, setShowRemoveConfirmation] = useState({
		show: false,
		info: { postId: null, postTitle: null },
		title: t("news.__removePostWarningTitle__")
	});
	const userId = userState.userConnected.id;
	const isBlogger = userState.userConnected.is(p.HOME_BLOG_WRITER);
	const isModerator = userState.userConnected.is(p.CAN_MODERATE_HOME_BLOG);
	const canRemovePost = userState.userConnected.is(p.CAN_POST_MODIFY_HOME_BLOG);

	useEffect(() => {
		if (postId) {
			http
				.get(`${url.BASEURL}/homes/blog_posts/${postId}/who/like`)
				.then(res => setFamilyLikes(res.data.family_users))
				.catch(() => setFamilyLikes([]));
		}
	}, [postId]);

	const displayMedias = medias => {
		const video = medias.filter(media => media.content_type === "video/mp4");
		const pdfFiles = medias.filter(media => media.content_type === "application/pdf");
		const pictures = medias.filter(media => media.content_type.split("/")[0] === "image");
		return (
			<>
				{pdfFiles.length > 0 &&
					pdfFiles.map(pdf => (
						<div key={pdf.path} className="header-details">
							<a href={`/storages/blog_media${pdf.path}`} download={`${pdf.filename}`}>
								<FaDownload className="mr-4" />
								<b>{pdf.filename}</b> ({t("common.__pdfFile__")})
							</a>
						</div>
					))}
				{video.length > 0 && (
					<div className="center">
						{video.map(v => (
							<div key={v.path}>
								<div className="video-container">
									<VideoPlayer uriVideo={v.path} contentType={v.content_type} />
								</div>
							</div>
						))}
					</div>
				)}
				{pictures.length > 0 && (
					<div className="pictures-container">
						{pictures.map(pict => (
							<div key={pict.path} className="single-post-pict">
								<LazyloadImg path={`/storages/blog_media${pict.path}`} display="full-pict-post" />
								<a
									href={`/storages/blog_media${pict.path}`}
									download={`${pict.path_original}`}
									className="single-post-pict-download">
									<div className="single-post-pict-download-content">
										<FaDownload className="single-post-pict-download-icon" />
									</div>
								</a>
							</div>
						))}
					</div>
				)}
			</>
		);
	};

	return (
		<>
			{error ? (
				<p className="error-msg mt-2">
					<span className="right" role="button" tabIndex="0" onClick={() => setError(false)}>
						<FaTimes />
					</span>
					{t("common.__errorOccured__")}
				</p>
			) : (
				<br />
			)}
			{post && post.title ? (
				<>
					<div className="d-flex justify-content-between">
						<h5 className="title">
							<b>{post.title}</b>
						</h5>
						<div>
							{handleEditRemoveButtons(
								post.accepted,
								post.added_by,
								post.id,
								post.title,
								isBlogger,
								canRemovePost,
								userId,
								isModerator,
								setShowRemoveConfirmation
							)}
						</div>
					</div>
					<div className="social-block">
						<FaRegClock className="mr-2 ml-1" />{" "}
						<span className="meta">{handleDate(post.date, "P", userState.userConnected.locale)}</span>
						{post.accepted && (
							<b className="green ml-4">
								<FaCheckCircle className="mr-2" />
								{t("news.__moderationOk__")}
							</b>
						)}
						{post.accepted === null && (
							<Link to="/moderation/blog">
								<b className="blue ml-4">
									<FaExclamationTriangle className="mr-2" />
									{t("news.__moderationPending__")}
								</b>
							</Link>
						)}
						{post.accepted === false && (
							<span className="red">
								<FaTimesCircle className="mr-2 ml-4" />
								{t("news.__moderationRefused__")}
							</span>
						)}
						{post.like_counter > 0 ? (
							<span className="fake-link red">
								<FaHeart className="mr-2 ml-4" /> <b>{post.like_counter}</b>
							</span>
						) : (
							<span className="red">
								<FaRegHeart className="mr-2 ml-4" /> 0
							</span>
						)}
						{post.comment_counter > 0 ? (
							<span className="blue">
								<FaCommentAlt className="blue mr-2 ml-4" /> <b>{post.comment_counter}</b>
							</span>
						) : (
							<span className="blue">
								<FaRegCommentAlt className="mr-2 ml-4" /> 0
							</span>
						)}
					</div>
					{post.focused_on ? (
						<div className="mt-3">
							{post.seniors_focused_on.map(senior => (
								<span key={senior.id} className="msg-tag blue mr-1">
									{senior.last_name.toUpperCase()} {senior.first_name}
								</span>
							))}
						</div>
					) : null}
					<div className="light-separator"></div>
					<div className="post-content mt-4">{Parse(post.body)}</div>
					<div className="mt-5">{displayMedias(post.medias)}</div>
					{post.like_counter > 0 ? (
						<div className="mt-4 mb-5">
							<FaHeart className="mr-3 red" style={{ height: "20px", width: "auto" }} />
							{familyLikes.map(family => (
								<span key={family.id}>
									<span className={family.principal ? "msg-tag grey" : "msg-tag yellow"}>
										{family.last_name} {family.first_name}
									</span>
								</span>
							))}
						</div>
					) : (
						<br />
					)}
					{post.accepted ? (
						<>
							<hr />
							<div className="space"></div>
							<Comments
								postId={post.id}
								blogDispatch={blogDispatch}
								commentsCounter={post.comment_counter}
								userState={userState}
							/>
						</>
					) : null}
				</>
			) : null}
			<ModalContainer show={showRemoveConfirmation} setShow={setShowRemoveConfirmation}>
				<DisplayRemovePostModal
					show={showRemoveConfirmation}
					setShow={setShowRemoveConfirmation}
					blogDispatch={blogDispatch}
					redirection
				/>
			</ModalContainer>
		</>
	);
}
