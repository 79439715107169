import React from "react";
import { UserInterface } from "../interfaces/UserInterface";
import AccessControl from "../layout/AccessControl";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import AdminSecondaryNav from "../layout/AdminSecondaryNav";
import AddTenant from "../components/Admin/Tenants/AddTenant";
import * as p from "../constants/Permissions";

interface Props {
	userState: UserInterface;
}

const AdminAddTenant = ({ userState }: Props): JSX.Element => {
	return (
		<AccessControl userPermissions={userState.userConnected.permissions} permissionNeeded={[p.SUPER_ADMIN]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<AdminSecondaryNav userState={userState} />}
				component={<AddTenant userState={userState} />}
			/>
		</AccessControl>
	);
};
export default AdminAddTenant;
