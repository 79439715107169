const ErrorAndSuccessReducer = (state, action) => {
	switch (action.type) {
		case "LOADING":
			return {
				...state
			};
		case "DISPLAY_ERROR":
			return {
				error: true,
				success: false,
				msg: action.payload
			};

		case "DISPLAY_SUCCESS":
			return {
				error: false,
				success: true,
				msg: action.payload
			};

		case "HIDE_ERROR":
			return {
				...state,
				error: false,
				msg: null
			};

		case "HIDE_SUCCESS":
			return {
				...state,
				success: false,
				msg: null
			};

		case "RESET_ES":
			return {
				error: false,
				success: false,
				msg: null
			};

		default:
			return state;
	}
};

export default ErrorAndSuccessReducer;
