import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaInfoCircle, FaRegClock } from "react-icons/fa";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import { Link } from "react-router-dom";
import Parse from "html-react-parser";

export default function VisioTimingConstraints({ homeId }) {
	const { t } = useTranslation("common");
	const [displayInfo, setDisplayInfo] = useState(false);
	const [visioConstraints, setVisioConstraints] = useState({});
	const visioSchedule = visioConstraints ? (visioConstraints.validity ? visioConstraints.validity.values : null) : null;
	const visioScheduleOrdered = visioSchedule ? visioSchedule.sort((a, b) => a.day - b.day) : null;

	useEffect(() => {
		if (homeId) {
			http
				.get(`${url.HOMESURL}/${homeId}/visio/validity`)
				.then(res => {
					setVisioConstraints(res.data.home_visio_validity);
				})
				.catch(() => setVisioConstraints(null));
		}
	}, [homeId]);

	const handleDay = day => {
		switch (day) {
			case 1:
				return t("common.__monday__");
			case 2:
				return t("common.__tuesday__");
			case 3:
				return t("common.__wednesday__");
			case 4:
				return t("common.__thursday__");
			case 5:
				return t("common.__friday__");
			case 6:
				return t("common.__saturday__");
			case 7:
				return t("common.__sunday__");
			default:
				return;
		}
	};

	const handleMinutes = minute => {
		switch (minute) {
			case 0:
				return "00";
			case 1:
				return "01";
			case 2:
				return "02";
			case 3:
				return "03";
			case 4:
				return "04";
			case 5:
				return "05";
			case 6:
				return "06";
			case 7:
				return "07";
			case 8:
				return "08";
			case 9:
				return "09";
			default:
				return minute;
		}
	};

	return (
		<div className="row">
			<div className="col-6">
				<h2>{t("common.__visioTiming__")}</h2>
			</div>
			<div className="col-6">
				<Link to="/manage/recipients/updatevisioconfig">
					<button type="button" className="right btn btn-sm btn-outline-primary mt-1">
						{t("common.__update__")}
					</button>
				</Link>
			</div>
			<div className="col-10">
				<p className="meta mt-3">{t("home.__daysAndHoursOfVisio__")}</p>
			</div>
			<div className="col-2">
				<span className="right">
					<FaInfoCircle
						role="button"
						className="moderation-card-icon-no mt-2"
						onClick={() => setDisplayInfo(prevState => !prevState)}
					/>
				</span>
			</div>
			<div className="col-12">
				{displayInfo ? (
					<>
						<h4>{t("home.__whyVisioConstraints__")}</h4>
						<p>{t("home.__visioConstraintsWarning__")}</p>
						<img src="/img/visioschedule.png" alt="visio schedule display in Family App" className="img-fluid" />
					</>
				) : null}
			</div>
			<div className="col-12">
				{visioConstraints.description ? (
					<div className="header-list-items post-content">{Parse(visioConstraints.description)}</div>
				) : (
					<p className="small-list-items">
						<i>{t("home.__noVisioTimeConstraints__")}</i>
					</p>
				)}
				<div className="space"></div>
				{visioScheduleOrdered &&
					visioScheduleOrdered.map((visio, index) => (
						<div className="small-list-items" key={index}>
							<FaRegClock /> <b className="ml-2 mr-2">{handleDay(visio.day)} :</b>
							{visio.ranges.map((range, index) => (
								<span key={index}>
									{t("common.__begining__")} {range[0]}:{handleMinutes(range[1])} {t("common.__to__")} {range[2]}:{" "}
									{handleMinutes(range[3])}
								</span>
							))}
						</div>
					))}
			</div>
		</div>
	);
}
