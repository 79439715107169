import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { FaAngleDoubleDown, FaAngleDoubleRight, FaUsers, FaTimes } from "react-icons/fa";
import { FamilyInterface, SeniorInterface } from "../../interfaces/UserInterface";
import Select from "react-select";
import MyInputTextarea from "../../utils/Form/MyInputTextarea";
import MyInputText from "../../utils/Form/MyInputText";
import HandleError from "../../layout/HandleError";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

interface Props {
	seniorsFromHome: SeniorInterface[];
	families: any[];
	homeId: number;
}

interface InputProps {
	subject: string;
	message: string;
}

const NewMessage = ({ seniorsFromHome, families, homeId }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const history = useHistory();
	const methods = useForm<InputProps>();
	const { handleSubmit } = methods;
	const [recipientSelection, setRecipientSelection] = useState("all");
	const [selectedFamilies, setSelectedFamilies] = useState<any[]>([]);
	const [viewRecipients, setViewRecipients] = useState(false);
	const [seniors, setSeniors] = useState<{ value: number; label: string }[]>([]);
	const [recipientsError, setRecipientsError] = useState(false);
	const [error, setError] = useState(false);

	useEffect(() => {
		setSelectedFamilies(families);
	}, [families]);

	const onSubmit = (values: InputProps): void => {
		if (selectedFamilies.length > 0) {
			setRecipientsError(false);
			setError(false);
			const targets = selectedFamilies.map((elt: any) => {
				return {
					senior: {
						id: elt.senior_id,
						first_name: elt.senior_first_name,
						last_name: elt.senior_last_name
					},
					family_user: {
						id: elt.family_user_id,
						first_name: elt.family_user_first_name,
						last_name: elt.family_user_last_name
					}
				};
			});
			const data = {
				targets: targets,
				subject: values.subject,
				message: values.message,
				home_id: homeId
			};
			http
				.post(`${url.MESSAGES_URL}/multi/new`, data)
				.then(() => history.push("/msg/messagesbox"))
				.catch(() => setError(true));
		} else {
			setRecipientsError(true);
		}
	};

	useEffect(() => {
		const data = seniorsFromHome.map(senior => {
			return {
				value: senior.id,
				label: senior.name
			};
		});
		setSeniors(data);
	}, [seniorsFromHome]);

	const handleSenior = (e: any): void => {
		const ids = e.map((data: { value: number }) => data.value);
		handleRecipientsSelection("custom", ids);
	};

	const handleRecipientsSelection = (type: string, ids?: number[]): void => {
		switch (type) {
			case "all":
				setSelectedFamilies(families);
				setRecipientSelection("all");
				break;
			case "onlyPrincipals":
				setSelectedFamilies(families.filter((family: any) => family.family_user_is_principal));
				setRecipientSelection("onlyPrincipals");
				break;
			case "onlyAffiliates":
				setSelectedFamilies(families.filter((family: any) => !family.family_user_is_principal));
				setRecipientSelection("onlyAffiliates");
				break;
			case "custom":
				setSelectedFamilies(ids ? families.filter((family: any) => ids.includes(family.senior_id)) : []);
				setViewRecipients(true);
				setRecipientSelection("custom");
				break;

			default:
				setSelectedFamilies(families);
				setRecipientSelection("all");
		}
	};

	const removeFamily = (family: FamilyInterface): void => {
		const familiesList = selectedFamilies.filter(f => f !== family);
		setSelectedFamilies(familiesList);
	};

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<HandleError error={recipientsError} setError={setRecipientsError} message={t("mailbox.__recipientError__")} />
				<HandleError error={error} setError={setError} noSpace />

				<div className="row">
					<div className="col-12 mb-2">
						<label>{t("common.__recipients__")} :</label>
					</div>

					<div className="col-md-4">
						<input
							type="checkbox"
							checked={recipientSelection === "all"}
							onChange={(): void => handleRecipientsSelection("all")}
						/>
						<span className="ml-2">
							<b>{t("family.__allFamilies__")}</b>
						</span>
					</div>
					<div className="col-md-4">
						<input
							type="checkbox"
							checked={recipientSelection === "onlyPrincipals"}
							onChange={(): void => handleRecipientsSelection("onlyPrincipals")}
						/>
						<span className="ml-2">
							<b>{t("family.__principals__")}</b>
						</span>
					</div>
					<div className="col-md-4">
						<input
							type="checkbox"
							checked={recipientSelection === "onlyAffiliates"}
							onChange={(): void => handleRecipientsSelection("onlyAffiliates")}
						/>
						<span className="ml-2">
							<b>{t("family.__affiliates__")}</b>
						</span>
					</div>
					<div className="col-md-4 mt-3">
						<input
							type="checkbox"
							checked={recipientSelection === "custom"}
							onChange={(): void => handleRecipientsSelection("custom")}
						/>
						<span className="ml-2">
							<b>{t("mailbox.__searchBySenior__")} :</b>
						</span>
					</div>
					<div className="col-md-8 mt-3">
						<Select
							placeholder={t("senior.__selectSenior__")}
							isMulti
							name="seniors"
							options={seniors}
							className="basic-multi-select mb-3"
							classNamePrefix="select"
							onChange={(e: any): void => handleSenior(e)}
						/>
					</div>
				</div>

				<div className="header-details">
					<ul className="msg-recipients-list mt-1">
						{selectedFamilies.length > 0 ? (
							<>
								<div className="row">
									<div className="col-6">
										<p role="button" onClick={(): void => setViewRecipients(prevState => !prevState)}>
											<FaUsers style={{ width: "auto", height: "1.5rem", marginRight: "1rem" }} />
											<b>{selectedFamilies.length}</b> {t("family.__familiesSelected__")}
										</p>
									</div>
									<div className="col-6">
										{viewRecipients ? (
											<FaAngleDoubleDown
												className="right mr-2 table-icon"
												role="button"
												onClick={(): void => setViewRecipients(false)}
											/>
										) : (
											<FaAngleDoubleRight
												className="right mr-2 table-icon"
												role="button"
												onClick={(): void => setViewRecipients(true)}
											/>
										)}
									</div>
								</div>
								{viewRecipients
									? selectedFamilies.map((family, index) => (
											<li key={index}>
												<span
													className={
														!family.family_user_is_principal ? "msg-recipient-tag principal" : "msg-recipient-tag affiliate"
													}>
													<b>
														{family.family_user_last_name} {family.family_user_first_name}
													</b>{" "}
													-{" "}
													<i>
														{family.senior_id ? (
															<>
																{family.senior_last_name} {family.senior_first_name}
															</>
														) : null}
													</i>
													<FaTimes className="ml-3 msg-recipient-icon" role="button" onClick={(): void => removeFamily(family)} />
												</span>
											</li>
									  ))
									: null}
							</>
						) : (
							<p className="meta">
								<FaUsers style={{ width: "auto", height: "1.5rem", marginRight: "1rem" }} />
								{t("mailbox.__noRecipientSelected__")}
							</p>
						)}
					</ul>
				</div>

				<MyInputText label={t("mailbox.__object__")} name="subject" behavior={{ required: true }} />
				<MyInputTextarea label={t("common.__message__")} name="message" behavior={{ required: true }} rows="8" />

				<button type="submit" className="btn btn-primary mt-3 center">
					{t("common.__send__")}
				</button>
				<div className="space"></div>
			</form>
		</FormProvider>
	);
};
export default NewMessage;
