import React, { useEffect, useState } from "react";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { Link } from "react-router-dom";
import { getActivityAction } from "../../utils/Activities/Activities";
import { FaClipboard, FaRegClock, FaUsersCog, FaRegCalendarCheck } from "react-icons/fa";
import DisplayName from "../../utils/DisplayName";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

export default function OccupationContainer({ occupation, seniors, isPanel }) {
	const [event, setEvent] = useState(null);
	const [displayDetails, setDisplayDetails] = useState({
		show: false,
		id: null
	});

	const activeSeniorIds = seniors.map(senior => senior.id);
	const activeSeniorsIdsIn = occupation.senior_ids.map(id => (activeSeniorIds.includes(id) ? id : null));
	const activeSeniorsIn = activeSeniorsIdsIn.filter(id => id);

	useEffect(() => {
		if (occupation.home_calendar_entry_id) {
			http
				.get(`${url.BASEURL}/home_calendar_entries/${occupation.home_calendar_entry_id}`)
				.then(res => setEvent(res.data.calendar_entry));
		}
	}, [occupation.home_calendar_entry_id]);

	return (
		<>
			<div className="d-flex justify-content-between flow">
				<p className="blog-title">
					<FaRegCalendarCheck className="mr-3" />
					{getActivityAction(occupation.action_id)}
				</p>
				{activeSeniorsIn.length > 0 ? (
					<div
						className="occupation-toggle"
						role="button"
						onClick={() => setDisplayDetails({ show: !displayDetails.show, id: occupation.home_calendar_entry_id })}>
						<FaUsersCog className="flow-button" />
						<span className="meta">{activeSeniorsIn.length}</span>
					</div>
				) : null}
			</div>
			<div className="flow-block">
				<p className="meta">
					<FaRegClock className="nav-icon mr-2" />
					{handleDate(occupation.datetime, "Pp")}
					{event ? (
						<span className="ml-3">
							<strong>
								<FaClipboard className="nav-icon mr-2" />
								{event.info}
							</strong>
							{event.detail ? <> : {event.detail}</> : null}
						</span>
					) : null}
				</p>
				{displayDetails.show && displayDetails.id === occupation.home_calendar_entry_id ? (
					<div className="mt-3">
						{activeSeniorsIn.map(id =>
							isPanel ? (
								<b key={id} className="small-tag blue mr-2 mb-1">
									{DisplayName(id, seniors)}
								</b>
							) : (
								<Link to={`/senior/profile/${id}`} key={id} className="category-outline-badge blue mr-2">
									{DisplayName(id, seniors)}
								</Link>
							)
						)}
					</div>
				) : null}
			</div>
		</>
	);
}
