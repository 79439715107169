import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { FaRegTimesCircle } from "react-icons/fa";
import MyInputText from "../../../utils/Form/MyInputText";
import HeaderContainer from "../../../layout/HeaderContainer";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import HandleError from "../../../layout/HandleError";
import { useTranslation } from "react-i18next";
import * as p from "../../../constants/Permissions";
import Select from "react-select";

const EditHomeUnit = ({ store, dispatch, userState, activeUsersToSelect, homeUnitSupervisors, homeUnitDetails }) => {
	const { t } = useTranslation("common");
	const history = useHistory();
	const methods = useForm();
	const { handleSubmit } = methods;

	const [usersAvailables, setUsersAvailables] = useState(activeUsersToSelect);
	const [supervisorsList, setSupervisorsList] = useState([...homeUnitSupervisors]);
	const [newSupervisorsList, setNewSupervisorsList] = useState([]);

	const handleAddSupervisor = supervisor => {
		setNewSupervisorsList([...supervisor]);
	};

	const handleRemoveSupervisor = supervisor => {
		const supervisors = supervisorsList.filter(user => user.id !== supervisor.id);
		const supervisorsIds = supervisors.map(user => user.id);
		const deletedUser = {
			...supervisor,
			value: supervisor.id,
			label:
				supervisor.first_name === "badge_general" ? (
					`${supervisor.last_name} `
				) : (
					<>
						{supervisor.first_name} {supervisor.last_name.toUpperCase()}
					</>
				)
		};
		const data = {
			home_unit: {
				supervisor_ids: supervisorsIds
			}
		};
		http
			.put(`${url.BASEURL}/home_units/${homeUnitDetails.id}?supervisors=true`, data)
			.then(res => {
				dispatch({
					type: "EDIT_HOMEUNIT",
					payload: res.data.home_unit
				});

				setSupervisorsList(res.data.home_unit.supervisors);
				setUsersAvailables([...usersAvailables, deletedUser]);
			})
			.catch(() => {
				dispatch({
					type: "DISPLAY_ERROR"
				});
			});
	};

	const onSubmit = data => {
		const supervisorsIds = supervisorsList.map(user => user.id);
		const newSupervisorIds = newSupervisorsList.map(user => user.id);
		const ids = [...supervisorsIds, ...newSupervisorIds];
		const homeUnitData = {
			home_unit: {
				name: data.homeUnitName,
				supervisor_ids: ids
			}
		};
		http
			.put(`${url.BASEURL}/home_units/${homeUnitDetails.id}?supervisors=true`, homeUnitData)
			.then(res => {
				dispatch({
					type: "EDIT_HOMEUNIT",
					payload: res.data.home_unit
				});
				dispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("home.__editHomeUnitSuccess__", {
						name: res.data.home_unit.name
					})
				});
				history.push("/manage/hu/homeunits");
			})
			.catch(() => {
				dispatch({
					type: "DISPLAY_ERROR"
				});
			});
	};

	return (
		<div className="big-card">
			<HeaderContainer
				title={t("home.__homeUnits__")}
				addButton={t("home.__addHomeUnit__")}
				link={"/manage/hu/addhomeunit/add"}
				permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_HOME_UNIT]}
				userState={userState}
			/>
			<HandleError error={store.es.error} dispatch={dispatch} message={t("common.__editError__")} />

			<FormProvider {...methods}>
				<h5 className="blue">{t("home.__updateHomeUnit__")}</h5>
				<form onSubmit={handleSubmit(onSubmit)}>
					<MyInputText
						label={t("common.__name__")}
						name="homeUnitName"
						behavior={{ required: false }}
						value={homeUnitDetails.name}
					/>
					<h3>{t("home.__homeUnitManagers__")} :</h3>
					{supervisorsList.length > 0 ? (
						supervisorsList.map(supervisor => (
							<div key={supervisor.id} className="recipient-badge">
								<div className="d-flex justify-content-between">
									<p className="mt-1">
										<strong>
											{supervisor.first_name === "badge_general"
												? supervisor.last_name
												: `${supervisor.first_name} ${supervisor.last_name.toUpperCase()}`}
										</strong>
									</p>
									<FaRegTimesCircle
										className="table-icon mt-1"
										role="button"
										onClick={() => handleRemoveSupervisor(supervisor)}
									/>
								</div>
							</div>
						))
					) : (
						<p className="meta">{t("home.__noHomeUnitManager__")}</p>
					)}

					{usersAvailables.length > 0 ? (
						<div className="mt-4">
							<h3>{t("home.__addHomeUnitManager__")} :</h3>
							<Select
								placeholder={t("hds.__workerSelection__")}
								isMulti
								options={usersAvailables}
								className="react-select-container"
								classNamePrefix="react-select"
								onChange={e => handleAddSupervisor(e)}
								isClearable
								isSearchable={false}
								name="supervisors"
							/>
						</div>
					) : null}
					<div className="mt-4">
						<button className="btn btn-outline-secondary btn-sm mr-2" type="button" onClick={() => history.goBack()}>
							{t("common.__back__")}
						</button>
						<button className="btn btn-primary btn-sm" type="submit">
							{t("common.__save__")}
						</button>
					</div>
				</form>
			</FormProvider>
		</div>
	);
};

export default EditHomeUnit;
