import React from "react";
import { useTranslation } from "react-i18next";
import PieChartContainer from "../../../utils/PieChartContainer";

export default function DisplayPieChart({ display, data, title }) {
	const { t } = useTranslation("common");

	const hasValues = values => {
		return values.every(elt => elt.value > 0);
	};

	return display ? (
		<div className="col-6">
			<h5 className="center">{title}</h5>
			{hasValues(data) ? (
				<PieChartContainer data={data} dataKey="value" height={320} />
			) : (
				<p className="meta center">{t("common.__noElement__")}</p>
			)}
		</div>
	) : null;
}
