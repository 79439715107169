import React, { Dispatch } from "react";
import { useTranslation } from "react-i18next";
import { FaTimesCircle } from "react-icons/fa";

interface Props {
	error: boolean;
	message?: string | null;
	dispatch?: Dispatch<any>;
	setError?: (arg: boolean) => void;
	noSpace?: boolean;
}

const HandleError = ({ error, message, dispatch, setError, noSpace }: Props): JSX.Element | null => {
	const { t } = useTranslation("common");

	const closeError = (): void => {
		if (setError) {
			setError(false);
		} else if (dispatch) {
			dispatch({ type: "RESET_ES" });
		} else {
			return;
		}
	};

	return error ? (
		<div className="error-msg mt-2">
			<div className="d-flex justify-content-between">
				<p>
					<b>{t("common.__errorOccured__")}</b>
				</p>
				<FaTimesCircle className="table-icon" role="button" tabIndex={0} onClick={closeError} />
			</div>
			{message ? <p>{message}</p> : null}
		</div>
	) : noSpace ? null : (
		<br />
	);
};
export default HandleError;
