import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import VisioTimingDayConstraintForm from "./VisioTimingDayConstraintForm";
import { Link, useHistory } from "react-router-dom";
import SelectHomeInput from "../../../layout/SelectHomeInput";
import { FaInfoCircle, FaTimes } from "react-icons/fa";
import { wantedDays } from "../../HealthWorkers/Badges/ValidityTypes";
import { RichTextarea } from "../../News/RichEditor/RichTextarea";

export default function VisioTimingConstraintsUpdate({
	homeId,
	visioDescription,
	setVisioDescription,
	daysValues,
	setDaysValues,
	daysToDisplay,
	setDaysToDisplay,
	error,
	setError
}) {
	const { t } = useTranslation("common");
	const history = useHistory();
	const [updateError, setUpdateError] = useState(false);

	const onSubmit = e => {
		e.preventDefault();
		const data = {
			home_visio_validity: {
				validity: {
					type: 1,
					values: daysValues
				},
				description: visioDescription
			}
		};
		http
			.put(` ${url.HOMESURL}/${homeId}/visio/validity`, data)
			.then(() => {
				setUpdateError(false);
				history.push("/manage/recipients/visioconfig");
			})
			.catch(() => {
				window.scrollTo(0, 0);
				setUpdateError(true);
			});
	};

	return (
		<div className="big-card">
			<div className="row">
				<div className="col-6">
					<h2>{t("common.__visioTiming__")}</h2>
				</div>
				<div className="col-6">
					<Link to="/manage/recipients/visioconfig">
						<button type="button" className="right btn btn-sm btn-outline-secondary mt-1">
							{t("common.__back__")}
						</button>
					</Link>
				</div>
			</div>

			{homeId ? (
				error ? (
					<p className="error-msg mt-2">
						<span className="right" role="button" tabIndex="0" onClick={() => setError(false)}>
							<FaTimes />
						</span>
						{t("common.__errorOccured__")} {t("common.__noData__")}
					</p>
				) : (
					<form onSubmit={onSubmit}>
						<div className="header-details">
							<FaInfoCircle className="blue mr-1" /> {t("home.__howConstraintsAreDisplayed__")}
						</div>
						{updateError ? (
							<p className="error-msg mt-2">
								<span className="right" role="button" tabIndex="0" onClick={() => setUpdateError(false)}>
									<FaTimes />
								</span>
								{t("common.__updateError__")}
								<br />
								<b>{t("home.__visioDescriptionMissing__")}</b>
							</p>
						) : null}
						<b>{t("home.__describeConditions__")} :</b>
						{visioDescription ? (
							<RichTextarea initialContent={visioDescription} setPostContent={setVisioDescription} />
						) : null}
						<p>
							<b>{t("home.__selectVisioTimeConstraints__")}</b>
						</p>
						{wantedDays.map(day => (
							<VisioTimingDayConstraintForm
								day={day}
								key={day.id}
								daysValues={daysValues}
								setDaysValues={setDaysValues}
								daysToDisplay={daysToDisplay}
								setDaysToDisplay={setDaysToDisplay}
							/>
						))}
						<button type="submit" className="btn btn-sm btn-primary">
							{t("common.__save__")}
						</button>
					</form>
				)
			) : (
				<SelectHomeInput />
			)}
		</div>
	);
}
