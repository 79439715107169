import React from "react";
import { useTranslation } from "react-i18next";
import SelectHomeInput from "../../../layout/SelectHomeInput";
import HandleSuccess from "../../../layout/HandleSuccess";
import HeaderContainer from "../../../layout/HeaderContainer";
import HomeRulesSettings from "./HomeRulesSettings";
import * as p from "../../../constants/Permissions";

export default function HomeRulesContainer({ store, dispatch, homeId, userState }) {
	const { t } = useTranslation("common");
	const canManageRules = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_CHECKER_RULE]);

	return (
		<div className="big-card">
			<HeaderContainer
				title={t("notifications.__notificationsRules__")}
				addButton={t("rules.__addRule__")}
				link={"/manage/nr/rule/add"}
				permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_CHECKER_RULE]}
				userState={userState}
			/>

			<HandleSuccess success={store.es.success} dispatch={dispatch} message={store.es.msg} />
			{!homeId ? (
				<SelectHomeInput />
			) : (
				<HomeRulesSettings store={store} homeId={homeId} dispatch={dispatch} canManageRules={canManageRules} />
			)}
		</div>
	);
}
