import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaRegTimesCircle, FaRegEdit } from "react-icons/fa";
import CreateHealthWorkersType from "./CreateHealthWorkersType";
import DisplayHealthWorkersType from "./DisplayHealthWorkersType";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import HandleError from "../../../layout/HandleError";
import HandleSuccess from "../../../layout/HandleSuccess";

const HealthWorkersTypes = ({ store, dispatch }) => {
	const { t } = useTranslation("common");
	const [addMode, setAddMode] = useState(false);
	const [editMode, setEditMode] = useState({ edit: false, id: null });
	const healthWorkerTypes = store.users.healthWorkerTypes;

	const removeHealthWorkerType = id => {
		http
			.delete(`${url.BASEURL}/health_worker_types/${id}`)
			.then(() => {
				dispatch({
					type: "DELETE_HEALTHWORKER_TYPE",
					payload: id
				});
				dispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("healthworker.__removeSuccess__")
				});
			})
			.catch(() => {
				dispatch({
					type: "DISPLAY_ERROR",
					payload: t("common.__delError__")
				});
			});
	};

	const displayAddMode = boolean => {
		dispatch({ type: "RESET_ES" });
		setAddMode(boolean);
	};

	const displayEditMode = type => {
		dispatch({ type: "RESET_ES" });
		setEditMode({ edit: true, type: type });
	};

	return (
		<div className="big-card">
			<div className="row">
				<div className="col-md-6">
					<h2>{t("healthworker.__team__")}</h2>
				</div>
				<div className="col-md-6">
					{addMode ? (
						<button type="button" className="right btn btn-sm btn-outline-secondary" onClick={() => displayAddMode(false)}>
							{t("common.__back__")}
						</button>
					) : (
						<button type="button" className="right btn btn-sm btn-outline-primary" onClick={() => displayAddMode(true)}>
							{t("common.__add__")}
						</button>
					)}
				</div>
			</div>

			{addMode ? (
				<CreateHealthWorkersType setAddMode={setAddMode} dispatch={dispatch} store={store} />
			) : editMode.edit ? (
				<DisplayHealthWorkersType editMode={editMode} setEditMode={setEditMode} dispatch={dispatch} store={store} />
			) : (
				<>
					<HandleSuccess success={store.es.success} dispatch={dispatch} message={store.es.msg} />
					<HandleError error={store.es.error} dispatch={dispatch} message={store.es.msg} />

					{healthWorkerTypes.map(type => (
						<div className="small-list-items" key={type.id}>
							<div className="row">
								<div className="col-md-9">
									{type.name}
									<br />
									<span className="meta">{type.description}</span>
								</div>
								<div className="col-md-3">
									<div className="right mr-3">
										<FaRegEdit className="table-icon" role="button" onClick={() => displayEditMode(type)} />
										<FaRegTimesCircle className="table-icon" role="button" onClick={() => removeHealthWorkerType(type.id)} />
									</div>
								</div>
							</div>
						</div>
					))}
				</>
			)}
		</div>
	);
};

export default HealthWorkersTypes;
