import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BlogContext } from "../context/BlogContext";
import { UserInterface } from "../interfaces/UserInterface";
import { BlogTabs } from "../utils/Tabs/TabsToDisplay";
import useStoreData from "../hooks/useStoreData";
import AccessControl from "../layout/AccessControl";
import SelectHomeInput from "../layout/SelectHomeInput";
import BlogHeader from "../layout/BlogHeader";
import AddNews from "../components/News/AddNews";
import Tabs from "../utils/Tabs/Tabs";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const SocialNews_AddPost = ({ homeId, userState }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const { blogStore, blogDispatch } = useContext(BlogContext);
	const { store } = useStoreData({ userState, homeId });

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.HOME_BLOG_WRITER, p.CAN_POST_MODIFY_HOME_BLOG]}>
			<>
				<Tabs tabsToDisplay={BlogTabs} currentTab={"BLOG"} />
				<div className="big-card">
					<BlogHeader displayAddPostButton={false} title={t("news.__addNews__")} />
					{homeId ? (
						<AddNews
							homeId={homeId}
							blogStore={blogStore}
							blogDispatch={blogDispatch}
							seniorsFromHome={store.seniors.seniorsFromHome}
							homeUnits={store.home.homeUnits}
							isControlPanel={false}
						/>
					) : (
						<SelectHomeInput />
					)}
				</div>
			</>
		</AccessControl>
	);
};
export default SocialNews_AddPost;
