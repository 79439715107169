import React from "react";
import { UserInterface } from "../interfaces/UserInterface";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import HomesSecondaryNav from "../layout/HomesSecondaryNav";
import HomeUnit from "../components/Home/HomeUnit/HomeUnit";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const MyHome_HomeUnit = ({ homeId, userState }: Props): JSX.Element => {
	return (
		<PageWithSecondaryNavLayout
			secondaryNav={<HomesSecondaryNav userState={userState} />}
			component={<HomeUnit userState={userState} homeId={homeId} />}
		/>
	);
};
export default MyHome_HomeUnit;
