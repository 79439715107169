import React, { useEffect, useState, useContext } from "react";
import { HomeContext } from "../context/HomeContext";
import { UserInterface } from "../interfaces/UserInterface";
import { HomeStoreInterface } from "../interfaces/HomeInterface";
import DevicesList from "../components/Devices/DevicesList";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import AdminSecondaryNav from "../layout/AdminSecondaryNav";
import AccessControl from "../layout/AccessControl";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
	homeStore: HomeStoreInterface;
	setHomeId: (id: number) => void;
}

const Devices = ({ userState, homeId, setHomeId, homeStore }: Props): JSX.Element => {
	const { devicesStore, devicesDispatch } = useContext(HomeContext);
	const [deviceSNType, setDeviceSNType] = useState([]);
	const isAdminOrSuperAdmin = userState.userConnected.is_in([p.SUPER_ADMIN, p.ADMIN]);
	const allHomes = homeStore.home.allHomes;

	useEffect(() => {
		if (isAdminOrSuperAdmin) {
			http
				.get(`${url.BASEURL}/device_serial_number_types`)
				.then(res => setDeviceSNType(res.data.device_serial_number_types))
				.catch(() => setDeviceSNType([]));
		}
	}, [isAdminOrSuperAdmin]);

	useEffect(() => {
		return function cleanup(): void {
			devicesDispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	return (
		<AccessControl userPermissions={userState.userConnected.permissions} permissionNeeded={[p.ADMIN, p.SUPER_ADMIN]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<AdminSecondaryNav userState={userState} />}
				component={
					<DevicesList
						homeId={homeId}
						setHomeId={setHomeId}
						deviceSNType={deviceSNType}
						devicesStore={devicesStore}
						devicesDispatch={devicesDispatch}
						allHomes={allHomes}
					/>
				}
			/>
		</AccessControl>
	);
};
export default Devices;
