import React from "react";
import { useTranslation } from "react-i18next";
import { FaCheck, FaIdCardAlt } from "react-icons/fa";
import { badgeValidityType } from "../../../constants/BadgeValidityType";
import { handleValidityDetails } from "../../../utils/BadgeRestrictions";

export default function DisplayBadge({ badge, badgeSelected, setBadgeSelected, userState }) {
	const { t } = useTranslation("common");
	const userLocale = userState.userConnected.locale;

	const handleSelectedBadges = badge => {
		if (badgeSelected.includes(badge)) {
			const badges = badgeSelected.filter(id => id !== badge);
			setBadgeSelected(badges);
		} else {
			const badges = [...badgeSelected, badge];
			setBadgeSelected(badges);
		}
	};

	return (
		<div
			className={badgeSelected.includes(badge.id) ? "badge-card-selected" : "badge-card"}
			onClick={() => handleSelectedBadges(badge.id)}>
			<div className="row">
				<div className="col-md-9">
					<p>
						{badge.type_id === 1 && (
							<>
								<FaIdCardAlt className="mr-2" />
								<b>{t("badge.__pinCode__")} :</b> {badge.value}
							</>
						)}
						{badge.type_id === 2 && (
							<>
								<FaIdCardAlt className="mr-2" />
								<b>{t("badge.__qrCode__")} :</b> {badge.value}
							</>
						)}
						<br />
						{badgeValidityType(badge.validity.type)}{" "}
						{handleValidityDetails(badge.validity.type, badge.validity.values, userLocale)}
					</p>
				</div>
				<div className="col-md-3">
					<div className="right">
						<FaCheck className={badgeSelected.includes(badge.id) ? "blue" : "grey"} />
					</div>
				</div>
			</div>
		</div>
	);
}
