import React, { useEffect, useReducer } from "react";
import { useTranslation } from "react-i18next";
import Toggle from "../../../utils/Toggle";
import * as url from "../../../constants/Url";
import http from "../../../services/Interceptor";
import HandleError from "../../../layout/HandleError";
import SensorPreferencesReducer from "./SensorPreferencesReducer";
import NoData from "../../../layout/NoData";

export default function SeniorSensorPreferences({ senior }) {
	const { t } = useTranslation("common");

	const initialState = {
		sensorPreference: false,
		error: false
	};

	const [preferences, dispatch] = useReducer(SensorPreferencesReducer, initialState);

	useEffect(() => {
		if (senior.id) {
			http
				.get(`${url.SENIORSURL}/${senior.id}/device_config`)
				.then(res => {
					dispatch({
						type: "GET_PREFERENCE",
						payload: res.data.DIAPER_BLE_SENSOR
					});
				})
				.catch(() => {
					dispatch({
						type: "GET_PREFERENCE",
						payload: null
					});
				});
		}
	}, [senior.id]);

	const toggleSeniorSensor = (preference, seniorId) => {
		dispatch({
			type: "RESET_ES"
		});
		const data = {
			config: {
				DIAPER_BLE_SENSOR: preference
			}
		};
		http
			.put(`${url.SENIORSURL}/${seniorId}/device_config`, data)
			.then(res => {
				dispatch({
					type: "GET_PREFERENCE",
					payload: res.data.DIAPER_BLE_SENSOR
				});
			})
			.catch(() => {
				dispatch({
					type: "DISPLAY_ERROR"
				});
			});
	};

	return (
		<div className="mt-3">
			<HandleError error={preferences.error} dispatch={dispatch} message={t("common.__editError__")} noSpace />
			{preferences.sensorPreference !== null ? (
				<Toggle
					isToggle={preferences.sensorPreference}
					action={() => toggleSeniorSensor(!preferences.sensorPreference, senior.id)}
					textActif={senior.name}
					textInactif={senior.name}
					meta={false}
				/>
			) : (
				<NoData />
			)}
		</div>
	);
}
