import React from "react";
import JitsiMeet from "./JitsiMeet";
// import "./style.scss";
import { useParams } from "react-router-dom";
import ControlPanelLayout from "../../../layout/ControlPanelLayout";
import useStoreData from "../../../hooks/useStoreData";

export default function LiveMeeting({ userState, homeId, userDispatch }) {
	const params = useParams();
	const subject = params.subject;
	const roomName = params.room;
	const { dispatch } = useStoreData({ userState, homeId });

	return (
		<ControlPanelLayout userState={userState} homeId={homeId} userDispatch={userDispatch} dispatch={dispatch}>
			<div className="panel-container">
				<h3 className="center">{subject}</h3>
				<JitsiMeet roomName={roomName} subject={subject} />
			</div>
		</ControlPanelLayout>
	);
}
