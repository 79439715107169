import React, { useRef } from "react";
import useLazyLoading from "../hooks/useLazyLoading";

interface Props {
	path: string;
	display: string;
}

const LazyloadImg = ({ path, display }: Props): JSX.Element => {
	const imgRef = useRef(null);
	const [inView] = useLazyLoading(imgRef);

	return (
		<span ref={imgRef}>
			{inView ? (
				path ? (
					<img src={path} loading="lazy" className={display} alt="activities of the residents" />
				) : null
			) : null}
		</span>
	);
};
export default LazyloadImg;
