export interface PhotoFrameInterface {
	photoframe: { start: [number, number]; end: [number, number] }[];
	error: boolean;
	msg: string | null;
}

export interface PhotoFramePreferenceInterface {
	home: PhotoFrameInterface;
	seniorSelected: PhotoFrameInterface;
}

const PreferencesReducer = (state: PhotoFramePreferenceInterface, action: { type: string; payload?: any }): any => {
	switch (action.type) {
		case "GET_HOME_PHOTOFRAME_PREFERENCES":
			return {
				seniorSelected: { ...state.seniorSelected },
				home: {
					...state.home,
					photoframe: action.payload
				}
			};
		case "GET_SENIOR_PHOTOFRAME_PREFERENCES":
			return {
				home: { ...state.home },
				seniorSelected: {
					...state.seniorSelected,
					photoframe: action.payload
				}
			};
		case "REMOVE_HOME_PHOTOFRAME_PREFERENCE":
			return {
				seniorSelected: { ...state.seniorSelected },
				home: {
					...state.home,
					photoframe: action.payload,
					error: false
				}
			};
		case "REMOVE_SENIOR_PHOTOFRAME_PREFERENCE":
			return {
				home: { ...state.home },
				seniorSelected: {
					...state.seniorSelected,
					photoframe: action.payload,
					error: false
				}
			};
		case "ADD_HOME_PHOTOFRAME_PREFERENCE":
			return {
				seniorSelected: { ...state.seniorSelected },
				home: {
					...state.home,
					photoframe: action.payload,
					error: false
				}
			};
		case "ADD_SENIOR_PHOTOFRAME_PREFERENCE":
			return {
				home: { ...state.home },
				seniorSelected: {
					...state.seniorSelected,
					photoframe: action.payload,
					error: false
				}
			};
		case "DISPLAY_HOME_ERROR":
			return {
				seniorSelected: { ...state.seniorSelected },
				home: {
					...state.home,
					error: true
				}
			};
		case "DISPLAY_SENIOR_ERROR":
			return {
				home: { ...state.home },
				seniorSelected: {
					...state.seniorSelected,
					error: true
				}
			};
		case "RESET_ES":
			return {
				home: {
					...state.home,
					error: false
				},
				seniorSelected: {
					...state.seniorSelected,
					error: false
				}
			};

		default:
			return state;
	}
};

export default PreferencesReducer;
