import React from "react";
import { useTranslation } from "react-i18next";
import { FaCheck } from "react-icons/fa";
import { Tooltip } from "./Tooltip";

interface Props {
	markAsRead: (id: number) => void;
	id: number;
}

const MarkAsRead = ({ markAsRead, id }: Props): JSX.Element => {
	const { t } = useTranslation("common");

	return (
		<Tooltip text={t("mailbox.__msgRead__")}>
			<FaCheck className="notification-res blue" role="button" onClick={(): void => markAsRead(id)} />
		</Tooltip>
	);
};
export default MarkAsRead;
