import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { sub } from "date-fns";
import { FaAngleRight, FaRegCopy, FaCheck, FaMapPin } from "react-icons/fa";
import { copyToClipboard } from "../../utils/CopyToClipboard";
import { useTranslation } from "react-i18next";
import { fromToUrl, handleDate } from "@e4ia/e4link_modules.datetime";
import DisplayName from "../../utils/DisplayName";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import * as p from "../../constants/Permissions";

export default function SeniorMemoT({ senior, healthWorkers, startDate, endDate, userState }) {
	const { t } = useTranslation("common");
	const canReadAllMemo = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_READ_ALL_MEMOT]);
	const [seniorMemo, setSeniorMemo] = useState([]);
	const [copied, setCopied] = useState(0);
	const copyThisNote = (id, note) => {
		setCopied(id);
		copyToClipboard(note);
	};
	const today = new Date();
	const treeDaysBefore = sub(today, { days: 3 });

	useEffect(() => {
		const displayMemo = (seniorId, fromTo) => {
			if (seniorId) {
				if (canReadAllMemo) {
					http
						.get(`${url.SENIORSURL}/${seniorId}/memo_t_notes/${fromTo}`)
						.then(res => {
							const allMemo = res.data.memo_t_notes;
							const data = allMemo.map(function (memo) {
								return {
									...memo,
									healthworker: DisplayName(memo.health_worker_id, healthWorkers),
									copy: copied === memo.id ? true : false
								};
							});
							setSeniorMemo(data);
						})
						.catch(() => setSeniorMemo([]));
				} else {
					http
						.get(`${url.BASEURL}/me/seniors/${seniorId}/memo_t_notes/${fromTo}`)
						.then(res => {
							const allMemo = res.data.memo_t_notes;
							const data = allMemo.map(function (memo) {
								return {
									...memo,
									copy: copied === memo.id ? true : false
								};
							});
							setSeniorMemo(data);
						})
						.catch(() => setSeniorMemo([]));
				}
			}
		};

		if (startDate && endDate) {
			const fromTo = fromToUrl(startDate, endDate);
			displayMemo(senior.id, fromTo);
		} else {
			const fromTo = fromToUrl(treeDaysBefore, today);
			displayMemo(senior.id, fromTo);
		}
		// eslint-disable-next-line
	}, [senior.id, startDate, endDate, copied, healthWorkers]);

	return seniorMemo.length > 0 ? (
		<>
			{seniorMemo.map(memo => (
				<div key={memo.id} className="mt-2 background-white row">
					<div className="col-10">
						{canReadAllMemo ? (
							<Link to={`/user/healthworker/${memo.health_worker_id}`}>
								<span className="category-outline-badge grey mr-2">
									<b>{memo.healthworker}</b>
								</span>
							</Link>
						) : null}
						<span className="meta">{handleDate(memo.date, "PPPp", userState.userConnected.locale)}</span>
						<p className="mt-2">
							<FaMapPin className="mr-2" /> {memo.note}
						</p>
					</div>
					<div className="col-2">
						<div className="right">
							{memo.copy ? (
								<span className="category-badge green">
									<FaCheck /> {t("memo.__memoCopied__")}
								</span>
							) : (
								<button className="category-outline-badge grey" onClick={() => copyThisNote(memo.id, memo.note)} type="button">
									<FaRegCopy /> {t("memo.__memoCopy__")}
								</button>
							)}
						</div>
					</div>
				</div>
			))}
		</>
	) : (
		<p className="meta">
			<FaAngleRight /> {t("memo.__noMemo__")}
		</p>
	);
}
