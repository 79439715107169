import { sub } from "date-fns";
import React from "react";
import DatePicker from "react-datepicker";
import HandleLocale from "../HandleLocale";

interface Props {
	userLocale: string;
	startDate: Date;
	setStartDate?: (date: Date) => void;
	dispatch?: React.Dispatch<any>;
	type?: string;
	filter?: string;
}

const OnlyDatePicker = ({ startDate, setStartDate, userLocale, dispatch, type, filter }: Props): JSX.Element => {
	const locale = HandleLocale(userLocale);

	return (
		<div className="mb-3">
			<DatePicker
				selected={startDate}
				onChange={(date: Date): void =>
					setStartDate
						? setStartDate(date)
						: type === "END"
						? dispatch && dispatch({ type: "END_DATE", payload: date })
						: dispatch && dispatch({ type: "START_DATE", payload: date })
				}
				locale={locale}
				dateFormat="PPPP"
				className="form-control form-control-sm"
				maxDate={filter && filter === "BEFORE_TODAY" ? sub(new Date(), { days: 1 }) : null}
			/>
		</div>
	);
};
export default OnlyDatePicker;
