import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { NotificationsContext } from "../context/NotificationsContext";
import { handleButtons } from "../constants/TypesConstants";
import { MailBoxTabs } from "../utils/Tabs/TabsToDisplay";
import { UserInterface } from "../interfaces/UserInterface";
import useDateBeforeOrAfter from "../hooks/useDateBeforeOrAfter";
import AccessControl from "../layout/AccessControl";
import HandleMessages from "../components/Messages/HandleMessages";
import SendResponse from "../components/Messages/SendResponse";
import RangePicker from "../utils/DatePicker/RangePicker";
import Tabs from "../utils/Tabs/Tabs";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const MessagesBox = ({ userState, homeId }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const canInitiateMessagesToFamily = userState.userConnected.is_in([
		p.CAN_INITIATE_MESSAGES_TO_FAMILY,
		p.SUPER_ADMIN,
		p.ADMIN
	]);
	// Check if the user want to access MAILBOX or a SINGLE MSG
	const params = useParams<{ mode?: string }>();
	const mailbox = params.mode ? false : true;
	const { notificationsDispatch, msgState, msgDispatch } = useContext(NotificationsContext);

	// Initialize dates to the last 30 days to display Messages List & set it in the context
	// User can switch to Selected Message and keep the latest dates selected
	const today = new Date();
	const last30Days = useDateBeforeOrAfter({ isBefore: true, numberOfDays: 30 });
	const [startDate, setStartDate] = useState(msgState.startDate ? msgState.startDate : last30Days);
	const [endDate, setEndDate] = useState(msgState.endDate ? msgState.endDate : today);

	useEffect(() => {
		if (startDate) {
			msgDispatch({
				type: "MSG_STARTDATE",
				payload: startDate
			});
		}
	}, [startDate]);

	useEffect(() => {
		if (endDate) {
			msgDispatch({
				type: "MSG_ENDDATE",
				payload: endDate
			});
		}
	}, [endDate]);

	useEffect(() => {
		return function cleanup(): void {
			msgDispatch({
				type: "RESET_ES"
			});
		};
	}, []);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.MY_FAMILY_USER_MESSAGES, p.ALL_FAMILY_USER_MESSAGES, p.SUPER_ADMIN, p.ADMIN]}>
			{canInitiateMessagesToFamily ? <Tabs tabsToDisplay={MailBoxTabs} currentTab={"MESSAGES"} /> : null}
			<div className="big-card">
				{mailbox ? (
					<>
						<div className="row mt-2">
							<div className="col-md-6">
								<div className="btn-group" role="group" aria-label="Message Box Filters">
									{handleButtons(msgState, msgDispatch, t)}
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-row right">
									<RangePicker
										userLocale={userState.userConnected.locale}
										startDate={startDate}
										setStartDate={setStartDate}
										endDate={endDate}
										setEndDate={setEndDate}
									/>
								</div>
							</div>
						</div>
						<HandleMessages
							userState={userState}
							homeId={homeId}
							startDate={startDate}
							endDate={endDate}
							notificationsDispatch={notificationsDispatch}
							msgDispatch={msgDispatch}
							msgState={msgState}
						/>
					</>
				) : (
					<SendResponse
						params={params}
						notificationsDispatch={notificationsDispatch}
						userState={userState}
						msgDispatch={msgDispatch}
						msgState={msgState}
					/>
				)}
			</div>
		</AccessControl>
	);
};
export default MessagesBox;
