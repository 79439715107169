import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fromToUrl } from "@e4ia/e4link_modules.datetime";
import PieChartContainer from "../../utils/PieChartContainer";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

interface Props {
	startDate: Date;
	endDate: Date;
	homeId: number | null;
}

const VisioStatistics = ({ startDate, endDate, homeId }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const [visioStats, setVisioStats] = useState([]);

	useEffect(() => {
		if (homeId) {
			const fromTo = fromToUrl(startDate, endDate);

			http.get(`${url.HOMESURL}/${homeId}/stats/visio/${fromTo}`).then(res => {
				const data = res.data.visio;
				const visioData: any = [
					{
						name: t("mailbox.__vAccepted__"),
						value: data.accepted
					},
					{
						name: t("mailbox.__vCanceled__"),
						value: data.canceled
					},
					{
						name: t("mailbox.__vPending__"),
						value: data.pending
					},
					{
						name: t("mailbox.__vRefused__"),
						value: data.rejected
					}
				];
				if (visioData.every((item: any) => item.value === 0)) {
					setVisioStats([]);
				} else {
					setVisioStats(visioData);
				}
			});
		}
		// eslint-disable-next-line
	}, [startDate, endDate, homeId]);

	return (
		<div className="col">
			<p>
				<b>{t("homeBoard.__visioStats__")}</b>
			</p>
			{visioStats.length > 0 ? (
				<PieChartContainer data={visioStats} dataKey="value" height={320} />
			) : (
				<>
					<div className="light-separator"></div>
					<p className="meta">
						{t("common.__noElement__")} {t("common.__selectNewDate__")}
					</p>
				</>
			)}
		</div>
	);
};
export default VisioStatistics;
