import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { FaRegEdit, FaTimes } from "react-icons/fa";
import MyInputNumber from "../../utils/Form/MyInputNumber";
import DisplayName from "../../utils/DisplayName";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

export default function EditHealtWorkerTypeBudget({
	setUpdate,
	setHttpResponse,
	healthWorkerType,
	hwTypeId,
	hwType,
	care,
	accomodation,
	dependency
}) {
	const { t } = useTranslation("common");
	const methods = useForm();
	const { handleSubmit } = methods;
	const [calculError, setCalculError] = useState(false);
	const [editHwBudget, setEditHwBudget] = useState(false);

	const onSubmit = data => {
		setHttpResponse(null);
		if (parseInt(data.care) + parseInt(data.dependency) + parseInt(data.accomodation) === 100) {
			setCalculError(false);
			const budgetData = {
				health_worker_budget_distribution: {
					care: data.care,
					dependency: data.dependency,
					accomodation: data.accomodation
				}
			};
			http
				.put(`${url.BASEURL}/health_worker_budget_distributions/${hwTypeId}`, budgetData)
				.then(() => {
					setUpdate(prevUpdate => !prevUpdate);
					setHttpResponse({ error: false });
					setEditHwBudget(false);
				})
				.catch(() => setHttpResponse({ error: true }));
		} else {
			setCalculError(true);
		}
	};

	return (
		<>
			{calculError ? (
				<p className="error-msg">
					{t("hds.__caution100__")}
					<span className="right" role="button" tabIndex="0" onClick={() => setCalculError(false)}>
						<FaTimes />
					</span>
				</p>
			) : null}

			{editHwBudget ? (
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row">
							<div className="col-md-3">
								<p>
									<b>{DisplayName(hwType, healthWorkerType)}</b>
								</p>
							</div>
							<div className="col-md-9">
								<div className="row">
									<div className="col-md-3">
										<MyInputNumber
											value={accomodation === 0 ? "0" : accomodation}
											id={`accomodation${hwType}`}
											name="accomodation"
											behavior={{ maxLength: 3, pattern: /[0-9]/ }}
										/>
									</div>
									<div className="col-md-3">
										<MyInputNumber
											value={dependency === 0 ? "0" : dependency}
											id={`dependency${hwType}`}
											name="dependency"
											behavior={{ maxLength: 3, pattern: /[0-9]/ }}
										/>
									</div>
									<div className="col-md-3">
										<MyInputNumber
											value={care === 0 ? "0" : care}
											id={`care${hwType}`}
											name="care"
											behavior={{ maxLength: 3, pattern: /[0-9]/ }}
										/>
									</div>
									<div className="col-md-3">
										<button className="btn btn-sm btn-primary" type="submit">
											{t("common.__save__")}
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</FormProvider>
			) : (
				<div className="row">
					<div className="col-md-3">
						<p>
							<b>{DisplayName(hwType, healthWorkerType)}</b>
						</p>
					</div>
					<div className="col-md-9">
						<div className="row">
							<div className="col-md-3">
								<div className="budget-card">{accomodation} %</div>
							</div>
							<div className="col-md-3">
								<div className="budget-card">{dependency} %</div>
							</div>
							<div className="col-md-3">
								<div className="budget-card">{care} %</div>
							</div>
							<div className="col-md-3">
								<div type="button" className="mt-1" onClick={() => setEditHwBudget(true)}>
									<FaRegEdit className="table-icon" />
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}
