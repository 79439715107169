import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
	FaRegTimesCircle,
	FaCheckCircle,
	FaUserTag,
	FaAngleDoubleUp,
	FaAngleDoubleDown,
	FaAngleRight
} from "react-icons/fa";
import NoData from "../../layout/NoData";
import * as API from "../../services/API";
import DisplayName from "../../utils/DisplayName";

export default function LifestoriesModeration({ seniorsFromHome, homeId, notificationsDispatch }) {
	const { t } = useTranslation("common");
	const [displayStories, setDisplayStories] = useState(false);
	const [lifestories, setLifestories] = useState([]);
	const [error, setError] = useState(false);
	const [update, setUpdate] = useState(false);

	useEffect(() => {
		if (homeId) {
			API.getLifestoriesModeration(
				homeId,
				res => setLifestories(res),
				() => setError(true)
			);
		}
	}, [homeId, update]);

	const lifestoryValidation = (avatarId, boolean) => {
		const data = {
			accepted: boolean
		};
		API.lifestoriesModeration(
			avatarId,
			data,
			() => {
				setUpdate(prevUpdate => !prevUpdate);
				notificationsDispatch({
					type: "MODERATE_LIFESTORY"
				});
			},
			() => {
				notificationsDispatch({
					type: "MODERATION_ERROR"
				});
			}
		);
	};

	return (
		<>
			<div className="row">
				<div className="col-2">
					<FaUserTag className={`${displayStories ? "blue" : " "} + moderation-icon ml-2`} />
				</div>
				<div className="col-8">
					<h5>{t("moderation.__bios__")}</h5>
					{lifestories.length > 0 ? (
						<p className="meta">
							{t("moderation.__moderationCounter__")} :<b className="blue"> {lifestories.length}</b>
						</p>
					) : (
						<p className="meta">{t("moderation.__noModerationPending__")}</p>
					)}
				</div>
				<div className="col-2">
					{lifestories.length > 0 ? (
						displayStories ? (
							<FaAngleDoubleUp className="family-icon right" onClick={() => setDisplayStories(false)} />
						) : (
							<FaAngleDoubleDown className="family-icon right" onClick={() => setDisplayStories(true)} />
						)
					) : null}
				</div>
			</div>
			{displayStories ? (
				error ? (
					<NoData />
				) : lifestories.length > 0 ? (
					<>
						<div className="space"></div>
						<div className="row moderation-header-details">
							<div className="col-md-9">
								<p>
									<b>{t("moderation.__bioToModerate__")}</b>
								</p>
							</div>
							<div className="col-md-3 center">
								<p>
									<b>{t("moderation.__moderation__")}</b>
								</p>
							</div>
						</div>
						{lifestories.map((lifestory, index) => (
							<div className="row moderation-body-details" key={index}>
								<div className="col-md-9">
									<p className="meta">
										{t("common.__senior__")} : {DisplayName(lifestory.senior_id, seniorsFromHome)}
									</p>
									<p>
										<FaAngleRight /> {lifestory.life_story}
									</p>
								</div>
								<div className="col-md-3 center">
									<FaRegTimesCircle
										className="moderation-card-icon-no"
										role="button"
										onClick={() => lifestoryValidation(lifestory.id, false)}
									/>
									<FaCheckCircle
										className="moderation-card-icon-ok"
										role="button"
										onClick={() => lifestoryValidation(lifestory.id, true)}
									/>
								</div>
							</div>
						))}
					</>
				) : null
			) : null}
		</>
	);
}
