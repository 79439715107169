import React from "react";
import { useTranslation } from "react-i18next";

const DeviceDetails = ({ show, devices }) => {
	const { t } = useTranslation("common");
	const deviceId = show.info;
	const oneDevice = devices.find(device => deviceId === device.id);

	return (
		<div className="mt-3">
			<p>
				{t("common.__type__")} : {oneDevice.serial_number_type}
			</p>
			<p>
				{t("common.__home__")} : {oneDevice.home}
			</p>
			<p>
				{t("device.__serialNumber__")} : <b>{oneDevice.serial_number}</b>
			</p>
		</div>
	);
};

export default DeviceDetails;
