import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { HealthWorkersTabs } from "../utils/Tabs/TabsToDisplay";
import { UserInterface } from "../interfaces/UserInterface";
import AccessControl from "../layout/AccessControl";
import HeaderContainer from "../layout/HeaderContainer";
import HealthWorkerActivities from "../components/HealthWorkers/Activities/HealthWorkerActivities";
import Tabs from "../utils/Tabs/Tabs";
import * as p from "../constants/Permissions";

interface Props {
	userState: UserInterface;
}

const HealthWorkerTimeline = ({ userState }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const { id } = useParams<{ id: string }>();

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_READ_ACTIVITIES]}>
			<Tabs tabsToDisplay={HealthWorkersTabs} currentTab={"HEALTHWORKERS"} />
			<div className="big-card">
				<HeaderContainer
					title={t("healthworker.__team__")}
					addButton={t("healthworker.__addAccount__")}
					link={"/user/adduser/newuser"}
					permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_USER_AND_BADGE]}
					userState={userState}
				/>
				<HealthWorkerActivities hwId={parseInt(id)} userState={userState} />
			</div>
		</AccessControl>
	);
};
export default HealthWorkerTimeline;
