import React from "react";
import { useTranslation } from "react-i18next";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

export default function ChangeStatisticsBarChart({ barChartStats }) {
	const { t } = useTranslation("common");
	// const renderLabel = props => {
	// 	const { x, y, width, value } = props;
	// 	return value > 0 ? (
	// 		<text x={x + width / 2} y={y} dy={-4} fontSize="12" fontFamily="sans-serif" fill="#778899" textAnchor="middle">
	// 			{value}%
	// 		</text>
	// 	) : null;
	// };

	return (
		<ResponsiveContainer width={"100%"} height={400}>
			<BarChart
				data={barChartStats}
				margin={{
					top: 20,
					right: 0,
					left: 0,
					bottom: 20
				}}
				barSize={25}
			>
				<CartesianGrid strokeDasharray="5 5" />
				<XAxis dataKey="name" />
				<YAxis />
				<Tooltip />
				<Legend verticalAlign="top" height={50} />

				<Bar dataKey={"yes"} stackId="a" fill="#00a0e3" name={t("common.__yes__")} fillOpacity={0.85} />
				<Bar dataKey={"no"} stackId="a" fill="#fa5456" name={t("common.__no__")} fillOpacity={0.85} />
				<Bar dataKey={"half"} stackId="a" fill="#50c878" name={t("diaper.__half__")} fillOpacity={0.85} />
				{/* <Bar dataKey={"noAnswer"} stackId="a" fill="#ffae42" name={t("diaper.__undefined__")} /> */}
			</BarChart>
		</ResponsiveContainer>
	);
}
