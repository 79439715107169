import React, { createContext, useReducer } from "react";
import DiaperReducer from "./reducers/DiaperReducer";

const initialState = {
	loading: false,
	diapers: [],
	homeDiapersList: [],
	sensors: [],
	seniors: [],
	error: false,
	success: false,
	msg: null,
	hasAmdGoals: null
};

export const DiaperContext = createContext();

export const DiaperContextProvider = ({ children }) => {
	const [diaperStore, diaperDispatch] = useReducer(DiaperReducer, initialState);

	return (
		<DiaperContext.Provider
			value={{
				diaperStore,
				diaperDispatch
			}}>
			{children}
		</DiaperContext.Provider>
	);
};

export const DiaperContextConsumer = DiaperContext.Consumer;
