import React from "react";
import { Link } from "react-router-dom";
import DeviceIcon from "../Buttons/DeviceIcon";
import TimelineIcon from "../Buttons/TimelineIcon";
import SeniorRemoveIcon from "../Buttons/SeniorRemoveIcon";
import SeniorMailboxIcon from "../Buttons/SeniorMailboxIcon";
import SeniorVisioIcon from "../Buttons/SeniorVisioIcon";
import SeniorMemoIcon from "../Buttons/SeniorMemoIcon";
import SeniorAssignToRoomIcon from "../Buttons/SeniorAssignToRoomIcon";
import ModalContainer from "../../utils/ModalContainer";
import AddSeniorInARoom from "./AddSeniorInARoom";
import HandleDeviceInRoom from "../Devices/HandleDeviceInRoom";
import RemoveSeniorConfirmation from "./RemoveSeniorConfirmation";
import ModalSeniorMemoT from "../MemoT/ModalSeniorMemoT";
import SelectedFamilyVisioToCome from "../Messages/SelectedFamilyVisioToCome";
import * as p from "../../constants/Permissions";
import "./MainBoard.scss";

export default function MainBoardContainer({
	mainBoard,
	show,
	setShow,
	showAddSeniorModal,
	setShowAddSeniorModal,
	homeId,
	store,
	dispatch,
	notesShow,
	setNotesShow,
	userState,
	showDevice,
	setShowDevice,
	showNextVisio,
	setShowNextVisio
}) {
	const canManageHomeUnit = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_HOME_UNIT]);
	const canHandleMailbox = userState.userConnected.is_in([
		p.ADMIN,
		p.SUPER_ADMIN,
		p.ALL_FAMILY_USER_MESSAGES,
		p.MY_FAMILY_USER_MESSAGES
	]);
	const canReadTimeline = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_READ_ACTIVITIES]);

	return (
		<>
			{mainBoard.map(homeUnit => (
				<div key={homeUnit.id} id={homeUnit.id} className="mainboard">
					<div className="d-flex justify-content-between">
						<h3>{homeUnit.name}</h3>
						<p>
							{homeUnit.supervisors.map(supervisor => (
								<Link
									to={`/user/healthworker/${supervisor.id}`}
									key={supervisor.id}
									className="category-outline-badge grey mt-2">
									{supervisor.first_name === "badge_general" ? (
										supervisor.last_name
									) : (
										<>
											{supervisor.first_name} {supervisor.last_name}
										</>
									)}
								</Link>
							))}
						</p>
					</div>

					<div className="row">
						{homeUnit.sortedRooms.map(room =>
							room.senior_id ? (
								<div className="board-card" key={room.id}>
									<Link to={`/manage/room/details/${room.id}`}>
										<h3 className="center room-name">{room.name}</h3>
									</Link>
									<div className="board-card-body">
										<Link to={`/senior/profile/${room.senior.id}`}>
											<p className="center">
												{room.senior.last_name.toUpperCase()} {room.senior.first_name}
											</p>
										</Link>
									</div>
									<div className="board-card-footer center">
										<DeviceIcon room={room} canManageHomeUnit={canManageHomeUnit} setShowDevice={setShowDevice} />
										<SeniorMemoIcon senior={room.senior} setNotesShow={setNotesShow} />
										{canReadTimeline ? <TimelineIcon type="SENIOR" id={room.senior.id} /> : null}
										{canHandleMailbox ? (
											<>
												<SeniorMailboxIcon senior={room.senior} unreadCounter={room.senior.unread_message_count} />
												<SeniorVisioIcon
													senior={room.senior}
													unreadCounter={room.senior.visio_count}
													setShowNextVisio={setShowNextVisio}
												/>
											</>
										) : null}
										{canManageHomeUnit ? <SeniorRemoveIcon setShow={setShow} room={room} /> : null}
									</div>
								</div>
							) : (
								<div className="p-2 board-card-empty" key={room.id}>
									<Link to={`/manage/room/details/${room.id}`}>
										<h3 className="center room-name">{room.name}</h3>
									</Link>
									<div className="board-card-footer center">
										<DeviceIcon room={room} canManageHomeUnit={canManageHomeUnit} setShowDevice={setShowDevice} />
										{canManageHomeUnit ? (
											<SeniorAssignToRoomIcon setShowAddSeniorModal={setShowAddSeniorModal} room={room} homeId={homeId} />
										) : null}
									</div>
								</div>
							)
						)}
					</div>
					<div className="light-separator"></div>
				</div>
			))}
			<ModalContainer show={notesShow} setShow={setNotesShow}>
				<ModalSeniorMemoT show={notesShow} homeId={homeId} userState={userState} />
			</ModalContainer>
			<ModalContainer show={showAddSeniorModal} setShow={setShowAddSeniorModal}>
				<AddSeniorInARoom show={showAddSeniorModal} setShow={setShowAddSeniorModal} store={store} dispatch={dispatch} />
			</ModalContainer>
			<ModalContainer show={showDevice} setShow={setShowDevice}>
				<HandleDeviceInRoom show={showDevice} setShow={setShowDevice} store={store} dispatch={dispatch} homeId={homeId} />
			</ModalContainer>
			<ModalContainer show={show} setShow={setShow}>
				<RemoveSeniorConfirmation show={show} setShow={setShow} dispatch={dispatch} store={store} />
			</ModalContainer>
			<ModalContainer show={showNextVisio} setShow={setShowNextVisio}>
				<SelectedFamilyVisioToCome show={showNextVisio} userState={userState} />
			</ModalContainer>
		</>
	);
}
