import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaBell } from "react-icons/fa";
import useStoreData from "../../../hooks/useStoreData";
import AlertCards from "./AlertCards";
import SelectHomeInput from "../../../layout/SelectHomeInput";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import Loader from "../../../layout/Loader";
import NoData from "../../../layout/NoData";

export default function DiaperCards({ refetch, diaperStore, diaperDispatch, userState, homeId, isDiaperManager }) {
	const { t } = useTranslation("common");
	const { store } = useStoreData({ userState, homeId });
	const [showDiaperChange, setShowDiaperChange] = useState({
		show: false,
		info: { senior: null },
		title: t("diaper.__change__"),
		success: null
	});
	const [showRemoveSensor, setShowRemoveSensor] = useState({
		show: false,
		info: { senior: null },
		title: t("diaper.__sensor__"),
		success: null
	});
	const [showAffectSensor, setShowAffectSensor] = useState({
		show: false,
		info: { senior: null },
		title: t("diaper.__affectSensor__"),
		success: false
	});
	const [update, setUpdate] = useState(false);

	const homeUnits = store.home.homeUnits;
	const rooms = store.home.rooms;
	const allFloors = rooms.map(room => room.floor);
	const allFloorsValues = allFloors.filter(floor => floor !== null);
	const floorsList = [...new Set(allFloorsValues)].sort((a, b) => a - b);
	const floors = floorsList.map(floor => {
		return { id: floor, name: floor === 0 ? t("common.__rdc__") : floor };
	});

	const diapers = diaperStore.diapers;
	const seniors = diaperStore.seniors;
	const alerts = seniors.length > 0 ? seniors.filter(data => data.alert) : [];

	useEffect(() => {
		if (diapers.length === 0) {
			http.get(`${url.BASEURL}/rht/diapers`).then(res => {
				diaperDispatch({
					type: "GET_DIAPERS",
					payload: res.data.diapers
				});
			});
		}
		// eslint-disable-next-line
	}, [diapers, homeId]);

	useEffect(() => {
		if (homeId && isDiaperManager && diaperStore.sensors.length === 0) {
			http.get(`${url.HOMESURL}/${homeId}/rht/diaper_sensors`).then(res => {
				diaperDispatch({
					type: "GET_SENSORS",
					payload: res.data.diaper_sensors
				});
			});
		}
		// eslint-disable-next-line
	}, [refetch, homeId, isDiaperManager, update]);

	useEffect(() => {
		window.scrollTo(0, 0);
		if (isDiaperManager && homeId) {
			diaperDispatch({ type: "LOADING" });
			http
				.get(`${url.HOMESURL}/${homeId}/rht/all/for/seniors`)
				.then(res => {
					diaperDispatch({
						type: "GET_SENIORS_INCO",
						payload: res.data.seniors
					});
				})
				.catch(() => {
					diaperDispatch({
						type: "GET_SENIORS_INCO",
						payload: []
					});
				});
		}
		// eslint-disable-next-line
	}, [refetch, homeId, isDiaperManager, update]);

	return (
		<div className="big-card">
			<div className="row">
				<div className="col-6">
					<h2>{t("diaper.__seniorMonitoring__")}</h2>
				</div>
				<div className="col-6">
					<span className={`${alerts.length > 0 ? "red" : "grey"} right mt-2`}>
						<FaBell className="mr-2" style={{ width: "auto", height: "1.25rem" }} />
						<b>{alerts.length}</b>
					</span>
				</div>
			</div>
			{homeId ? (
				diaperStore.loading ? (
					<Loader />
				) : seniors.length > 0 ? (
					<AlertCards
						showDiaperChange={showDiaperChange}
						setShowDiaperChange={setShowDiaperChange}
						showRemoveSensor={showRemoveSensor}
						setShowRemoveSensor={setShowRemoveSensor}
						showAffectSensor={showAffectSensor}
						setShowAffectSensor={setShowAffectSensor}
						userState={userState}
						diaperStore={diaperStore}
						homeUnits={homeUnits}
						floors={floors}
						alerts={alerts}
						setUpdate={setUpdate}
					/>
				) : (
					<NoData />
				)
			) : (
				<SelectHomeInput />
			)}
		</div>
	);
}
