import i18n from "../../../i18n/i18next";

export const WHEN_ACTION_HAPPEN = 1;
export const IF_ACTION_REPEATED = 2;
export const ACTION_MUST_BE_DONE_IN_RANGE = 3;
export const ACTION_MUST_NOT_BE_DONE_IN_RANGE = 4;
export const ACTION_MUST_BE_DONE_AFTER_THIS_ACTION = 5;
export const ACTION_MUST_NOT_BE_DONE_IN_RANGE_OTHERWISE = 6;

export const nutrition = [
	{
		id: 103,
		name: i18n.t("common:rules.__breakfastBad__")
	},
	{
		id: 113,
		name: i18n.t("common:rules.__lunchBad__")
	},
	{
		id: 133,
		name: i18n.t("common:rules.__dinnerBad__")
	}
];

export const distribution = [
	{
		id: 1,
		name: i18n.t("common:rules.__distributionBreakfast__")
	},
	{
		id: 2,
		name: i18n.t("common:rules.__distributionLunch__")
	},
	{
		id: 3,
		name: i18n.t("common:rules.__distributionDinner__")
	}
];

export const logistic = [
	{
		id: 1,
		name: i18n.t("common:rules.__logisticBreakfast__")
	},
	{
		id: 2,
		name: i18n.t("common:rules.__logisticLunch__")
	},
	{
		id: 3,
		name: i18n.t("common:rules.__logisticDinner__")
	}
];

export const timeAfterAction = [
	{
		id: 900,
		name: i18n.t("common:rules.__timeAfterDelay15mn__")
	},
	{
		id: 1800,
		name: i18n.t("common:rules.__timeAfterDelay30mn__")
	},
	{
		id: 2700,
		name: i18n.t("common:rules.__timeAfterDelay45mn__")
	},
	{
		id: 3600,
		name: i18n.t("common:rules.__timeAfterDelay1h__")
	},
	{
		id: 4500,
		name: i18n.t("common:rules.__timeAfterDelay1h15__")
	},
	{
		id: 5400,
		name: i18n.t("common:rules.__timeAfterDelay1h30__")
	},
	{
		id: 6300,
		name: i18n.t("common:rules.__timeAfterDelay1h45__")
	},
	{
		id: 7200,
		name: i18n.t("common:rules.__timeAfterDelay2h__")
	},
	{
		id: 8100,
		name: i18n.t("common:rules.__timeAfterDelay2h15__")
	},
	{
		id: 9000,
		name: i18n.t("common:rules.__timeAfterDelay2h30__")
	},
	{
		id: 9900,
		name: i18n.t("common:rules.__timeAfterDelay2h45__")
	},
	{
		id: 10800,
		name: i18n.t("common:rules.__timeAfterDelay3h__")
	}
];

export function getTiming(seconds) {
	switch (seconds) {
		case 900:
			return i18n.t("common:rules.__timeAfterDelay15mn__");
		case 1800:
			return i18n.t("common:rules.__timeAfterDelay30mn__");
		case 2700:
			return i18n.t("common:rules.__timeAfterDelay45mn__");
		case 3600:
			return i18n.t("common:rules.__timeAfterDelay1h__");
		case 4500:
			return i18n.t("common:rules.__timeAfterDelay1h15__");
		case 5400:
			return i18n.t("common:rules.__timeAfterDelay1h30__");
		case 6300:
			return i18n.t("common:rules.__timeAfterDelay1h45__");
		case 7200:
			return i18n.t("common:rules.__timeAfterDelay2h__");
		case 8100:
			return i18n.t("common:rules.__timeAfterDelay2h15__");
		case 9000:
			return i18n.t("common:rules.__timeAfterDelay2h30__");
		case 9900:
			return i18n.t("common:rules.__timeAfterDelay2h45__");
		case 10800:
			return i18n.t("common:rules.__timeAfterDelay3h__");
		default:
			return;
	}
}

export const displayCategory = (actionId, actionType) => {
	let action = parseInt(actionId);
	let type = parseInt(actionType);
	let category = i18n.t("common:activities.__other__");

	if (action === 140) {
		return (category = i18n.t("common:rules.__hydratationRule__"));
	} else if (action === 103 || action === 113 || action === 133) {
		return (category = i18n.t("common:rules.__mealRule__"));
	} else if (action === 1 || action === 2 || action === 3 || action === 4 || action === 5) {
		return (category = i18n.t("common:rules.__moodRule__"));
	} else if (type === 3 && action === 100) {
		return (category = i18n.t("common:rules.__mealDistributionRule__"));
	} else if ((type === 3 && action === 110) || (type === 3 && action === 130)) {
		return (category = i18n.t("common:rules.__mealDistributionRule__"));
	} else if (action === 200 || action === 201 || action === 202) {
		return (category = i18n.t("common:rules.__hygieneRule__"));
	} else if ((type === 5 && action === 100) || (type === 5 && action === 110) || (type === 5 && action === 130)) {
		return (category = i18n.t("common:rules.__mealReturnRule__"));
	} else if (action === 13) {
		return (category = i18n.t("common:rules.__visitsRule__"));
	} else {
		return category;
	}
};

export const check = checked => {
	if (checked) {
		return i18n.t("common:rules.__check__");
	} else {
		return i18n.t("common:rules.__todo__");
	}
};

export const handleRulesTypes = type => {
	let ruleType = parseInt(type);
	switch (ruleType) {
		case 1:
			return i18n.t("common:rules.__directNotification__");
		case 2:
			return i18n.t("common:rules.__repeatedAction__");
		case 3:
			return i18n.t("common:rules.__outsideTiming__");
		case 4:
			return i18n.t("common:rules.__outsideTiming__");
		case 5:
			return i18n.t("common:rules.__outsideDelay__");
		case 6:
			return i18n.t("common:rules.__notRealised__");
		default:
			return;
	}
};
