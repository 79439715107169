import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getActivityTitle } from "../../../utils/Activities/Activities";
import Select from "react-select";
import SelectHomeInput from "../../../layout/SelectHomeInput";
import TimelineSettingsContainer from "./TimelineSettingsContainer";
import FamilyTimelineSettings from "../../Seniors/Family/FamilyTimelineSettings";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import NoData from "../../../layout/NoData";

export default function HomeTimelineSettings({ homeId, seniorId, userState, seniorsFromHome }) {
	const { t } = useTranslation("common");
	const history = useHistory();
	const seniorsWithDevice = seniorsFromHome.filter(senior => senior.deviceId);
	const initialSeniorSelected = seniorsFromHome.find(senior => senior.id === seniorId);
	const [seniorSelected, setSeniorSelected] = useState(seniorId ? seniorId : null);
	const [categoriesChecked, setCategoriesChecked] = useState([]);
	const [initialCheckBox, setInitialCheckBox] = useState(false);
	const [actionsToHide, setActionsToHide] = useState([]);
	const [error, setError] = useState(null);

	useEffect(() => {
		const handleCategoryCheckbox = actionsToHide => {
			const categories = actionsToHide.map(action => getActivityTitle(action));
			const categoriesList = Array.from(new Set(categories));
			setCategoriesChecked(...categoriesChecked, categoriesList);
		};
		if (actionsToHide.length > 0) {
			handleCategoryCheckbox(actionsToHide);
		}
		// eslint-disable-next-line
	}, [initialCheckBox]);

	useEffect(() => {
		if (homeId) {
			if (seniorSelected) {
				http
					.get(`${url.SENIORSURL}/${seniorSelected}/timeline_filters`)
					.then(res => {
						if (res.data.timeline_filter.filter.length < 1) {
							getHomeTimelineFilters(homeId);
						} else {
							setActionsToHide(res.data.timeline_filter.filter);
							setInitialCheckBox(true);
						}
					})
					.catch(() => setError(true));
			} else {
				getHomeTimelineFilters(homeId);
			}
		}
		// eslint-disable-next-line
	}, [homeId, seniorSelected]);

	const getHomeTimelineFilters = homeId => {
		http
			.get(`${url.HOMESURL}/${homeId}/timeline_filters`)
			.then(res => {
				setActionsToHide(res.data.timeline_filter.filter);
				setInitialCheckBox(true);
			})
			.catch(() => setError(true));
	};

	return (
		<div className="big-card">
			<div className="row">
				<div className="col-6">
					<h2>{t("home.__timelineParams__")}</h2>
				</div>
				<div className="col-6">
					<button type="button" className="right btn btn-sm btn-outline-primary" onClick={() => history.goBack()}>
						{t("common.__back__")}
					</button>
				</div>
				<div className="col-md-8">
					<h5 className="blue">{t("home.__timelineTitle__")}</h5>
					<p>{t("home.__timelineInfo__")}</p>
					<h3>{t("common.__howTo__")}</h3>
					<p>{t("home.__timelineTuto__")}</p>
					<div className="header-details pb-4">
						<p className="mb-3 mt-2">
							<b>{t("home.__timelineCaution__")}</b>
						</p>
						<Select
							placeholder={t("senior.__selectSenior__")}
							options={seniorsWithDevice}
							classNamePrefix="react-select"
							onChange={e => (e ? setSeniorSelected(e.value) : setSeniorSelected(null))}
							isClearable
							isOptionSelected={(option, selectValue) => selectValue.some(i => i === option)}
							defaultValue={seniorId ? initialSeniorSelected : null}
						/>
					</div>
				</div>
				<div className="col-md-4 center">
					<img
						src="/img/family-timeline-mobile.png"
						className="img-fluid img-timeline-mobile"
						alt="timeline sur un téléphone mobile"
					/>
				</div>
			</div>

			{homeId ? (
				error ? (
					<NoData />
				) : seniorSelected ? (
					<FamilyTimelineSettings
						homeId={homeId}
						initialCheckBox={initialCheckBox}
						actionsToHide={actionsToHide}
						setActionsToHide={setActionsToHide}
						seniorSelected={seniorSelected}
						seniorsFromHome={seniorsFromHome}
						userState={userState}
					/>
				) : (
					<TimelineSettingsContainer
						homeId={homeId}
						initialCheckBox={initialCheckBox}
						actionsToHide={actionsToHide}
						setActionsToHide={setActionsToHide}
					/>
				)
			) : (
				<SelectHomeInput />
			)}
		</div>
	);
}
