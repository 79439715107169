import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import { displayDiaper } from "../../DiaperConnected/Illustrations";
import { Table, SelectColumnFilter } from "../../../utils/Table/TableWithFilter";
import { Link } from "react-router-dom";
import { fromToUrl, handleDate } from "@e4ia/e4link_modules.datetime";

export default function SaturationDebug({ startDate, endDate, homeId, seniorsFromHome, diapers, sensors }) {
	const { t } = useTranslation("common");
	const [saturationList, setSaturationList] = useState([]);

	useEffect(() => {
		if (homeId && startDate && endDate) {
			const fromTo = fromToUrl(startDate, endDate);
			http
				.get(`${url.HOMESURL}/${homeId}/rht/saturations/${fromTo}`)
				.then(res => setSaturationList(res.data.senior_diaper_saturations))
				.catch(() => setSaturationList([]));
		}
	}, [homeId, startDate, endDate]);

	const data = useMemo(
		() =>
			saturationList.map(saturation => {
				return {
					...saturation,
					sensor: sensors.find(sensor => sensor.id === saturation.diaper_sensor_id),
					senior: seniorsFromHome.find(senior => saturation.senior_id === senior.id).label,
					diaper: diapers.find(diaper => diaper.id === saturation.diaper_id)
				};
			}),
		[saturationList, seniorsFromHome, diapers, sensors]
	);

	const columns = [
		{
			Header: t("diaper.__sensor__"),
			accessor: "sensor.mac_address",
			Cell: row => (row.row.original.sensor ? row.row.original.sensor.mac_address : null)
		},
		{
			Header: t("common.__senior__"),
			accessor: "senior",
			Filter: SelectColumnFilter,
			Cell: row => (
				<Link to={`/senior/profile/${row.row.original.senior_id}`}>
					<span className="category-outline-badge blue">{row.row.values.senior}</span>
				</Link>
			)
		},
		{
			Header: t("common.__doneAt__"),
			accessor: "done_at",
			Cell: row => handleDate(row.row.original.done_at, "PPp")
		},
		{
			Header: t("diaper.__diaper__"),
			accessor: "diaper.description",
			Cell: row => (row.row.original.diaper ? displayDiaper(row.row.original.diaper) : null)
		}
	];

	return data.length < 1 ? (
		<p className="red mt-3">
			{t("common.__noDataAvailable__")} {t("common.__changeChoice__")}
		</p>
	) : (
		<Table columns={columns} data={data} />
	);
}
