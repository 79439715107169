import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { FaInfoCircle, FaTimes, FaUserLock } from "react-icons/fa";
import DisplayName from "../../utils/DisplayName";
import MyInputText from "../../utils/Form/MyInputText";
import MyInputSelect from "../../utils/Form/MyInputSelect";
import MyInputEmail from "../../utils/Form/MyInputEmail";
import MyInputPassword from "../../utils/Form/MyInputPassword";
import { BadgeInterface, HealthWorkerTypesInterface } from "../../interfaces/UserInterface";
import MyInputNumber from "../../utils/Form/MyInputNumber";

interface Props {
	hwTypes: HealthWorkerTypesInterface[];
	hwFormStore: {
		badge: BadgeInterface | null;
		role: {
			group_id: number | null;
			home_id: number | null;
			tenant_id: number | null;
			permissions: number[];
		};
		user: {
			email: string | null;
			first_name: string | null;
			last_name: string | null;
			login: string | null;
			mobile_phone: string | null;
			password: string | null;
			phone: string | null;
			type_id: number | null;
		};
	};
	hwFormDispatch: React.Dispatch<any>;
}

const AddHealthWorkerStepOne = ({ hwTypes, hwFormStore, hwFormDispatch }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const history = useHistory();
	const methods = useForm();
	const { handleSubmit } = methods;
	const [personalAccount, setPersonalAccount] = useState(hwFormStore.user.first_name === "badge_general" ? false : true);
	const [definePassword, setDefinePassword] = useState(hwFormStore.user.password ? true : false);
	const healthWorkerType = [{ id: 0, name: t("healthworker.__selectFunction__") }, ...hwTypes];

	const onSubmit = (values: any): void => {
		const data = {
			email: values.email,
			login: values.login,
			password: definePassword ? values.password : null,
			first_name: personalAccount ? values.firstname : "badge_general",
			last_name: personalAccount
				? values.lastname
				: values.type
				? DisplayName(parseInt(values.type), healthWorkerType)
				: "badge_general",
			type_id: parseInt(values.type),
			phone: values.phone,
			mobile_phone: values.mobile_phone
		};
		hwFormDispatch({
			type: "SAVE_USER",
			payload: data
		});
		history.push("/user/adduser/role");
	};

	return (
		<>
			<h5 className="mt-2 mb-3">{t("healthworker.__accountId__")} :</h5>
			<div className="row">
				<div className="col-md-6">
					<input
						type="checkbox"
						checked={personalAccount}
						onChange={(): void => setPersonalAccount(prevState => !prevState)}
						data-testid="checkbox-personal-account"
					/>
					<span className="label-checkbox">
						<b>{t("healthworker.__addPersonalAccount__")}</b>
					</span>
				</div>
				<div className="col-md-6">
					<input
						type="checkbox"
						checked={!personalAccount}
						onChange={(): void => setPersonalAccount(prevState => !prevState)}
						data-testid="checkbox-not-personal-account"
					/>
					<span className="label-checkbox">
						<b>{t("healthworker.__addGeneralAccount__")}</b>
					</span>
				</div>
			</div>
			<div className="form-card">
				<p>{t("healthworker.__generalAccountInfo__")}</p>
			</div>

			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					{personalAccount ? (
						<div className="row" data-testid="personal-account-inputs">
							<div className="col-md-6">
								<MyInputText
									label={t("common.__firstname__")}
									name="firstname"
									behavior={{ required: true }}
									value={hwFormStore.user.first_name}
								/>
							</div>
							<div className="col-md-6">
								<MyInputText
									label={t("common.__name__")}
									name="lastname"
									behavior={{ required: true }}
									value={hwFormStore.user.last_name}
								/>
							</div>
						</div>
					) : null}
					<MyInputSelect
						label={t("common.__job__")}
						name="type"
						behavior={{
							required: true,
							validate: (value: number): boolean => value != 0
						}}
						list={healthWorkerType}
						value={hwFormStore.user.type_id}
						dataTestid="add-user-select-type"
					/>
					<div className="row">
						<div className="col-md-6">
							<MyInputNumber
								label={t("common.__phone__")}
								name="phone"
								behavior={{ required: false }}
								value={hwFormStore.user.phone}
							/>
						</div>
						<div className="col-md-6">
							<MyInputNumber
								label={t("common.__smartphone__")}
								name="mobile_phone"
								behavior={{ required: false }}
								value={hwFormStore.user.mobile_phone}
							/>
						</div>
						<div className="col-md-6">
							<MyInputEmail
								label={t("common.__mail__")}
								name="email"
								behavior={{ required: true, pattern: /^\S+@\S+\.\S+$/ }}
								value={hwFormStore.user.email}
								dataTestid="add-user-email-input"
							/>
						</div>
						<div className="col-md-6">
							<MyInputText
								label={t("common.__login__")}
								name="login"
								behavior={{ required: true }}
								value={hwFormStore.user.login}
								dataTestid="add-user-login-input"
							/>
						</div>
					</div>
					{definePassword ? (
						<div className="header-details" data-testid="add-password-block">
							<div className="d-flex justify-content-between mb-1">
								<label>
									<FaUserLock className=" mr-2" /> {t("common.__password__")}
								</label>
								<FaTimes
									data-testid="dont-add-password-btn"
									className="mt-1"
									role="button"
									onClick={(): void => setDefinePassword(false)}
								/>
							</div>
							<MyInputPassword
								label={t("users.__passwordWarning__")}
								name="password"
								behavior={{
									required: true,
									pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.\-_])[A-Za-z\d@$!%*?&.\-_]{8,}$/
								}}
								value={hwFormStore.user.password}
							/>
						</div>
					) : (
						<div className="header-details">
							<div className="d-flex justify-content-between mb-1">
								<p className="mt-1">
									<FaInfoCircle className="mr-2" />
									{t("family.__passwordWarning__")}
								</p>
								<button
									data-testid="add-password-btn"
									type="button"
									className="category-outline-badge blue"
									onClick={(): void => setDefinePassword(true)}>
									{t("common.__addPassword__")}
								</button>
							</div>
						</div>
					)}
					<div className="d-flex justify-content-end mb-2 mt-2">
						<button data-testid="go-to-step2-btn" type="submit" className="btn btn-primary btn-sm">
							{t("common.__nextStep__")}
						</button>
					</div>
				</form>
			</FormProvider>
		</>
	);
};

export default AddHealthWorkerStepOne;
