import React from "react";
import { UserInterface } from "../interfaces/UserInterface";
import { CalendarTabs } from "../utils/Tabs/TabsToDisplay";
import useStoreData from "../hooks/useStoreData";
import SelectHomeInput from "../layout/SelectHomeInput";
import Tabs from "../utils/Tabs/Tabs";
import CustomCalendar from "../utils/CustomCalendar";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const CalendarEntries_Calendar = ({ homeId, userState }: Props): JSX.Element => {
	const { store } = useStoreData({ userState, homeId });
	const canManageCalendar = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.CAN_MANAGE_HOME_CALENDAR]);

	return (
		<>
			{canManageCalendar ? <Tabs tabsToDisplay={CalendarTabs} currentTab="CALENDAR" /> : null}
			<div className="big-card">
				{homeId ? (
					<CustomCalendar
						homeId={homeId}
						locale={userState.userConnected.locale}
						seniorsFromHome={store.seniors.seniorsFromHome}
					/>
				) : (
					<SelectHomeInput />
				)}
			</div>
		</>
	);
};
export default CalendarEntries_Calendar;
