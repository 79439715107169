import React, { createContext, useReducer } from "react";

const initialState = {
	user: {
		email: null,
		login: null,
		password: null,
		first_name: null,
		last_name: null,
		type_id: null,
		phone: null,
		mobile_phone: null
	},
	role: {
		home_id: null,
		group_id: null,
		tenant_id: null,
		permissions: []
	},
	badge: null
};

export const HealthWorkerFormReducer = (state, action) => {
	switch (action.type) {
		case "SAVE_USER":
			return {
				...state,
				user: action.payload
			};
		case "SAVE_ROLE":
			return {
				...state,
				role: action.payload
			};
		case "SAVE_BADGE":
			return {
				...state,
				badge: action.payload
			};
		case "EMPTY_NEW_USER_STATE":
			return {
				user: {
					email: null,
					login: null,
					password: null,
					first_name: null,
					last_name: null,
					type_id: null,
					phone: null,
					mobile_phone: null
				},
				role: {
					home_id: null,
					group_id: null,
					tenant_id: null,
					permissions: []
				},
				badge: null
			};
		default:
			return state;
	}
};

export const HealthWorkerFormContext = createContext();

export const HealthWorkerFormContextProvider = ({ children }) => {
	const [hwFormStore, hwFormDispatch] = useReducer(HealthWorkerFormReducer, initialState);

	return (
		<HealthWorkerFormContext.Provider
			value={{
				hwFormStore,
				hwFormDispatch
			}}>
			{children}
		</HealthWorkerFormContext.Provider>
	);
};

export const HealthWorkerFormContextConsumer = HealthWorkerFormContext.Consumer;
