import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaRegFileExcel } from "react-icons/fa";
import Excel from "./Excel";
import AddEventContainer from "./AddEventContainer";

export default function AddEvent({ homeId, eventsState, eventsDispatch, userState }) {
	const { t } = useTranslation("common");
	const [excelMode, setExcelMode] = useState(false);

	return (
		<>
			<div className="d-flex justify-content-between">
				<h2>{t("calendar.__addAnimation__")}</h2>
				<div>
					{excelMode ? (
						<button className="btn btn-sm btn-outline-secondary mt-2" onClick={() => setExcelMode(false)}>
							{t("common.__back__")}
						</button>
					) : (
						<button className="btn btn-sm btn-outline-success mt-2" onClick={() => setExcelMode(true)}>
							<FaRegFileExcel className="mr-2" />
							{t("calendar.__excelImport__")}
						</button>
					)}
				</div>
			</div>

			{excelMode ? (
				<Excel homeId={homeId} />
			) : (
				<AddEventContainer
					homeId={homeId}
					eventsState={eventsState}
					eventsDispatch={eventsDispatch}
					userState={userState}
				/>
			)}
		</>
	);
}
