import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import UpdateFamilyUser from "./UpdateFamilyUser";
import ModalContainer from "../../../utils/ModalContainer";
import NoData from "../../../layout/NoData";

export default function FamilyUserDetails({ familyId }) {
	const { t } = useTranslation("common");
	const [familyUserDetails, setFamilyUserDetails] = useState([]);
	const [familyUserError, setFamilyError] = useState(false);
	const [editFamily, setEditFamily] = useState({
		show: false,
		info: null,
		title: "Compte Family",
		success: false
	});
	const history = useHistory();

	useEffect(() => {
		const getFamilyUserDetails = familyId => {
			if (familyId) {
				http
					.get(`${url.BASEURL}/family_users/${familyId}`)
					.then(res => {
						setFamilyError(false);
						setFamilyUserDetails(res.data.family_user);
					})
					.catch(() => setFamilyError(true));
			}
		};
		getFamilyUserDetails(familyId);
		// eslint-disable-next-line
	}, [familyId, editFamily.success]);

	return (
		<div className="big-card">
			<div className="row">
				<div className="col-md-6">
					<h2>{t("family.__families__")}</h2>
				</div>
				<div className="col-md-6">
					<button className="btn btn-sm btn-outline-secondary right" type="button" onClick={() => history.goBack()}>
						{t("common.__back__")}
					</button>
				</div>
			</div>

			{familyUserError ? (
				<NoData />
			) : (
				<div className="card">
					<h2 className="card-header">
						<button
							className="btn btn-sm btn-primary right"
							type="button"
							onClick={() => setEditFamily({ show: true, info: familyUserDetails, title: "Compte Family", success: false })}>
							{t("common.__update__")}
						</button>
						{familyUserDetails.last_name} {familyUserDetails.first_name}
					</h2>

					<div className="card-body">
						<div className="row">
							<div className="col-md-3">
								<p>{t("common.__login__")}</p>
							</div>
							<div className="col-md-9">
								<p>{familyUserDetails.login}</p>
							</div>
							<div className="col-md-3">
								<p>{t("common.__mail__")}</p>
							</div>
							<div className="col-md-9">
								<p>{familyUserDetails.email}</p>
							</div>
							<div className="col-md-3">
								<p>{t("common.__phone__")}</p>
							</div>
							<div className="col-md-9">
								<p>{familyUserDetails.phone}</p>
							</div>
							<div className="col-md-3">
								<p>{t("common.__role__")}</p>
							</div>
							<div className="col-md-9">
								<p>
									{familyUserDetails.principal ? (
										<span className="colored-tag grey">{t("family.__principal__")}</span>
									) : (
										<span className="colored-tag yellow">{t("family.__affiliate__")}</span>
									)}
								</p>
							</div>
							<div className="col-md-3">
								<p>{t("family.__rgpd__")}</p>
							</div>
							<div className="col-md-9">
								<span className="mr-2">
									{familyUserDetails.has_accepted_rgpd ? t("family.__rgpdOk__") : t("family.__rgpdNo__")}
								</span>
							</div>
						</div>
					</div>
				</div>
			)}
			<ModalContainer show={editFamily} setShow={setEditFamily}>
				<UpdateFamilyUser show={editFamily} setShow={setEditFamily} />
			</ModalContainer>
		</div>
	);
}
