const NotificationsReducer = (state, action) => {
	switch (action.type) {
		case "GET_AVATARS_COUNTER":
			return {
				...state,
				avatarModeration: action.payload,
				moderationCounter: state.lifeStoryModeration + state.picturesModeration + state.blogModeration + action.payload
			};

		case "GET_LIFESTORIES_COUNTER":
			return {
				...state,
				lifeStoryModeration: action.payload,
				moderationCounter: state.avatarModeration + state.picturesModeration + state.blogModeration + action.payload
			};

		case "GET_PICTURES_COUNTER":
			return {
				...state,
				picturesModeration: action.payload,
				moderationCounter: state.lifeStoryModeration + state.avatarModeration + state.blogModeration + action.payload
			};

		case "GET_BLOG_COUNTER":
			return {
				...state,
				blogModeration: action.payload,
				moderationCounter: state.lifeStoryModeration + state.avatarModeration + state.picturesModeration + action.payload
			};

		case "MODERATE_AVATAR":
			return {
				...state,
				avatarModeration: state.avatarModeration - 1,
				moderationCounter: state.moderationCounter - 1,
				moderationSuccess: true
			};

		case "MODERATE_LIFESTORY":
			return {
				...state,
				lifeStoryModeration: state.lifeStoryModeration - 1,
				moderationCounter: state.moderationCounter - 1,
				moderationSuccess: true
			};

		case "MODERATE_PICTURES":
			return {
				...state,
				picturesModeration: state.picturesModeration - 1,
				moderationCounter: state.moderationCounter - 1,
				moderationSuccess: true
			};

		case "MODERATE_BLOG":
			return {
				...state,
				blogModeration: state.blogModeration - 1,
				moderationCounter: state.moderationCounter - 1,
				moderationSuccess: true
			};

		case "GET_MESSAGES_COUNTER":
			return {
				...state,
				messageCounter: action.payload
			};

		case "UPDATE_MESSAGES_COUNTER":
			return {
				...state,
				messageCounter: state.messageCounter - action.payload
			};

		case "MODERATION_ERROR":
			return {
				...state,
				moderationError: true
			};

		case "RESET_ES":
			return {
				...state,
				moderationSuccess: false,
				moderationError: false
			};

		default:
			return state;
	}
};

export default NotificationsReducer;
