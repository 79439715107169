import React from "react";
import { Link } from "react-router-dom";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { FaAngleRight, FaCheck, FaRegBell } from "react-icons/fa";
import { displayRule, displayRuleCategory } from "../Home/NotificationsRules/RuleUtils";

interface NotificationInterface {
	actionId: string;
	activity: string;
	category: string;
	checked: boolean;
	checked_by: number | null;
	checked_when: string | null;
	checker_rule_id: number;
	checker_rule_name: string;
	details?: string;
	device_id: number;
	displayCheck: string;
	homeUnit: string;
	home_id: number;
	home_unit_id: number;
	id: number;
	info: { when: any };
	notificationType: string;
	room: string;
	room_id: number;
	rule: {
		actions: any[];
		begin?: { hour: number; minute: number };
		end?: { hour: number; minute: number };
		nb_repeat?: number;
		max_seconds_after?: string;
		type: number;
	};
	senior: string;
	senior_id: number;
}

interface Props {
	show: { show: boolean; info: NotificationInterface | null };
	userLocale: string;
}

const NotificationDetails = ({ show, userLocale }: Props): JSX.Element | null => {
	const notification = show.info;

	return (
		notification && (
			<>
				<div className="d-flex">
					{notification.rule.type === 6 ? null : (
						<p className="meta mr-3">
							<FaRegBell className="red mr-2" />
							{notification.rule.type === 2
								? handleDate(notification.info.when[1], "Pp", userLocale)
								: handleDate(notification.info.when, "Pp", userLocale)}
						</p>
					)}
					{notification.checked
						? notification.checked_when && (
								<p className="meta">
									<FaCheck className="mr-2" />
									{handleDate(notification.checked_when, "Pp", userLocale)}
								</p>
						  )
						: null}
				</div>

				<div className="mt-3 mb-4">
					<h5>
						<span className="mr-3">{displayRuleCategory(notification.category)}</span>
						{notification.checker_rule_name}
					</h5>
					<p className="red">
						<FaAngleRight className="mr-2" />
						{notification.notificationType}
					</p>
					<div>{displayRule(notification.rule)}</div>
				</div>
				<div className="toast-separator"></div>
				<div className="d-flex justify-content-between">
					<Link to={`/senior/profile/${notification.senior_id}`}>
						<strong>{notification.senior}</strong>
					</Link>
					<Link to={`/manage/room/details/${notification.room_id}`}>
						{notification.room} - {notification.homeUnit}
					</Link>
				</div>
			</>
		)
	);
};
export default NotificationDetails;
