import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { SeniorTabs } from "../utils/Tabs/TabsToDisplay";
import { UserInterface } from "../interfaces/UserInterface";
import SeniorFullProfile from "../components/Seniors/SeniorFullProfile";
import useStoreData from "../hooks/useStoreData";
import Tabs from "../utils/Tabs/Tabs";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}
const Seniors_SelectedSenior = ({ homeId, userState }: Props): JSX.Element => {
	const { seniorId } = useParams<{ seniorId?: string }>();
	const { store, dispatch } = useStoreData({ userState, homeId });

	useEffect(() => {
		return function cleanup(): void {
			dispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<Tabs tabsToDisplay={SeniorTabs} currentTab={"SENIORS"} />
			{seniorId && homeId ? (
				<SeniorFullProfile
					seniorId={parseInt(seniorId)}
					userState={userState}
					homeId={homeId}
					store={store}
					dispatch={dispatch}
				/>
			) : null}
		</>
	);
};
export default Seniors_SelectedSenior;
