const TimelineReducer = (state, action) => {
	switch (action.type) {
		case "LOADING":
			return {
				...state,
				loading: true
			};

		case "GET_ACTIVITIES":
			return {
				...state,
				loading: false,
				data: action.payload
			};

		default:
			return state;
	}
};

export default TimelineReducer;
