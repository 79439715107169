import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { UserInterface } from "../interfaces/UserInterface";
import { HomeStoreInterface } from "../interfaces/HomeInterface";
import useStoreData from "../hooks/useStoreData";
import AccessControl from "../layout/AccessControl";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import AdminSecondaryNav from "../layout/AdminSecondaryNav";
import HealthWorkersListForAdmin from "../components/Admin/HealthWorkersListForAdmin";
import DisplayName from "../utils/DisplayName";
import http from "../services/Interceptor.js";
import * as p from "../constants/Permissions";
import * as url from "../constants/Url";

interface Props {
	userState: UserInterface;
	homeId: number | null;
	groupId: number | null;
	homeStore: HomeStoreInterface;
	setHomeId: (homeId: number) => void;
}

const AdminHealthWorkers = ({ userState, homeId, setHomeId, groupId, homeStore }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const { store, dispatch } = useStoreData({ userState, homeId });
	const isAdminOrSuperAdmin = userState.userConnected.is_in([p.SUPER_ADMIN, p.ADMIN]);
	const allHomes = homeStore.home.allHomes;
	const groups = homeStore.home.groups;
	const userLocale = userState.userConnected.locale;

	useEffect(() => {
		return function cleanup(): void {
			dispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (isAdminOrSuperAdmin) {
			http.get(`${url.BASEURL}/users`).then(res => {
				const result = res.data.users;
				const healthworkers = result.map((hw: any) => {
					return {
						...hw,
						homeName: hw.home_id ? DisplayName(hw.home_id, allHomes) : null,
						groupName: hw.group_id ? DisplayName(hw.group_id, groups) : null,
						lastSignInDate: hw.last_sign_in_at ? handleDate(hw.last_sign_in_at, "Pp", userLocale) : null,
						lockedDate: hw.locked_at ? handleDate(hw.locked_at, "Pp", userLocale) : null,
						deletedDate: hw.deleted_at ? handleDate(hw.deleted_at, "Pp", userLocale) : null,
						locked: hw.locked_at ? t("healthworker.__locked__") : t("healthworker.__unLocked__"),
						deleted: hw.deleted_at ? t("healthworker.__deletedAccount__") : t("healthworker.__lastSignIn__")
					};
				});
				dispatch({
					type: "GET_ALL_HEALTHWORKERS",
					payload: healthworkers
				});
			});
		}
	}, [isAdminOrSuperAdmin]);

	return (
		<AccessControl userPermissions={userState.userConnected.permissions} permissionNeeded={[p.ADMIN, p.SUPER_ADMIN]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<AdminSecondaryNav userState={userState} />}
				component={
					<HealthWorkersListForAdmin
						store={store}
						dispatch={dispatch}
						groupId={groupId}
						homeId={homeId}
						userState={userState}
						setHomeId={setHomeId}
					/>
				}
			/>
		</AccessControl>
	);
};
export default AdminHealthWorkers;
