import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

export default function DesactivateSeniorModal({ show, setShow }) {
	const { t } = useTranslation("common");
	const senior = show.info.senior;
	const [error, setError] = useState(false);

	const desactivateAccount = () => {
		setError(false);
		http
			.delete(`${url.SENIORSURL}/${senior.id}/inactivate`)
			.then(() => setShow({ show: false, info: null, title: "", success: true }))
			.catch(() => setError(true));
	};

	return (
		<>
			<p>
				{t("common.__senior__")} :{" "}
				<strong>
					{senior.last_name.toUpperCase()} {senior.first_name}
				</strong>
			</p>

			{/* {success ? <p className="success-msg">{t("senior.__desactivedAccount__", { name })}</p> : null} */}
			{error ? <p className="error-msg">{t("senior.__desactiveAccountError__")}</p> : null}

			<div className="header-details">
				<p className="red">
					<b>{t("common.__caution__")} :</b>
				</p>
				<p>{t("senior.__desactiveAccountDetails__")}</p>
			</div>
			<div className="d-inline-flex">
				{/* <button className="excel-badge mr-2">
					<FaFileDownload className="mr-2" />
					Exporter les données
				</button> */}
				<button className="btn btn-sm btn-danger" onClick={() => desactivateAccount()}>
					{t("senior.__desactiveSeniorAccount__")}
				</button>
			</div>
		</>
	);
}
