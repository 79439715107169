import React from "react";
import { useTranslation } from "react-i18next";

export const HandleVersion = (): JSX.Element => {
	const { t } = useTranslation("common");

	return (
		<p className="small-meta">
			<b>© {process.env.REACT_APP_NAME} </b> v.1.11.1 -{" "}
			<a href={`mailto:${process.env.REACT_APP_SUPPORT}`}>{t("common.__techSupport__")}</a>
		</p>
	);
};
