import React, { useEffect, useState } from "react";
import { SeniorInterface, UserInterface } from "../interfaces/UserInterface";
import AccessControl from "../layout/AccessControl";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import AdminSecondaryNav from "../layout/AdminSecondaryNav";
import Admin from "../components/Admin/Admin";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import * as p from "../constants/Permissions";

interface Props {
	userState: UserInterface;
	homeId: number | null;
}

const AdminDebugTimeline = ({ userState, homeId }: Props): JSX.Element => {
	const isAdmin = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN]);
	const [allSeniors, setAllSeniors] = useState([]);

	useEffect(() => {
		if (isAdmin) {
			http.get(`${url.BASEURL}/seniors`).then(res => {
				const result = res.data.seniors;
				const data = result.map((senior: SeniorInterface) => {
					return {
						...senior,
						value: senior.id,
						label: `${senior.last_name.toUpperCase()} ${senior.first_name}`
					};
				});
				setAllSeniors(data);
			});
		}
	}, [isAdmin, homeId]);

	return (
		<AccessControl userPermissions={userState.userConnected.permissions} permissionNeeded={[p.ADMIN, p.SUPER_ADMIN]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<AdminSecondaryNav userState={userState} />}
				component={<Admin userState={userState} homeId={homeId} allSeniors={allSeniors} />}
			/>
		</AccessControl>
	);
};
export default AdminDebugTimeline;
