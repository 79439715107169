import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import { FaUpload } from "react-icons/fa";
import MyInputText from "../../../utils/Form/MyInputText";
import ErrorContainer from "../../../layout/ErrorContainer";
import * as API from "../../../services/API";

const SendSeniorDocuments = ({ seniorId, setAddMode, setSuccess, canManageSeniorDocuments, setRemoveError }) => {
	const { t } = useTranslation("common");
	const methods = useForm();
	const { handleSubmit } = methods;
	const [httpResponse, setHttpResponse] = useState(null);
	const [file, setFile] = useState(null);
	const [filename, setFilename] = useState(null);

	const onSelectFile = e => {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader();
			reader.readAsDataURL(e.target.files[0]);
			setFile(e.target.files[0]);
			setFilename(e.target.files[0].name);
		}
	};

	const onSubmit = (data, e) => {
		e.target.reset();

		const formData = new FormData();
		formData.append("document", file);
		formData.append("title", data.title);

		API.sendSeniorDocument(
			seniorId,
			formData,
			() => {
				setAddMode(false);
				setSuccess(true);
				setRemoveError(false);
			},
			() => {
				setRemoveError(false);
				setHttpResponse({ error: true });
			}
		);
	};

	return canManageSeniorDocuments ? (
		<FormProvider {...methods}>
			<ErrorContainer
				alert={t("documents.__docNotSent__")}
				success={t("documents.__docSent__")}
				httpResponse={httpResponse}
				setHttpResponse={setHttpResponse}
			/>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="form-group">
					{file === null ? (
						<div className="center">
							<label htmlFor="sendDocument" id="document" className="btn btn-secondary">
								<FaUpload className="download-icon" /> {t("documents.__import__")}
							</label>
							<input
								type="file"
								id="sendDocument"
								name="document"
								accept="image/jpeg,image/png,application/pdf"
								className="form-control-file"
								onChange={onSelectFile}
								style={{ display: "none" }}
							/>
						</div>
					) : (
						<div className="form-card">
							<p className="center">
								{t("documents.__docSelected__")}:<br />
								<span className="success">{filename}</span>
							</p>
						</div>
					)}
				</div>

				<MyInputText label={t("common.__name__")} name="title" behavior={{ required: true }} />

				<button type="submit" className="btn btn-primary btn-sm">
					{t("common.__send__")}
				</button>
			</form>
		</FormProvider>
	) : null;
};

export default SendSeniorDocuments;
