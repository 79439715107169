import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { FaAngleRight } from "react-icons/fa";
import MyInputText from "../../utils/Form/MyInputText";
import MyInputSelect from "../../utils/Form/MyInputSelect";
import MyInputNumber from "../../utils/Form/MyInputNumber";
import girLevel from "../../constants/GirLevel";
import genderList from "../../constants/GenderList";
import HeaderContainer from "../../layout/HeaderContainer";
import CreateFamilyUsersContainer from "./Family/CreateFamilyUsersContainer";
import http from "../../services/Interceptor";
import * as constant from "../../constants/Url";
import * as p from "../../constants/Permissions";
import { FamilyInterface, NewSeniorInterface, StoreInterface, UserInterface } from "../../interfaces/UserInterface";
import { RoomInterface } from "../../interfaces/HomeInterface";

interface Props {
	homeId: number;
	store: StoreInterface;
	dispatch: React.Dispatch<any>;
	userState: UserInterface;
	newSeniorStore: NewSeniorInterface;
	newSeniorDispatch: React.Dispatch<any>;
}

const AddSenior = ({ homeId, store, dispatch, userState, newSeniorStore, newSeniorDispatch }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const history = useHistory();
	const methods = useForm();
	const { handleSubmit } = methods;
	const roomsFromHome = store.home.rooms;
	const [isAutonomous, setIsAutonomous] = useState(false);
	const canDisplayHDS = process.env.REACT_APP_SHOW_HDS === "true" ? true : false;

	useEffect(() => {
		const availableRooms = (roomsFromHome: RoomInterface[]): void => {
			const rooms = roomsFromHome.filter(room => room.senior_id === null);
			newSeniorDispatch({
				type: "SET_ROOMS_LIST",
				payload: [{ id: 0, name: t("senior.__addSeniorNoRoom__") }, ...rooms]
			});
		};
		availableRooms(roomsFromHome);
		// eslint-disable-next-line
	}, [roomsFromHome]);

	const onSubmit = (values: any): void => {
		dispatch({
			type: "HIDE_ERROR"
		});
		newSeniorDispatch({
			type: "LOADING"
		});
		const existingFamiliesSelected = newSeniorStore.existingFamilyUserIds.map((familyId: number) => ({
			family_user_id: familyId
		}));
		const newFamiliesAdded = newSeniorStore.newFamilyUsers.map((family: FamilyInterface) => ({ family_user: family }));
		const seniorData = {
			senior: {
				arrival_date: values.arrivalYear + "-" + values.arrivalMonth + "-" + values.arrivalDay,
				birth_date: values.year + "-" + values.month + "-" + values.day,
				first_name: values.firstName,
				last_name: values.lastName,
				gender: values.gender,
				home_id: homeId,
				gir_level: parseInt(values.girLevel),
				profile_id: isAutonomous ? 1 : 0
			},
			family_users: [...existingFamiliesSelected, ...newFamiliesAdded],
			room_id: values.room === "0" ? null : parseInt(values.room)
		};
		http
			.post(`${constant.SENIORSURL}`, seniorData)
			.then(res => {
				dispatch({
					type: "ADD_SENIOR",
					payload: res.data.senior
				});
				history.push(`/senior/profile/${res.data.senior.id}`);
			})
			.catch(err => {
				window.scrollTo(0, 0);
				newSeniorDispatch({
					type: "ERROR",
					payload:
						err.response.request.responseText === JSON.stringify({ errors: { login: ["has already been taken"] } })
							? t("senior.__addSeniorLoginError__")
							: err.response.request.responseText === JSON.stringify({ errors: { email: ["has already been taken"] } })
							? t("family.__userAlreadyExist__")
							: t("senior.__addSeniorError__")
				});
			});
	};

	return (
		<div className="big-card">
			<HeaderContainer
				title={t("senior.__addSenior__")}
				addButton={t("senior.__addSenior__")}
				link={"/senior/addsenior/add"}
				permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.CAN_CREATE_SENIOR]}
				userState={userState}
			/>
			{newSeniorStore.loading ? (
				<>
					<div className="spinner-border text-primary mr-3" role="status"></div>
					<span>
						<b>{t("senior.__addSeniorLoading__")}</b>
					</span>
				</>
			) : (
				<FormProvider {...methods}>
					<CreateFamilyUsersContainer
						newFamilyStore={newSeniorStore}
						newFamilyDispatch={newSeniorDispatch}
						isModal={false}
					/>
					<form onSubmit={handleSubmit(onSubmit)}>
						<p className="mt-4 mb-3">
							<FaAngleRight /> <b>{t("common.__senior__")}</b>
						</p>
						<div className="row">
							<div className="col-md-2">
								<MyInputSelect
									label={t("senior.__addSeniorGender__")}
									name="gender"
									behavior={{ required: true }}
									list={genderList}
								/>
							</div>
							<div className="col-md-5">
								<MyInputText label={t("common.__firstname__")} name="firstName" behavior={{ required: true }} />
							</div>
							<div className="col-md-5">
								<MyInputText label={t("common.__name__")} name="lastName" behavior={{ required: true }} />
							</div>
							<div className="col-md-3 mt-2">
								<p className="mt-4">{t("senior.__birthDate__")} : </p>
							</div>
							<div className="col-md-3">
								<MyInputNumber
									label={t("common.__day__")}
									name="day"
									behavior={{ required: true, maxLength: 2, pattern: /[0-9]{2}/ }}
									placeholder="01"
								/>
							</div>
							<div className="col-md-3">
								<MyInputNumber
									label={t("common.__month__")}
									name="month"
									behavior={{ required: true, maxLength: 2, pattern: /[0-9]{2}/ }}
									placeholder="01"
								/>
							</div>
							<div className="col-md-3">
								<MyInputNumber
									label={t("common.__year__")}
									name="year"
									behavior={{ required: true, maxLength: 4, pattern: /[0-9]{4}/ }}
									placeholder="1900"
								/>
							</div>

							<div className="col-md-3 mt-2">
								<p className="mt-4">{t("senior.__arrivalDate__")} : </p>
							</div>
							<div className="col-md-3">
								<MyInputNumber
									label={t("common.__day__")}
									name="arrivalDay"
									behavior={{ required: true, maxLength: 2, pattern: /[0-9]{2}/ }}
									placeholder="01"
								/>
							</div>
							<div className="col-md-3">
								<MyInputNumber
									label={t("common.__month__")}
									name="arrivalMonth"
									behavior={{ required: true, maxLength: 2, pattern: /[0-9]{2}/ }}
									placeholder="01"
								/>
							</div>
							<div className="col-md-3">
								<MyInputNumber
									label={t("common.__year__")}
									name="arrivalYear"
									behavior={{ required: true, maxLength: 4, pattern: /[0-9]{4}/ }}
									placeholder="1900"
								/>
							</div>
							{canDisplayHDS ? (
								<>
									<div className="col-md-6 mt-2">
										<MyInputSelect label={t("senior.__agir__")} name="girLevel" behavior={{ required: false }} list={girLevel} />
									</div>
									<div className="col-md-6 mt-3">
										<div className="mt-3">
											<input
												type="checkbox"
												name="profile"
												className="mr-2"
												onChange={(): void => setIsAutonomous(prevState => !prevState)}
											/>{" "}
											<label>{t("senior.__independant__")}</label>
										</div>
									</div>
								</>
							) : null}
							<div className="col-12 mt-2">
								<MyInputSelect
									name="room"
									behavior={{ required: false }}
									list={newSeniorStore.rooms}
									label={t("senior.__roomAssignment__")}
								/>
							</div>
						</div>

						<button type="submit" className="btn btn-primary btn-sm mt-3 mb-3">
							{t("common.__save__")}
						</button>
					</form>
				</FormProvider>
			)}
		</div>
	);
};

export default AddSenior;
