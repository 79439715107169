import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaAngleRight, FaCheckCircle, FaTimes, FaTimesCircle } from "react-icons/fa";
import http from "../../../services/Interceptor";
import * as p from "../../../constants/Permissions";
import * as url from "../../../constants/Url";
import VisioRefusedModal from "./VisioRefusedModal";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import ModalContainer from "../../../utils/ModalContainer";
import HandleError from "../../../layout/HandleError";

export default function VisioRequests({ data, userState }) {
	const { t } = useTranslation("common");
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const [show, setShow] = useState({ show: false, info: null, title: " " });
	const canManageMessages = userState.userConnected.is_in([p.ADMIN, p.SUPER_ADMIN, p.ALL_FAMILY_USER_MESSAGES]);

	const messages = data.map(function (msg) {
		return {
			...msg,
			family: msg.family_user ? `${msg.family_user.last_name.toUpperCase()} ${msg.family_user.first_name}` : null,
			seniorName: msg.senior ? `${msg.senior.last_name.toUpperCase()} ${msg.senior.first_name}` : null
		};
	});

	const visioIsAccepted = id => {
		setSuccess(false);
		setError(false);
		const data = {
			message_id: id
		};
		http
			.post(`${url.BASEURL}/homes/visio/accept`, data)
			.then(() => setSuccess(true))
			.catch(() => setError(true));
	};

	return (
		<div className="big-card">
			<div className="row">
				<div className="col-6">
					<h2>{t("mailbox.__visioRequests__")}</h2>
				</div>
				<div className="col-6"></div>
				{canManageMessages ? null : (
					<div className="col-12">
						<div className="error-msg">
							<b>{t("common.__permissionError__")}</b>
						</div>
						<div className="space"></div>
					</div>
				)}
			</div>
			<HandleError error={error} setError={setError} />
			{success ? (
				<p className="success-msg mt-2">
					<span className="right" role="button" tabIndex="0" onClick={() => setSuccess(false)}>
						<FaTimes />
					</span>
					{t("mailbox.__visioIsAccepted__")}
				</p>
			) : null}
			{data.length === 0 ? (
				<h5>
					<FaAngleRight className="mr-2" />
					{t("mailbox.__noMsg__")}
				</h5>
			) : (
				<table className="table panel-table">
					<tbody>
						{messages.map(message => (
							<tr key={message.id}>
								<td>
									<div className="mailbox">
										<p>
											<b className="blue">{message.family}</b>
											<br />
											<span className="meta">
												{t("common.__senior__")} : {message.seniorName}
											</span>
										</p>
										<p>
											{t("mailbox.__visioAsked__")}{" "}
											<b>{handleDate(message.visio_datetime, "PPPPp", userState.userConnected.locale)}</b>
										</p>
									</div>
								</td>
								{canManageMessages ? (
									<td style={{ textAlign: "center", width: "140px" }}>
										<FaTimesCircle
											className="panel-notification-no red"
											role="button"
											onClick={() => setShow({ show: true, info: message.id, title: t("mailbox.__vRefused__") })}
										/>
										<FaCheckCircle
											className="panel-notification-ok blue"
											role="button"
											onClick={() => visioIsAccepted(message.id)}
										/>
									</td>
								) : null}
							</tr>
						))}
					</tbody>
				</table>
			)}
			<ModalContainer show={show} setShow={setShow}>
				<VisioRefusedModal show={show} setShow={setShow} />
			</ModalContainer>
		</div>
	);
}
