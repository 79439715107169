import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import MyInputText from "../../utils/Form/MyInputText";
import MyInputEmail from "../../utils/Form/MyInputEmail";
import ErrorContainer from "../../layout/ErrorContainer";
import LanguageSwitcher from "../../i18n/LanguageSwitcher";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";

const UpdateUserAccount = ({ user, userDispatch, setEditMode, setUserData }) => {
	const { t } = useTranslation("common");
	const methods = useForm();
	const { handleSubmit } = methods;
	const [userLocale, setUserLocale] = useState(user.locale);
	const [httpResponse, setHttpResponse] = useState(null);

	const languages = [
		{
			id: 1,
			name: t("users.__french__"),
			locale: "fr"
		},
		{
			id: 2,
			name: t("users.__english__"),
			locale: "en"
		},
		{
			id: 3,
			name: t("users.__dutch__"),
			locale: "nl"
		}
	];

	const onSubmit = data => {
		const userData = {
			user: {
				first_name: data.firstName,
				last_name: data.lastName,
				phone: data.phone,
				email: data.email,
				locale: userLocale,
				mobile_phone: data.mobile
			}
		};
		http
			.put(`${url.BASEURL}/me`, userData)
			.then(res => {
				userDispatch({
					type: "UPDATE_MY_ACCOUNT",
					payload: res.data.user
				});
				if (res.data.user.locale === "fr") {
					localStorage.setItem("i18next", "fr-FR");
				} else if (res.data.user.locale === "en") {
					localStorage.setItem("i18next", "en-EN");
				}
				setUserData(res.data.user);
				setEditMode({ edit: false, success: true });
			})
			.catch(() => setHttpResponse({ error: true }));
	};

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<ErrorContainer
					alert={t("common.__editError__")}
					success={t("users.__editUserSuccess__")}
					httpResponse={httpResponse}
					setHttpResponse={setHttpResponse}
				/>

				<div className="row">
					<div className="col-md-6">
						<MyInputText
							label={t("common.__firstname__")}
							name="firstName"
							behavior={{ required: true }}
							value={user.first_name}
						/>{" "}
					</div>
					<div className="col-md-6">
						<MyInputText label={t("common.__name__")} name="lastName" behavior={{ required: true }} value={user.last_name} />
					</div>
					<div className="col-md-6">
						<MyInputEmail
							label={t("common.__mail__")}
							name="email"
							behavior={{ required: true, pattern: /^\S+@\S+\.\S+$/ }}
							value={user.email}
						/>
					</div>
					<div className="col-md-6">
						<MyInputText label={t("common.__phone__")} name="phone" behavior={{ required: false }} value={user.phone} />
					</div>
					<div className="col-md-6">
						<MyInputText
							label={t("common.__smartphone__")}
							name="mobile"
							behavior={{ required: false }}
							value={user.mobile_phone}
						/>
					</div>
					<div className="col-6">
						<label className="form-label">{t("common.__language__")}</label>
						<LanguageSwitcher userLocale={userLocale} setUserLocale={setUserLocale} languages={languages} />
					</div>
					<div className="col-12 mt-4">
						<button type="submit" className="btn btn-primary btn-sm">
							{t("common.__save__")}
						</button>
					</div>
				</div>
			</form>
		</FormProvider>
	);
};

export default UpdateUserAccount;
