import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DiaperContext } from "../context/DiaperContext";
import { DiaperInterface } from "../interfaces/DiaperInterface";
import { UserInterface } from "../interfaces/UserInterface";
import AccessControl from "../layout/AccessControl";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import DiaperSecondaryNav from "../layout/DiaperSecondaryNav";
import SelectHomeInput from "../layout/SelectHomeInput";
import HomeDiapersListUpdate from "../components/DiaperConnected/Diapers/HomeDiapersListUpdate";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

const DiapersReferenced_Update = ({ userState, homeId }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const { diaperStore, diaperDispatch } = useContext(DiaperContext);
	const diapers = diaperStore.diapers;
	const homeDiapersListIds = diaperStore.homeDiapersList;
	const homeDiapersList = useMemo(
		() => homeDiapersListIds.map((id: number) => diapers.find((diaper: DiaperInterface) => diaper.id === id)),
		[diapers, homeDiapersListIds]
	);
	const diapersNotReferenced = useMemo(
		() => diapers.filter((diaper: DiaperInterface) => !homeDiapersListIds.includes(diaper.id)),
		[diapers, homeDiapersListIds]
	);
	const [diapersReferenced, setDiapersReferenced] = useState([]);
	const [diapersToReference, setDiapersToReference] = useState([]);

	useEffect(() => {
		return function cleanup(): void {
			diaperDispatch({
				type: "RESET_ES"
			});
		};
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (diapers.length === 0) {
			http.get(`${url.BASEURL}/rht/diapers`).then(res => {
				diaperDispatch({
					type: "GET_DIAPERS",
					payload: res.data.diapers
				});
			});
		}
		// eslint-disable-next-line
	}, [diapers]);

	useEffect(() => {
		if (homeId) {
			http
				.get(`${url.HOMESURL}/${homeId}/home_diapers_list`)
				.then(res => {
					diaperDispatch({
						type: "GET_HOME_DIAPERS_LIST",
						payload: res.data.home_diapers_list
					});
				})
				.catch(() => {
					diaperDispatch({
						type: "GET_HOME_DIAPERS_LIST",
						payload: []
					});
				});
		}
		// eslint-disable-next-line
	}, [homeId]);

	useEffect(() => {
		setDiapersReferenced(homeDiapersList);
	}, [homeDiapersList, homeId]);

	useEffect(() => {
		setDiapersToReference(diapersNotReferenced);
	}, [diapersNotReferenced, homeId]);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.SUPER_ADMIN, p.ADMIN, p.DIAPER_APPLICATION]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<DiaperSecondaryNav homeId={homeId} userState={userState} />}
				component={
					homeId ? (
						<HomeDiapersListUpdate
							homeId={homeId}
							diaperStore={diaperStore}
							diaperDispatch={diaperDispatch}
							diapersNotReferenced={diapersNotReferenced}
							homeDiapersList={homeDiapersList}
							diapersReferenced={diapersReferenced}
							setDiapersReferenced={setDiapersReferenced}
							diapersToReference={diapersToReference}
							setDiapersToReference={setDiapersToReference}
						/>
					) : (
						<div className="big-card">
							<h2>{t("diaper.__referencedDiapers__")}</h2>
							<SelectHomeInput />
						</div>
					)
				}
			/>
		</AccessControl>
	);
};
export default DiapersReferenced_Update;
