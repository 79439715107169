import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";

export default function DirtyBedStatistics({ homeId, userState, startDate, endDate, selectedSenior }) {
	const { t } = useTranslation("common");
	const userLocale = userState.userConnected.locale;
	const [data, setData] = useState([]);

	useEffect(() => {
		if (startDate && endDate) {
			if (selectedSenior) {
				const data = {
					from: handleDate(startDate, "yyyy-MM-dd' '00:00:00"),
					to: handleDate(endDate, "yyyy-MM-dd' '23:59:59"),
					senior_id: parseInt(selectedSenior.id)
				};
				http
					.post(`${url.BASEURL}/rht/report/dirty_bed`, data)
					.then(res => {
						const result = res.data;
						const data = result.map(elt => {
							return {
								...elt,
								formatedDate: handleDate(elt.date, "P", userLocale)
							};
						});
						setData(data);
					})
					.catch(() => setData([]));
			} else if (homeId) {
				const data = {
					from: handleDate(startDate, "yyyy-MM-dd' '00:00:00"),
					to: handleDate(endDate, "yyyy-MM-dd' '23:59:59"),
					home_id: homeId
				};
				http
					.post(`${url.BASEURL}/rht/report/dirty_bed`, data)
					.then(res => {
						const result = res.data;
						const data = result.map(elt => {
							return {
								...elt,
								formatedDate: handleDate(elt.date, "P", userLocale)
							};
						});
						setData(data);
					})
					.catch(() => setData([]));
			} else {
				return;
			}
		}
	}, [homeId, startDate, endDate, selectedSenior, userLocale]);

	return (
		<div className="big-card mt-2 mb-3">
			<h5>{t("diaper.__dirtBedCounter__")}</h5>

			<div style={{ width: "100%", height: 325 }}>
				<ResponsiveContainer>
					<BarChart data={data} margin={{ top: 10, right: 5, left: 5, bottom: 10 }}>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="formatedDate" />
						<YAxis />
						<Tooltip />
						<Bar
							dataKey="count"
							stroke="#00a0e3"
							fill="#00a0e3"
							fillOpacity={0.8}
							radius={[5, 5, 0, 0]}
							name={t("diaper.__dirtyBed__")}
						/>
					</BarChart>
				</ResponsiveContainer>
			</div>
		</div>
	);
}
