import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import MyInputText from "../../utils/Form/MyInputText";
import MyInputSelect from "../../utils/Form/MyInputSelect";
import DisplayName from "../../utils/DisplayName";
import http from "../../services/Interceptor";
import * as url from "../../constants/Url";
import HandleError from "../../layout/HandleError";

const EditDevice = ({ editMode, setEditMode, devicesStore, devicesDispatch, deviceSNType }) => {
	const { t } = useTranslation("common");
	const deviceId = editMode.id;
	const devices = devicesStore.devices.devices;
	const oneDevice = devices.find(device => device.id === deviceId);
	const [changeHome, setChangeHome] = useState(false);
	const [error, setError] = useState(false);
	const methods = useForm();
	const { handleSubmit } = methods;
	const [allHomes, setAllHomes] = useState([]);

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			http
				.get(`${url.BASEURL}/homes`)
				.then(res => setAllHomes(res.data.homes))
				.catch(() => setAllHomes([]));
		}
		return () => (mounted = false);
	}, []);

	const onSubmit = data => {
		setError(false);
		let deviceData = {};
		if (changeHome) {
			deviceData = {
				device: {
					home_id: data.homeId,
					serial_number_type: data.type,
					serial_number: data.serialNumber
				}
			};
		} else {
			deviceData = {
				device: {
					serial_number_type: data.type,
					serial_number: data.serialNumber
				}
			};
		}

		http
			.put(`${url.BASEURL}/devices/${deviceId}`, deviceData)
			.then(res => {
				devicesDispatch({
					type: "EDIT_DEVICE",
					payload: res.data.device
				});
				devicesDispatch({
					type: "DISPLAY_SUCCESS",
					payload: t("device.__editSuccess__")
				});
			})
			.then(() => setEditMode({ edit: false, id: null }))
			.catch(() => setError(true));
	};

	return (
		<div className="small-form">
			<h5>{t("device.__editMode__")} :</h5>

			<HandleError error={error} dispatch={devicesDispatch} message={t("common.__addError__")} />

			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="form-group">
						{changeHome === false ? (
							<>
								<span className="float-right">
									<button className="btn btn-sm btn-outline-primary" type="button" onClick={() => setChangeHome(true)}>
										{t("device.__updateHome__")}
									</button>
								</span>
								<p>
									{t("device.__affectation__")} : <strong>{DisplayName(oneDevice.home_id, allHomes)}</strong>{" "}
								</p>
							</>
						) : (
							<MyInputSelect
								label={t("common.__home__")}
								name="homeId"
								behavior={{ required: true }}
								list={allHomes}
								value={oneDevice.home_id}
							/>
						)}
						<MyInputSelect
							label={t("common.__type__")}
							name="type"
							behavior={{ required: true }}
							list={deviceSNType}
							value={oneDevice.serial_number_type}
						/>
						<MyInputText
							label={t("device.__serialNumber__")}
							name="serialNumber"
							behavior={{ required: true }}
							value={oneDevice.serial_number}
						/>
					</div>
					<div className="mt-4">
						<button
							className="btn btn-outline-secondary btn-sm mr-2"
							onClick={() => setEditMode({ edit: false, id: null, success: false })}
						>
							{t("common.__cancel__")}
						</button>
						<button className="btn btn-primary btn-sm" type="submit">
							{t("common.__update__")}
						</button>
					</div>
				</form>
			</FormProvider>
		</div>
	);
};

export default EditDevice;
