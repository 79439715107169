import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaCheck, FaGripVertical, FaSync } from "react-icons/fa";
import { Table } from "../../../utils/Table/TableWithFilter";
import { Link } from "react-router-dom";
import { SeniorInterface } from "../../../interfaces/UserInterface";
import ModalContainer from "../../../utils/ModalContainer";
import AmdSeniorDotation from "./AmdSeniorDotation";
import AmdSeniorSyncInput from "./AmdSeniorSyncInput";
import NoData from "../../../layout/NoData";

interface Props {
	seniors: SeniorInterface[];
	editSeniorPartnerId: {
		show: boolean;
		info: {
			id: number;
			partnerId: number;
		} | null;
		title: string;
		success: boolean;
	};
	setEditSeniorPartnerId: (args: any) => void;
}

const AmdSeniorSyncContainer = ({ seniors, editSeniorPartnerId, setEditSeniorPartnerId }: Props): JSX.Element => {
	const { t } = useTranslation("common");
	const [show, setShow] = useState({ show: false, info: null, title: "" });

	const columns = [
		{
			Header: t("common.__senior__"),
			accessor: "name",
			Cell: (row: any) => (
				<Link to={`/senior/profile/${row.row.original.id}`}>
					<p className="category-outline-badge blue">{row.row.values.name}</p>
				</Link>
			)
		},
		{
			Header: "AMD ID Résident",
			accessor: "senior_partner_id",
			Cell: (row: any) => (row.row.values.senior_partner_id ? <p>{row.row.values.senior_partner_id}</p> : null)
		},
		{
			Header: "",
			accessor: "id",
			disableFilters: true,
			minWidth: 30,
			width: 40,
			maxWidth: 50,
			Cell: (row: any) => (
				<div className="center">
					{editSeniorPartnerId.show &&
					editSeniorPartnerId.info &&
					row.row.values.id === editSeniorPartnerId.info.id ? null : row.row.values.senior_partner_id ? (
						<>
							<FaCheck
								className={row.row.original.provisions.length > 0 ? "big-check-icon mr-2" : "big-sync-icon"}
								role="button"
								onClick={(): void =>
									setEditSeniorPartnerId({
										show: true,
										info: { id: row.row.values.id, partnerId: row.row.values.senior_partner_id },
										title: row.row.values.name,
										success: false
									})
								}
							/>
							{row.row.original.provisions.length > 0 ? (
								<FaGripVertical
									className="big-sync-icon"
									onClick={(): void => setShow({ show: true, info: row.row.original, title: row.row.values.name })}
									role="button"
								/>
							) : null}
						</>
					) : (
						<FaSync
							role="button"
							className="big-sync-icon"
							onClick={(): void =>
								setEditSeniorPartnerId({
									show: true,
									info: { id: row.row.values.id, partnerId: row.row.values.senior_partner_id },
									title: row.row.values.name,
									success: false
								})
							}
						/>
					)}
				</div>
			)
		}
	];

	return seniors.length > 0 ? (
		<>
			<Table columns={columns} data={seniors} />
			<ModalContainer show={editSeniorPartnerId} setShow={setEditSeniorPartnerId}>
				<AmdSeniorSyncInput show={editSeniorPartnerId} setShow={setEditSeniorPartnerId} />
			</ModalContainer>
			<ModalContainer show={show} setShow={setShow}>
				<AmdSeniorDotation show={show} />
			</ModalContainer>
		</>
	) : (
		<NoData />
	);
};
export default AmdSeniorSyncContainer;
