import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, FormProvider } from "react-hook-form";
import MyInputText from "../../../utils/Form/MyInputText";
import { useHistory } from "react-router-dom";
import http from "../../../services/Interceptor";
import * as url from "../../../constants/Url";
import HeaderContainer from "../../../layout/HeaderContainer";
import * as p from "../../../constants/Permissions";
import HandleError from "../../../layout/HandleError";

const AddTenant = ({ userState }) => {
	const { t } = useTranslation("common");
	const [error, setError] = useState(false);
	const methods = useForm();
	const { handleSubmit } = methods;
	const history = useHistory();

	const onSubmit = values => {
		setError(false);
		const data = {
			tenant: {
				name: values.name,
				description: values.description
			}
		};
		http
			.post(`${url.BASEURL}/tenants`, data)
			.then(() => {
				setError(false);
				history.goBack();
			})
			.catch(() => setError(true));
	};

	return (
		<div className="big-card">
			<HeaderContainer
				title={t("common.__tenants__")}
				addButton={t("common.__add__")}
				link={"/support/tenant/admintenants/add"}
				permissionNeeded={[p.SUPER_ADMIN]}
				userState={userState}
			/>

			<HandleError error={error} setError={setError} />

			<h5 className="blue">{t("common.__addTenant__")} :</h5>
			<FormProvider {...methods}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<MyInputText label={t("common.__name__")} name="name" behavior={{ required: true }} />
					<MyInputText label={t("common.__description__")} name="description" behavior={{ required: true }} />
					<button className="btn btn-primary btn-sm" type="submit">
						{t("common.__save__")}
					</button>
				</form>
			</FormProvider>
		</div>
	);
};

export default AddTenant;
