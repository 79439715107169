import React from "react";
import { FaEnvelope, FaRegEnvelopeOpen } from "react-icons/fa";
import DisplayName from "../../../utils/DisplayName";
import * as constant from "../../../constants/TypesConstants";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import useStoreData from "../../../hooks/useStoreData";

export default function DisplayThread({ thread, family, userState, homeId }) {
	const { store } = useStoreData({ userState, homeId });
	const users = store.users.healthWorkers;

	return thread.map(msg => (
		<div key={msg.id} className={msg.type === constant.MSG_FROM_FAMILY_TO_USER ? "family-card" : "response-card"}>
			<p className="meta right">{handleDate(msg.datetime, "PPPp", userState.userConnected.locale)}</p>
			<p className="d-flex align-items-center">
				{msg.unread ? <FaEnvelope className="unread-icon red" /> : <FaRegEnvelopeOpen className="unread-icon" />}
				{msg.type === constant.MSG_FROM_FAMILY_TO_USER ? <b>{family}</b> : null}
				{msg.type === constant.MSG_FROM_USER_TO_FAMILY ? <b>{DisplayName(msg.user_id, users)}</b> : null}
			</p>
			<div className="light-separator"></div>
			<p className="thread-msg">{msg.message}</p>
		</div>
	));
}
