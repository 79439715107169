import React from "react";
import { useTranslation } from "react-i18next";
import { FaTabletAlt } from "react-icons/fa";
import { RoomInterface } from "../../interfaces/HomeInterface";
import { Tooltip } from "./Tooltip";

interface Props {
	room: RoomInterface;
	canManageHomeUnit: boolean;
	setShowDevice: (data: {
		show: boolean;
		info: { room: RoomInterface | null };
		title: string;
		success: boolean;
	}) => void;
}

const DeviceIcon = ({ room, canManageHomeUnit, setShowDevice }: Props): JSX.Element => {
	const { t } = useTranslation("common");

	return (
		<>
			{room.device_id ? (
				canManageHomeUnit ? (
					<Tooltip text="Tablette">
						<FaTabletAlt
							className="device-icon fake-link"
							role="button"
							onClick={(): void =>
								setShowDevice({
									show: true,
									success: false,
									info: { room: room },
									title: `${t("common.__room__")} ${room.name}`
								})
							}
						/>
					</Tooltip>
				) : (
					<FaTabletAlt className="device-icon" />
				)
			) : canManageHomeUnit ? (
				<FaTabletAlt
					className="no-device-in-room fake-link"
					role="button"
					onClick={(): void =>
						setShowDevice({
							show: true,
							success: false,
							info: { room: room },
							title: `${t("common.__room__")} ${room.name}`
						})
					}
					title="tablette"
				/>
			) : (
				<FaTabletAlt className="no-device-in-room" />
			)}
		</>
	);
};
export default DeviceIcon;
