import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import OnlyTimePicker from "../../../utils/DatePicker/OnlyTimePicker";
import OnlyDatePicker from "../../../utils/DatePicker/OnlyDatePicker";
import SaturationStatistics from "./SaturationStatistics";
import SeniorWithDiapersDetails from "./SeniorWithDiaperDetails";
import SeniorsSaturationsList from "./SeniorsSaturationsList";
import SeniorWithDiaperSaturationChart from "./SeniorWithDiaperSaturationChart";
import SaturationBoards from "./SaturationBoards";

export default function SeniorsSaturationStatistics({
	userState,
	startDate,
	endDate,
	saturationValues,
	setStartDate,
	setEndDate,
	startTime,
	setStartTime,
	endTime,
	setEndTime,
	statistics,
	homeId,
	delay,
	setDelay,
	setStatistics,
	setSaturationValues,
	seniorsWithSensor,
	selectedSenior,
	setSelectedSenior
}) {
	const { t } = useTranslation("common");
	const repartition = statistics ? statistics.range_percentages : [];
	const userLocale = userState.userConnected.locale;

	return (
		<>
			<div className="big-card dont-print-this">
				<h2>{t("diaper.__saturationMonitoring__")}</h2>
				<div className="row mt-3">
					<div className="col-sm-2 mt-2">
						<p>
							<b>{t("common.__dates__")} : </b>
						</p>
					</div>
					<div className="col-sm-5 mt-2">
						<OnlyDatePicker startDate={startDate} setStartDate={setStartDate} userLocale={userLocale} />
					</div>
					<div className="col-sm-5 mt-2">
						<OnlyTimePicker startTime={startTime} setStartTime={setStartTime} userLocale={userLocale} />
					</div>
					<div className="col-sm-2">
						<p>{t("common.__toward__")}</p>
					</div>
					<div className="col-sm-5">
						<OnlyDatePicker startDate={endDate} setStartDate={setEndDate} userLocale={userLocale} />
					</div>
					<div className="col-sm-5">
						<OnlyTimePicker startTime={endTime} setStartTime={setEndTime} userLocale={userLocale} />
					</div>
					<div className="col-12">
						<p className="meta">{t("common.__filterBySenior__")} :</p>
						<Select
							placeholder={t("senior.__selectSenior__")}
							name="seniors"
							options={seniorsWithSensor}
							className="basic-multi-select mb-2 mt-3"
							classNamePrefix="select"
							onChange={e => setSelectedSenior(e)}
							isClearable
							value={selectedSenior ? selectedSenior : 0}
						/>
					</div>
				</div>
			</div>

			<SaturationBoards statistics={statistics} />

			{selectedSenior ? (
				<SeniorWithDiaperSaturationChart
					seniorId={selectedSenior.value}
					startDate={startDate}
					endDate={endDate}
					startTime={startTime}
					endTime={endTime}
					userLocale={userState.userConnected.locale}
				/>
			) : null}

			<SaturationStatistics
				startDate={startDate}
				endDate={endDate}
				startTime={startTime}
				endTime={endTime}
				homeId={homeId}
				delay={delay}
				setDelay={setDelay}
				repartition={repartition}
				setStatistics={setStatistics}
				setSaturationValues={setSaturationValues}
			/>

			{saturationValues.length > 0 ? (
				<div className="big-card">
					<h5>{t("diaper.__antechronologicalList__")}</h5>
					{selectedSenior ? (
						<SeniorWithDiapersDetails saturationValues={saturationValues} userState={userState} />
					) : (
						<SeniorsSaturationsList saturationValues={saturationValues} userState={userState} />
					)}
				</div>
			) : null}
		</>
	);
}
