import React from "react";
import { FaEnvelope, FaRegEnvelopeOpen } from "react-icons/fa";
import * as constant from "../../constants/TypesConstants";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { handleDate } from "@e4ia/e4link_modules.datetime";

export default function OnlyThread({ threadMessages, subject, userLocale }) {
	const { t } = useTranslation("common");

	const handleCard = type => {
		switch (type) {
			case constant.MSG_FROM_FAMILY_TO_USER:
				return "family-card";
			case constant.MSG_FROM_USER_TO_FAMILY:
				return "response-card";
			case constant.MULTI_MSG_FROM_USER_TO_FAMILY:
				return;
			default:
				return "form-card";
		}
	};

	const handleMultiRecipients = targets => {
		let families = targets.map(family => family.family_user);
		const ids = families.map(elt => elt.id);
		const filteredFamilies = families.filter(({ id }, index) => !ids.includes(id, index + 1));
		return filteredFamilies.map(family => (
			<span key={family.id} className="category-outline-badge grey">
				{family.last_name.toUpperCase()} {family.first_name}
			</span>
		));
	};

	return (
		<>
			{subject ? (
				<p className="modal-title">
					{t("mailbox.__object__")} : {subject}
				</p>
			) : null}
			{threadMessages.map(thread => (
				<div key={thread.id} className={handleCard(thread.type)}>
					<p className="meta right">{handleDate(thread.datetime, "PPPp", userLocale)}</p>
					<p className="d-flex align-items-center">
						{thread.type === constant.MSG_FROM_FAMILY_TO_USER ? (
							<>
								{thread.unread ? <FaEnvelope className="unread-icon red" /> : <FaRegEnvelopeOpen className="unread-icon" />}
								<b>
									{t("mailbox.__from__")} : {thread.family_user.last_name.toUpperCase()} {thread.family_user.first_name}
								</b>
							</>
						) : null}
						{thread.type === constant.MSG_FROM_USER_TO_FAMILY || thread.type === constant.MULTI_MSG_FROM_USER_TO_FAMILY ? (
							<>
								{t("common.__begining__")} :{" "}
								<Link to={`/user/healthworker/${thread.user.id}`} className="category-outline-badge blue">
									<b>
										{thread.user.first_name} {thread.user.last_name}
									</b>
								</Link>
							</>
						) : null}
					</p>

					{thread.type === constant.MSG_FROM_FAMILY_TO_USER ? (
						<p className="meta">
							{t("mailbox.__seniorFamily__")} :
							<Link to={`/senior/profile/${thread.senior.id}`} className="category-outline-badge grey">
								{thread.senior.last_name.toUpperCase()} {thread.senior.first_name}
							</Link>
						</p>
					) : null}
					{thread.type === constant.MULTI_MSG_FROM_USER_TO_FAMILY ? (
						<>
							{t("common.__recipients__")} :{handleMultiRecipients(thread.targets)}
						</>
					) : null}
					<div className="light-separator mt-3"></div>
					<p className="thread-msg">{thread.message}</p>
				</div>
			))}
		</>
	);
}
