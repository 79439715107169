import React, { useEffect, useReducer } from "react";
import { handleDate } from "@e4ia/e4link_modules.datetime";
import { SeniorInterface, UserInterface } from "../interfaces/UserInterface";
import useStoreData from "../hooks/useStoreData";
import useDateBeforeOrAfter from "../hooks/useDateBeforeOrAfter";
import PageWithSecondaryNavLayout from "../layout/PageWithSecondaryNavLayout";
import DiaperSecondaryNav from "../layout/DiaperSecondaryNav";
import AccessControl from "../layout/AccessControl";
import SelectHomeInput from "../layout/SelectHomeInput";
import ChangeStatistics from "../components/DiaperConnected/ChangeStatistics/ChangeStatistics";
import http from "../services/Interceptor";
import * as url from "../constants/Url";
import * as p from "../constants/Permissions";

interface Props {
	homeId: number | null;
	userState: UserInterface;
}

interface InitialStateProps {
	loading: boolean;
	startDate: Date;
	endDate: Date;
	startTime: Date;
	endTime: Date;
	selectedSenior: SeniorInterface | null;
	error: boolean;
	data: any | null;
}

export const DiaperChangeReducer = (state: InitialStateProps, action: { type: string; payload?: any }): any => {
	switch (action.type) {
		case "LOADING":
			return {
				...state,
				loading: true
			};
		case "START_DATE":
			return {
				...state,
				startDate: action.payload
			};
		case "END_DATE":
			return {
				...state,
				endDate: action.payload
			};
		case "START_TIME":
			return {
				...state,
				startTime: action.payload
			};
		case "END_TIME":
			return {
				...state,
				endTime: action.payload
			};
		case "SET_SENIOR":
			return {
				...state,
				selectedSenior: action.payload
			};
		case "GET_DATA":
			return {
				...state,
				data: action.payload,
				loading: false
			};
		default:
			return state;
	}
};

const DiaperChangeStatistics = ({ homeId, userState }: Props): JSX.Element => {
	const { store } = useStoreData({ userState, homeId });
	const isDiaperManager = userState.userConnected.is_in([p.SUPER_ADMIN, p.ADMIN, p.DIAPER_APPLICATION]);
	const seniorsFromHome = store.seniors.seniorsFromHome;

	const initialState = {
		startDate: useDateBeforeOrAfter({ isBefore: true, numberOfDays: 1 }),
		endDate: new Date(),
		startTime: useDateBeforeOrAfter({ isBefore: true, numberOfDays: 1 }),
		endTime: new Date(),
		selectedSenior: null,
		loading: false,
		error: false,
		data: null
	};

	const [changeStatisticStore, changeStatisticDispatch] = useReducer(DiaperChangeReducer, initialState);

	useEffect(() => {
		let mounted = true;
		if (homeId && isDiaperManager && mounted) {
			changeStatisticDispatch({ type: "LOADING" });
			const startDateFormatted = handleDate(changeStatisticStore.startDate, "yyyy-MM-dd");
			const endDateFormatted = handleDate(changeStatisticStore.endDate, "yyyy-MM-dd");
			const startTimeFormatted = handleDate(changeStatisticStore.startTime, "HH:mm:ss");
			const endTimeFormatted = handleDate(changeStatisticStore.endTime, "HH:mm:ss");

			const data = changeStatisticStore.selectedSenior
				? {
						from: `${startDateFormatted} ${startTimeFormatted}`,
						to: `${endDateFormatted} ${endTimeFormatted}`,
						senior_id: changeStatisticStore.selectedSenior.id
				  }
				: {
						from: `${startDateFormatted} ${startTimeFormatted}`,
						to: `${endDateFormatted} ${endTimeFormatted}`,
						home_id: homeId
				  };
			http
				.post(`${url.BASEURL}/rht/report/change_evals`, data)
				.then(res => changeStatisticDispatch({ type: "GET_DATA", payload: res.data }))
				.catch(() => changeStatisticDispatch({ type: "GET_DATA", payload: null }));
		}
		return function cleanup() {
			mounted = false;
		};
	}, [
		homeId,
		isDiaperManager,
		changeStatisticStore.startDate,
		changeStatisticStore.endDate,
		changeStatisticStore.startTime,
		changeStatisticStore.endTime,
		changeStatisticStore.selectedSenior
	]);

	return (
		<AccessControl
			userPermissions={userState.userConnected.permissions}
			permissionNeeded={[p.ADMIN, p.SUPER_ADMIN, p.DIAPER_APPLICATION]}>
			<PageWithSecondaryNavLayout
				secondaryNav={<DiaperSecondaryNav homeId={homeId} userState={userState} />}
				component={
					homeId ? (
						<ChangeStatistics
							userState={userState}
							homeId={homeId}
							seniorsFromHome={seniorsFromHome}
							changeStatisticStore={changeStatisticStore}
							changeStatisticDispatch={changeStatisticDispatch}
						/>
					) : (
						<SelectHomeInput />
					)
				}
			/>
		</AccessControl>
	);
};
export default DiaperChangeStatistics;
